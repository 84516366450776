import React, { useState, useContext, useEffect } from 'react'

import {
  EuiButton,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiSpacer,
  EuiTableRowCell,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiAvatar,
  EuiTabs,
} from '@elastic/eui'

import { LEFT_ALIGNMENT, RIGHT_ALIGNMENT } from '@elastic/eui/lib/services'

import { api } from '../../lib'
import { AppContext } from '../../app'
import { AdminPageContext } from './admin_page'
import { ConfirmDeleteModal } from '..'
import { AdminTable } from '..'
import { StatCount } from '../stats/stat_count'

export const UsersPage = () => {
  const {
    users,
    toaster,
    usersPagination,
    keycloak,
    usersLastQtr
  } = useContext(AppContext)

  const {
    updateUser,
    loadUsers,
    renderAdminTabs,
    usersSearch,
    adminState,
    adminDispatch
  } = useContext(AdminPageContext)

  const [loading, setLoading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [itemIdToOpenActionsPopoverMap, setItemIdToOpenActionsPopoverMap] =
    useState({})

  const setSearch = (value) => adminDispatch({ type: 'set', state: 'usersSearch', value })
  const addUser = () => adminDispatch({ type: 'set', state: 'userModal', value: 'create' })
  const viewUser = (user) => adminDispatch({ type: 'view_user', user })
  const editUser = (user) => adminDispatch({ type: 'edit_user', user })

  for (var i = 0; i < users.length; i++) {
    let user = users[String(i)]
    users[String(i)].index = i
    users[String(i)].avatar = user.name
  }

  useEffect(() => {
    loadUsers({ reset: true })
  }, [])

  const cancelDelete = () => {
    setShowDeleteModal(false)
    adminDispatch({ type: 'reset_user' })
    setItemIdToOpenActionsPopoverMap({})
  }

  const deleteUsers = async (ids) => {
    if (!ids.length) return
    try {
      setItemIdToOpenActionsPopoverMap({})
      setShowDeleteModal(false)
      setLoading(true)

      const resp = await api.deleteUsers(ids)
      if (resp.data.errors?.length) {
        toaster({
          color: 'danger',
          title: 'Oops!',
          text: 'There was a problem deleting some users.',
        })
        return
      }

      loadUsers({ reset: true, search: usersSearch })

      toaster({
        color: 'success',
        title: `${ids.length > 1 ? 'Users' : 'User'} succesfully deleted.`,
      })

    }
    catch (err) {
      console.log(err)
      toaster({
        color: 'danger',
        title: 'Unable to delete users.',
        text: err.message,
      })
    }
    finally {
      setLoading(false)
      adminDispatch({ type: 'reset_user' })
    }
  }

  const columns = [
    {
      id: 'avatar',
      label: '',
      alignment: LEFT_ALIGNMENT,
      width: '52px',
      cellProvider: (cell) => cell && <EuiAvatar name={cell} size='s' />,
      mobileOptions: {
        show: false,
      },
    },
    {
      id: 'name',
      label: 'Name',
      alignment: LEFT_ALIGNMENT,
      isSortable: true,
      mobileOptions: {
        show: false,
      },
    },

    {
      id: 'email',
      label: 'Email',
      footer: '',
      alignment: LEFT_ALIGNMENT,
    },
    {
      id: 'created_at',
      label: 'Date Created',
      alignment: LEFT_ALIGNMENT,
      isSortable: true,
    },
    {
      id: 'updated_at',
      label: 'Last Updated',
      alignment: LEFT_ALIGNMENT,
      isSortable: true,
    },
    {
      id: 'actions',
      label: '',
      alignment: RIGHT_ALIGNMENT,
      isActionsPopover: keycloak.hasClientRole({ roleName: 'edit' }) ? true : false,
      width: '58px',
    },
  ]

  const actionsPopover = (column, item) => {
    return (
      <EuiTableRowCell
        key={column.id}
        header={column.label}
        textOnly={false}
        hasActions={true}
        align='right'
        style={{ zIndex: 999 }}
      >
        <EuiPopover
          id={`${item.id}-actions`}
          button={
            <EuiButtonIcon
              aria-label='Actions'
              iconType='gear'
              size='s'
              color='text'
              onClick={e => {
                e.stopPropagation()
                togglePopover(item.id)
              }}
            />
          }
          isOpen={isPopoverOpen(item.id)}
          closePopover={() => closePopover(item.id)}
          panelPaddingSize='none'
          anchorPosition='leftCenter'
        >
          <EuiContextMenuPanel
            items={[
               <EuiContextMenuItem
                key='A'
                icon='pencil'
                onClick={e => {
                  e.stopPropagation()
                  togglePopover(item.id)
                  editUser(item)
                }}
              >
                Edit
              </EuiContextMenuItem>,
              <EuiContextMenuItem
                key='B'
                icon='trash'
                onClick={e => {
                  e.stopPropagation()
                  togglePopover(item.id)
                  updateUser(item)
                  setShowDeleteModal(true)
                }}
              >
                Delete
              </EuiContextMenuItem>,
            ]}
          />
        </EuiPopover>
      </EuiTableRowCell>
    )
  }

  const togglePopover = (itemId) => {
    const newItemIdToOpenActionsPopoverMap = {
      ...itemIdToOpenActionsPopoverMap,
      [itemId]: !itemIdToOpenActionsPopoverMap[String(itemId)],
    }

    setItemIdToOpenActionsPopoverMap(newItemIdToOpenActionsPopoverMap)
  }

  const closePopover = (itemId) => {
    if (isPopoverOpen(itemId)) {
      const newItemIdToOpenActionsPopoverMap = {
        ...itemIdToOpenActionsPopoverMap,
        [itemId]: false,
      }

      setItemIdToOpenActionsPopoverMap(newItemIdToOpenActionsPopoverMap)
    }
  }

  const isPopoverOpen = (itemId) => {
    return itemIdToOpenActionsPopoverMap[String(itemId)]
  }

  return (
    <>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle size='m'>
            <h1>User Accounts</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>


      <EuiFlexGroup gutterSize='s'>
        <EuiFlexItem style={{ justifyContent: 'center' }}>
          <EuiTabs>
            {renderAdminTabs()}
          </EuiTabs>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup gutterSize='s' style={{ alignItems: 'center' }}>
            <EuiFlexItem grow={false}>
              <StatCount
                title={usersPagination.total ? usersPagination.total : 0}
                description='Total'
                tooltip={`total users: ${usersPagination.total ? usersPagination.total : 0}`}
                color='secondary'
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <StatCount
                title={usersLastQtr ? usersLastQtr : 0}
                description='Last Qtr.'
                tooltip={`new users: ${usersLastQtr ? usersLastQtr : 0}`}
                color='secondary'
              />
            </EuiFlexItem>
            {keycloak.hasClientRole({ roleName: 'edit' }) &&
              <EuiFlexItem grow={false}>
                <EuiButton
                  fill
                  color='primary'
                  iconType='listAdd'
                  onClick={addUser}
                >
                  Add User
                </EuiButton>
              </EuiFlexItem>
            }
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='m' />

      <EuiFlexGroup gutterSize='m'>
        <EuiFlexItem>
          <EuiFieldSearch
            fullWidth
            placeholder='Search...'
            value={adminState.usersSearch}
            onChange={(e) => {
              setSearch(e.target.value)
              if (e.target.value === '') {
                loadUsers({ reset: true })
              }
            }}
            onSearch={(search) => {
              setSearch(search)
              if (search === '') return
              loadUsers({ search: adminState.usersSearch, reset: true })
            }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='m' />

      <AdminTable
        items={users}
        columns={columns}
        tablePagination={usersPagination}
        loadMore={loadUsers}
        loading={loading}
        actionsPopover={actionsPopover}
        search={adminState.usersSearch}
        onRowClick={viewUser}
      />
      <ConfirmDeleteModal
        showDeleteModal={showDeleteModal}
        cancelDelete={cancelDelete}
        title='Confirm Delete'
        deleteUsers={deleteUsers}
        activeUser={adminState.activeUser}
      />
    </>
  )
}
