import React, { useState, useContext } from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSuperSelect,
  EuiTitle,
  EuiSpacer,
} from '@elastic/eui'

import { GroupsPageContext } from './groups_page'

export const CreateGroupeRule = ({ newRule, updateNewRule }) => {
  const { groupsState } = useContext(GroupsPageContext)

  const group = groupsState.activeGroup

  const getEntities = (entityType) => {
    if (!group.entities?.length) {
      return []
    }
    return group.entities.filter(e => e.entityType === entityType)
  }

  const resourceOptions = [
    {
      value: 'project',
      inputDisplay: <>Project</>,
      'data-test-subj': 'option-project',
    },
    {
      value: 'useCase',
      inputDisplay: <>Use Case</>,
      'data-test-subj': 'option-useCase',
    },
    {
      value: 'sender',
      inputDisplay: <>Sender</>,
      'data-test-subj': 'option-sender',
    },
    {
      value: 'subsender',
      inputDisplay: <>Subsender</>,
      'data-test-subj': 'option-subsender',
    },
    {
      value: 'recipient',
      inputDisplay: <>Recipient</>,
      'data-test-subj': 'option-recipient',
    },
  ]

  const actionOptions = [
    {
      value: 'read',
      inputDisplay: <>Read</>,
      'data-test-subj': 'option-read',
    },
  ]

  const accessOptions = [
    {
      value: 'allow',
      inputDisplay: <>Allow</>,
      'data-test-subj': 'option-allow',
    },
    {
      value: 'deny',
      inputDisplay: <>Deny</>,
      'data-test-subj': 'option-deny',
    },
  ]

  const resourceNames = (value) => {
    switch (value) {
      case 'project': {
        const projects = getEntities('project')
        if (!projects?.length)
          return [{ value: null, inputDisplay: 'No projects available' }]
        return projects.map((project) => ({
          value: `${project.entityName}`,
          inputDisplay: <>{project.displayName || project.entityName}</>,
          'data-test-subj': `option-${project.entityName}`,
        }))
      }
      case 'useCase': {
        const useCases = getEntities('useCase')
        if (!useCases?.length)
          return [{ value: null, inputDisplay: 'No use cases available' }]
        return useCases.map((useCase) => ({
          value: `${useCase.entityName}`,
          inputDisplay: <>{useCase.displayName || useCase.entityName}</>,
          'data-test-subj': `option-${useCase.entityName}`,
        }))
      }
      case 'sender': {
        const senders = getEntities('sender')
        let senderOptions = senders
          .reduce((agg, sender) => {
            agg.push({
              value: `${sender.entityName}`,
              inputDisplay: <>{sender.displayName || sender.entityName}</>,
              'data-test-subj': `option-${sender.entityName}`,
            })
            return agg
          }, [])
        if (!senderOptions.length) {
          senderOptions.push({ value: null, inputDisplay: 'No senders available' })
        }
        return senderOptions
      }
      case 'subsender': {
        const subsenders = getEntities('subsender')
        if (!subsenders?.length)
          return [{ value: null, inputDisplay: 'No subsenders available' }]
        return subsenders.reduce((agg, sender) => {
          agg.push({
            value: `${sender.entityName}`,
            inputDisplay: <>{sender.displayName || sender.entityName}</>,
            'data-test-subj': `option-${sender.entityName}`,
          })
          return agg
        }, [])
      }
      case 'recipient': {
        const recipients = getEntities('recipient')
        let recipientOptions = recipients
          .map((recipient) => ({
            value: `${recipient.entityName}`,
            inputDisplay: <>{recipient.displayName || recipient.entityName}</>,
            'data-test-subj': `option-${recipient.entityName}`,
          }))
        if (!recipientOptions.length) {
          recipientOptions.push({ value: null, inputDisplay: 'No recipients available' })
        }
        return recipientOptions
      }
      default:
        break
    }
  }

  const [resourceValue, setResourceValue] = useState(newRule.resourceType)
  const [resourceNameValue, setResourceNameValue] = useState(
    newRule.resourceName
  )
  const [actionValue, setActionValue] = useState(newRule.action)
  const [accessValue, setAccessValue] = useState(newRule.effect)

  const onChangeResource = (value) => {
    setResourceValue(value)
    setResourceNameValue(resourceNames(value)[0].value)
    updateNewRule({
      ...newRule,
      resourceType: value,
      resourceName: resourceNames(value)[0].value,
    })
  }

  const onChangeResourceName = (value) => {
    setResourceNameValue(value)
    updateNewRule({ ...newRule, resourceName: value })
  }

  const onChangeAction = (value) => {
    setActionValue(value)
    updateNewRule({ ...newRule, action: value })
  }

  const onChangeAccess = (value) => {
    setAccessValue(value)
    updateNewRule({ ...newRule, effect: value })
  }

  return (
    <div>
      <EuiFlexGroup gutterSize='m'>
        <EuiFlexItem>
          <EuiTitle size='xxxs' style={{ textAlign: 'left' }}>
            <h6>Resource</h6>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiSuperSelect
            options={resourceOptions}
            valueOfSelected={resourceValue}
            onChange={(value) => onChangeResource(value)}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size='xxxs' style={{ textAlign: 'left' }}>
            <h6>Name</h6>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiSuperSelect
            options={resourceNames(resourceValue)}
            valueOfSelected={resourceNameValue}
            onChange={(value) => onChangeResourceName(value)}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size='xxxs' style={{ textAlign: 'left' }}>
            <h6>Action</h6>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiSuperSelect
            options={actionOptions}
            valueOfSelected={actionValue}
            onChange={(value) => onChangeAction(value)}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size='xxxs' style={{ textAlign: 'left' }}>
            <h6>Access</h6>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiSuperSelect
            options={accessOptions}
            valueOfSelected={accessValue}
            onChange={(value) => onChangeAccess(value)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}
