import React from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiListGroupItem,
  EuiToolTip,
} from '@elastic/eui'
import { RouterListGroupItem } from '..'

import './nav_bar.css'

export const NavBar = ({ expanded, setNavBarExpanded, keycloak }) => {
  const renderAdminAndGroupLinks = () => {
    if (!keycloak) return
    const links = []
    if (keycloak.hasClientRole({ roleName: 'view' })) {
      links.push(
        <EuiFlexItem key='admin-0'>
          <EuiToolTip position='right' content='Admin'>
            <RouterListGroupItem
              to='/admin'
              label={<span>Admin</span>}
              iconType='usersRolesApp'
              color='text'
            />
          </EuiToolTip>
        </EuiFlexItem>
      )
    }
    if (keycloak.hasClientRole({ roleName: 'view'}) ||
      keycloak.hasClientRole({ roleName: 'group-manager'})
    ) {
      links.push(
        <EuiFlexItem key='groups-0'>
          <EuiToolTip position='right' content='Groups'>
            <RouterListGroupItem
              to='/groups'
              label={<span>Groups</span>}
              iconType='users'
              color='text'
            />
          </EuiToolTip>
        </EuiFlexItem>
      )
    }
    if (keycloak.hasClientRole({ roleName: 'onboard' })) {
      links.push(
        <EuiFlexItem key='admin-1'>
          <EuiToolTip position='right' content='Settings'>
            <RouterListGroupItem
              to='/settings'
              label={<span>Settings</span>}
              iconType='managementApp'
              color='text'
            />
          </EuiToolTip>
        </EuiFlexItem>
      )
    }
    return links
  }
  const isEtorHcoUser = keycloak?.hasClientRole({ roleName: 'etor-hco'})
  return (
    <>
      <div className={expanded ? `NavBar-expanded` : `NavBar-collapsed`}>
        <div
          className='navBarBorder'
          onClick={() => setNavBarExpanded(!expanded)}
        ></div>
        <EuiFlexGroup direction='column' gap='2px' gutterSize='m'>
          <EuiFlexItem>
            <EuiToolTip position='right' content='Dashboard'>
              <RouterListGroupItem
                to={isEtorHcoUser ? '/projects/ETOR?projects=ETOR' : '/'}
                label={<span>Dashboard</span>}
                iconType='dashboardApp'
                color='text'
              />
            </EuiToolTip>
          </EuiFlexItem>
          {!isEtorHcoUser && <EuiFlexItem>
            <EuiToolTip position='right' content='Projects'>
              <RouterListGroupItem
                to='/projects'
                label={<span>Projects</span>}
                iconType='spacesApp'
                color='text'
              />
            </EuiToolTip>
          </EuiFlexItem>}
          <EuiFlexItem>
            <EuiToolTip position='right' content='Notifications'>
              <RouterListGroupItem
                to='/notifications'
                label={<span>Notifications</span>}
                iconType='watchesApp'
                color='text'
              />
            </EuiToolTip>
          </EuiFlexItem>
          {renderAdminAndGroupLinks()}
        </EuiFlexGroup>
        <EuiListGroupItem
          label={
            <span
              className={
                expanded
                  ? `expand-button-label-expanded`
                  : `expand-button-label-collapsed`
              }
            >
              Collapse
            </span>
          }
          iconType={expanded ? `menuLeft` : `menuRight`}
          color='text'
          className={
            expanded ? `expand-button-expanded` : `expand-button-collapsed`
          }
          onClick={() => setNavBarExpanded(!expanded)}
        ></EuiListGroupItem>
      </div>
    </>
  )
}
