import React, { useRef, useEffect } from "react";
import moment from "moment";
import { EuiIcon } from "@elastic/eui";

export const getNotificationTime = (date) => {
  const seconds = moment.duration(moment().diff(moment(date))).asSeconds();
  if (seconds > 60 && seconds < 3600) {
    return `${parseInt(seconds / 60)} minutes ago`;
  } else if (seconds > 3600 && seconds < 86400) {
    return `${parseInt(seconds / 3600)} hours ago`;
  } else if (seconds > 86400) {
    return `${parseInt(seconds / 86400)} days ago`;
  } else {
    return `${parseInt(seconds)} seconds ago`;
  }
};

export const getNotificationDateHeader = (date) => {
  const fDate = moment(date).format('l')
  const today = moment().format('l')
  if (fDate === today) {
    return 'Today'
  }
  const yesterday = moment().subtract(1, 'days').format('l')
  if (fDate === yesterday) {
    return 'Yesterday'
  }
  return fDate
}

export const buildSettingsState = (notificationSettings) => {
  let settingsState = {};
  if (!Object.keys(notificationSettings).length) {
    return settingsState;
  }
  Object.entries(notificationSettings).forEach(([project, { settings }]) => {
    if (settings) {
      settingsState[String(project)] = { ...settings };
    }
  });
  return settingsState;
};

export const getProjectField = (data) => {
  if (!data) return "general";
  const { project } = JSON.parse(data);
  if (project && project.length) {
    return project[0];
  }
  return "general";
};

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

export const mergeGroupedNotifications = (prevNotis, newNotis) => {
  const result = { ...prevNotis }

  for (let [date, notifications] of Object.entries(newNotis)) {
    if (!result[String(date)]) {
      result[String(date)] = notifications
      continue
    }
    const mergedDate = [...prevNotis[String(date)]]
    for (let n of notifications) {
      if (n.id) {
        if (!mergedDate.includes(n.id)) {
          mergedDate.push(n)
        }
        continue
      }
      if (n.groupedNotifications || n.duplicateNotifications) {
        let prevGroupedIdx = mergedDate.findIndex(p => p.project === n.project && p.type === n.type)
        if (prevGroupedIdx === -1) {
          mergedDate.push(n)
          continue
        }
        const grouped = mergedDate.splice(prevGroupedIdx, 1)[0]
        for (let g of n.groupedNotifications) {
          const idx = grouped.groupedNotifications.findIndex(e => e.id === g.id)
          if (idx === -1) {
            grouped.groupedNotifications.push(g)
          }
        }
        for (let d of n.duplicateNotifications) {
          const idx = grouped.duplicateNotifications.findIndex(e => e.id === d.id)
          if (idx === -1) {
            grouped.duplicateNotifications.push(d)
          }
        }
        grouped.groupedNotifications.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        grouped.duplicateNotifications.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        mergedDate.push(grouped)
      }
    }
    result[String(date)] = mergedDate
  }
  return result
}

export const notificationTypeMap = {
  warning: {
    displayName: "Warning",
    icon: "alert",
    color: "#FDCF79",
    groupedText: "",
    pushText: "There is a status change on your dashboard"
  },
  status: {
    displayName: "Status",
    icon: "alert",
    color: "#FDCF79",
    groupedText: "Multiple status changes have populated on your dashboard",
    pushText: "Status change has populated on your dashboard"
  },
  threshold: {
    displayName: "Threshold",
    icon: "visGauge",
    color: "#FDCF79",
    groupedText: "Multiple message counts outside of your expected range have populated on your dashboard",
    pushText: "Message counts outside of your expected range have populated on your dashboard"
  },
  maintenance: {
    displayName: "Maintenance",
    icon: "wrench",
    color: "#F2F2F2",
    groupedText: "There is an update on your dashboard"
  },
  critical: {
    displayName: "Critical",
    icon: "alert",
    color: "#FB6967",
    groupedText: "",
    pushText: "There is a status change on your dashboard"
  },
  error: {
    displayName: "Error",
    icon: "alert",
    color: "#FB6967",
    groupedText: "Multiple error files have populated on your dashboard",
    pushText: "There is an error on your dashboard"
  },
  healthy: {
    displayName: "Healthy",
    icon: "check",
    color: "#4BD497",
    groupedText: "",
    pushText: "There is a status change on your dashboard"
  },
  info: {
    displayName: "Info",
    icon: "bell",
    color: "#F2F2F2",
    groupedText: "Multiple updates from your dashboard",
    pushText: "There is an update on your dashboard"
  },
  update: {
    displayName: "Update",
    icon: "bell",
    color: "#F2F2F2",
    groupedText: "Multiple updates from your dashboard",
    pushText: "There is an update on your dashboard"
  },
  default: {
    displayName: "Notification",
    icon: "bell",
    color: "#F2F2F2",
    groupedText: "",
    pushText: "There is an update on your dashboard"
  },
};

export const CircleIcon = ({ type }) => {
  const mappedType = notificationTypeMap[String(type)]
    ? notificationTypeMap[String(type)]
    : notificationTypeMap["default"];
  return (
    <div
      className="circle-icon"
      style={{
        background: mappedType.color,
        width: "24px",
        height: "24px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <EuiIcon size="s" type={mappedType.icon} />
    </div>
  );
};

// mock data
export const notificationEventsData = [
  {
    id: "1",
    project: "Quest",
    system: "Transport",
    ticket: "In Progress",
    type: "threshold",
    time: "2021-08-23",
    messages: "Quest has recieved 150K messages in the past 24hrs",
  },
  {
    id: "2",
    project: "AIMS",
    system: "Auth",
    ticket: "In Progress",
    type: "maintenence",
    time: "2021-08-23",
    messages: "The AIMS Platform is scheduled to undergo maintenence",
  },
  {
    id: "3",
    project: "CELR",
    system: "Transport",
    ticket: "In Progress",
    type: "critical",
    time: "2021-08-23",
    messages: "CELR system component has entered a critical state",
  },
  {
    id: "4",
    project: "InterPartner",
    system: "Transport",
    ticket: "In Progress",
    type: "healthy",
    time: "2021-08-23",
    messages: "InterPartner has entered a healthy state",
  },
  {
    id: "5",
    project: "CDCStatistics",
    system: "Transport",
    ticket: "In Progress",
    type: "warning",
    time: "2021-08-23",
    messages: "CDCStatistics has entered a warning state",
  },
  {
    id: "6",
    project: "AIMS",
    system: "Auth",
    ticket: "In Progress",
    type: "maintenence",
    time: "2021-08-23",
    messages: "The AIMS Platform is scheduled to undergo maintenence",
  },
  {
    id: "7",
    project: "CELR",
    system: "Transport",
    ticket: "In Progress",
    type: "critical",
    time: "2021-08-23",
    messages: "CELR system component has entered a critical state",
  },
  {
    id: "8",
    project: "InterPartner",
    system: "Transport",
    ticket: "In Progress",
    type: "healthy",
    time: "2021-08-23",
    messages: "InterPartner has entered a healthy state",
  },
  {
    id: "9",
    project: "CDCStatistics",
    system: "Transport",
    ticket: "In Progress",
    type: "warning",
    time: "2021-08-23",
    messages: "CDCStatistics has entered a warning state",
  },
  {
    id: "10",
    project: "AIMS",
    system: "Auth",
    ticket: "In Progress",
    type: "maintenence",
    time: "2021-08-23",
    messages: "The AIMS Platform is scheduled to undergo maintenence",
  },
  {
    id: "11",
    project: "CELR",
    system: "Transport",
    ticket: "In Progress",
    type: "critical",
    time: "2021-08-23",
    messages: "CELR system component has entered a critical state",
  },
  {
    id: "12",
    project: "InterPartner",
    system: "Transport",
    ticket: "In Progress",
    type: "healthy",
    time: "2021-08-23",
    messages: "InterPartner has entered a healthy state",
  },
  {
    id: "13",
    project: "CDCStatistics",
    system: "Transport",
    ticket: "In Progress",
    type: "warning",
    time: "2021-08-23",
    messages: "CDCStatistics has entered a warning state",
  },
];

export const sampleSettings = {
  general: {
    enabled: true,
    sms: true,
    email: true,
    web: true,
  },
  Quest: {
    enabled: true,
    sms: true,
    email: true,
    web: true,
    volumeEnabled: true,
    messages: [30, 100],
    time: 20,
    timeInterval: "h",
    statusEnabled: true,
    healthy: true,
    warning: true,
    critical: true,
  },
  CentralizedELR: {
    enabled: true,
    sms: true,
    email: true,
    web: true,
    volumeEnabled: true,
    messages: [30, 100],
    time: 20,
    timeInterval: "h",
    statusEnabled: true,
    healthy: true,
    warning: true,
    critical: true,
  },
  InterPartner: {
    enabled: true,
    sms: true,
    email: true,
    web: true,
    volumeEnabled: true,
    messages: [30, 100],
    time: 20,
    timeInterval: "h",
    statusEnabled: true,
    healthy: true,
    warning: true,
    critical: true,
  },
  CDCStatistics: {
    enabled: true,
    sms: true,
    email: true,
    web: true,
    volumeEnabled: true,
    messages: [30, 100],
    time: 20,
    timeInterval: "h",
    statusEnabled: true,
    healthy: true,
    warning: true,
    critical: true,
  },
  AIMS: {
    enabled: true,
    sms: true,
    email: true,
    web: true,
    volumeEnabled: true,
    messages: [30, 100],
    time: 20,
    timeInterval: "h",
    statusEnabled: true,
    healthy: true,
    warning: true,
    critical: true,
  },
  CELR: {
    enabled: true,
    sms: true,
    email: true,
    web: true,
    volumeEnabled: true,
    messages: [30, 100],
    time: 20,
    timeInterval: "h",
    statusEnabled: true,
    healthy: true,
    warning: true,
    critical: true,
  },
  dates: {
    weekends: true,
    disabled: [],
  },
};
