import React from 'react'
import { v4 as uuid } from 'uuid'
import {
  EuiIcon,
} from '@elastic/eui'

import { EtorModalTable } from '..'

const buildRows = (data) => {
  try {
    const results = data.ProjectData.ETOR.Tests
    const rows = Object.entries(results).map(([k, v]) => {
      const obj = {
        name: k,
        ...v,
        id: uuid()
      }
      obj.Status = data.Status
      return obj
    })
    return rows
  }
  catch (e) {
    return []
  }
}

export const EtorTabResults = ({ data }) => {

  const columns = [
    {
      name: <EuiIcon type='pagesSelect' />,
      width: '32px',
      render: () => <EuiIcon type='document' />,
    },
    {
      field: 'OrderEtorId',
      name: 'ETOR ID',
    },
    {
      field: 'CollectionDate',
      name: 'Collected Date',
    },
    {
      field: 'ReportDate',
      name: 'Report Date',
    },
    {
      field: 'OrderStatus',
      name: 'Result Status',
    },
    {
      field: 'TestResults',
      name: 'Result Count',
    },
  ]

  return (
    <>
      <EtorModalTable
        columns={columns}
        items={buildRows(data)}
        id='etor-tab-results'
      />
    </>
  )
}
