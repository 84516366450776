import React from 'react'

import {
  EuiFlexGroup,
  EuiOverlayMask,
  EuiFlexItem,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiText,
  EuiSpacer,
  EuiLink,
  EuiIcon,
  EuiBadge
} from '@elastic/eui'
import { jiraMap } from '../support_tool/utils'
import { formatRecentTime } from '../../lib'
import { formatLongDate } from '../../lib/date'

export const ConfirmTicket = ({
  showModal,
  onConfirm,
  onCancel,
  ticket,
  activeNotification
}) => {
  if (!showModal || !ticket) return null

  const fStatus = ticket.jiraStatus.toLowerCase()
  const ticketStatus = jiraMap[String(fStatus)]
    ? jiraMap[String(fStatus)]
    : jiraMap['default'];

  return (
    <>
      <EuiOverlayMask>
        <EuiModal style={{ width: 500, textAlign: 'center' }} onClose={onCancel}>
          <EuiSpacer />
          <EuiModalHeader style={{ justifyContent: 'center' }}>
            <EuiText style={{ fontWeight: 600, fontSize: '1.1em' }}>
              Confirm Support Request
            </EuiText>
          </EuiModalHeader>

          <EuiSpacer />

          <EuiModalBody className='confirm-ticket-modal-body'>
            <EuiText>You created a support ticket for this notification on</EuiText>
            <EuiText style={{ fontWeight: 600 }}>{formatLongDate(ticket.created_at)}</EuiText>
            <EuiSpacer />
            <EuiLink href={ticket.jiraUrl} target='_blank'>{ticket.jiraUrl}</EuiLink>
            <EuiSpacer />

            <EuiFlexGroup
              gutterSize='none'
              style={{
                alignItems: 'center',
                padding: '8px',
                border: '1px solid rgb(224, 226, 229)',
                borderRadius: '4px',
                background: 'rgb(244, 246, 249)',
                margin: '4px 0',
                width: '340px',
                alignSelf: 'center',
                justifyContent: 'space-between'
              }}
            >
              <EuiFlexItem style={{ alignItems: 'center', maxWidth: '32px' }}>
                <EuiIcon
                  type='popout'
                  // eslint-disable-next-line
                  onClick={() => window.open(ticket.jiraUrl, '_blank')}
                  style={{ cursor: 'pointer' }}
                />
              </EuiFlexItem>

              <EuiFlexItem
                style={{
                  minWidth: '8rem',
                  maxWidth: '8rem',
                  alignItems: 'center'
                }}
              >
                <EuiText textAlign='left' size='xs' color={ticketStatus.color}>
                  {ticketStatus.displayName}
                </EuiText>
              </EuiFlexItem>

              <EuiFlexItem
                style={{
                  alignItems: 'center',
                  minWidth: '5rem',
                  maxWidth: '5rem',
                }}
              >
                <EuiBadge
                  style={{
                    backgroundColor: ticketStatus.bg,
                    color: ticketStatus.color,
                  }}
                >
                  {ticket.jiraTicket}
                </EuiBadge>

              </EuiFlexItem>

              <EuiFlexItem
                style={{
                  maxWidth: '5rem',
                  minWidth: '5rem',
                  padding: '0 8px',
                  alignItems: 'center'
                }}
              >
                <EuiText size='xs' color='#a0a6ba'>
                  {formatRecentTime(ticket.created_at)}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer />

            <EuiText>Are you sure you want to create another ticket?</EuiText>

          </EuiModalBody>

          <EuiModalFooter style={{ justifyContent: 'center' }}>
            <EuiButton
              iconType='cross'
              onClick={onCancel}
            >
              Cancel
            </EuiButton>
            <EuiButton
              iconType='check' 
              color='success'
              onClick={() => {
                onConfirm(activeNotification.id, activeNotification)
                onCancel()
              }}
              fill
            >
              Create
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    </>
  )
}
