import React, {
  useState,
  useContext,
  useEffect,
  forwardRef,
  useCallback,
} from 'react'
import { AppContext } from '../../app'
import { NotificationsContext } from './notifications_page'
import {
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiAccordion,
  EuiSwitch,
  EuiIcon,
  EuiSpacer,
  EuiCheckbox,
  EuiButtonIcon,
  EuiBadgeGroup,
  EuiBadge,
  EuiDualRange,
  EuiRange,
  EuiSuperSelect,
  EuiToolTip,
  EuiFieldNumber,
  EuiPopover,
  EuiButton
} from '@elastic/eui'
import debounce from 'lodash.debounce'

import api from '../../lib/api'

const ProjectSettingsItem = ({ id, name, scrollIntoView }, ref) => {
  const {
    notificationSettingsInitialOpen,
    displayNames,
    keycloak,
    environment
  } = useContext(AppContext)
  const {
    settingsState,
    updateSettings,
    toggleSection,
    toggleSettingsExpand,
    settingsExpandMap,
  } = useContext(NotificationsContext)
  const [initial, setInitial] = useState(false)
  const [messageSlider, setMessageSlider] = useState('')
  const [timeSlider, setTimeSlider] = useState('')
  const [recSettingsPopover, setRecSettingsPopover] = useState('')

  const [recMsgSettingsChecked, setRecMsgSettingsChecked] = useState(false)
  const [recMsgSettings, setRecMsgSettings] = useState({
    min: null,
    max: null,
    actualMin: null,
    actualMax: null
  })

  const maxMessagesVolume = 99999
  const defaultMaxMessageVolume = maxMessagesVolume

  const setting = settingsState[String(name)]
  const enabled = setting['enabled']
  const nameMap = displayNames.projects

  const getAverages = (counts, interval) => {
    if (!counts?.length) return []
    const nCounts = []
    for (let i = 0; i < counts.length; i += interval) {
      const slice = counts.slice(i, i + interval)
      if (slice.length === interval) {
        const avg = Math.round(slice.reduce((sum, c) => sum + c, 0) / interval)
        nCounts.push(avg)
      }
    }
    return nCounts
  }

  const fetchLastQtrCounts = async (project, interval = 0) => {
    setRecMsgSettings({
      min: null,
      max: null,
      actualMin: null,
      actualMax: null
    })
    try {
      const data = {
        projects: [project],
        start: 'now-4M/M',
        end: 'now',
        environment,
        interval: '1' + setting['timeInterval']
      }
      const resp = await api.fetchLastQtrCounts(data)
      const avgs = getAverages(resp?.counts ?? [], Number(interval))
      let recs = {}
      if (!avgs?.length) {
        recs = {
          min: 0,
          max: 0,
          actualMin: 0,
          actualMax: 0
        }
      } else {
        avgs.sort((a, b) => a - b)
        recs = {
          min: Math.round(avgs[0] * 0.95),
          max: Math.round(avgs[avgs.length - 1] * 1.05),
          actualMin: avgs[0],
          actualMax: avgs[avgs.length - 1]
        }
      }
      setRecMsgSettings(recs)
      return recs
    }
    catch (e) {
      console.log(e)
      setRecMsgSettings({
        min: 0,
        max: 0,
        actualMin: 0,
        actualMax: 0
      })
    }
  }

  const checkRecMsgSettings = () => {
    const msgVol = setting['messages']
    if (typeof msgVol == 'string' || typeof msgVol == 'number') {
        return Number(msgVol) === recMsgSettings.min
      } else {
        if (Number(msgVol[0]) !== recMsgSettings.min) {
        return false
      }
      if (Number(msgVol[1]) !== recMsgSettings.max) {
        return false
      }
      return true
    }
  }

  const applyRecSettings = async () => {
    if (typeof recMsgSettings.min !== 'number') {
      return
    }
    if (typeof setting['messages'] === 'number' ||
      typeof setting['messages'] === 'string') {
      onChangeMessageSlider(recMsgSettings.min)
    } else {
      onChangeMessageSlider([recMsgSettings.min, recMsgSettings.max])
    }
    setRecMsgSettingsChecked(true)
  }

  useEffect(() => {
    if (typeof recMsgSettings.min !== 'number') {
      return
    }
    setRecMsgSettingsChecked(checkRecMsgSettings())
  }, [recMsgSettings, settingsState])

  const updateMessageSlider = (value) => {
    updateSettings({
      key: name,
      setting: 'messages',
      newValue: value,
    })
  }

  // eslint-disable-next-line
  const debounceMessageSlider = useCallback(
    debounce(updateMessageSlider, 500),
    []
  )

  const onChangeMessageSlider = (value) => {
    setMessageSlider(value)
    debounceMessageSlider(value)
  }

  const updateTimeSlider = (value) => {
    updateSettings({
      key: name,
      setting: 'time',
      newValue: value,
    })
    fetchLastQtrCounts(name, value)
  }

  // eslint-disable-next-line
  const debounceTimeSlider = useCallback(debounce(updateTimeSlider, 1000), [])

  const onChangeTimeSlider = (value) => {
    setTimeSlider(value)
    debounceTimeSlider(value)
  }

  useEffect(() => {
    if (!setting['messages'] || !setting['time']) return
    setMessageSlider(setting['messages'])
    setTimeSlider(setting['time'])
  }, [settingsState, setting])

  useEffect(() => {
    if (notificationSettingsInitialOpen === name && !initial) {
      setTimeout(() => {
        scrollIntoView()
      }, 100)
      toggleSettingsExpand(name)
      setInitial(true)
    }
  }, [
    initial,
    name,
    notificationSettingsInitialOpen,
    scrollIntoView,
    toggleSettingsExpand,
  ])

  useEffect(() => {
    if (
      !setting['events'] &&
      !setting['volumeEnabled'] &&
      !setting['statusEnabled'] &&
      setting['enabled']
    ) {
      toggleSection({ key: name, enable: false })
    }
  }, [name, setting, toggleSection])

  const checkboxes = [
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'sms',
      label: 'SMS',
    },
  ]

  const timeframeOptions = [
    {
      value: 'h',
      inputDisplay: 'Hours',
    },
    {
      value: 'd',
      inputDisplay: 'Days',
    },
  ]

  const sliderSwitch = () => {
    if (
      typeof messageSlider === 'number' ||
      typeof messageSlider === 'string'
    ) {
      const currentValue = parseInt(messageSlider)
      const newMax =
        currentValue >= defaultMaxMessageVolume
          ? maxMessagesVolume
          : defaultMaxMessageVolume
      setMessageSlider([messageSlider, newMax])
      updateSettings({
        key: name,
        setting: 'messages',
        newValue: [messageSlider, newMax],
      })
    } else {
      setMessageSlider(messageSlider[0])
      updateSettings({
        key: name,
        setting: 'messages',
        newValue: messageSlider[0],
      })
    }
  }

  return (
    <EuiAccordion
      ref={notificationSettingsInitialOpen === name ? ref : null}
      id={`notification-accordion-project-${id}`}
      className='notifications-accordion'
      buttonContent={
        <EuiFlexGroup gutterSize='none' alignItems='center' responsive={false}>
          <EuiFlexItem>
            <EuiText
              style={
                settingsExpandMap[String(name)] && {
                  fontWeight: '500',
                  textDecoration: 'underline',
                }
              }
            >
              {nameMap[String(name)] ? nameMap[String(name)] : name}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      }
      initialIsOpen={notificationSettingsInitialOpen === name ? true : false}
      extraAction={
        <EuiSwitch
          checked={enabled}
          onChange={() =>
            toggleSection({
              key: name,
              enable: enabled ? false : true,
            })
          }
          label=''
        />
      }
      onToggle={() => {
        toggleSettingsExpand(name)
        if (!settingsExpandMap[String(name)]) {
          fetchLastQtrCounts(name, timeSlider)
        }
      }}
    >
      <div style={{ padding: '0 5px' }}>
        <EuiFlexGroup
          gutterSize='none'
          responsive={false}
          direction='column'
          style={{ marginTop: '.5rem' }}
        >
          <EuiFlexGroup
            gutterSize='none'
            responsive={false}
            alignItems='center'
            style={{
              marginTop: '.4rem',
              minHeight: '40px',
            }}
          >
            <EuiFlexItem
              grow={false}
              style={{ opacity: setting['events'] ? 1 : 0.3, marginRight: '4px', width: '24px' }}
            >
              <EuiIcon type='watchesApp' size='m' color={'#343741'} />
            </EuiFlexItem>
            <EuiFlexItem style={{ opacity: setting['events'] ? 1 : 0.3 }}>
              <EuiText
                color={'#343741'}
                style={{
                  fontSize: '13px',
                  letterSpacing: '1px',
                  fontWeight: '600',
                }}
              >
                <p>EVENTS</p>
              </EuiText>
            </EuiFlexItem>
            <EuiSwitch
              checked={setting['events']}
              onChange={() =>
                updateSettings({
                  key: name,
                  setting: 'events',
                  newValue: !setting['events'],
                })
              }
              style={{ marginLeft: 'auto', marginRight: '0' }}
              compressed
              label=''
            />
          </EuiFlexGroup>

          <EuiSpacer size='xs' className='notifications-spacer' />

          <EuiFlexGroup
            gutterSize='none'
            responsive={false}
            alignItems='center'
            style={{ minHeight: '40px' }}
          >
            <EuiFlexItem
              grow={false}
              style={{
                opacity: setting['volumeEnabled'] ? 1 : 0.3,
                marginRight: '4px',
                width: '24px'
              }}
            >
              <EuiIcon type='visGauge' size='m' color={'#343741'} />
            </EuiFlexItem>
            <EuiFlexItem
              style={{ opacity: setting['volumeEnabled'] ? 1 : 0.3 }}
            >
              <EuiText
                size='s'
                color={'#343741'}
                style={{
                  fontSize: '13px',
                  letterSpacing: '1px',
                  fontWeight: '600',
                }}
              >
                <p>MESSAGE VOLUME</p>
              </EuiText>
            </EuiFlexItem>
            <EuiSwitch
              checked={setting['volumeEnabled']}
              onChange={() =>
                updateSettings({
                  key: name,
                  setting: 'volumeEnabled',
                  newValue: !setting['volumeEnabled'],
                })
              }
              style={{ marginLeft: 'auto', marginRight: '0' }}
              compressed
              label=''
            />
          </EuiFlexGroup>

          {setting['volumeEnabled'] && (
            <>
              <EuiSpacer size='xs' className='notifications-spacer' />

              <EuiText>Messages</EuiText>

              <EuiFlexGroup gutterSize='none' style={{ margin: '8px 0' }}>
                <EuiFlexItem grow={false}>
                  <EuiCheckbox
                    disabled={!enabled}
                    id={`project-check-apply-rec-${name}`}
                    className='project-check-apply-rec'
                    label={'Apply Recommended Settings'}
                    checked={recMsgSettingsChecked}
                    onChange={() => {
                      const checked = checkRecMsgSettings()
                      if (checked) return
                      applyRecSettings()
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem style={{ margin: '0 4px' }}>
                  <EuiPopover
                    button={
                      <EuiButtonIcon
                        iconType='iInCircle'
                        aria-label='rec-settings-info'
                        color='text'
                        onClick={() => {
                          if (recSettingsPopover === id) {
                            setRecSettingsPopover('')
                          } else {
                            setRecSettingsPopover(id)
                          }
                        }}
                      />
                    }
                    isOpen={recSettingsPopover === id}
                    closePopover={() => setRecSettingsPopover('')}
                    anchorPosition='leftCenter'
                  >
                    <div style={{ width: '12rem' }}>
                      <EuiFlexGroup gutterSize='none'>
                        <EuiFlexItem
                          grow={false}
                          style={{ width: '24px' }}
                        >
                          <EuiIcon type='visGauge' size='m' color={'#343741'} />
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          <EuiText
                            size='s'
                            color={'#343741'}
                            style={{
                              fontSize: '12px',
                              fontWeight: '500',
                            }}
                          >
                            <p>Recommended Settings</p>
                          </EuiText>
                        </EuiFlexItem>
                      </EuiFlexGroup>

                      <EuiSpacer size='xs' className='notifications-spacer' />

                      <EuiText size='xs' color='subdued'>
                        Based on your selected time range, we recommend these volume settings.
                      </EuiText>
                      <br /><br />
                      <EuiText size='xs' color='subdued'>
                        Last Qtr. {timeSlider + setting['timeInterval']} Min:
                      </EuiText>
                      <EuiText color='subdued' style={{ fontSize: '20px' }}>
                        {typeof recMsgSettings.actualMin === 'number' ? recMsgSettings.actualMin : '--'}
                      </EuiText>
                      <br />
                      <EuiText size='xs' color='subdued'>
                        Last Qtr. {timeSlider + setting['timeInterval']} Max:
                      </EuiText>
                      <EuiText color='subdued' style={{ fontSize: '20px' }}>
                        {typeof recMsgSettings.actualMax === 'number' ? recMsgSettings.actualMax : '--'}
                      </EuiText>
                      <br /><br />
                      <EuiFlexGroup gutterSize='none'>
                        <EuiFlexItem>
                          <EuiFlexGroup gutterSize='none' direction='column'>
                            <EuiFlexItem>
                              <EuiText style={{ color: '#006BB4' }}>
                                Min:
                              </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiText style={{ fontSize: '20px', fontWeight: '500' }}>
                                {typeof recMsgSettings.min === 'number' ? recMsgSettings.min : '--'}
                              </EuiText>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiFlexItem>
                        <EuiFlexItem>
                          <EuiFlexGroup gutterSize='none' direction='column'>
                            <EuiFlexItem>
                              <EuiText style={{ color: '#006BB4' }}>
                                Max:
                              </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem>
                              <EuiText style={{ fontSize: '20px', fontWeight: '500' }}>
                                {typeof recMsgSettings.max === 'number' ? recMsgSettings.max : '--'}
                              </EuiText>
                            </EuiFlexItem>
                          </EuiFlexGroup>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                      <EuiSpacer size='xs' className='notifications-spacer' />
                      <EuiButton
                        iconType='check'
                        onClick={() => {
                          setRecSettingsPopover('')
                          applyRecSettings()
                        }}
                        fullWidth
                        fill
                      >
                        Apply
                      </EuiButton>
                    </div>
                  </EuiPopover>
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiFlexGroup
                gutterSize='none'
                responsive={false}
                alignItems='center'
                justifyContent='spaceBetween'
                className='notifications-message-sliders'
              >
                <EuiFlexItem grow={false}>
                  <label>Min</label>
                </EuiFlexItem>
                {typeof setting['messages'] === 'number' ||
                typeof setting['messages'] === 'string' ? (
                  <EuiFlexGroup
                    gutterSize='s'
                    alignItems='center'
                    responsive={false}
                  >
                    <EuiFlexItem>
                      <label style={{ color: '#BEBEBF' }}>Max</label>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiButtonIcon
                        iconType='plusInCircle'
                        size='xs'
                        color='primary'
                        onClick={sliderSwitch}
                        aria-label='messages-volume-max-button'
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                ) : (
                  <EuiFlexGroup
                    gutterSize='s'
                    alignItems='center'
                    responsive={false}
                  >
                    <EuiFlexItem>
                      <label>Max</label>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiButtonIcon
                        iconType='trash'
                        size='xs'
                        color='primary'
                        onClick={sliderSwitch}
                        aria-label='messages-volume-max-button'
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                )}
              </EuiFlexGroup>

              <EuiFlexItem style={{ margin: '.5rem 0' }}>
                {typeof setting['messages'] === 'number' ||
                typeof setting['messages'] === 'string' ? (
                  <>
                    <EuiFlexGroup gutterSize='m'>
                      <EuiFlexItem grow={false}>
                        <EuiFieldNumber
                          max={maxMessagesVolume}
                          value={messageSlider}
                          style={{ width: '6em' }}
                          onChange={(e) => onChangeMessageSlider(e.target.value)}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiRange
                          id={`messages-single-slider-${id}`}
                          value={messageSlider}
                          onChange={(e) => onChangeMessageSlider(e.target.value)}
                          aria-label='Messages volume slider'
                          fullWidth
                          max={maxMessagesVolume}
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </>
                ) : (
                  <EuiDualRange
                    id={`messages-dual-slider-${id}`}
                    value={messageSlider}
                    onChange={onChangeMessageSlider}
                    showInput
                    minInputProps={{ 'aria-label': 'Min value' }}
                    maxInputProps={{ 'aria-label': 'Max value' }}
                    aria-label='Messages volume dual slider'
                    fullWidth
                    max={maxMessagesVolume}
                  />
                )}
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiText>Time Frame</EuiText>
              </EuiFlexItem>

              <EuiFlexGroup
                gutterSize='none'
                responsive={false}
                alignItems='center'
                style={{ margin: '.5rem' }}
              >
                <EuiFlexItem>
                  <EuiRange
                    id={`time-frame-slider-${id}`}
                    value={timeSlider}
                    onChange={(e) => onChangeTimeSlider(e.target.value)}
                    showInput
                    aria-label='Timeframe volume slider'
                  />
                </EuiFlexItem>
                <EuiSuperSelect
                  className='time-frame-select'
                  options={timeframeOptions}
                  valueOfSelected={setting['timeInterval']}
                  onChange={(selected) => {
                    updateSettings({
                      key: name,
                      setting: 'timeInterval',
                      newValue: selected,
                    })
                    fetchLastQtrCounts(name, timeSlider)
                  }}
                />
              </EuiFlexGroup>
            </>
          )}

          <EuiSpacer size='xs' className='notifications-spacer' />

          <EuiFlexGroup
            gutterSize='none'
            responsive={false}
            alignItems='center'
            style={{ minHeight: '40px', marginBottom: '5px' }}
          >
            <EuiFlexItem
              grow={false}
              style={{
                opacity: setting['statusEnabled'] ? 1 : 0.3,
                marginRight: '4px',
                width: '24px'
              }}
            >
              <EuiIcon type='alert' size='m' color={'#343741'} />
            </EuiFlexItem>
            <EuiFlexItem
              style={{ opacity: setting['statusEnabled'] ? 1 : 0.3 }}
            >
              <EuiText
                size='s'
                color={'#343741'}
                style={{
                  fontSize: '13px',
                  letterSpacing: '1px',
                  fontWeight: '600',
                }}
              >
                <p>STATUS</p>
              </EuiText>
            </EuiFlexItem>
            <EuiSwitch
              checked={setting['statusEnabled']}
              onChange={() =>
                updateSettings({
                  key: name,
                  setting: 'statusEnabled',
                  newValue: !setting['statusEnabled'],
                })
              }
              style={{ marginLeft: 'auto', marginRight: '0' }}
              compressed
              label=''
            />
          </EuiFlexGroup>

          {setting['statusEnabled'] && (
            <>
              <EuiBadgeGroup className='notification-badges'>
                <EuiBadge
                  className='notification-badge'
                  iconType={setting['healthy'] ? 'check' : ''}
                  color='#177C73'
                  onClick={() =>
                    updateSettings({
                      key: name,
                      setting: 'healthy',
                      newValue: !setting['healthy'],
                    })
                  }
                  onClickAriaLabel={'Update healthy setting'}
                >
                  Healthy
                </EuiBadge>
                <EuiBadge
                  className='notification-badge'
                  iconType={setting['warning'] ? 'check' : ''}
                  color='#EFA600'
                  onClick={() =>
                    updateSettings({
                      key: name,
                      setting: 'warning',
                      newValue: !setting['warning'],
                    })
                  }
                  onClickAriaLabel={'Update warning setting'}
                >
                  Warning
                </EuiBadge>
                <EuiBadge
                  className='notification-badge'
                  iconType={setting['critical'] ? 'check' : ''}
                  color='#B72B20'
                  onClick={() =>
                    updateSettings({
                      key: name,
                      setting: 'critical',
                      newValue: !setting['critical'],
                    })
                  }
                  onClickAriaLabel={'Update critical setting'}
                >
                  Critical
                </EuiBadge>
              </EuiBadgeGroup>
            </>
          )}

          {enabled && (
            <div>
              <EuiFlexGroup
                gutterSize='none'
                responsive={false}
                alignItems='center'
                style={{ minHeight: '40px' }}
              >
                <EuiFlexItem grow={false} style={{ marginRight: '4px', width: '24px' }}>
                  <EuiIcon type='bell' size='m' />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText
                    size='s'
                    color={'#343741'}
                    style={{
                      fontSize: '13px',
                      letterSpacing: '1px',
                      fontWeight: '600',
                    }}
                  >
                    <p>NOTIFICATION</p>
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiSpacer size='xs' className='notifications-spacer' />

              <EuiFlexItem>
                <EuiText size='s' style={{ fontWeight: 500 }}>Type</EuiText>
              </EuiFlexItem>

              <EuiFlexGroup
                responsive={false}
                alignItems='center'
                justifyContent='spaceBetween'
                style={{ padding: '.5rem 0' }}
              >
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup gutterSize='m'>
                    {checkboxes.map(({ label, value }, i) => (
                      <EuiFlexItem key={`notifications-project-check-${i}`}>
                        {keycloak && keycloak.tokenParsed[String(value)] ? (
                          <EuiCheckbox
                            disabled={!enabled}
                            id={`project-check-${id}-${value}`}
                            label={label}
                            checked={enabled ? setting[String(value)] : false}
                            onChange={() =>
                              updateSettings({
                                key: name,
                                setting: value,
                                newValue: !setting[String(value)],
                              })
                            }
                          />
                        ) : (
                          <EuiToolTip
                            position='bottom'
                            content='Update your personal information using "Edit profile" located in the top right user menu to enable this feature.'
                          >
                            <EuiCheckbox
                              disabled={true}
                              id={`project-check-${id}-${value}`}
                              label={label}
                              checked={false}
                              onChange={() => {}}
                            />
                          </EuiToolTip>
                        )}
                      </EuiFlexItem>
                    ))}
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          )}
        </EuiFlexGroup>
      </div>
    </EuiAccordion>
  )
}

export default forwardRef(ProjectSettingsItem)
