import React, { useContext } from 'react'
import { AppContext } from '../../app'
import { NotificationsContext } from './notifications_page'
import {
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiAccordion,
  EuiSwitch,
  EuiIcon,
  EuiSpacer,
  EuiCheckbox,
  EuiToolTip,
} from '@elastic/eui'
import { messagesTemplate } from '../../lib/messagesTemplate'

export const GeneralSettingsItem = () => {
  const { notificationSettingsInitialOpen, keycloak } = useContext(AppContext)
  const { settingsState, updateSettings, toggleSettingsExpand } =
    useContext(NotificationsContext)

  const setting = settingsState['general']
  const enabled = setting['enabled']

  const checkboxes = [
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'sms',
      label: 'SMS',
    },
  ]

  const GeneralSettingsOptions = () => {
    return (
      <div style={{ opacity: enabled ? 1 : 0.3 }}>
        <EuiSpacer />
        <EuiFlexGroup gutterSize='none' responsive={false} direction='column'>
          <EuiFlexGroup
            gutterSize='s'
            responsive={false}
            alignItems='center'
          >
            <EuiFlexItem grow={false}>
              <EuiIcon type='bell' size='m' />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText
                size='s'
                color={'#343741'}
                style={{
                  fontSize: '13px',
                  letterSpacing: '1px',
                  fontWeight: '600',
                }}
              >
                <p>NOTIFICATION</p>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>

          <EuiSpacer size='xs' className='notifications-spacer' />

          <EuiFlexItem>
            <EuiText size='s' style={{ fontWeight: 500 }}>Type</EuiText>
          </EuiFlexItem>

          <EuiFlexGroup
            responsive={false}
            alignItems='center'
            justifyContent='spaceBetween'
            style={{ paddingTop: '.5rem' }}
          >
            <EuiFlexItem grow={false}>
              <EuiFlexGroup gutterSize='m'>
                {checkboxes.map(({ label, value }, i) => (
                  <EuiFlexItem key={`notifications-general-check${i}`} grow={false}>
                    {keycloak && keycloak.tokenParsed[String(value)]
                      ? <EuiCheckbox
                          disabled={!enabled}
                          id={`general-check-${value}`}
                          label={label}
                          checked={enabled ? setting[String(value)] : false}
                          onChange={() =>
                            updateSettings({
                              key: 'general',
                              setting: value,
                              newValue: !setting[String(value)],
                            })
                          }
                        />
                      : <EuiToolTip
                          position='bottom'
                          content='Update your personal information using "Edit profile" located in the top right user menu to enable this feature.'
                        >
                          <EuiCheckbox
                            disabled={true}
                            id={`general-check-${value}`}
                            label={label}
                            checked={false}
                            onChange={() => {}}
                          />
                        </EuiToolTip>
                    }
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexGroup>
      </div>
    )
  }

  return (
    <EuiAccordion
      id='notification-general'
      className='notifications-accordion'
      initialIsOpen={
        notificationSettingsInitialOpen === 'general' ? true : false
      }
      buttonContent={
        <EuiFlexGroup
          gutterSize='m'
          alignItems='center'
          responsive={false}
          style={{ minHeight: '40px' }}
        >
          <EuiFlexItem>{messagesTemplate.notificationsGeneralTitle}</EuiFlexItem>
        </EuiFlexGroup>
      }
      extraAction={
        <EuiSwitch
          checked={enabled}
          onChange={() =>
            updateSettings({
              key: 'general',
              setting: 'enabled',
              newValue: !enabled,
            })
          }
          label=''
        />
      }
      onToggle={() => toggleSettingsExpand('general')}
    >
      <GeneralSettingsOptions />
    </EuiAccordion>
  )
}
