import React from 'react'

import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui'

export const ConfirmDeleteModal = ({
  showDeleteModal,
  cancelDelete,
  deleteUsers,
  title,
  activeUser,
  userIds
}) => {
  if (!showDeleteModal) return null

  if (activeUser.id) {
    return (
      <>
        <EuiOverlayMask>
          <EuiConfirmModal
            title={title}
            onCancel={cancelDelete}
            onConfirm={() => deleteUsers([activeUser.id])}
            cancelButtonText='Cancel'
            confirmButtonText='Confirm'
            buttonColor='danger'
            defaultFocusedButton='confirm'
          >
            <p>Are you sure you want to delete {activeUser.name}?</p>
          </EuiConfirmModal>
        </EuiOverlayMask>
      </>
    )
  }

  if (userIds.length) {
    return (
      <>
        <EuiOverlayMask>
          <EuiConfirmModal
            title={title}
            onCancel={cancelDelete}
            onConfirm={() => deleteUsers(userIds)}
            cancelButtonText='Cancel'
            confirmButtonText='Confirm'
            buttonColor='danger'
            defaultFocusedButton='confirm'
          >
            <p>Are you sure you want to delete {userIds.length} users?</p>
          </EuiConfirmModal>
        </EuiOverlayMask>
      </>
    )
  }

  return null
}
