import React, {
  useState,
  useEffect,
} from 'react'

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiTabbedContent,
  EuiSpacer,
  EuiText,
  EuiSwitch,
  EuiComboBox
} from '@elastic/eui'

import { entityDisplay } from './utils'

const getSenderOptions = (senders) => {
  if (!senders)
    return [{ value: null, label: 'No senders available' }]
  return senders.reduce((agg, sender) => {
    if (!sender.parent) {
      agg.push({
        value: sender.name,
        label: sender.displayName || sender.name,
        'data-test-subj': `option-${sender.name}`,
      })
    }
    return agg
  }, [])
}

export const EntityForm = ({
  data,
  state,
  dispatch,
  entityType,
  handleAdd,
  fieldId,
  handleAddSubsender,
  handleAddCollection,
}) => {
  const [selectedSender, setSelectedSender] = useState([])
  const [senderOptions, setSenderOptions] = useState([])
  const [subsenderName, setSubsenderName] = useState('')
  const [subsenderDisplay, setSubsenderDisplay] = useState('')
  const [showCollection, setShowCollection] = useState(false)
  const [subsenderCol, setSubsenderCol] = useState('')
  const [curSenderTab, setCurSenderTab] = useState('')

  const subsenderFieldId = `${fieldId}-subsender`
  const nameField = `${subsenderFieldId}-name`
  const displayField = `${subsenderFieldId}-display`
  const parentField = `${subsenderFieldId}-parent`
  const collectionField = `${subsenderFieldId}-col`

  useEffect(() => {
    if (entityType === 'sender' && data?.length) {
      const options = getSenderOptions(data)
      setSenderOptions(options)
    }
  }, [data])

  const getSubsenderError = () => {
    const errorMap = state.errorMap
    if (errorMap[String(nameField)]) {
      return errorMap[String(nameField)]
    }
    if (errorMap[String(displayField)]) {
      return errorMap[String(displayField)]
    }
    if (errorMap[String(parentField)]) {
      return errorMap[String(parentField)]
    }
    if (errorMap[String(collectionField)]) {
      return errorMap[String(collectionField)]
    }
    return null
  }

  const formRow = (
    <EuiFormRow
      style={{ maxWidth: '100%', textAlign: 'left' }}
      isInvalid={state.errorMap[String(fieldId)]?.length ? true : false}
      error={state.errorMap[String(fieldId)]}
    >
      <EuiFlexGroup gutterSize='none' style={{ alignItems: 'center' }}>
        <EuiFlexItem style={{ marginRight: '.75rem' }}>
          <EuiFieldText
            placeholder={`${entityDisplay[String(entityType)].display} Name`}
            value={state.addNameMap[String(entityType)]}
            onChange={e => dispatch({
              type: 'set_name',
              entityType,
              value: e.target.value
            })}
            aria-label='entity add'
            fullWidth
            icon='addDataApp'
            isInvalid={state.errorMap[String(fieldId)]?.length ? true : false}
          />
        </EuiFlexItem>
        <EuiButton
          aria-label='Add Entity'
          iconType='plusInCircle'
          fill
          onClick={() => handleAdd(state.addNameMap[String(entityType)])}
        >
          Add
        </EuiButton>
      </EuiFlexGroup>
    </EuiFormRow>
  )

  const tabs = [
    {
      id: 'sender',
      name: 'Sender',
      content: (
        <>
          <EuiSpacer size='s' />
          {formRow}
        </>
      )
    },
    {
      id: 'subsender',
      name: 'Subsender',
      content: (
        <EuiFormRow
          style={{ maxWidth: '100%', textAlign: 'left' }}
          isInvalid={getSubsenderError() ? true : false}
          error={getSubsenderError()}
        >
          <>
            <EuiSpacer size='s' />
            <EuiFlexGroup gutterSize='none' style={{ alignItems: 'center' }}>
              <EuiFlexItem style={{ marginRight: '.75rem', maxWidth: '20rem' }}>
                <EuiComboBox
                  placeholder='Select Sender'
                  singleSelection={{ asPlainText: true }}
                  options={senderOptions}
                  selectedOptions={selectedSender}
                  onChange={value => setSelectedSender(value)}
                  isClearable={false}
                  isInvalid={state.errorMap[String(parentField)]?.length ? true : false}
                />
              </EuiFlexItem>
              {showCollection ?
              <>
                <EuiFlexItem style={{ marginRight: '.75rem' }}>
                  <EuiFieldText
                    placeholder='Subsender List'
                    value={subsenderCol}
                    onChange={e => setSubsenderCol(e.target.value)}
                    aria-label='subsender name add'
                    icon='addDataApp'
                    isInvalid={state.errorMap[String(collectionField)]?.length ? true : false}
                    fullWidth
                  />
                </EuiFlexItem>
                <EuiButton
                  aria-label='Add Entity'
                  iconType='plusInCircle'
                  fill
                  onClick={() => handleAddCollection(subsenderCol, selectedSender, setSubsenderCol)}
                >
                  Add
                </EuiButton>
              </> :
              <>
                <EuiFlexItem style={{ marginRight: '.75rem' }}>
                  <EuiFieldText
                    placeholder='CLIA #'
                    value={subsenderName}
                    onChange={e => setSubsenderName(e.target.value)}
                    aria-label='subsender name add'
                    icon='addDataApp'
                    isInvalid={state.errorMap[String(nameField)]?.length ? true : false}
                    fullWidth
                    />
                </EuiFlexItem>
                <EuiFlexItem style={{ marginRight: '.75rem' }}>
                  <EuiFieldText
                    placeholder='Display Name'
                    value={subsenderDisplay}
                    onChange={e => setSubsenderDisplay(e.target.value)}
                    aria-label='subsender display name add'
                    icon='addDataApp'
                    isInvalid={state.errorMap[String(displayField)]?.length ? true : false}
                    fullWidth
                  />
                </EuiFlexItem>
                <EuiButton
                  aria-label='Add Entity'
                  iconType='plusInCircle'
                  fill
                  onClick={() => handleAddSubsender(subsenderName, subsenderDisplay, selectedSender[0])}
                >
                  Add
                </EuiButton>
              </>
              }
            </EuiFlexGroup>
          </>
        </EuiFormRow>
      )
    },
  ]

  if (entityType === 'sender')
    return (
      <>
        <div className='settings-subsender-switch'>
          {curSenderTab === 'subsender' &&
            <EuiFlexGroup gutterSize='s' alignItems='center'>
              <EuiFlexItem grow={false}>
                <EuiText color='subdued' size='xs'>Collection</EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiSwitch
                  checked={showCollection}
                  onChange={() => setShowCollection(!showCollection)}
                  compressed
                  label=''
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          }
        </div>
        <EuiTabbedContent
          id='settings-sender-tabs'
          tabs={tabs}
          autoFocus='selected'
          onTabClick={t => setCurSenderTab(t.id)}
        />
      </>
    )
  return (
    <>
      <EuiSpacer size='m' />
      <EuiFlexItem>
        <EuiText textAlign='left' size='s' style={{ marginBottom: '.25rem' }}>
          Add {entityDisplay[String(entityType)].display}
        </EuiText>
      </EuiFlexItem>
      {formRow}
    </>
  )
}
