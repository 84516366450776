import React from 'react'
import { GeneralSettings } from './general_settings'
import { ProjectsSettings } from './projects_settings'
import { DisabledDaysSettings } from './disabled_days_settings'
import { PushNotifications } from './push_notifications'
import './notifications.css'
import {
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiModalHeaderTitle,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer
} from '@elastic/eui'
import { messagesTemplate } from '../../lib/messagesTemplate'

export const NotificationsSettings = ({ showModal, closeModal }) => {
  if (!showModal) return null

  return (
    <>
      <EuiModal onClose={closeModal} style={{ width: '30rem' }}>
        <EuiModalHeader style={{ paddingLeft: '35px' }}>
          <EuiFlexGroup gutterSize='none' direction='column'>
            <EuiFlexItem>
              <EuiText>{messagesTemplate.notificationConfigsTitle}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiModalHeaderTitle
                style={{
                  fontWeight: '500',
                  fontSize: '29px',
                  letterSpacing: '1px',
                }}
              >
                Notification Settings
              </EuiModalHeaderTitle>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalHeader>

        <EuiModalBody>
          
          <PushNotifications />
          <EuiSpacer size='s' />
          <GeneralSettings />
          <ProjectsSettings />
          <DisabledDaysSettings />

        </EuiModalBody>
      </EuiModal>
    </>
  )
}
