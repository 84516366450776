import React, { useState, useContext } from 'react'

import {
  EuiButton,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiSpacer,
  EuiTableRowCell,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiTabs,
  EuiIcon,
  EuiText,
  EuiPanel
} from '@elastic/eui'

import {
  LEFT_ALIGNMENT,
  RIGHT_ALIGNMENT,
  euiPaletteColorBlindBehindText,
  toInitials
} from '@elastic/eui/lib/services'

import { api } from '../../lib'
import { AppContext } from '../../app'
import { AdminPageContext } from './admin_page'
import { AdminTable } from '..'
import { StatCount } from '../stats/stat_count'

const CircleIcon = ({ value, style, color }) => {
  return (
    <div
      className='euiAvatar'
      style={{
        ...style,
        background: color,
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        fontSize: '.7em',
        border: '2px solid #fff',
        position: 'relative',
        zIndex: 99,
      }}
    >
      {value}
    </div>
  )
}

export const TagsPage = () => {
  const {
    tags,
    toaster,
    tagsPagination,
    keycloak
  } = useContext(AppContext)

  const {
    loadTags,
    loading,
    setLoading,
    renderAdminTabs,
    adminState,
    adminDispatch
  } = useContext(AdminPageContext)

  const [itemIdToOpenActionsPopoverMap, setItemIdToOpenActionsPopoverMap] =
    useState({})

  const setSearch = (value) => adminDispatch({ type: 'set', state: 'tagsSearch', value })
  const addTag = () => adminDispatch({ type: 'set', state: 'tagModal', value: 'create' })
  const viewTag = (tag) => adminDispatch({ type: 'view_tag', tag })
  const editTag = (tag) => adminDispatch({ type: 'edit_tag', tag })

  const deleteTag = async (id) => {
    if (!id) return
    setItemIdToOpenActionsPopoverMap({})
    setLoading(true)
    try {
      await api.deleteTag(id)
      loadTags({ reset: true, search: adminState.tagsSearch })

      toaster({
        color: 'success',
        title: `Tag succesfully deleted.`,
      })

    }
    catch (err) {
      console.log(err)
      toaster({
        color: 'danger',
        title: 'Unable to delete tag.',
        text: err.message,
      })
    }
    finally {
      setLoading(false)
    }
  }

  const renderUserAvs = (userTags) => {
    if (!userTags?.length) {
      return <></>
    }
    const avatars = []
    const limit = 4
    const visColors = euiPaletteColorBlindBehindText()
    for (const { user } of userTags) {
      if (!user) continue
      const assignedColor = visColors[Math.floor(user.name.length % visColors.length)]
      const margin = avatars.length ? '-12px' : '0'
      if (avatars.length >= limit) {
        const usersLeft = userTags.length - limit
        if (usersLeft === 1) {
          avatars.push(
            <CircleIcon
              key={`tag-av-last-${user.name}`}
              value={`+${usersLeft}`}
              style={{ marginLeft: margin }}
              color={'#D294AE'}
            />
          )
        } else {
          avatars.push(
            <CircleIcon
              key={`tag-av-last-${user.name}`}
              value={`+${usersLeft}`}
              style={{ marginLeft: margin }}
              color={assignedColor}
            />
          )
        }
        return avatars
      }
      avatars.push(
        <CircleIcon
          key={`tag-av-${user.name}`}
          value={toInitials(user.name, 2).toUpperCase()}
          style={{ marginLeft: margin }}
          color={assignedColor}
        />
      )
    }
    return avatars
  }

  const columns = [
    {
      id: 'icon',
      label: '',
      alignment: LEFT_ALIGNMENT,
      width: '42px',
      cellProvider: () => <EuiIcon type='tag' style={{ minHeight: '24px', paddingTop: '4px' }} />,
      mobileOptions: {
        show: false,
      },
    },
    {
      id: 'name',
      label: 'Name',
      alignment: LEFT_ALIGNMENT,
      isSortable: true,
      mobileOptions: {
        show: false,
      },
      width: '25%'
    },
    {
      id: 'description',
      label: 'Description',
      alignment: LEFT_ALIGNMENT,
      width: '40%'
    },
    {
      id: 'users',
      label: 'Users',
      alignment: LEFT_ALIGNMENT,
      render: (name, row) => {
        if (!row.userTags) {
          return <></>
        }
        return (
          <>{renderUserAvs(row.userTags)}</>
        )
      }
    },
    {
      id: 'actions',
      label: '',
      alignment: RIGHT_ALIGNMENT,
      isActionsPopover: keycloak.hasClientRole({ roleName: 'edit' }) ? true : false,
      width: '58px',
    },
  ]

  const actionsPopover = (column, item) => {
    return (
      <EuiTableRowCell
        key={column.id}
        header={column.label}
        textOnly={false}
        hasActions={true}
        align='right'
      >
        <EuiPopover
          id={`${item.id}-actions`}
          button={
            <EuiButtonIcon
              aria-label='Actions'
              iconType='gear'
              size='s'
              color='text'
              onClick={e => {
                e.stopPropagation()
                togglePopover(item.id)
              }}
            />
          }
          isOpen={isPopoverOpen(item.id)}
          closePopover={() => closePopover(item.id)}
          panelPaddingSize='none'
          anchorPosition='leftCenter'
        >
          <EuiContextMenuPanel
            items={[
              <EuiContextMenuItem
                key='A'
                icon='pencil'
                onClick={e => {
                  e.stopPropagation()
                  togglePopover(item.id)
                  editTag(item)
                }}
              >
                Edit
              </EuiContextMenuItem>,
              <EuiContextMenuItem
                key='C'
                icon='trash'
                onClick={e => {
                  e.stopPropagation()
                  deleteTag(item.id)
                }}
              >
                Delete
              </EuiContextMenuItem>,
            ]}
          />
        </EuiPopover>
      </EuiTableRowCell>
    )
  }

  const togglePopover = (itemId) => {
    const newItemIdToOpenActionsPopoverMap = {
      ...itemIdToOpenActionsPopoverMap,
      [itemId]: !itemIdToOpenActionsPopoverMap[String(itemId)],
    }

    setItemIdToOpenActionsPopoverMap(newItemIdToOpenActionsPopoverMap)
  }

  const closePopover = (itemId) => {
    if (isPopoverOpen(itemId)) {
      const newItemIdToOpenActionsPopoverMap = {
        ...itemIdToOpenActionsPopoverMap,
        [itemId]: false,
      }

      setItemIdToOpenActionsPopoverMap(newItemIdToOpenActionsPopoverMap)
    }
  }

  const isPopoverOpen = (itemId) => {
    return itemIdToOpenActionsPopoverMap[String(itemId)]
  }

  return (
    <>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle size='m'>
            <h1>User Tags</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>

      <EuiFlexGroup gutterSize='s'>
        <EuiFlexItem style={{ justifyContent: 'center' }}>
          <EuiTabs>{renderAdminTabs()}</EuiTabs>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup gutterSize='s' style={{ alignItems: 'center' }}>
            <EuiFlexItem grow={false}>
              <StatCount
                title={tagsPagination.total ? tagsPagination.total : 0}
                description='Total'
                tooltip={`total tags: ${tagsPagination.total ? tagsPagination.total : 0}`}
                color='secondary'
              />
            </EuiFlexItem>
            {keycloak.hasClientRole({ roleName: 'edit' }) &&
              <EuiFlexItem grow={false}>
                <EuiButton
                  fill
                  color='primary'
                  iconType='listAdd'
                  onClick={addTag}
                >
                  Add Tag
                </EuiButton>
              </EuiFlexItem>
            }
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='m' />

      <EuiFlexGroup gutterSize='m'>
        <EuiFlexItem>
          <EuiFieldSearch
            fullWidth
            placeholder='Search...'
            value={adminState.tagsSearch}
            onChange={(e) => {
              setSearch(e.target.value)
              if (e.target.value === '') {
                loadTags({ reset: true })
              }
            }}
            onSearch={(search) => {
              setSearch(search)
              if (search === '') return
              loadTags({ search: adminState.tagsSearch, reset: true })
            }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='m' />

      {tags?.length ?
        <>

          <AdminTable
            items={tags}
            columns={columns}
            tablePagination={tagsPagination}
            loadMore={loadTags}
            loading={loading}
            actionsPopover={actionsPopover}
            search={adminState.tagsSearch}
            onRowClick={viewTag}
          />
        </> :
        <>
          <EuiPanel>
            <EuiFlexGroup
              style={{
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '2rem'
              }}
              gutterSize='none'
            >
              <EuiFlexItem grow={false}>
                <EuiIcon type='tag' size='l' color='#4A4D55' />
              </EuiFlexItem>
              <EuiSpacer />
              <EuiFlexItem grow={false}>
                <EuiText color='#4A4D55' style={{ fontSize: '1.2em' }}>
                  Tags unavailable
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </>
      }
    </>
  )
}
