import React from 'react'
import {
  EuiHeader,
  EuiHeaderLogo,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiSuperSelect,
} from '@elastic/eui'
// import logo from '../../assets/heart.svg'
import logo from '../../assets/logo.svg'
import HeaderUserMenu from './header_user_menu'
import HeaderUpdates from './header_updates'
import DateRangeSelector from './date_range_selector'
import SearchBar from './search_bar'
import { useHistory } from 'react-router-dom'
import './header.css'

export const Header = (props) => {
  const history = useHistory()
  const {
    notifications,
    keycloak,
    query,
    updateQuery,
    date,
    updateDate,
    refresh,
    environment,
    updateEnvironment,
    envSelectorHidden,
  } = props
  const options = [
    {
      value: 'Production',
      inputDisplay: <span>Production</span>,
    },
    {
      value: 'Onboard',
      inputDisplay: <span>Onboard</span>,
    },
    {
      value: 'Staging',
      inputDisplay: <span>Staging</span>,
    },
    {
      value: 'Test',
      inputDisplay: <span>Test</span>,
    },
    {
      value: 'Debug',
      inputDisplay: <span>Debug</span>,
    },
  ]

  const onChangeEnvironment = (value) => {
    updateEnvironment(value)
  }
  return (
    <>
      <EuiHeader position='fixed'>
        <EuiHeaderSection grow={true}>
          <EuiHeaderSectionItem border='none'>
            <EuiHeaderLogo
              iconType={logo}
              aria-label='Go to home page'
              onClick={() => history.push('/')}
              className='header-logo'
            />
          </EuiHeaderSectionItem>
          {!envSelectorHidden && (
            <EuiHeaderSectionItem>
              <EuiSuperSelect
                style={{ width: '175px' }}
                options={options}
                valueOfSelected={environment}
                onChange={(value) => onChangeEnvironment(value)}
              />
            </EuiHeaderSectionItem>
          )}
          <EuiHeaderSectionItem border='none' style={{ width: '100%' }}>
            <SearchBar updateQuery={updateQuery} query={query} />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
        <EuiHeaderSection side='right'>
          <EuiHeaderSectionItem border='none'>
            <DateRangeSelector
              updateDate={updateDate}
              refresh={refresh}
              date={date}
            />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem border='none'>
            <HeaderUpdates notifications={notifications} />
          </EuiHeaderSectionItem>
          <EuiHeaderSectionItem border='none'>
            <HeaderUserMenu keycloak={keycloak} {...props} />
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
    </>
  )
}
