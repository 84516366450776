import React, { useState, useContext } from 'react'
import { v4 as uuid } from 'uuid'

import {
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiIcon,
} from '@elastic/eui'

import { EtorModalTable } from '..'
import { EtorContext } from '..'
import { EtorErrorsList } from '..'

export const EtorTabErrorWarnings = () => {
  const [activeTab, setActiveTab] = useState('structure-validation')
  const { activeMessage } = useContext(EtorContext)

  const tabs = [
    {
      id: 'structure-validation',
      name: 'Structure Validation',
      disabled: false,
      onClick: () => setActiveTab('structure-validation'),
    },
    {
      id: 'errors',
      name: 'Errors',
      disabled: false,
      onClick: () => setActiveTab('errors'),
    },
  ]

  const renderTabs = () => {
    return tabs.map((tab, idx) => (
      <EuiTab
        key={`etor-tab-errors-warnings-${idx}`}
        onClick={tab.onClick}
        isSelected={tab.id === activeTab}
        disabled={tab.disabled}
        id={idx}
      >
        {tab.name}
      </EuiTab>
    ))
  }

  const buildStructItems = (data) => {
    try {
      return data.map(d => {
        const parts = d.split(': ')
        return {
          Validation: parts[0],
          Message: parts[1],
          id: uuid()
        }
      })
    }
    catch {
      return []
    }
  }

  const tabContentSwitch = () => {
    switch (activeTab) {
      case 'structure-validation': {
        const structValCols = [
          {
            name: <EuiIcon type='pagesSelect' />,
            width: '32px',
            render: () => <EuiIcon type='document' />,
          },
          {
            field: 'Validation',
            name: 'Validation',
          },
          {
            field: 'Message',
            name: 'Message',
          },
        ]

        const structItems = buildStructItems(activeMessage?.ProjectData?.ETOR?.Warnings?.StructureValidation)

        return (
          <EtorModalTable
            columns={structValCols}
            items={structItems}
            id='etor-tab-errors-warnings-structure-validation'
          />
        )
      }
      case 'errors': {
        return (
          <EtorErrorsList
            errorMessage={activeMessage?.ProjectData?.ETOR?.Errors?.ErrorMessage}
            id='etor-tab-errors-warnings-errors'
          />
        )
      }
    }
  }

  if (!activeMessage) return (<></>)

  return (
    <>
      <EuiTabs>{renderTabs()}</EuiTabs>
      <EuiSpacer />
      {tabContentSwitch()}
    </>
  )
}
