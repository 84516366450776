import React, { useContext } from 'react'
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiToolTip,
  EuiBadge
} from '@elastic/eui'

import moment from 'moment'
import commaNumber from 'comma-number'

import { HealthIndicator } from '../stats/health_indicator'
import { ListItemToggleButton } from '..'
import { EntityTableHeader } from './table_header/entity_table_header'
import { ChildrenList } from '..'
import './entity_table.css'

import { AppContext } from '../../app'

export const SendersTable = ({
  title,
  onToggleFilter,
  filteringEnabled,
  items = [],
  addItem = () => {},
  removeItem = () => {},
  selectedItems = [],
  name = 'Name',
  loading = false,
  sort,
  onSort,
  entityType = 'sender'
}) => {
  const [rowsExpanded, setRowsExpanded] = React.useState([])
  const { offlineStatus } = useContext(AppContext)

  const sorting = { sort }
  const onTableChange = ({ sort }) => {
    onSort(sort)
  }
  const toggleRow = (toggled, id) => {
    let rowIndex = rowsExpanded.indexOf(id)

    toggled
      ? setRowsExpanded((prevRowsExpanded) => [...prevRowsExpanded, id])
      : setRowsExpanded((prevRowsExpanded) => {
          let expanded = prevRowsExpanded
          expanded.splice(rowIndex, 1)
          return [...expanded]
        })
  }

  const getOffline = (entityName) => {
    if (!offlineStatus[String(entityType)]) {
      return null
    }
    const offline = offlineStatus[String(entityType)][String(entityName)]
    if (!offlineStatus[String(entityType)][String(entityName)]) {
      return null
    }
    const now = moment()
    if (moment.unix(offline.StartTime) > now) {
      return null
    }
    if (moment.unix(offline.EndTime) < now) {
      return null
    }
    return offline
  }

  const columns = [
    {
      field: 'name',
      name,
      width: '70%',
      truncateText: true,
      sortable: true,
      render: (
        id,
        { name, description, status, children, displayName, parentCount }
      ) => {
        const offline = getOffline(name)
        if (children.length > 0) {
          let subsenderTotal = 0

          for (var child of children) {
            subsenderTotal += child.count
          }

          return (
            <ChildrenList
              key={name}
              data={children}
              name='data'
              id={name}
              displayName={displayName}
              description={description}
              status={status}
              addItem={addItem}
              removeItem={removeItem}
              selectedItems={selectedItems}
              toggleRow={toggleRow}
              toggled={rowsExpanded.includes(name)}
              parentTotal={parentCount}
              subsenderTotal={subsenderTotal}
            />
          )
        } else {
          return (
            <ListItemToggleButton
              addItem={addItem}
              removeItem={removeItem}
              id={id}
              displayName={displayName}
              isIncluded={selectedItems.includes(id)}
            >
              <EuiFlexGroup gutterSize='s' alignItems='center'>
                <EuiFlexItem>
                  {description ? (
                    <EuiToolTip content={description}>
                      <span>{displayName || name}</span>
                    </EuiToolTip>
                  ) : (
                    <span>{displayName || name}</span>
                  )}
                </EuiFlexItem>
                {status && <HealthIndicator {...status} />}
                {offline && <EuiBadge color='hollow' style={{ marginRight: '.5rem' }}>Offline</EuiBadge>}
              </EuiFlexGroup>
            </ListItemToggleButton>
          )
        }
      },
    },
    {
      field: 'count',
      name: 'Count',
      align: 'right',
      sortable: true,
      render: (count, { name, children, parentCount }) => {
        if (children.length > 0) {
          // parent cell
          let parentCountTotal = count
          let parentSelectedCountTotal = 0
          let childSelected = false
          for (var child of children) {
            if (selectedItems.includes(child.name)) {
              childSelected = true
              parentSelectedCountTotal += child.count
            }
          }

          if (parentCount) {
            parentSelectedCountTotal += parentCount
          }

          return (
            <>
              <EuiFlexGroup direction='column'>
                <EuiFlexItem>
                  <span>
                    {childSelected
                      ? parentSelectedCountTotal > 0
                        ? commaNumber(parentSelectedCountTotal)
                        : '...'
                      : parentCountTotal > 0
                      ? commaNumber(parentCountTotal)
                      : '...'}
                  </span>
                </EuiFlexItem>
              </EuiFlexGroup>
              {rowsExpanded.includes(name) && (
                <EuiFlexGroup
                  direction='column'
                  gutterSize='none'
                  justifyContent='center'
                >
                  {children.map((child, idx) => {
                    const topMargin = idx === 0 ? '8px' : '5px'
                    return (
                      <EuiFlexItem
                        key={`senders-table-rows-expanded-idx${idx}`}
                        style={{
                          marginTop: topMargin,
                          minHeight: '30px',
                        }}
                      >
                        <span
                          className='subsender_count'
                          style={{
                            margin: 'auto 0px',
                          }}
                        >
                          {child.count}
                        </span>
                      </EuiFlexItem>
                    )
                  })}
                </EuiFlexGroup>
              )}
            </>
          )
        } else {
          return <span>{count && commaNumber(count)}</span>
        }
      },
    },
  ]

  const getRowProps = (item) => {
    const { name, isCurrent } = item
    return {
      'data-test-subj': `row-${name}`,
      className: `senderCustomRow${
        selectedItems.includes(name) ? ' selected' : ''
      }${isCurrent ? '' : ' previous'}`,
    }
  }

  const getCellProps = (item, column) => {
    const { id, children } = item
    let childrenExist = !children.isEmpty
    const { field } = column
    const offline = getOffline(item.name)
    return {
      className: `customCell ${childrenExist ? 'parentCell' : ''} ${offline ? 'entity-offline' : ''}`,
      'data-test-subj': `cell-${id}-${field}`,
      textOnly: true,
    }
  }

  return (
    <EuiFlexItem className='EntityTable'>
      <EuiFlexGroup direction='column' gutterSize='s'>
        <EntityTableHeader
          title={title}
          items={items}
          onToggleFilter={onToggleFilter}
          filteringEnabled={filteringEnabled}
        />
        <EuiFlexItem>
          <EuiPanel
            className='customPanel'
            style={{
              overflow: 'auto',
              height: '325px',
            }}
          >
            <EuiBasicTable
              items={items}
              columns={columns}
              rowProps={getRowProps}
              cellProps={getCellProps}
              loading={loading}
              noItemsMessage={loading ? 'Loading...' : 'No Items Found'}
              sorting={sorting}
              onChange={onTableChange}
            />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
  )
}
