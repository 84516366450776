import axios from 'axios'
import keycloak from './keycloak'
import urljoin from 'url-join'
import {
  calculateHistogramInterval,
  formatForElasticsearch,
  getEsAbsoluteDatetime,
} from './date'
import config from './config'

axios.interceptors.response.use(
  (res) => Promise.resolve(res),
  (err) =>
    Promise.reject(
      err.response
        ? err.response && err.response.data && err.response.data
        : err
    )
)

const apiUrl = urljoin(config.API_URL, 'api')

const etorErrorTypes = [
  'ExtractionError',
  'MappingError',
  'PackagingError',
  'RoutingError',
  'TransportError',
  'ValidatorError',
]

const getHeaders = () => ({
  authorization: `Bearer ${keycloak.token}`,
})

const fetchUsers = (params) => {
  return axios(urljoin(apiUrl, 'users'), {
    headers: getHeaders(),
    params,
  })
}

const fetchAllUsers = () => {
  return axios(urljoin(apiUrl, 'users/export'), {
    headers: getHeaders(),
  })
}

const fetchUser = (userId) =>
  axios(`${apiUrl}/users/${userId}`, { headers: getHeaders() })

const fetchProjects = () =>
  axios(urljoin(apiUrl, 'projects'), { headers: getHeaders() })

const fetchUserProjects = (email) =>
  axios(urljoin(apiUrl, 'users'), {
    params: { email },
    headers: getHeaders(),
  })

const fetchUseCases = () =>
  axios(urljoin(apiUrl, 'use_cases'), { headers: getHeaders() })

const fetchSenders = () =>
  axios(urljoin(apiUrl, 'senders'), { headers: getHeaders() })

const fetchRecipients = () =>
  axios(urljoin(apiUrl, 'recipients'), { headers: getHeaders() })

const fetchNotifications = (offset, limit, search) => {
  const params = { offset, limit, q: search, grouped: 't' }
  return axios(urljoin(apiUrl, 'notifications'), {
    headers: getHeaders(),
    params,
  })
}

const fetchLatestNotifications = (offset, limit, date) => {
  const params = { date, offset, limit }
  return axios(urljoin(apiUrl, 'notifications'), {
    headers: getHeaders(),
    params,
  })
}

const deleteNotification = (id) => {
  const config = { headers: getHeaders() }
  return axios.delete(urljoin(apiUrl, `notifications/${id}`), config)
}

const deleteNotifications = (ids) => {
  const config = { headers: getHeaders() }
  const data = { ids }
  return axios.post(urljoin(apiUrl, `notifications/delete`), data, config)
}

const readNotification = (id) => {
  const config = { headers: getHeaders() }
  return axios.put(urljoin(apiUrl, `notifications/${id}/read`), {}, config)
}

const readNotifications = (ids, read) => {
  const config = { headers: getHeaders() }
  const data = { ids, read }
  return axios.post(urljoin(apiUrl, 'notifications/read'), data, config)
}

const readAllNotifications = () => {
  const config = { headers: getHeaders() }
  return axios.put(urljoin(apiUrl, `notifications/markAllRead`), {}, config)
}

const fetchNotificationSettings = (projects) => {
  const data = { projects }
  const config = { headers: getHeaders() }
  return axios.post(urljoin(apiUrl, 'notifications/settings'), data, config)
}

const updateNotificationSettings = (id, data) => {
  const config = { headers: getHeaders() }
  return axios.put(
    urljoin(apiUrl, `notifications/${id}/settings`),
    data,
    config
  )
}

const fetchNotificationSettingsGlobal = () => {
  const config = { headers: getHeaders() }
  return axios(urljoin(apiUrl, 'notifications/settingsGlobal'), config)
}

const updateNotificationSettingsGlobal = (id, data) => {
  const config = { headers: getHeaders() }
  return axios.put(
    urljoin(apiUrl, `notifications/${id}/settingsGlobal`),
    data,
    config
  )
}

const updateAllNotificationSettings = (data) => {
  const config = { headers: getHeaders() }
  return axios.put(urljoin(apiUrl, `notifications/settings`), data, config)
}

const createSupportTicket = (data) => {
  const config = { headers: getHeaders() }
  return axios.post(urljoin(apiUrl, `support`), data, config)
}

const fetchSupportTickets = () => {
  const config = { headers: getHeaders() }
  return axios(urljoin(apiUrl, 'support'), config)
}

const deleteSupportTicket = (id) => {
  const config = { headers: getHeaders() }
  return axios.delete(urljoin(apiUrl, `support/${id}`), config)
}

const fetchOffline = () => {
  const config = { headers: getHeaders() }
  return axios(urljoin(apiUrl, 'offline'), config)
}

const updateOffline = (entityType, entityName, data) => {
  const config = { headers: getHeaders() }
  return axios.post(
    urljoin(apiUrl, `offline/${entityType}/${entityName}`),
    data,
    config
  )
}

const deleteOffline = (entityType, entityName) => {
  const config = { headers: getHeaders() }
  return axios.delete(
    urljoin(apiUrl, `offline/${entityType}/${entityName}`),
    config
  )
}

const fetchExports = () => {
  const config = { headers: getHeaders() }
  return axios(urljoin(apiUrl, 'exports'), config)
}

const createExport = (data) => {
  const config = { headers: getHeaders() }
  return axios.post(urljoin(apiUrl, 'exports'), data, config)
}

const deleteExport = (id) => {
  const config = { headers: getHeaders() }
  return axios.delete(urljoin(apiUrl, `exports/${id}`), config)
}

const retryExport = (id) => {
  const config = { headers: getHeaders() }
  return axios(urljoin(apiUrl, `exports/${id}/retry`), config)
}

const downloadExport = (id) => {
  const config = { headers: getHeaders() }
  return axios(urljoin(apiUrl, `exports/${id}/download`), config)
}

const fetchOnboard = (params) => {
  const config = { headers: getHeaders(), params }
  return axios(urljoin(apiUrl, 'onboard'), config)
}

const createOnboard = (data) => {
  const config = { headers: getHeaders() }
  return axios.post(urljoin(apiUrl, 'onboard'), data, config)
}

const fetchTags = (params) =>
  axios(`${apiUrl}/tags`, { headers: getHeaders(), params }).then(
    ({ data }) => data
  )

const fetchUserTags = (userId) =>
  axios(`${apiUrl}/tags/${userId}`, { headers: getHeaders() })

const mapToIds = (arr = []) => arr.map((obj) => (obj && obj.id) || obj)

const createUser = (user) => {
  const trimUser = { ...user }
  if (trimUser.tags) {
    trimUser.tags = mapToIds(trimUser.tags)
  }
  return axios
    .post(`${apiUrl}/users`, { user: trimUser }, { headers: getHeaders() })
    .then(({ data }) => data)
}

const updateUser = (user) => {
  const trimUser = { ...user }
  if (trimUser.tags) {
    trimUser.tags = mapToIds(trimUser.tags)
  }
  return axios
    .put(
      `${apiUrl}/users/${user.id}`,
      { user: trimUser },
      { headers: getHeaders() }
    )
    .then(({ data }) => data)
}

const createTag = (tag) =>
  axios
    .post(`${apiUrl}/tags`, { ...tag }, { headers: getHeaders() })
    .then(({ data }) => data)

const updateTag = (tag) => {
  return axios
    .put(`${apiUrl}/tags/${tag.id}`, { tag }, { headers: getHeaders() })
    .then(({ data }) => data)
}

const deleteTag = (tagId) => {
  return axios
    .delete(`${apiUrl}/tags/${tagId}`, { headers: getHeaders() })
    .then(({ data }) => data)
}

const deleteUser = (userId) =>
  axios
    .delete(`${apiUrl}/users/${userId}`, { headers: getHeaders() })
    .then(({ data }) => data)

const deleteUsers = (ids) => {
  const config = { headers: getHeaders() }
  const data = { ids }
  return axios.post(urljoin(apiUrl, 'users/delete'), data, config)
}

const getUserRoles = (userId) => {
  const config = { headers: getHeaders() }
  const endpoint = `users/${userId}/roles`
  return axios(urljoin(apiUrl, endpoint), config)
}

const getAvailRoles = (data) => {
  const config = { headers: getHeaders() }
  return axios.post(urljoin(apiUrl, 'users/roles'), data, config)
}

const getAvailUserRoles = (clientId, userId) => {
  const config = { headers: getHeaders() }
  const endpoint = `users/${userId}/clients/${clientId}/roles`
  return axios(urljoin(apiUrl, endpoint), config)
}

const getEtorAggregation = (data) => {
  const config = { headers: getHeaders() }
  const endpoint = 'messages/aggregations'
  return axios.post(urljoin(apiUrl, endpoint), data, config)
}

const fetchMessages = ({
  start,
  end,
  projects,
  useCases,
  senders,
  subsenders,
  recipients,
  statuses,
  filename,
  size,
  count,
  text,
  aggs,
  from,
  environment,
  sort,
  projectData,
}) => {
  const params = {}
  if (start) {
    const absoluteStart = getEsAbsoluteDatetime(start)
    params.start = absoluteStart
  }
  if (end) {
    const absoluteEnd = getEsAbsoluteDatetime(end)
    params.end = absoluteEnd
  }
  if (projects) {
    params.projects = projects
  }
  if (useCases) {
    params.useCases = useCases
  }
  if (senders) {
    params.senders = senders
  }
  if (subsenders) {
    params.subsenders = subsenders
  }
  if (recipients) {
    params.recipients = recipients
  }
  if (filename) {
    params.filename = filename
  }
  if (statuses) {
    params.statuses = statuses
  }
  if (typeof size !== 'undefined') {
    params.size = size
  }
  if (typeof count !== 'undefined') {
    params.count = count
  }
  if (text) {
    params.text = text
  }
  if (aggs) {
    params.aggs = aggs
  }
  if (typeof from !== 'undefined') {
    params.from = from
  }
  if (environment) {
    params.environment = environment
  }
  if (sort) {
    params.sort = sort
  }
  if (projectData) {
    params.projectData = projectData
  }
  return axios.post(urljoin(apiUrl, 'messages'), params, {
    headers: getHeaders(),
  })
}

const fetchMessagesByProject = ({
  start,
  end,
  projects,
  useCases,
  senders,
  subsenders,
  recipients,
  statuses,
  filename,
  size,
  count,
  text,
  aggs,
  from,
  environment,
  sort,
  projectData,
  eventTypes,
  warnings,
}) => {
  const project = projects[0]
  const params = {}
  if (start) {
    const absoluteStart = getEsAbsoluteDatetime(start)
    params.start = absoluteStart
  }
  if (end) {
    const absoluteEnd = getEsAbsoluteDatetime(end)
    params.end = absoluteEnd
  }
  if (projects) {
    params.projects = projects
  }
  if (useCases) {
    params.useCases = useCases
  }
  if (senders) {
    params.senders = senders
  }
  if (subsenders) {
    params.subsenders = subsenders
  }
  if (recipients) {
    params.recipients = recipients
  }
  if (filename) {
    params.filename = filename
  }
  if (statuses) {
    params.statuses = statuses
  }
  if (typeof size !== 'undefined') {
    params.size = size
  }
  if (typeof count !== 'undefined') {
    params.count = count
  }
  if (text) {
    params.text = text
  }
  if (aggs) {
    params.aggs = aggs
  }
  if (typeof from !== 'undefined') {
    params.from = from
  }
  if (environment) {
    params.environment = environment
  }
  if (sort) {
    params.sort = sort
  }
  if (projectData) {
    params.projectData = projectData
  }
  if (eventTypes) {
    params.eventTypes = eventTypes
  }
  if (warnings) {
    params.warnings = warnings
  }
  return axios.post(urljoin(apiUrl, `messages/${project}`), params, {
    headers: getHeaders(),
  })
}

const statusAggs = {
  status: {
    terms: {
      field: 'Status',
    },
  },
}

const etorStatusAggs = {
  status: {
    terms: {
      field: 'Status.keyword',
    },
  },
}

const reduceStatusBuckets = (buckets, doTotal) => {
  return buckets.reduce(
    (counts, { key, doc_count, status, parentCount }) => {
      counts[String(key)] = status.buckets.reduce(
        (statusCounts, { key, doc_count }) => {
          if (parentCount && parentCount?.doc_count > 0) {
            statusCounts['parentCount'] = parentCount?.doc_count
          }

          if (key === 'processed') {
            statusCounts.processed = doc_count
            if (doTotal) {
              counts.total.processed += doc_count
            }
          } else if (key === 'rejected') {
            statusCounts.rejected = doc_count
            if (doTotal) {
              counts.total.rejected += doc_count
            }
          } else if (key === 'error') {
            statusCounts.errors = doc_count
            if (doTotal) {
              counts.total.errors += doc_count
            }
          } else {
            statusCounts.processing += doc_count
            if (doTotal) {
              counts.total.processing += doc_count
            }
          }
          statusCounts.total = doc_count
          return statusCounts
        },
        { processed: 0, processing: 0, rejected: 0, errors: 0 }
      )
      counts[String(key)].total = doc_count
      return counts
    },
    doTotal
      ? { total: { processed: 0, processing: 0, rejected: 0, errors: 0 } }
      : {}
  )
}

const reduceEtorStatusBuckets = (buckets, doTotal) => {
  return buckets.reduce(
    (counts, { key, doc_count, status, parentCount }) => {
      counts[String(key)] = status.buckets.reduce(
        (statusCounts, { key, doc_count }) => {
          if (parentCount && parentCount?.doc_count > 0) {
            statusCounts['parentCount'] = parentCount?.doc_count
          }

          if (key === 'Routed') {
            statusCounts.routed = doc_count
            if (doTotal) {
              counts.total.routed += doc_count
            }
          } else if (key === 'Processing') {
            statusCounts.processing = doc_count
            if (doTotal) {
              counts.total.processing += doc_count
            }
          } else if (etorErrorTypes.includes(key)) {
            statusCounts[String(key)] = doc_count
            if (doTotal) {
              counts.total.errors += doc_count
            }
          } else {
            statusCounts.unknown += doc_count
            if (doTotal) {
              counts.total.unknown += doc_count
            }
          }
          statusCounts.total = doc_count
          return statusCounts
        },
        { routed: 0, processing: 0, unknown: 0, errors: 0 }
      )
      counts[String(key)].total = doc_count
      return counts
    },
    doTotal
      ? { total: { routed: 0, processing: 0, unknown: 0, errors: 0 } }
      : {}
  )
}

const entityAggSizes = {
  projects: 50,
  useCases: 100,
  senders: 500,
  recipients: 1000,
  eventTypes: 50
}

async function fetchMessageCounts({
  projects,
  useCases,
  senders,
  subsenders = [],
  recipients,
  environment,
  date,
  query,
  projectData,
  eventTypes,
  warnings,
}) {
  const q = {
    count: true,
    projects,
    useCases,
    senders,
    subsenders,
    recipients,
    environment,
    start: date[0],
    end: date[1],
    text: query,
    projectData,
    aggs: {
      projects: {
        terms: {
          field: 'Project.keyword',
          size: (projects && projects.length) || entityAggSizes.projects,
        },
        aggs: statusAggs,
      },
      useCases: {
        terms: {
          field: 'UseCase.keyword',
          size: (useCases && useCases.length) || entityAggSizes.useCases,
        },
        aggs: statusAggs,
      },
      senders: {
        terms: {
          field: 'Senders.keyword',
          size: (senders && senders.length) || entityAggSizes.senders,
        },
        aggs: {
          ...statusAggs,
          parentCount: {
            filter: {
              bool: {
                must_not: [
                  {
                    exists: {
                      field: "SenderData.ParentSenderId"
                    }
                  }
                ]
              }
            },
          },
        },
      },
      subsenders: {
        filter: {
          exists: {
            field: 'SenderData.ParentSenderId'
          }
        },
        aggs: {
          SenderId: {
            terms: {
              field: 'SenderData.SenderId.keyword',
              size: (subsenders && subsenders.length) || 10000,
            },
            aggs: statusAggs,
          },
        },
      },
      recipients: {
        terms: {
          field: 'Recipients',
          size: (recipients && recipients.length) || entityAggSizes.recipients,
        },
        aggs: statusAggs,
      },
    },
  }
  if (eventTypes) {
    q.eventTypes = eventTypes
    q.aggs.eventTypes = {
      terms: {
        field: 'ProjectData.ETOR.EventType.keyword',
        size: (eventTypes && eventTypes.length) || entityAggSizes.eventTypes,
      },
      aggs: statusAggs,
    }
  }
  if (warnings) {
    q.warnings = warnings
    q.aggs.warnings = {
      filters: {
        other_bucket: true,
        filters: [
            {
                exists: {
                    field: 'ProjectData.ETOR.Warnings.StructureValidation',
                }
            },
            {
                exists: {
                    field: 'ProjectData.ETOR.Warnings.Untranscode*',
                }
            }
        ]
      },
      aggs: statusAggs,
    }
  }
  const { aggs } = await fetchMessages(q).then(({ data }) => data)

  const projectCounts = reduceStatusBuckets(aggs.projects.buckets, true)
  const total = Object.assign({}, projectCounts.total)
  delete projectCounts.total

  const useCaseCounts = reduceStatusBuckets(aggs.useCases.buckets)
  const senderCounts = reduceStatusBuckets(aggs.senders.buckets)

  const subsenderCounts = reduceStatusBuckets(aggs.subsenders.SenderId.buckets)
  const recipientCounts = reduceStatusBuckets(aggs.recipients.buckets)

  const result = {
    total,
    projectCounts,
    useCaseCounts,
    senderCounts,
    subsenderCounts,
    recipientCounts,
  }

  if (eventTypes) {
    result.eventTypeCounts = reduceStatusBuckets(aggs.eventTypes.buckets)
  }
  if (warnings) {
    const warningCounts = aggs.warnings.buckets
    warningCounts[0].key = 'Structure Validation'
    warningCounts[1].key = 'Transcoding Failure'
    warningCounts[2].key = 'None'
    result.warningCounts = reduceStatusBuckets(warningCounts)
  }

  return result
}

async function fetchLastQtrCounts({ projects, environment, start, end, interval }) {
  const q = {
    count: true,
    projects,
    environment,
    start,
    end,
    aggs: {
      per_interval: {
        date_histogram: {
          field: 'MessageDateTime',
          calendar_interval: interval,
          format: 'yyyy-MM-dd',
          extended_bounds: {
            min: start,
            max: end
          }
        },
      }
    }
  }

  const resp = await fetchMessages(q).then(({ data }) => data)

  const counts = resp.aggs.per_interval.buckets.map(b => b.doc_count)

  const result = {
    total: resp.total,
    counts
  }
  return result
}

const buildEtorMessageCountsQuery = ({
  projects,
  useCases,
  senders,
  subsenders = [],
  recipients,
  environment,
  date,
  query,
  projectData, 
  eventTypes,
  warnings,
}) => {
  return {
    count: true,
    projects,
    useCases,
    senders,
    subsenders,
    recipients,
    environment,
    start: date[0],
    end: date[1],
    text: query,
    projectData,
    eventTypes,
    warnings,
    aggs: {
      projects: {
        terms: {
          field: 'Project.keyword',
          size: (projects && projects.length) || entityAggSizes.projects,
        },
        aggs: etorStatusAggs,
      },
      useCases: {
        terms: {
          field: 'UseCase.keyword',
          size: (useCases && useCases.length) || entityAggSizes.useCases,
        },
        aggs: etorStatusAggs,
      },
      senders: {
        terms: {
          field: 'Senders.keyword',
          size: (senders && senders.length) || entityAggSizes.senders,
        },
        aggs: {
          ...etorStatusAggs,
          parentCount: {
            filter: {
              script: {
                script: {
                  source:
                    "doc['Senders.keyword'] == doc['SenderData.SenderId.keyword']",
                  lang: 'painless',
                },
              },
            },
          },
        },
      },
      subsenders: {
        filter: {
          exists: {
            field: 'SenderData.ParentSenderId'
          }
        },
        aggs: {
          SenderId: {
            terms: {
              field: 'SenderData.SenderId.keyword',
              size: (subsenders && subsenders.length) || 10000,
            },
            aggs: etorStatusAggs,
          },
        },
      },
      recipients: {
        terms: {
          field: 'Recipients',
          size: (recipients && recipients.length) || entityAggSizes.recipients,
        },
        aggs: etorStatusAggs,
      },
      eventTypes: {
        terms: {
          field: 'ProjectData.ETOR.EventType.keyword',
          size: (eventTypes && eventTypes.length) || entityAggSizes.eventTypes,
        },
        aggs: etorStatusAggs,
      },
      warnings: {
        filters: {
          other_bucket: true,
          filters: [
              {
                  exists: {
                      field: 'ProjectData.ETOR.Warnings.StructureValidation',
                  }
              },
              {
                  exists: {
                      field: 'ProjectData.ETOR.Warnings.Untranscode*',
                  }
              }
          ]
        },
        aggs: etorStatusAggs,
      },
    },
  }
}

const messageCountsByProjectQueryHelpers = {
  ETOR: {
    buildMessageCountsQuery: buildEtorMessageCountsQuery,
    reduceStatusBuckets: reduceEtorStatusBuckets,
  }
}

async function fetchMessageCountsByProject(queryData) {
  const projectName = queryData?.projects[0]?.toUpperCase()
  const { buildMessageCountsQuery, reduceStatusBuckets } = messageCountsByProjectQueryHelpers[String(projectName)]
  const q = buildMessageCountsQuery(queryData)
  const { aggs } = await fetchMessagesByProject(q).then(({ data }) => data)

  const projectCounts = reduceStatusBuckets(aggs.projects.buckets, true)
  const total = Object.assign({}, projectCounts.total)
  delete projectCounts.total

  const useCaseCounts = reduceStatusBuckets(aggs.useCases.buckets)
  const senderCounts = reduceStatusBuckets(aggs.senders.buckets)

  const subsenderCounts = reduceStatusBuckets(aggs.subsenders.SenderId.buckets)
  const recipientCounts = reduceStatusBuckets(aggs.recipients.buckets)

  const result = {
    total,
    projectCounts,
    useCaseCounts,
    senderCounts,
    subsenderCounts,
    recipientCounts,
  }

  if (projectName === 'ETOR') {
    result.eventTypeCounts = reduceStatusBuckets(aggs.eventTypes.buckets)
    const warningBuckets = aggs.warnings.buckets
    warningBuckets[0].key = 'Structure Validation'
    warningBuckets[1].key = 'Transcoding Failure'
    warningBuckets[2].key = 'None'
    result.warningCounts = reduceStatusBuckets(warningBuckets)    
  }

  return result
}

async function fetchEventTypes({
  environment,
}) {
  const { aggs } = await fetchMessages({
    count: true,
    environment,
    aggs: {
      eventTypes: {
        terms: {
          field: 'ProjectData.ETOR.EventType.keyword',
          size: entityAggSizes.eventTypes,
        },
      },
    },
  }).then(({ data }) => data)

  const eventTypes = aggs.eventTypes.buckets.map(({ key }) => ({ name: key }))
  return {
    eventTypes
  }
}

const reduceHistoBuckets = ({
  buckets,
  entityTypeLabel,
  idBuilder = ({ entityTypeLabel, key }) =>
    `${entityTypeLabel}${entityTypeLabel && ': '}${key}`,
}) =>
  buckets.reduce((groups, { key, histo: { buckets = [] } }) => {
    groups.push({
      id: idBuilder({ entityTypeLabel, key }),
      data: buckets.reduce((counts, { key, doc_count }) => {
        counts.push({ x: key, y: doc_count })
        return counts
      }, []),
    })
    return groups
  }, [])

async function fetchMessageCountsOverTime({
  projects = [],
  useCases = [],
  senders = [],
  sendersByName = {},
  subsenders = [],
  recipients = [],
  statuses = [],
  eventTypes = [],
  warnings = [],
  environment,
  date,
  query,
}) {
  const interval = calculateHistogramInterval({ date, buckets: 288 })
  const date_histogram = {
    interval,
    field: 'MessageDateTime',
    min_doc_count: 0,
    extended_bounds: {
      min: formatForElasticsearch(date[0]),
      max: formatForElasticsearch(date[1]),
    },
  }

  const entityHistoAggs = {
    histo: { date_histogram },
  }

  const histos = {
    Total: {
      date_histogram,
    },
  }

  if (projects.length) {
    histos.projects = {
      terms: {
        field: 'Project.keyword',
      },
      aggs: entityHistoAggs,
    }
  }

  if (useCases.length) {
    histos.useCases = {
      terms: {
        field: 'UseCase.keyword',
      },
      aggs: entityHistoAggs,
    }
  }

  if (senders.length) {
    histos.senders = {
      terms: {
        field: 'Senders.keyword',
      },
      aggs: entityHistoAggs,
    }
  }

  if (subsenders.length) {
    histos.subsenders = {
      filter: { terms: { 'SenderData.SenderId.keyword': subsenders } },
      aggs: {
        SenderId: {
          terms: { field: 'SenderData.SenderId.keyword' },
          aggs: entityHistoAggs,
        },
      },
    }
  }

  if (recipients.length) {
    histos.recipients = {
      terms: {
        field: 'Recipients',
      },
      aggs: entityHistoAggs,
    }
  }

  if (eventTypes.length) {
    histos.eventTypes = {
      terms: {
        field: 'ProjectData.ETOR.EventType.keyword',
      },
      aggs: entityHistoAggs,
    }
  }

  if (warnings.length) {
    histos.warnings = {
      filters: {
        other_bucket: true,
        filters: [
            {
                exists: {
                    field: 'ProjectData.ETOR.Warnings.StructureValidation',
                }
            },
            {
                exists: {
                    field: 'ProjectData.ETOR.Warnings.Untranscode*',
                }
            }
        ]
      },
      aggs: entityHistoAggs,
    }
  }

  const { aggs } = await fetchMessages({
    count: true,
    projects,
    useCases,
    senders,
    subsenders,
    recipients,
    statuses,
    environment,
    eventTypes,
    warnings,
    start: date[0],
    end: date[1],
    text: query,
    aggs: histos,
  }).then(({ data }) => data)

  let allHistos = aggs.Total.buckets.reduce(
    (counts, { key, doc_count }) => {
      const [total] = counts
      total.data.push({ x: key, y: doc_count })
      return counts
    },
    [{ id: 'Total', data: [] }]
  )

  if (aggs.projects) {
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: aggs.projects.buckets,
        entityTypeLabel: 'Project',
      })
    )
  }

  if (aggs.useCases) {
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: aggs.useCases.buckets,
        entityTypeLabel: 'Use Case',
      })
    )
  }

  if (aggs.senders) {
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: aggs.senders.buckets,
        entityTypeLabel: 'Sender',
      })
    )
  }

  if (aggs.subsenders) {
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: aggs.subsenders.SenderId.buckets,
        entityTypeLabel: 'Sender',
        idBuilder: ({ entityTypeLabel, key }) =>
          `${entityTypeLabel}${entityTypeLabel && ': '}${
            (sendersByName[String(key)] &&
              sendersByName[String(key)].displayName) ||
            key
          }`,
      })
    )
  }

  if (aggs.recipients) {
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: aggs.recipients.buckets,
        entityTypeLabel: 'Recipient',
      })
    )
  }

  if (aggs.eventTypes) {
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: aggs.eventTypes.buckets,
        entityTypeLabel: 'Event Type',
      })
    )
  }
  
  if (aggs.warnings) {
    const warningBuckets = aggs.warnings.buckets
    warningBuckets[0].key = 'Structure Validation'
    warningBuckets[1].key = 'Transcoding Failure'
    warningBuckets[2].key = 'None'
    const filteredWarningBuckets = warningBuckets.filter( bucket => warnings.includes(bucket.key))
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: filteredWarningBuckets,
        entityTypeLabel: 'Warning',
      })
    )
  }

  return {
    interval,
    counts: allHistos,
  }
}

async function fetchMessageCountsOverTimeByProject({
  projects = [],
  useCases = [],
  senders = [],
  sendersByName = {},
  subsenders = [],
  recipients = [],
  statuses = [],
  eventTypes = [],
  warnings = [],
  environment,
  date,
  query,
}) {
  const interval = calculateHistogramInterval({ date, buckets: 288 })
  const date_histogram = {
    interval,
    field: 'MessageDateTime',
    min_doc_count: 0,
    extended_bounds: {
      min: formatForElasticsearch(date[0]),
      max: formatForElasticsearch(date[1]),
    },
  }

  const entityHistoAggs = {
    histo: { date_histogram },
  }

  const histos = {
    Total: {
      date_histogram,
    },
  }

  if (projects.length) {
    histos.projects = {
      terms: {
        field: 'Project.keyword',
      },
      aggs: entityHistoAggs,
    }
  }

  if (useCases.length) {
    histos.useCases = {
      terms: {
        field: 'UseCase.keyword',
      },
      aggs: entityHistoAggs,
    }
  }

  if (senders.length) {
    histos.senders = {
      terms: {
        field: 'Senders.keyword',
      },
      aggs: entityHistoAggs,
    }
  }

  if (subsenders.length) {
    histos.subsenders = {
      filter: { terms: { 'SenderData.SenderId.keyword': subsenders } },
      aggs: {
        SenderId: {
          terms: { field: 'SenderData.SenderId.keyword' },
          aggs: entityHistoAggs,
        },
      },
    }
  }

  if (recipients.length) {
    histos.recipients = {
      terms: {
        field: 'Recipients',
      },
      aggs: entityHistoAggs,
    }
  }

  if (eventTypes.length) {
    histos.eventTypes = {
      terms: {
        field: 'ProjectData.ETOR.EventType.keyword',
      },
      aggs: entityHistoAggs,
    }
  }

  if (warnings.length) {
    histos.warnings = {
      filters: {
        other_bucket: true,
        filters: [
            {
                exists: {
                    field: 'ProjectData.ETOR.Warnings.StructureValidation',
                }
            },
            {
                exists: {
                    field: 'ProjectData.ETOR.Warnings.Untranscode*',
                }
            }
        ]
      },
      aggs: entityHistoAggs,
    }
  }

  const { aggs } = await fetchMessagesByProject({
    count: true,
    projects,
    useCases,
    senders,
    subsenders,
    recipients,
    statuses,
    environment,
    eventTypes,
    warnings,
    start: date[0],
    end: date[1],
    text: query,
    aggs: histos,
  }).then(({ data }) => data)

  let allHistos = aggs.Total.buckets.reduce(
    (counts, { key, doc_count }) => {
      const [total] = counts
      total.data.push({ x: key, y: doc_count })
      return counts
    },
    [{ id: 'Total', data: [] }]
  )

  if (aggs.projects) {
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: aggs.projects.buckets,
        entityTypeLabel: 'Project',
      })
    )
  }

  if (aggs.useCases) {
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: aggs.useCases.buckets,
        entityTypeLabel: 'Use Case',
      })
    )
  }

  if (aggs.senders) {
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: aggs.senders.buckets,
        entityTypeLabel: 'Sender',
      })
    )
  }

  if (aggs.subsenders) {
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: aggs.subsenders.SenderId.buckets,
        entityTypeLabel: 'Sender',
        idBuilder: ({ entityTypeLabel, key }) =>
          `${entityTypeLabel}${entityTypeLabel && ': '}${
            (sendersByName[String(key)] &&
              sendersByName[String(key)].displayName) ||
            key
          }`,
      })
    )
  }

  if (aggs.recipients) {
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: aggs.recipients.buckets,
        entityTypeLabel: 'Recipient',
      })
    )
  }

  if (aggs.eventTypes) {
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: aggs.eventTypes.buckets,
        entityTypeLabel: 'Event Type',
      })
    )
  }
  
  if (aggs.warnings) {
    const warningBuckets = aggs.warnings.buckets
    warningBuckets[0].key = 'Structure Validation'
    warningBuckets[1].key = 'Transcoding Failure'
    warningBuckets[2].key = 'None'
    const filteredWarningBuckets = warningBuckets.filter( bucket => warnings.includes(bucket.key))
    allHistos = [].concat(
      allHistos,
      reduceHistoBuckets({
        buckets: filteredWarningBuckets,
        entityTypeLabel: 'Warning',
      })
    )
  }

  return {
    interval,
    counts: allHistos,
  }
}

const createGroup = (data) => {
  const config = { headers: getHeaders() }
  return axios.post(urljoin(apiUrl, 'groups'), data, config)
}

const fetchGroup = (groupId) => {
  return axios(urljoin(apiUrl, `groups/${groupId}`), {
    headers: getHeaders()
  })
}

const managerFetchGroup = (groupId) => {
  return axios(urljoin(apiUrl, `groups/${groupId}/manager`), {
    headers: getHeaders()
  })
}

const fetchGroups = (params) => {
  return axios(urljoin(apiUrl, 'groups'), {
    headers: getHeaders(),
    params,
  })
}

const fetchUsersGroups = (params) => {
  return axios(urljoin(apiUrl, 'users/groups'), {
    headers: getHeaders(),
    params,
  })
}

const fetchUserGroups = () => {
  return axios(urljoin(apiUrl, 'groups/user'), {
    headers: getHeaders(),
  })
}

const fetchManagerGroups = (params) => {
  return axios(urljoin(apiUrl, 'groups/manager'), {
    headers: getHeaders(),
    params,
  })
}

const fetchGroupNotiSettings = (groupId) => {
  return axios(urljoin(apiUrl, `groups/${groupId}/notificationSettings`), {
    headers: getHeaders()
  })
}

const fetchGroupManagerNotiSettings = (groupId) => {
  return axios(urljoin(apiUrl, `groups/${groupId}/manager/notificationSettings`), {
    headers: getHeaders()
  })
}

const updateGroup = (groupId, data) => {
  return axios.put(urljoin(apiUrl, `groups/${groupId}`),
    { ...data },
    { headers: getHeaders() }
  )
}

const managerUpdateGroup = (groupId, data) => {
  return axios.put(urljoin(apiUrl, `groups/${groupId}/manager`),
    { ...data },
    { headers: getHeaders() }
  )
}

const deleteGroup = (groupId) => {
  return axios.delete(urljoin(apiUrl, `groups/${groupId}`), {
    headers: getHeaders()
  })
}

const updateGroupNotis = (groupId, data) => {
  return axios.put(
    urljoin(apiUrl, `groups/${groupId}/notificationSettings`), 
    { ...data },
    { headers: getHeaders()}
  )
}

const managerUpdateGroupNotis = (groupId, data) => {
  return axios.put(
    urljoin(apiUrl, `groups/${groupId}/manager/notificationSettings`), 
    { ...data },
    { headers: getHeaders()}
  )
}

const api = {
  fetchUsers,
  fetchAllUsers,
  fetchUser,
  fetchProjects,
  fetchUserProjects,
  fetchUseCases,
  fetchSenders,
  fetchRecipients,
  fetchNotifications,
  fetchLatestNotifications,
  deleteNotification,
  deleteNotifications,
  readNotification,
  readNotifications,
  readAllNotifications,
  fetchNotificationSettings,
  updateNotificationSettings,
  fetchNotificationSettingsGlobal,
  updateNotificationSettingsGlobal,
  updateAllNotificationSettings,
  createSupportTicket,
  fetchMessages,
  fetchMessagesByProject,
  fetchMessageCounts,
  fetchMessageCountsByProject,
  fetchMessageCountsOverTime,
  fetchMessageCountsOverTimeByProject,
  fetchTags,
  fetchUserTags,
  createUser,
  updateUser,
  deleteUser,
  deleteUsers,
  createTag,
  updateTag,
  deleteTag,
  fetchOnboard,
  createOnboard,
  fetchOffline,
  updateOffline,
  deleteOffline,
  fetchExports,
  createExport,
  downloadExport,
  retryExport,
  deleteExport,
  fetchSupportTickets,
  deleteSupportTicket,
  getAvailRoles,
  getAvailUserRoles,
  getUserRoles,
  getEtorAggregation,
  fetchEventTypes,
  createGroup,
  fetchGroup,
  managerFetchGroup,
  fetchGroups,
  deleteGroup,
  fetchManagerGroups,
  fetchGroupNotiSettings,
  fetchGroupManagerNotiSettings,
  updateGroup,
  managerUpdateGroup,
  updateGroupNotis,
  managerUpdateGroupNotis,
  fetchUsersGroups,
  fetchUserGroups,
  fetchLastQtrCounts
}

export default api
