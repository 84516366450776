import React, { useContext } from 'react'

import {
  EuiIcon,
  EuiButtonIcon,
  EuiLink,
  EuiSpacer,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableHeaderCellCheckbox,
  EuiTableRow,
  EuiTableRowCell,
  EuiText,
  EuiPanel
} from '@elastic/eui'

import { LEFT_ALIGNMENT } from '@elastic/eui/lib/services'

import { AppContext } from '../../app'

export const RulesList = ({
  rules,
  hideDelete,
  removeRule = () => {},
  view = 'user'
}) => {
  const { displayNames } = useContext(AppContext)

  const columns = [
    {
      id: 'icon',
      label: <EuiIcon type='pagesSelect' />,
      alignment: LEFT_ALIGNMENT,
      width: '32px',
      textOnly: false,
      cellProvider: () => <EuiIcon type='document' />,
      mobileOptions: {
        show: false,
      },
    },
    {
      id: 'resourceType',
      label: 'Resource',
      alignment: LEFT_ALIGNMENT,

      truncateText: true,
    },
    {
      id: 'principalType',
      label: 'Type',
      alignment: LEFT_ALIGNMENT,

      truncateText: true,
    },

    {
      id: 'resourceName',
      label: 'Name',

      alignment: LEFT_ALIGNMENT,
      truncateText: true,
    },
    {
      id: 'action',
      label: 'Action',
      alignment: LEFT_ALIGNMENT,

      truncateText: true,
    },
    {
      id: 'effect',
      label: 'Access',
      alignment: LEFT_ALIGNMENT,

      truncateText: true,
    },
    {
      id: 'actions',
      label: '',
      alignment: LEFT_ALIGNMENT,
      width: '50px',
      textOnly: false,
      cellProvider: ({ rule, ruleIndex }) => {
        if (hideDelete) {
          return (<></>)
        }
        if (view === 'user' && rule.principalType !== 'user') {
          return (<></>)
        }
        return (
          <EuiButtonIcon
            iconType='trash'
            aria-label='Trash'
            onClick={() => removeRule(ruleIndex)}
          />
        )
      },
    },
  ]

  const renderHeaderCells = () => {
    const headers = []

    columns.forEach((column, columnIndex) => {
      if (column.isCheckbox) {
        headers.push(
          <EuiTableHeaderCellCheckbox
            key={column.id}
            width={column.width}
            className='rules-table-header fade-up'
          ></EuiTableHeaderCellCheckbox>
        )
      } else {
        headers.push(
          <EuiTableHeaderCell
            style={{ padding: 0 }}
            key={column.id}
            align={columns[String(columnIndex)].alignment}
            width={column.width}
            className='rules-table-header fade-up'
          >
            {column.label}
          </EuiTableHeaderCell>
        )
      }
    })
    return headers.length ? headers : null
  }

  const resourceReference = {
    sender: 'Sender',
    project: 'Project',
    useCase: 'Use Case',
    subsender: 'Subsender',
    recipient: 'Recipient',
    '*': '*'
  }

  const formatCell = (column, rule, cellData) => {
    if (column === 'resourceName') {
      try {
        const resourceType = rule.resourceType
        if (resourceType === 'subsender') {
          return displayNames['senders'][String(cellData)]
        } else {
          return displayNames[String(resourceType)][String(cellData)]
        }
      } catch (e) {
        return cellData
      }
    } else if (column === 'resourceType') {
      return resourceReference[String(cellData)]
    } else {
      return cellData
    }
  }

  const renderRows = () => {
    const renderRow = (rule, ruleIndex) => {
      const cells = columns.map((column) => {
        const cellData = rule[column.id]

        let child

        if (column.render) {
          const nameText = rule.name.truncateText ? rule.name.value : rule.name
          const name = rule.name.isLink ? (
            <EuiLink href=''>{rule.name.value}</EuiLink>
          ) : (
            nameText
          )
          child = column.render(name, rule)
        } else if (column.cellProvider) {
          child = column.cellProvider({ rule, ruleIndex })
        } else {
          child = formatCell(column.id, rule, cellData)
        }

        return (
          <EuiTableRowCell
            key={column.id}
            align={column.alignment}
            truncateText
            textOnly={cellData ? cellData.textOnly : true}
            mobileOptions={{
              header: column.label,
              ...column.mobileOptions,
            }}
            style={{ textAlign: 'left', textTransform: 'capitalize' }}
          >
            {child}
          </EuiTableRowCell>
        )
      })

      return (
        <EuiTableRow
          key={`rule-row-${rule.id}-${ruleIndex}`}
          isSelectable={true}
          hasActions={true}
          className='fade-up'
        >
          {cells}
        </EuiTableRow>
      )
    }

    if (!rules?.length) {
      return (
        <EuiTableRow>
          <EuiTableRowCell
            colSpan={columns.length}
            align='center'
            style={{ padding: '2rem' }}
          >
            <EuiIcon type='documents' size='l' color='#4A4D55' />
            <EuiSpacer size='s' />
            <EuiText color='#4A4D55'>
              No rules added
            </EuiText>
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    return rules.map(renderRow)
  }

  return (
    <EuiPanel className='admin-table-panel'>
      <EuiTable id='RulesTable'>
        <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>

        <EuiTableBody>{renderRows()}</EuiTableBody>
      </EuiTable>
    </EuiPanel>
  )
}
