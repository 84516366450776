import React, {
  useState,
  useCallback,
  useContext,
  createContext,
  useEffect
} from 'react'

import {
  EuiPage,
  EuiPageBody,
  EuiTitle,
  EuiPanel,
  EuiLoadingChart,
} from '@elastic/eui'
import { useParams } from 'react-router-dom'
import {
  EtorTableGroup,
  EtorMessagesStats,
  NivoLineGraph,
  EtorMessagesTable,
  EtorDetailsModal,
} from '..'

import { api } from '../../lib'
import { countSelected, compareEntities } from './utils'
import merge from 'lodash.merge'
import './dashboard_page.css'

import { AppContext } from '../../app'

export const EtorContext = createContext()

export const EtorPage = () => {
  let {
    projects,
    useCases,
    senders,
    senderTree,
    recipients,
    eventTypes,
    warnings,
    messageCountsOverTime,
    selectedStatuses,
    selectedProjects,
    selectedUseCases,
    selectedSenders,
    selectedRecipients,
    selectedEventTypes,
    selectedWarnings,
    updateSelectedStatuses,
    updateSelectedProjects,
    updateSelectedUseCases,
    updateSelectedRecipients,
    updateSelectedSenders,
    updateSelectedEventTypes,
    updateSelectedWarnings,
    messageCounts,
    highlightLines,
    setHighlightLines,
    entityTableSorts,
    selectedProjectData,
    setSelectedProjectData,
    navBarExpanded,
    exportToolExpanded,
    supportToolExpanded,
    fetchEventTypes,
    clearFilters,
    environment,
    authenticated,
    query,
    date,
    toaster,
    extractSenders,
    extractSubsenders,
    setMessageCounts,
    setMessages,
    messages,
    messagesTableSort,
    setMoreMessagesAvailable,
    setMessageCountsOverTime,
  } = useContext(AppContext)

  const { projectName } = useParams()
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({})
  const [detailsModal, setDetailsModal] = useState(null)
  const [activeMessage, setActiveMessage] = useState(null)
  const [loadingEtorMessages, setLoadingEtorMessages] = useState(true)
  const [loadingEtorMessageCounts, setLoadingEtorMessageCounts] = useState(true)
  const [loadingEtorMessageCountsOverTime, setLoadingEtorMessageCountsOverTime] = useState(true)
  const initEntities = {
    eventTypes: [...eventTypes],
    useCases: [...useCases],
    senders: [...senders],
    recipients: [...recipients],
    warnings: [...warnings],
  }
  const [entities, setEntities] = useState({ ...initEntities })

  if (projectName && !selectedProjects.includes(projectName)) {
    updateSelectedProjects([projectName])
  }

  const project = projects.reduce((p, proj) => {
    if (proj.name === projectName) {
      return proj
    }
    return p
  }, {})

  const fetchMessages = useCallback(
    async ({ append = false } = {}) => {
      setLoadingEtorMessages(true)
      if (!append) {
        setMessages([])
      }
      try {
        const senders = selectedSenders.length ? extractSenders() : []
        const subsenders = selectedSenders.length ? extractSubsenders() : []
        const data = {
          start: date[0],
          end: date[1],
          text: query,
          projects: selectedProjects,
          useCases: selectedUseCases,
          senders,
          subsenders,
          recipients: selectedRecipients,
          projectData: selectedProjectData,
          statuses: selectedStatuses,
          from: append ? messages.length : 0,
          environment: environment,
          sort: {
            [messagesTableSort.field]: messagesTableSort.direction,
          },
        }
        if (window.location.pathname === '/projects/ETOR') {
          data.eventTypes = selectedEventTypes
          data.warnings = selectedWarnings
        }
        const result = await api.fetchMessagesByProject(data)
        setMessages((prevMessages) => [...prevMessages, ...result.data])
        setMoreMessagesAvailable(!!result.data.length)
      } catch (err) {
        toaster({
          color: 'danger',
          title: 'Unable to Fetch Messages',
          text: err.message,
        })
      } finally {
        setLoadingEtorMessages(false)
      }
    },
    [
      toaster,
      date,
      environment,
      extractSenders,
      extractSubsenders,
      messages,
      query,
      selectedProjectData,
      selectedStatuses,
      messagesTableSort,
      selectedProjects,
      selectedRecipients,
      selectedUseCases,
      selectedSenders,
      selectedEventTypes,
      selectedWarnings,
    ]
  )

  const fetchMessageCounts = useCallback(async () => {
    setLoadingEtorMessageCounts(true)

    try {
      const senders = selectedSenders.length ? extractSenders() : []
      const subsenders = selectedSenders.length ? extractSubsenders() : []
      const data = {
        projects: selectedProjects,
        useCases: selectedUseCases,
        senders,
        subsenders,
        recipients: selectedRecipients,
        statuses: selectedStatuses,
        projectData: selectedProjectData,
        environment,
        date,
        query,
        eventTypes: selectedEventTypes,
        warnings: selectedWarnings,
      }
      const updatedCounts = await api.fetchMessageCountsByProject(data)
      setMessageCounts((previous) => {
        if (previous.previous) {
          merge(previous, previous.previous)
          delete previous.previous
        }
        updatedCounts.previous = previous || {}
        return updatedCounts
      })
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to Fetch Message Counts',
        text: err.message,
      })
    } finally {
      setLoadingEtorMessageCounts(false)
    }
  }, [
    toaster,
    date,
    environment,
    extractSenders,
    extractSubsenders,
    query,
    selectedProjectData,
    selectedStatuses,
    selectedProjects,
    selectedRecipients,
    selectedUseCases,
    selectedSenders,
    selectedEventTypes,
    selectedWarnings,
  ])

  const fetchMessageCountsOverTime = useCallback(async () => {
    setLoadingEtorMessageCountsOverTime(true)
    const sendersByName = senders.reduce((agg, sender) => {
      agg[sender.name] = sender
      return agg
    }, {})
    try {
      const data = {
        projects: selectedProjects,
        useCases: selectedUseCases,
        senders: selectedSenders.filter(
          (name) =>
            sendersByName[String(name)] && !sendersByName[String(name)].parent
        ),
        subsenders: selectedSenders.filter(
          (name) =>
            sendersByName[String(name)] && sendersByName[String(name)].parent
        ),
        recipients: selectedRecipients,
        statuses: selectedStatuses,
        projectData: selectedProjectData,
        eventTypes: selectedEventTypes,
        warnings: selectedWarnings,
        sendersByName,
        environment,
        date,
        query,
      }
      const updatedCountsOverTime = await api.fetchMessageCountsOverTimeByProject(data)
      setHighlightLines()
      setMessageCountsOverTime(updatedCountsOverTime)
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to Fetch Graph Data',
        text: err.message,
      })
    } finally {
      setLoadingEtorMessageCountsOverTime(false)
    }
  }, [
    toaster,
    date,
    environment,
    query,
    selectedProjectData,
    selectedStatuses,
    selectedProjects,
    selectedRecipients,
    selectedUseCases,
    selectedSenders,
    selectedEventTypes,
    selectedWarnings,
    senders,
    setHighlightLines,
  ])

  const loadNewMessages = () => {
    fetchMessages({ append: true })
  }

  useEffect(() => {
    fetchEventTypes()
    return clearFilters
  }, [environment])

  useEffect(() => {
    if (senders.length > 0 && selectedProjects.length) {
      fetchMessages()
      fetchMessageCounts()
      fetchMessageCountsOverTime()
    } else {
      return
    }
    // eslint-disable-next-line
  }, [senders, selectedProjects])

  useEffect(() => {
    if (authenticated &&
        selectedProjects.length &&
        !loadingEtorMessageCounts &&
        !loadingEtorMessageCountsOverTime
    ) {
      fetchMessageCounts()
      fetchMessageCountsOverTime()
    }
    // eslint-disable-next-line
  }, [
    authenticated,
    query,
    date,
    selectedProjects,
    selectedSenders,
    selectedRecipients,
    selectedUseCases,
    selectedStatuses,
    selectedProjectData,
    selectedEventTypes,
    selectedWarnings,
    environment,
  ])

  useEffect(() => {
    if (authenticated &&
        selectedProjects.length &&
        !loadingEtorMessages
    ) {
      fetchMessages()
    }
    // eslint-disable-next-line
  }, [
    authenticated,
    query,
    date,
    selectedProjects,
    selectedSenders,
    selectedRecipients,
    selectedUseCases,
    selectedStatuses,
    selectedProjectData,
    selectedEventTypes,
    selectedWarnings,
    environment,
    messagesTableSort,
  ])

  useEffect(() => {
    const nEntities = { ...initEntities }
    nEntities.eventTypes = eventTypes
      .map(({ name, description, displayName }) => {
        const isCurrent =
          !selectedEventTypes.length || selectedEventTypes.includes(name)
        const entity = {
          name,
          displayName,
          count: null,
          isCurrent,
          status: null,
          description,
        }
        if (messageCounts.eventTypeCounts) {
          const { total, ...counts } = isCurrent
            ? messageCounts.eventTypeCounts[String(name)] || {}
            : messageCounts.previous.eventTypeCounts[String(name)] || {}
          entity.count = countSelected({ total, counts, selectedStatuses })
        }
        return entity
      })
      .sort(compareEntities(entityTableSorts.eventTypes))
    nEntities.useCases = useCases
      .map(({ name, description, displayName }) => {
        const isCurrent =
          !selectedUseCases.length || selectedUseCases.includes(name)
        const { total, ...counts } = isCurrent
          ? messageCounts.useCaseCounts[String(name)] || {}
          : messageCounts.previous.useCaseCounts[String(name)] || {}
        return {
          name,
          displayName,
          count: countSelected({ total, counts, selectedStatuses }),
          isCurrent,
          status: null,
          description,
        }
      })
      .sort(compareEntities(entityTableSorts.useCases))
    nEntities.senders = senders
      .map(({ name, description, parent, displayName }) => {
        var index = senderTree.findIndex((sender) => sender.name === name)
        let childSelected = false
        if (index !== -1) {
          // located a parent
          let parent = senderTree[String(index)]
          let children = parent.children

          // check if item is a parent

          // check if any of the parents children are selected
          for (let child of children) {
            if (selectedSenders.includes(child.name)) {
              childSelected = true

              break
            }
          }
        }

        const isCurrent =
          !selectedSenders.length ||
          selectedSenders.includes(name) ||
          childSelected

        const countsType = parent ? 'subsenderCounts' : 'senderCounts'

        const { total, parentCount, ...counts } = isCurrent
          ? messageCounts[String(countsType)][String(name)] || {}
          : messageCounts.previous[String(countsType)][String(name)] || {}
        return {
          name,
          displayName,
          count: countSelected({ total, counts, selectedStatuses }),
          isCurrent,
          status: null,
          description,
          parent,
          parentCount,
        }
      })
      .sort(compareEntities(entityTableSorts.senders))
    nEntities.recipients = recipients
      .map(({ name, description }) => {
        const isCurrent =
          !selectedRecipients.length || selectedRecipients.includes(name)
        const { total, ...counts } = isCurrent
          ? messageCounts.recipientCounts[name.toLowerCase()] || {}
          : messageCounts.previous.recipientCounts[name.toLowerCase()] || {}
        return {
          name,
          count: countSelected({ total, counts, selectedStatuses }),
          isCurrent,
          status: null,
          description,
        }
      })
      .sort(compareEntities(entityTableSorts.recipients))
    nEntities.warnings = warnings
      .map(({ name, description, displayName }) => {
        const isCurrent =
          !selectedWarnings.length || selectedWarnings.includes(name)
        const entity = {
          name,
          displayName,
          count: null,
          isCurrent,
          status: null,
          description,
        }
        if (messageCounts.warningCounts) {
          const { total, ...counts } = isCurrent
            ? messageCounts.warningCounts[String(name)] || {}
            : messageCounts.previous.warningCounts[String(name)] || {}
          entity.count = countSelected({ total, counts, selectedStatuses })
        }
        return entity
      })
      .sort(compareEntities(entityTableSorts.warnings))
    setEntities(nEntities)
  }, [messageCounts])

  const addStatus = (status) => {
    updateSelectedStatuses([...selectedStatuses, status])
  }

  const removeStatus = (status) => {
    updateSelectedStatuses(selectedStatuses.filter((s) => s !== status))
  }

  const addEventType = (name) => {
    updateSelectedEventTypes([...selectedEventTypes, name])
  }

  const removeEventType = (name) => {
    let updatedSelectedEventTypes = [].concat(selectedEventTypes)
    let eventTypeIndex = updatedSelectedEventTypes.indexOf(name)
    updatedSelectedEventTypes.splice(eventTypeIndex, 1)

    updateSelectedEventTypes(updatedSelectedEventTypes)
  }
  
  const addWarning = (name) => {
    updateSelectedWarnings([...selectedWarnings, name])
  }

  const removeWarning = (name) => {
    let updatedSelectedWarnings = [].concat(selectedWarnings)
    let warningIndex = updatedSelectedWarnings.indexOf(name)
    updatedSelectedWarnings.splice(warningIndex, 1)

    updateSelectedWarnings(updatedSelectedWarnings)
  }

  const addUseCase = (name) => {
    updateSelectedUseCases([...selectedUseCases, name])
  }

  const removeUseCase = (name) => {
    let updatedSelectedUseCases = [].concat(selectedUseCases)
    let projectIndex = updatedSelectedUseCases.indexOf(name)
    updatedSelectedUseCases.splice(projectIndex, 1)

    updateSelectedUseCases(updatedSelectedUseCases)
  }

  const addRecipient = (name) => {
    updateSelectedRecipients([...selectedRecipients, name])
  }

  const removeRecipient = (name) => {
    let updatedSelectedRecipients = [].concat(selectedRecipients)
    let projectIndex = updatedSelectedRecipients.indexOf(name)
    updatedSelectedRecipients.splice(projectIndex, 1)

    updateSelectedRecipients(updatedSelectedRecipients)
  }

  const addSender = (name) => {
    var index = senderTree.findIndex((sender) => sender.name === name)
    let latestSelectedSenders = [].concat(selectedSenders)

    let children = []
    if (index !== -1) {
      // located a non child sender (sender without a parent)
      let selectedSender = senderTree[String(index)]
      if (selectedSender.children.length > 0) {
        children = selectedSender.children.map((child) => {
          return child.name
        })
      }
    }
    updateSelectedSenders([...latestSelectedSenders, name, ...children])
    // updateSelectedSenders([...latestSelectedSenders, name])
  }

  const removeSender = (name) => {
    var index = senderTree.findIndex((sender) => sender.name === name)
    let updatedSelectedSenders = [].concat(selectedSenders)

    let selectedSenderIndex = updatedSelectedSenders.indexOf(name)
    updatedSelectedSenders.splice(selectedSenderIndex, 1)

    let children = []
    let parent = null
    if (index !== -1) {
      // located a parent
      let parent = senderTree[String(index)]
      children = parent.children.map((child) => {
        return child.name
      })

      for (let child of children) {
        let selectedSenderIndex = updatedSelectedSenders.indexOf(child.name)
        updatedSelectedSenders.splice(selectedSenderIndex, 1)
      }
    } else {
      // selected sender is a child, make sure to remove its parent if no children are selected any longer
      for (let sender of senderTree) {
        if (sender.children.length > 0) {
          for (let child of sender.children) {
            if (child.name === name) {
              parent = child.parent
            }
          }
        }
      }
    }

    if (parent) {
      let siblingsSelected = false
      let parentSender = null
      let children = []
      var parentIndex = senderTree.findIndex((sender) => sender.name === parent)

      if (parentIndex !== -1) {
        parentSender = senderTree[String(parentIndex)]
        children = parentSender.children

        for (let child of children) {
          if (updatedSelectedSenders.includes(child.name)) {
            siblingsSelected = true
          }
        }
      }

      if (!siblingsSelected && updatedSelectedSenders.includes(parent)) {
        // no siblings remain selected, remove parent if selected
        let selectedSenderIndex = updatedSelectedSenders.indexOf(parent)
        updatedSelectedSenders.splice(selectedSenderIndex, 1)
      }
    }

    updateSelectedSenders(updatedSelectedSenders)
  }

  const addProjectData = (newFieldObject) => {
    const projectData = Object.assign({}, selectedProjectData)
    const projectName = Object.keys(newFieldObject)[0]
    const newField = Object.keys(newFieldObject[String(projectName)])[0]

    if (projectData[String(projectName)]) {
      projectData[String(projectName)][String(newField)] =
        newFieldObject[String(projectName)][String(newField)]
    } else {
      projectData[String(projectName)] = newFieldObject[String(projectName)]
    }
    setSelectedProjectData(projectData)
  }

  const removeProjectData = (newFieldObject) => {
    let projectData = Object.assign({}, selectedProjectData)
    const projectName = Object.keys(newFieldObject)[0]
    const newField = Object.keys(newFieldObject[String(projectName)])[0]

    if (Object.keys(projectData[String(projectName)]).length > 1) {
      delete projectData[String(projectName)][String(newField)]
    } else {
      delete projectData[String(projectName)]
    }
    setSelectedProjectData(projectData)
  }

  const closeDetailsModal = () => {
    setDetailsModal(null)
  }

  const displayDetailsModal = (data) => {
    setActiveMessage(data)
    setDetailsModal(
      <EtorDetailsModal
        close={closeDetailsModal}
        data={data}
      />
    )
  }

  const value = {
    projectName,
    senders: entities.senders,
    projects,
    addEventType,
    removeEventType,
    addWarning,
    removeWarning,
    useCases: entities.useCases,
    addUseCase,
    removeUseCase,
    addSender,
    removeSender,
    recipients: entities.recipients,
    addRecipient,
    removeRecipient,
    addStatus,
    removeStatus,
    addProjectData,
    removeProjectData,
    itemIdToExpandedRowMap,
    setItemIdToExpandedRowMap,
    displayDetailsModal,
    closeDetailsModal,
    setActiveMessage,
    activeMessage,
    eventTypes: entities.eventTypes,
    warnings: entities.warnings,
    loadNewMessages,
    loadingEtorMessages
  }

  return (
    <EtorContext.Provider value={value}>
      <EuiPage
        className={`euiNavDrawerPage
          ${navBarExpanded ? 'navBarExpanded' : 'navBarCollapsed'}
          ${(exportToolExpanded || supportToolExpanded) &&
          'export-bar-expanded-pad'
          }`}
      >
        <EuiPageBody className='euiNavDrawerPage__pageBody'>
          <div className='page_wrapper'>
            <EtorMessagesStats
              project={project}
              messageCounts={messageCounts && messageCounts.total}
              loadingMessageCounts={loadingEtorMessageCounts}
              updateSelectedStatuses={updateSelectedStatuses}
              selectedStatuses={selectedStatuses}
            />
            <EtorTableGroup />
            <EuiTitle size='m' className='sectionHeader'>
              <p>Messages</p>
            </EuiTitle>
            <EuiPanel
              style={{
                height: 300,
                marginBottom: '1.75em',
                textAlign: 'right',
                position: 'relative',
              }}
            >
              {loadingEtorMessageCountsOverTime && (
                <EuiLoadingChart
                  size='l'
                  style={{ position: 'absolute', top: '8px', right: '6px' }}
                />
              )}
              {messageCountsOverTime && (
                <NivoLineGraph
                  data={messageCountsOverTime.counts}
                  interval={messageCountsOverTime.interval}
                  highlightLines={highlightLines}
                  setHighlightLines={setHighlightLines}
                />
              )}
            </EuiPanel>
            <EuiPanel
              style={{
                height: 500,
                overflow: 'auto',
                padding: 0,
              }}
            >
              <EtorMessagesTable />
            </EuiPanel>
          </div>
        </EuiPageBody>

        {detailsModal}
      </EuiPage>
    </EtorContext.Provider>
  )
}
