import React, { useState, useContext } from 'react'
import { v4 as uuid } from 'uuid'

import {
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiIcon,
  EuiToolTip,
  EuiText
} from '@elastic/eui'

import { EtorModalTable } from '..'
import { EtorContext } from '..'

const displayNames = {
  'SourceTermCode': 'Source Term Code',
  'SourceTerm': 'Source Term',
  'TargetTermCode': 'Target Term Code',
  'TargetTerm': 'Target Term',
  'TargetCatalogName': 'Target Catalog Name'
}

export const EtorTabTranscoding = () => {
  const [activeTab, setActiveTab] = useState('transcode-failures-in')
  const { activeMessage } = useContext(EtorContext)

  const tabs = [
    {
      id: 'transcode-failures-in',
      name: 'Transcode Failures In',
      disabled: false,
      onClick: () => setActiveTab('transcode-failures-in'),
    },
    {
      id: 'transcode-failures-out',
      name: 'Transcode Failures Out',
      disabled: false,
      onClick: () => setActiveTab('transcode-failures-out'),
    },
  ]

  const renderTabs = () => {
    return tabs.map((tab, idx) => (
      <EuiTab
        key={`etor-tab-warnings-${idx}`}
        onClick={tab.onClick}
        isSelected={tab.id === activeTab}
        disabled={tab.disabled}
        id={idx}
      >
        {tab.name}
      </EuiTab>
    ))
  }

  const getColumns = (items) => {
    try {
      const set = new Set()
      for (const item of items) {
        for (const key of Object.keys(item)) {
          if (key === 'id') continue
          set.add(key)
        }
      }
      const columns = []
      columns.push(
        {
          name: <EuiIcon type='pagesSelect' />,
          width: '32px',
          render: () => <EuiIcon type='document' />,
        }
      )
      for (const value of set) {
        columns.push(
          {
            name: displayNames[String(value)] || value,
            field: value,
            truncateText: true,
            render: (cell) => {
              return (
                <EuiToolTip
                  position='top'
                  content={cell}
                >
                  <EuiText size='s'>{cell}</EuiText>
                </EuiToolTip>
              )
            }
          }
        )
      }
      return columns
    }
    catch {
      return []
    }
  }

  const buildData = (data) => {
    try {
      const parsedData = []
      for (const d of data) {
        const parsed = JSON.parse(d)
        if (Object.keys(parsed).length > 0) {
          parsedData.push({ id: uuid(), ...parsed })
        }
      }
      return parsedData
    }
    catch {
      return []
    }
  }

  const tabContentSwitch = () => {
    switch (activeTab) {
      case 'transcode-failures-in': {
        const transFailuresInItems = buildData(activeMessage?.ProjectData?.ETOR?.Warnings?.UntranscodeIn)
        const transFailuresInColumns = getColumns(transFailuresInItems)
        return (
          <EtorModalTable
            columns={transFailuresInColumns}
            items={transFailuresInItems}
            id='etor-tab-transcoding-failures-in'
          />
        )
      }
      case 'transcode-failures-out': {
        const transFailuresOutItems = buildData(activeMessage?.ProjectData?.ETOR?.Warnings?.UntranscodeOut)
        const transFailuresOutColumns = getColumns(transFailuresOutItems)
        return (
          <EtorModalTable
            columns={transFailuresOutColumns}
            items={transFailuresOutItems}
            id='etor-tab-transcoding-failures-out'
          />
        )
      }
    }
  }

  if (!activeMessage) return (<></>)

  return (
    <>
      <EuiTabs>{renderTabs()}</EuiTabs>
      <EuiSpacer />
      {tabContentSwitch()}
    </>
  )
}
