import React, {
  useState,
  useContext,
  createContext,
  useReducer,
  useCallback
} from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiPage,
  EuiPageBody,
  EuiTab
} from '@elastic/eui'

import { UserForm } from './user_form_modal'
import { TagForm } from './tag_form_modal'
import { UsersPage } from './users_page'
import { TagsPage } from './tags_page'

import api from '../../lib/api'
import { AppContext } from '../../app'

export const AdminPageContext = createContext()

import './admin.css'

const initUser = {
  name: '',
  firstName: '',
  lastName: '',
  email: '',
  tags: [],
  rules: [],
  groups: []
}
const initTag = {
  name: '',
  description: '',
  rules: []
}


const initialState = () => {
  return {
    activeUser: {...initUser},
    activeTag: {...initTag},
    activeModal: '',
    activeTab: 'users-list',
    loading: false,
    usersSearch: '',
    tagsSearch: '',
    tagOptions: null,
    userModal: '',
    tagModal: ''
  }
}

const reducer = (state, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'set':
      newState[action.state] = action.value
      return newState
    case 'user_form':
      newState.activeUser[action.field] = action.value
      return newState
    case 'view_user':
      newState.activeUser = { ...action.user }
      newState.userModal = 'view'
      return newState
    case 'edit_user':
      newState.activeUser = { ...action.user }
      newState.userModal = 'edit'
      return newState
    case 'tag_form':
      newState.activeTag[action.field] = action.value
      return newState
    case 'view_tag':
      newState.activeTag = { ...action.tag }
      newState.tagModal = 'view'
      return newState
    case 'edit_tag':
      newState.activeTag = { ...action.tag }
      newState.tagModal = 'edit'
      return newState
    case 'select_user_tags':
      newState.activeUser.tags = action.tags.map(t => {
        return {
          ...t,
          label: t.name || t.tag.name
        }
      })
      return newState
    case 'reset_user':
      newState.activeUser = { ...initUser }
      return newState
    case 'close_modal':
      newState.activeUser = { ...initUser }
      newState.activeTag = { ...initTag }
      newState.activeModal = ''
      newState.userModal = ''
      newState.tagModal = ''
      return newState
  }
  throw Error('Invalid action')
}

export const AdminPage = () => {
  const [adminState, adminDispatch] = useReducer(reducer, null, initialState)
  const {
    navBarExpanded,
    exportToolExpanded,
    supportToolExpanded,
    users,
    setUsers,
    tags,
    setTags,
    toaster,
    fetchUsers,
    fetchTags,
    usersPagination,
    tagsPagination,
    initialPagination
  } = useContext(AppContext)

  const [loading, setLoading] = useState(false)

  const loadUsers = async ({
    search = '',
    add = false,
    reset = false
  } = {}) => {
    setLoading(true)
    try {
      const { offset, limit } = reset
        ? initialPagination
        : usersPagination
      const newOffset = add ? offset + limit : offset
      await fetchUsers(newOffset, limit, search, add)
    }
    catch (e) {
      console.log('Error loading users', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem loading notifications'
      })
    }
    finally {
      setLoading(false)
    }
  }

  const loadTags = async ({
    search = '',
    add = false,
    reset = false
  } = {}) => {
    setLoading(true)
    try {
      const { offset, limit } = reset
        ? initialPagination
        : tagsPagination
      const newOffset = add ? offset + limit : offset
      await fetchTags(newOffset, limit, search, add)
    }
    catch (e) {
      console.log('Error loading tags', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem loading tags'
      })
    }
    finally {
      setLoading(false)
    }
  }

  const tagsToOptions = (tags) => {
    if (!tags.length) {
      return []
    }
    return tags.map((tag) => ({ label: tag.name, ...tag }))
  }

  const searchTagOpts = useCallback(async(search='') => {
    try {
      const params = {
        offset: 0,
        limit: 20,
        search
      }
      const resp = await api.fetchTags(params)
      const tags = tagsToOptions(resp.tags)
      adminDispatch({ type: 'set', state: 'tagOptions', value: tags })
    }
    catch(e) {
      console.log('Error getting tag options', e)
    }
  }, [])

  const updateUser = (user) => adminDispatch({ type: 'set', state: 'activeUser', value: { ...user } })
  const updateTag = (tag) => adminDispatch({ type: 'set', state: 'activeTag', value: { ...tag } })

  const findAndUpdateUser = (user) => {
    const usersIdx = users.findIndex(u => u.id === user.id)
    if (usersIdx !== -1) {
      const newUsers = [...users]
      newUsers[String(usersIdx)] = user
      setUsers(newUsers)
    }
  }

  const findAndUpdateTag = (tag) => {
    const tagsIdx = tags.findIndex(t => t.id === tag.id)
    if (tagsIdx !== -1) {
      const newTags = [...tags]
      newTags[String(tagsIdx)] = tag
      setTags(newTags)
    } 
  }

  const adminComponentOptions = {
    'users-list': (
      <UsersPage />
    ),
    'tags-list': (
      <TagsPage />
    ),
  }

  const renderModals = () => {
    return (
      <>
        <UserForm />
        <TagForm />
      </>
    )
  }

  const adminTabs = [
    {
      id: 'users-list',
      name: 'Users',
      disabled: false,
      onClick: () => adminDispatch({ type: 'set', state: 'activeTab', value: 'users-list'})
    },
    {
      id: 'tags-list',
      name: 'Tags',
      disabled: false,
      onClick: () => adminDispatch({ type: 'set', state: 'activeTab', value: 'tags-list'})
    },
  ]

  const renderAdminTabs = () => {
    return adminTabs.map((tab, idx) => (
      <EuiTab
        onClick={tab.onClick}
        isSelected={tab.id === adminState.activeTab}
        disabled={tab.disabled}
        key={idx}>
        {tab.name}
      </EuiTab>
    ))
  }

  const value = {
    updateTag,
    updateUser,
    loadUsers,
    loadTags,
    setLoading,
    loading,
    renderAdminTabs,
    initTag,
    adminState,
    adminDispatch,
    searchTagOpts,
    findAndUpdateUser,
    findAndUpdateTag
  }

  return (
    <AdminPageContext.Provider value={value}>
      <EuiPage
        className={`euiNavDrawerPage
          ${navBarExpanded ? 'navBarExpanded' : 'navBarCollapsed'}
          ${(exportToolExpanded || supportToolExpanded) && 'export-bar-expanded-pad'}`
        }
        style={{ flexDirection: 'column' }}
      >
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPageBody className='euiNavDrawerPage__pageBody'>
              <div className='page_wrapper'>
                <EuiSpacer size='s'/>
                {adminComponentOptions[adminState.activeTab]}
              </div>
            </EuiPageBody>
          </EuiFlexItem>

        </EuiFlexGroup>
        {renderModals()}
      </EuiPage>
    </AdminPageContext.Provider>
  )
}
