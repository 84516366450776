import React, { useState, useEffect, useContext } from 'react'

import {
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiBeacon,
} from '@elastic/eui'

import { formatDateTime, formatTimeZone } from '../../lib'

import { EtorModalTable } from '..'
import { EtorContext } from '..'

import './etor_event_tabs.css'

export const EtorEventTabs = ({ items, loading }) => {
  const [activeTab, setActiveTab] = useState('all')
  const [sortedItems, setSortedItems] = useState([])

  const { activeMessage, setActiveMessage } = useContext(EtorContext)

  const onTabClick = (eventType) => {
    setActiveTab(eventType)
    try {
      const allItems = [...items]
      if (eventType === 'all') {
        setSortedItems(allItems)
        return
      }
      const filtered = allItems
        .filter(({ _source }) => _source.ProjectData.ETOR.EventType === eventType)
      setSortedItems(filtered)
    }
    catch (e) {
      console.log('Error sorting eventtypes', e)
    }
  }

  useEffect(() => {
    setSortedItems(items)
  }, [items])

  const columns = [
    {
      field: '',
      name: '',
      width: '32px',
      className: 'etor-table-beacon',
      render: (cell, row) => {
        if (activeMessage.MessageId === row.MessageId) {
          return (
            <EuiBeacon className='etor-beacon' />
          )
        }
      }
    },
    {
      field: 'MessageDateTime',
      isSortable: true,
      name: `Date ${formatTimeZone()}`,
      render: (date) => formatDateTime(date),
    },
    {
      field: 'EtorId',
      isSortable: true,
      name: 'EtorId',
      render: (cell, row) => {
        return (
          <>{row.ProjectData.ETOR.ETORId}</>
        )
      }
    },
    {
      field: 'EventType',
      name: 'Type',
      render: (cell, row) => {
        return (
          <>
            {row.ProjectData.ETOR.EventType}
          </>
        )
      }
    },
    {
      field: 'Status',
      isSortable: true,
      name: 'Status',
      truncateText: true,
      render: (status) => (
        <span>{status}</span>
      ),
    },
  ]

  const tabs = [
    {
      id: 'all',
      name: 'All',
      disabled: false,
      onClick: () => onTabClick('all'),
    },
    {
      id: 'Order',
      name: 'Orders',
      disabled: false,
      onClick: () => onTabClick('Order'),
    },
    {
      id: 'Result',
      name: 'Results',
      disabled: false,
      onClick: () => onTabClick('Result'),
    },
  ]

  const renderTabs = () => {
    return tabs.map((tab, idx) => (
      <EuiTab
        key={`etor-event-tabs-${idx}`}
        onClick={tab.onClick}
        isSelected={tab.id === activeTab}
        disabled={tab.disabled}
        id={idx}
      >
        {tab.name}
      </EuiTab>
    ))
  }

  return (
    <>
      <EuiTabs>{renderTabs()}</EuiTabs>
      <EuiSpacer />
      <EtorModalTable
        columns={columns}
        items={sortedItems.map(({ _source }) => _source)}
        id='etor-modal-table'
        loading={loading}
        onRowClick={item => {
          setActiveMessage(item)
        }}
        minHeight='20rem'
      />
    </>
  )
}
