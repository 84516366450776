import {
  EuiContextMenuItem,
  EuiNotificationEvent,
  EuiLoadingSpinner,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'
import { useHistory } from 'react-router'
import React, { useContext } from 'react'
import { AppContext } from '../../app'
import { notificationTypeMap, getNotificationTime, getProjectField } from './utils'
import { InView } from 'react-intersection-observer'

export const LatestNotifications = ({ closeFlyout, checkUnread }) => {
  const {
    setNotificationSettingsInitialOpen,
    setShowNotificationSettings,
    readNotifications,
    latestNotifications,
    displayNames,
    handleNotificationPopout,
    notificationsPaginationLatest,
    updateNotificationsPaginationLatest,
    notificationsLoadedLatest,
    fetchLatestNotifications,
  } = useContext(AppContext)
  const history = useHistory()

  const onRead = async (id, isRead) => {
    if (isRead) return
    await readNotifications([id])
    checkUnread()
  }

  const handleConfigure = (project) => {
    setNotificationSettingsInitialOpen(project)
    setShowNotificationSettings(true)
    history.push('/notifications')
    closeFlyout()
  }

  const loadMoreNotifications = () => {
    const { offset, limit } = notificationsPaginationLatest
    const newOffset = offset + limit
    updateNotificationsPaginationLatest('offset', newOffset)
    fetchLatestNotifications(newOffset, limit, true)
  }

  const onOpenContextMenu = (id) => {
    const { read, to } = latestNotifications.find(
      ({ id: eventId }) => eventId === id
    )

    const menuItems = [
      <EuiContextMenuItem
        key='contextMenuItemD'
        onClick={() => handleConfigure(getDisplayName(to))}
      >
        Configure settings
      </EuiContextMenuItem>,
    ]

    if (!read) {
      menuItems.push(
        <EuiContextMenuItem
          key='contextMenuItemA'
          onClick={() => onRead(id, read)}
        >
          Mark as read
        </EuiContextMenuItem>
      )
    }

    return menuItems
  }

  const getDisplayName = (to) => {
    const project = getProjectField(to)
    const nameMap = displayNames.projects
    return nameMap[String(project)] ? nameMap[String(project)] : project
  }

  const notificationEvents = latestNotifications.map((event) => {
    // we want to make the news title unclickable
    const mappedType = notificationTypeMap[event.type]
      ? notificationTypeMap[event.type]
      : notificationTypeMap['default']
    const onClickTitle = event.type === 'News' ? undefined : () => {}

    return (
      <EuiNotificationEvent
        key={event.id}
        id={event.id}
        className='notifications-sidepanel'
        type={getDisplayName(event.to)}
        severity={mappedType.displayName}
        badgeColor={mappedType.color}
        iconType={mappedType.icon}
        iconAriaLabel={event.displayName}
        time={getNotificationTime(event.created_at)}
        title={event.title}
        isRead={event.read ? true : false}
        primaryAction={'View'}
        primaryActionProps={{
          iconType: 'inspect',
        }}
        messages={[event.content]}
        onRead={onRead}
        onOpenContextMenu={onOpenContextMenu}
        onClickPrimaryAction={() => {
          handleNotificationPopout(event.id)
          closeFlyout()
        }}
        onClickTitle={onClickTitle}
      />
    )
  })

  const renderScroll = () => {
    const { total } = notificationsPaginationLatest
    const noMore = latestNotifications?.length >= total
    return(
      <EuiFlexGroup
        gutterSize='none'
        alignItems='center'
        responsive={false}
        style={{ padding: '5px' }}
      >
        <EuiFlexItem style={{ alignItems: 'center' }}>
          <InView
            hidden={!latestNotifications?.length || noMore || !notificationsLoadedLatest}
            onChange={(inView => {
              if (inView && latestNotifications.length && !noMore) {
                loadMoreNotifications()
              }
            })}
          >
            <EuiLoadingSpinner size='m' style={{ margin: 'auto' }} />
          </InView>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  if (!notificationsLoadedLatest) {
    return (
      <EuiFlexGroup
        gutterSize='none'
        alignItems='center'
        responsive={false}
        style={{ padding: '10px' }}
      >
        <EuiFlexItem style={{ alignItems: 'center' }}>
          <EuiLoadingSpinner size='m' style={{ margin: 'auto' }} />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  if (!latestNotifications?.length) {
    return (
      <EuiFlexGroup
        gutterSize='none'
        alignItems='center'
        responsive={false}
        style={{ padding: '15px' }}
      >
        <EuiFlexItem style={{ alignItems: 'center' }}>
          No new notifications
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  return (
    <>
      {notificationEvents}
      {renderScroll()}
    </>
  )
}
