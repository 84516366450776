import React, {
  useState,
  useContext,
  createContext,
  useReducer,
  useEffect
} from 'react'

import cloneDeep from 'lodash/cloneDeep'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiPage,
  EuiPageBody,
  EuiTab
} from '@elastic/eui'

import { GroupsListPage } from './groups_list_page'
import { GroupForm } from './group_form_modal'

import { AppContext } from '../../app'
import api from '../../lib/api'

export const GroupsPageContext = createContext()

import './groups.css'

const initGroup = {
  id: '',
  created_at: '',
  updated_at: '',
  name: '',
  description: '',
  users: [],
  addUsers: [],
  delUsers: [],
  managers: [],
  entities: [],
  rules: []
}

const initialState = () => {
  return {
    activeGroup: {...initGroup},
    activeGroupNotiSettings: null,
    notiBackup: null,
    activeTab: 'groups-list',
    loading: false,
    groupsSearch: '',
    groupModal: '',
    notiSettingsExpand: {}
  }
}

const reducer = (state, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'set':
      newState[action.state] = action.value
      return newState
    case 'group_form':
      newState.activeGroup[action.field] = action.value
      return newState
    case 'view_group':
      newState.activeGroup = { ...action.group }
      newState.groupModal = 'view'
      return newState
    case 'edit_group':
      newState.activeGroup = { ...action.group }
      newState.groupModal = 'edit'
      return newState
    case 'reset_group':
      newState.activeGroup = { ...initGroup }
      return newState
    case 'close_modal':
      newState.activeGroup = { ...initGroup }
      newState.groupModal = ''
      newState.activeGroupNotiSettings = null,
      newState.notiBackup = null
      return newState
    case 'update_group_manager':
      newState.activeGroup.managers = [{ userId: action.userId }]
      return newState
    case 'remove_group_manager':
      newState.activeGroup.managers = []
      return newState
    case 'set_noti_settings':
      newState.activeGroupNotiSettings = cloneDeep(action.value)
      newState.notiBackup = cloneDeep(action.value)
      return newState
    case 'reset_group_notis':
      newState.activeGroupNotiSettings = cloneDeep(newState.notiBackup)
      newState.userModal = 'view'
      return newState
  }
  throw Error('Invalid action')
}

export const GroupsPage = () => {
  const [groupsState, groupsDispatch] = useReducer(reducer, null, initialState)
  const {
    navBarExpanded,
    exportToolExpanded,
    supportToolExpanded,
    groups,
    setGroups,
    toaster,
    keycloak,
    fetchGroups,
    groupsPagination,
    initialPagination,
  } = useContext(AppContext)

  const [loading, setLoading] = useState(false)

  const loadGroups = async ({
    search = '',
    add = false,
    reset = false
  } = {}) => {
    setLoading(true)
    try {
      const { offset, limit } = reset
        ? initialPagination
        : groupsPagination
      const newOffset = add ? offset + limit : offset
      await fetchGroups(newOffset, limit, search, add)
    }
    catch (e) {
      console.log('Error loading groups', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem loading groups'
      })
    }
    finally {
      setLoading(false)
    }
  }

  const fetchNotiSettings = async () => {
    setLoading(true)
    try {
      let resp
      if (keycloak.hasClientRole({ roleName: 'view' })) {
        resp = await api.fetchGroupNotiSettings(groupsState.activeGroup.id)
      } else if (keycloak.hasClientRole({ roleName: 'group-manager' })) {
        resp = await api.fetchGroupManagerNotiSettings(groupsState.activeGroup.id)
      } else {
        return
      }
      groupsDispatch({
        type: 'set_noti_settings',
        value: [...resp.data]
      })
    }
    catch(e) {
      console.log('Error group notification settings', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem loading group notification settings'
      })
    }
    finally {
      setLoading(false)
    }
  }

  const updateGroup = (group) => groupsDispatch({ type: 'set', state: 'activeGroup', value: { ...group } })

  const findAndUpdateGroup = (group) => {
    const groupsIndex = groups.findIndex(g => g.id === group.id)
    if (groupsIndex !== -1) {
      const newGroups = [...groups]
      newGroups[String(groupsIndex)] = group
      setGroups(newGroups)
    }
  }

  const groupsComponentOptions = {
    'groups-list': (
      <GroupsListPage />
    ),
  }

  const groupsTabs = [
    {
      id: 'groups-list',
      name: 'All Groups',
      disabled: false,
      onClick: () => groupsDispatch({ type: 'set', state: 'activeTab', value: 'groups-list'})
    },
  ]

  const renderGroupsTabs = () => {
    return groupsTabs.map((tab, idx) => (
      <EuiTab
        onClick={tab.onClick}
        isSelected={tab.id === groupsState.activeTab}
        disabled={tab.disabled}
        key={idx}>
        {tab.name}
      </EuiTab>
    ))
  }

  useEffect(() => {
    const { offset, limit } = initialPagination
    fetchGroups(offset, limit)
  }, [])

  const value = {
    updateGroup,
    loadGroups,
    setLoading,
    loading,
    renderGroupsTabs,
    groupsState,
    groupsDispatch,
    findAndUpdateGroup,
    fetchNotiSettings
  }

  return (
    <GroupsPageContext.Provider value={value}>
      <EuiPage
        className={`euiNavDrawerPage
          ${navBarExpanded ? 'navBarExpanded' : 'navBarCollapsed'}
          ${(exportToolExpanded || supportToolExpanded) && 'export-bar-expanded-pad'}`
        }
        style={{ flexDirection: 'column' }}
      >
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPageBody className='euiNavDrawerPage__pageBody'>
              <div className='page_wrapper'>
                <EuiSpacer size='s'/>
                {groupsComponentOptions[groupsState.activeTab]}
              </div>
            </EuiPageBody>
          </EuiFlexItem>
        </EuiFlexGroup>
        <GroupForm />
      </EuiPage>
    </GroupsPageContext.Provider>
  )
}
