import React from 'react'

import {
  EuiIcon,
  EuiSpacer,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiText,
  EuiPanel
} from '@elastic/eui'


export const GroupEntitiesList = ({ columns, items, filteredItems }) => {

  const renderHeaderCells = () => {
    const headers = []

    columns.forEach((column, columnIndex) => {
      headers.push(
        <EuiTableHeaderCell
          style={{ padding: 0 }}
          key={column.id}
          align={columns[String(columnIndex)].alignment}
          width={column.width}
          className='group-entities-header'
        >
          {column.label}
        </EuiTableHeaderCell>
      )
    })
    return headers.length ? headers : null
  }

  const renderRows = () => {
    const renderRow = (row, rowIdx) => {
      const cells = columns.map((column) => {
        const cellData = row[column.id]

        let child

        if (column.render) {
          child = column.render(column, row)
        } else if (column.cellProvider) {
          child = column.cellProvider({ row, rowIdx })
        } else {
          child = cellData
        }

        return (
          <EuiTableRowCell
            key={column.id}
            align={column.alignment}
            truncateText
            className={column.className}
            textOnly={cellData ? cellData.textOnly : true}
            mobileOptions={{
              header: column.label,
              ...column.mobileOptions,
            }}
            style={{ textAlign: 'left' }}
          >
            {child}
          </EuiTableRowCell>
        )
      })

      return (
        <EuiTableRow key={`group-entity-row-${row.id}-${rowIdx}`} >
          {cells}
        </EuiTableRow>
      )
    }

    if (!items?.length) {
      return (
        <EuiTableRow>
          <EuiTableRowCell
            colSpan={columns.length}
            align='center'
            style={{ padding: '2rem', border: 'none' }}
          >
            <EuiIcon type='package' size='l' color='#4A4D55' />
            <EuiSpacer size='s' />
            <EuiText color='#4A4D55'>
              {filteredItems ? 'No results found' : 'No entities'}
            </EuiText>
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    return items.map(renderRow)
  }

  return (
    <EuiPanel className='groups-table-panel'>
      <EuiTable id='group-entities-table' className='group-entities-table admin-table'>
        <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>

        <EuiTableBody>{renderRows()}</EuiTableBody>
      </EuiTable>
    </EuiPanel>
  )
}
