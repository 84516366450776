import React from 'react'

import {
  EuiIcon,
  EuiSpacer,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiText,
  EuiPanel
} from '@elastic/eui'

import { LEFT_ALIGNMENT } from '@elastic/eui/lib/services'

export const GroupsList = ({ groups }) => {

  const columns = [
    {
      id: 'icon',
      label: <EuiIcon type='users' />,
      alignment: LEFT_ALIGNMENT,
      width: '32px',
      textOnly: false,
      cellProvider: () => <EuiIcon type='users' />,
      mobileOptions: {
        show: false,
      },
    },
    {
      id: 'group',
      label: 'Group',
      alignment: LEFT_ALIGNMENT,
      truncateText: true,
      render: (col, row) => {
        return (
          <>{row.group.name}</>
        )
      }
    },
    {
      id: 'role',
      label: 'Role',
      alignment: LEFT_ALIGNMENT,
      truncateText: true,
      cellProvider: ({ row }) => {
        const manager = row.userId === row.group.managers[0]?.userId
        if (manager) {
          return (
            <EuiText color='#006BB4' size='s'>
              Manager
            </EuiText>
          )
        }
        return (
          <>Member</>
        )
      }
    },
  ]

  const renderHeaderCells = () => {
    const headers = []

    columns.forEach((column, columnIndex) => {
      headers.push(
        <EuiTableHeaderCell
          style={{ padding: 0 }}
          key={column.id}
          align={columns[String(columnIndex)].alignment}
          width={column.width}
        >
          {column.label}
        </EuiTableHeaderCell>
      )
    })
    return headers.length ? headers : null
  }

  const renderRows = () => {
    const renderRow = (row, rowIdx) => {
      const cells = columns.map((column) => {
        const cellData = row[column.id]

        let child

        if (column.render) {
          child = column.render(column, row)
        } else if (column.cellProvider) {
          child = column.cellProvider({ row, rowIdx })
        } else {
          child = cellData
        }

        return (
          <EuiTableRowCell
            key={column.id}
            align={column.alignment}
            truncateText
            textOnly={cellData ? cellData.textOnly : true}
            mobileOptions={{
              header: column.label,
              ...column.mobileOptions,
            }}
            style={{ textAlign: 'left' }}
          >
            {child}
          </EuiTableRowCell>
        )
      })

      return (
        <EuiTableRow key={`user-group-row-${row.id}-${rowIdx}`} >
          {cells}
        </EuiTableRow>
      )
    }

    if (!groups?.length) {
      return (
        <EuiTableRow>
          <EuiTableRowCell
            colSpan={columns.length}
            align='center'
            style={{ padding: '2rem' }}
          >
            <EuiIcon type='users' size='l' color='#4A4D55' />
            <EuiSpacer size='s' />
            <EuiText color='#4A4D55'>
              No groups found
            </EuiText>
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    return groups.map(renderRow)
  }

  return (
    <EuiPanel className='admin-table-panel'>
      <EuiTable id='user-groups-table' className='admin-table'>
        <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>

        <EuiTableBody>{renderRows()}</EuiTableBody>
      </EuiTable>
    </EuiPanel>
  )
}
