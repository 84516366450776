import React from 'react'

import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiModalFooter,
  EuiSpacer,
  EuiButton,
  EuiText,
  EuiIcon,
} from '@elastic/eui'

export const ConfirmDeleteNotiModal = ({
  onConfirm,
  onCancel,
  showModal,
  ids
}) => {
  if (!showModal || !ids?.length ) return null

  return (
    <>
      <EuiOverlayMask>
        <EuiModal style={{ width: 500, textAlign: 'center' }} onClose={onCancel}>
          <EuiSpacer />
          <EuiModalHeader style={{ flexDirection: 'column', justifyContent: 'center' }}>
            <EuiIcon size='l' type='trash' color='subdued' />
            <EuiSpacer />
            <EuiText style={{ fontWeight: 600, fontSize: '1.1em' }}>
              Confirm Delete Notifications
            </EuiText>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiText>Are you sure you want to delete ({ids.length}) notifications?</EuiText>
          </EuiModalBody>

          <EuiModalFooter style={{ justifyContent: 'center' }}>
            <EuiButton
              iconType='cross'
              onClick={onCancel}
            >
              Cancel
            </EuiButton>
            <EuiButton
              iconType='trash' 
              color='danger'
              onClick={() => {
                onConfirm()
                onCancel()
              }}
              fill
            >
              Delete
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    </>
  )
}
