import React from 'react'
import abbreviateNumber from 'number-abbreviate'
import commaNumber from 'comma-number'
import {
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'
import { StatButton } from './stat_button'
import './messages_stats.css'

export const EtorMessagesStats = ({
  project,
  messageCounts,
  loadingMessageCounts,
  updateSelectedStatuses,
  selectedStatuses = [],
}) => {
  const onChange = (status) => (shouldSelect) => {
    if (shouldSelect) {
      updateSelectedStatuses([...selectedStatuses, status])
    } else {
      updateSelectedStatuses(selectedStatuses.filter((s) => s !== status))
    }
  }
  const onChangeMulti =
    (statuses = []) =>
    (shouldSelect) => {
      if (shouldSelect) {
        updateSelectedStatuses([...selectedStatuses, ...statuses])
      } else {
        updateSelectedStatuses(
          selectedStatuses.filter((s) => !statuses.includes(s))
        )
      }
    }
  const abbreviateCounts = (messageCounts = 0) => abbreviateNumber(messageCounts)
  const countsCommaNumber = (messageCounts = 0) => commaNumber(messageCounts)
  return (
    <EuiPageHeader className='MessagesStats'>
      <EuiPageHeaderSection>
        {project && project.name && (
          <EuiFlexGroup alignItems='center'>
            <EuiFlexItem>
              <EuiTitle size='l'>
                <h1>{project.name}</h1>
              </EuiTitle>
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
      </EuiPageHeaderSection>
      <EuiPageHeaderSection className='messageCounts'>
        <EuiFlexGroup gutterSize='none'>
          <EuiFlexItem>
            <StatButton
              title={abbreviateCounts(messageCounts?.routed)}
              description='Routed'
              color='secondary'
              isLoading={loadingMessageCounts}
              isSelected={selectedStatuses.includes('Routed')}
              onChange={onChange('Routed')}
              tooltip={`Add ${countsCommaNumber(messageCounts?.routed)} routed messages to filter.`}
              tooltipSelected={`Remove ${countsCommaNumber(messageCounts?.routed)} routed messages from filter.`}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <StatButton
              title={abbreviateCounts(messageCounts?.processing)}
              description='Processing'
              color='warning'
              isLoading={loadingMessageCounts}
              onChange={onChange('Processing')}
              isSelected={selectedStatuses.includes('Processing')}
              tooltip={`Add ${countsCommaNumber(messageCounts?.processing)} processing messages to filter.`}
              tooltipSelected={`Remove ${countsCommaNumber(messageCounts?.processing)} processing messages from filter.`}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <StatButton
              title={abbreviateCounts(messageCounts?.unknown)}
              description='Unknown'
              isLoading={loadingMessageCounts}
              onChange={onChange('Unknown')}
              isSelected={selectedStatuses.includes('Unknown')}
              tooltip={`Add ${commaNumber(
                messageCounts ? messageCounts.unknown : 0
              )} messages of unknown processing status to filter.`}
              tooltipSelected={`Remove ${commaNumber(
                messageCounts ? messageCounts.unknown : 0
              )} messages of unknown processing status from filter.`}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <StatButton
              title={abbreviateCounts(messageCounts?.errors)}
              description='Errors'
              color='danger'
              isLoading={loadingMessageCounts}
              onChange={onChangeMulti([
                'TransportError',
                'RoutingError',
                'ExtractionError',
                'ValidatorError',
                'MappingError',
                'PackagingError',
              ])}
              isSelected={
                selectedStatuses.includes('TransportError') &&
                selectedStatuses.includes('RoutingError') &&
                selectedStatuses.includes('ExtractionError') &&
                selectedStatuses.includes('ValidatorError') &&
                selectedStatuses.includes('MappingError') &&
                selectedStatuses.includes('PackagingError')
              }
              tooltip={`Add ${commaNumber(
                messageCounts ? messageCounts.errors : 0
              )} errored messages to filter.`}
              tooltipSelected={`Remove ${commaNumber(
                messageCounts ? messageCounts.errors : 0
              )} errored messages from filter.`}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPageHeaderSection>
    </EuiPageHeader>
  )
}
