import React from "react";
import { useHistory } from "react-router-dom";
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButtonEmpty,
} from "@elastic/eui";
import { SupportTicket } from "./support_ticket";

export const SupportList = ({ supportTickets }) => {
  const history = useHistory();
  const listTickets = () => {
    if (!supportTickets.length) {
      return (
        <EuiFlexGroup
          gutterSize="m"
          direction="column"
          style={{
            alignItems: "center",
            padding: "20px 8px",
            border: "1px solid rgb(224, 226, 229)",
            borderRadius: "4px",
            background: "rgb(244, 246, 249)",
            margin: "4px 0",
            height: "100%",
          }}
        >
          <EuiFlexItem>
            <EuiText size="m" textAlign="center" style={{ fontWeight: "600" }}>
              No Support Tickets
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText size="s" textAlign="center" style={{ fontWeight: "500" }}>
              You can create a support ticket from a notification.
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiButtonEmpty onClick={() => history.push("/notifications")}>
              Notifications
            </EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    }
    return supportTickets.map((e, idx) => (
      <SupportTicket
        key={`support-ticket-${idx}`}
        id={e.id}
        ticket={e.jiraTicket}
        status={e.jiraStatus}
        url={e.jiraUrl}
        createdAt={e.created_at}
      />
    ));
  };

  return (
    <EuiFlexGroup gutterSize="none" direction="column" className="fade-up">
      <EuiFlexItem grow={false} style={{ padding: "4px 0" }}>
        <EuiText size="s" textAlign="left" style={{ fontWeight: "500" }}>
          Tickets
        </EuiText>
      </EuiFlexItem>

      <EuiFlexItem
        style={{
          maxHeight: "calc(100vh - 210px)",
          overflow: "scroll",
        }}
      >
        {listTickets()}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
