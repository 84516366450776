export const jiraMap = {
  done: {
    displayName: "Done",
    bg: "rgba(2, 227, 208, .2)",
    color: "green",
  },
  resolved: {
    displayName: "Resolved",
    bg: "rgba(2, 227, 208, .2)",
    color: "green",
  },
  "work in progress": {
    displayName: "In Progress",
    bg: "rgba(7, 71, 166, .1)",
    color: "rgb(7, 71, 166)",
  },
  "waiting for support": {
    displayName: "Waiting for Support",
    bg: "rgba(7, 71, 166, .1)",
    color: "rgb(7, 71, 166)",
  },
  pending: {
    displayName: "Pending",
    bg: "rgba(7, 71, 166, .1)",
    color: "rgb(7, 71, 166)",
  },
  "waiting for customer": {
    displayName: "Waiting for Customer",
    bg: "rgba(255, 211, 81, .3)",
    color: "rgb(255, 171, 4)",
  },
  open: {
    displayName: "Open",
    bg: "rgba(7, 71, 166, .1)",
    color: "rgb(7, 71, 166)",
  },
  error: {
    displayName: "Error",
    bg: "rgba(189, 39, 30, .2)",
    color: "#BD271E",
  },
  default: {
    displayName: "Submitted",
    bg: "rgba(211, 218, 230, .3)",
    color: "#69707D",
  },
};
