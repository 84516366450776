import React, {
  useContext,
  createContext,
  useState,
} from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButtonIcon,
  EuiHorizontalRule,
  EuiIcon,
} from '@elastic/eui'
import { AppContext } from '../../app'
import { ExportList } from './export_list'
import { ExportForm } from './export_form'

import api from '../../lib/api'
import './export_tool.css'

export const ExportContext = createContext()


export const ExportTool = () => {
  const [showForm, setShowForm] = useState(false)

  const {
    exportToolExpanded,
    setExportToolExpanded,
    setShowToolBar,
    showToolBar,
    toaster,
    fetchExports,
  } = useContext(AppContext)

  const deleteExport = async (id) => {
    try {
      const res = await api.deleteExport(id)
      if (res.status !== 204) {
        toaster({
          color: 'danger',
          title: 'Unable to delete export',
        })
        return
      }
      fetchExports()
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to delete export',
        text: err.message,
      })
    }
  }

  const retryExport = async (id) => {
    try {
      const res = await api.retryExport(id)
      if (res.status !== 200) {
        toaster({
          color: 'danger',
          title: 'Unable to delete export',
        })
        return
      }
      fetchExports()
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to delete export',
        text: err.message,
      })
    }
  }

  const downloadExport = async (id) => {
    try {
      const res = await api.downloadExport(id)
      if (res.status !== 200 || !res.data.url) {
        toaster({
          color: 'danger',
          title: 'Unable to download export',
        })
        return
      }
      // eslint-disable-next-line
      window.open(res.data.url, '_blank')
    } catch (err) {
      toaster({
        color: 'danger',
        title: 'Unable to download export',
        text: err.message,
      })
    }
  }

  const exportToolButton = () => {
    return (
      <EuiButtonIcon
        color='text'
        onClick={() => setExportToolExpanded(!exportToolExpanded)}
        iconType='exportAction'
        aria-label='export csv'
        id='exportPanelButton'
      />
    )
  }

  const renderTool = () => {
    if (!exportToolExpanded) {
      return (
        <EuiFlexGroup
          gutterSize='none'
          style={{
            padding: '4px',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {exportToolButton()}
          <EuiFlexItem
            grow={false}
            className='tool-bar-arrow'
            onClick={() => setShowToolBar(false)}
          >
            <EuiIcon type='arrowRight' color='text' size='s' />
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
    return (
      <>
        <EuiFlexGroup gutterSize='m' style={{ padding: '8px 4px' }}>
          <EuiFlexItem grow={false}>{exportToolButton()}</EuiFlexItem>
          <EuiFlexItem style={{ margin: 'auto 0' }}>
            <EuiText textAlign='left'>Export CSV</EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              color='text'
              onClick={() => setExportToolExpanded(!exportToolExpanded)}
              iconType='cross'
              aria-label='export csv'
              style={{ marginRight: '0', marginLeft: 'auto' }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiHorizontalRule margin='xs' />

        {showForm ? <ExportForm /> : <ExportList />}
      </>
    )
  }

  if (!showToolBar) {
    return (
      <div className='ExportBar-hidden'>
        <EuiFlexGroup
          gutterSize='none'
          className='tool-bar-arrow-hidden'
          onClick={() => setShowToolBar(true)}
        >
          <EuiFlexItem grow={false}>
            <EuiIcon
              type='arrowLeft'
              color='text'
              size='s'
              style={{ margin: 'auto' }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    )
  }

  const value = {
    showForm,
    setShowForm,
    deleteExport,
    retryExport,
    downloadExport,
  }

  return (
    <ExportContext.Provider value={value}>
      {renderTool()}
    </ExportContext.Provider>
  )
}
