import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useCallback,
} from 'react'
import {
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiSpacer,
  EuiPanel,
  EuiButtonIcon,
  EuiPopover,
  EuiContextMenuPanel,
  EuiContextMenuItem,
  EuiIcon,
  EuiTable,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableRowCellCheckbox,
  EuiTableBody,
  EuiTableFooter,
  EuiTableFooterCell,
  EuiCheckbox,
  EuiProgress,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from '@elastic/eui'
import { NotificationsSettings } from './notifications_settings'
import { ConfirmTicket, ConfirmDeleteNotiModal } from '../modals'
import {
  CircleIcon,
  buildSettingsState,
  getProjectField,
  notificationTypeMap,
  getNotificationDateHeader,
} from './utils'
import { AppContext } from '../../app'
import { formatDateTime, formatTimeZone, formatDateDash } from '../../lib/date'
import api from '../../lib/api'
import { useIsMount } from './utils'
export const NotificationsContext = createContext()

export const NotificationsPage = () => {
  const [popoverId, setPopoverId] = useState('')

  const {
    keycloak,
    showNotificationSettings,
    setShowNotificationSettings,
    setNotificationSettingsInitialOpen,
    notifications,
    setNotifications,
    notificationsPagination,
    setNotificationsSearch,
    fetchNotifications,
    notificationsTableIdMap,
    setNotificationsTableIdMap,
    selectNotificationsTable,
    notificationsLoaded,
    notificationSettings,
    notificationSettingsGlobal,
    toaster,
    displayNames,
    initialPagination,
    navBarExpanded,
    exportToolExpanded,
    supportToolExpanded,
    fetchLatestNotifications,
    fetchSupportTickets,
    setSupportToolExpanded,
    setShowToolBar,
    notificationInitialSelect,
    setNotificationInitialSelect,
    supportTickets,
  } = useContext(AppContext)

  const [settingsState, setSettingsState] = useState({})
  const [settingsStateCopy, setSettingsStateCopy] = useState(null)
  const [settingsStateGlobal, setSettingsStateGlobal] = useState({})
  const [loading, setLoading] = useState(false)

  const [settingsExpandMap, setSettingsExpandMap] = useState({})
  const [searchText, setSearchText] = useState('')

  const [expandGroupedRowSet, setExpandGroupedRowSet] = useState(new Set())
  const [selectGroupedRowSet, setSelectGroupedRowSet] = useState(new Set())
  const [expandDuplicateRowSet, setExpandDuplicateRowSet] = useState(new Set())

  const [confirmTicketModal, setConfirmTicketModal] = useState(false)
  const [confirmExistingTicket, setConfirmExistingTicket] = useState(null)
  const [activeNotification, setActiveNotification] = useState(null)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)

  const isMount = useIsMount()

  const loadNotifications = async ({
    search = '',
    add = false,
    reset = false,
  } = {}) => {
    try {
      setLoading(true)
      const { offset, limit } = reset
        ? initialPagination
        : notificationsPagination
      const newOffset = add ? offset + limit : offset
      await fetchNotifications(newOffset, limit, search, add)
      if (search || reset) {
        setNotificationsTableIdMap({})
      }
      setNotificationsSearch(search)
    } catch (e) {
      console.log('Error loading notifications', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem loading notifications.',
      })
    } finally {
      setLoading(false)
    }
  }

  const saveSettings = async ({ id, settings, setSaving }) => {
    setSaving(true)
    try {
      await api.updateNotificationSettings(id, settings)
    } catch (e) {
      console.log('error saving settings', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem saving your changes.',
      })
    } finally {
      setSaving(false)
    }
  }

  const saveSettingsGlobal = async (settings) => {
    const id = notificationSettingsGlobal['id']
    if (!id) return
    try {
      await api.updateNotificationSettingsGlobal(id, settings)
    } catch (e) {
      console.log('Error saving global settings')
      toaster({
        color: 'danger',
        title: 'Ooops!',
        text: 'There was a problem saving your changes.',
      })
    }
  }

  const saveAllSettings = useCallback(async () => {
    let allSettings = {}

    Object.entries(notificationSettings).forEach(
      ([project, { settings, id }]) => {
        if (settings) {
          allSettings[String(id)] = { ...settingsState[String(project)] }
        }
      }
    )
    try {
      await api.updateAllNotificationSettings(allSettings)
    } catch (e) {
      console.log('error saving settings', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem saving your changes.',
      })
    }
  }, [notificationSettings, settingsState, toaster])

  const deleteNotifications = async (ids) => {
    setLoading(true)
    try {
      if (!ids.length) return
      await api.deleteNotifications(ids)
      loadNotifications({ search: searchText, reset: true })
      setNotificationsTableIdMap({})
      const { offset, limit } = initialPagination
      fetchLatestNotifications(offset, limit)
      const title =
        ids.length > 1
          ? `${ids.length} notifications successfully deleted.`
          : 'Notification successfully deleted.'
      toaster({
        color: 'success',
        title,
      })
    } catch (e) {
      console.log('error deleting', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem deleting notifications.',
      })
    } finally {
      setLoading(false)
    }
  }

  const supportRequest = ({ title, type, content, to }) => `
    h4. Project: ${getProjectField(to)}

    h4. ${title}: ${type}

    ${content}
  `

  const createSupportTicket = async (id, notification) => {
    if (!id) return
    const request = {
      name: keycloak.getUserFullName(),
      email: keycloak && keycloak.tokenParsed.email,
      request: supportRequest(notification),
      notificationId: id,
    }
    try {
      await api.createSupportTicket(request)
      await fetchSupportTickets()
      setSupportToolExpanded(true)
      setShowToolBar(true)
      toaster({
        color: 'success',
        title: 'Support ticket successfully created.',
      })
    } catch (e) {
      console.log('error creating ticket', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem creating a support ticket.',
      })
    }
  }

  const findNotification = (id) => {
    if (!Object.keys(notifications).length) {
      return null
    }
    for (let date of Object.values(notifications)) {
      for (let noti of date) {
        if (noti.id === id) {
          return noti
        }
        if (noti.groupedNotifications) {
          for (let groupedNoti of noti.groupedNotifications) {
            if (groupedNoti.id === id) {
              return groupedNoti
            }
          }
        }
        if (noti.duplicateNotifications) {
          for (let duplicateNoti of noti.duplicateNotifications) {
            if (duplicateNoti.id === id) {
              return duplicateNoti
            }
          }
        }
      }
    }
    return null
  }

  const findDuplicateTicket = (id, notification) => {
    if (!supportTickets.length) {
      return null
    }
    for (let ticket of supportTickets) {
      if (!ticket.notificationId) {
        continue
      }
      if (ticket.notificationId === id) {
        return ticket
      }
      const existingNoti = findNotification(ticket.notificationId)
      if (!existingNoti) {
        continue
      }
      const existingNotiProject = getProjectField(existingNoti.to)
      const project = getProjectField(notification.to)
      if (
        existingNoti.type === notification.type &&
        existingNotiProject === project
      ) {
        return ticket
      }
    }
    return null
  }

  const handleOpenTicket = (id, notification) => {
    const existingTicket = findDuplicateTicket(id, notification)
    if (existingTicket) {
      setConfirmExistingTicket(existingTicket)
      setConfirmTicketModal(true)
      setActiveNotification(notification)
    } else {
      createSupportTicket(id, notification)
    }
  }

  const closeModal = () => {
    setNotificationSettingsInitialOpen('')
    setShowNotificationSettings(false)
    setSettingsExpandMap({})
  }

  const openModal = (id, project) => {
    setNotificationSettingsInitialOpen(project)
    setShowNotificationSettings(true)
    closePopover(id)
  }

  const updateSettings = ({ key, setting, newValue }) => {
    let newSettings = { ...settingsState }
    newSettings[String(key)][String(setting)] = newValue

    if (
      (setting === 'events' && newValue === true) ||
      (setting === 'volumeEnabled' && newValue === true) ||
      (setting === 'statusEnabled' && newValue === true)
    ) {
      newSettings[String(key)]['enabled'] = true
    } else if (
      newSettings[String(key)]['events'] === false &&
      newSettings[String(key)]['volumeEnabled'] === false &&
      newSettings[String(key)]['statusEnabled'] === false
    ) {
      newSettings[String(key)]['enabled'] = false
    }
    setSettingsState(newSettings)
  }

  const updateSettingsGlobal = ({ setting, newValue }) => {
    let newSettings = { ...settingsStateGlobal }
    newSettings[String(setting)] = newValue
    setSettingsStateGlobal(newSettings)
    saveSettingsGlobal(newSettings)
  }

  const toggleSection = ({ key, enable }) => {
    let newSettings = { ...settingsState }
    const setting = newSettings[String(key)]
    if (enable) {
      setting['enabled'] = true
      setting['events'] = true
      setting['volumeEnabled'] = true
      setting['statusEnabled'] = true
    } else {
      setting['enabled'] = false
      setting['events'] = false
      setting['volumeEnabled'] = false
      setting['statusEnabled'] = false
    }
    setSettingsState(newSettings)
  }

  const toggleSettingsExpand = (key) => {
    const newObject = { ...settingsExpandMap }
    if (newObject[String(key)]) {
      delete newObject[String(key)]
    } else {
      newObject[String(key)] = true
    }
    setSettingsExpandMap(newObject)
  }

  const togglePopover = (itemId) => setPopoverId(itemId)

  const closePopover = () => setPopoverId('')

  const isPopoverOpen = (itemId) => popoverId === itemId

  const getGroupId = (noti) => {
    const defaultGroupId = `${formatDateDash(noti.created_at)}-${
      noti.project
    }-${noti.type}`
    try {
      if (noti?.duplicateNotifications.length) {
        return noti.duplicateNotifications.map(({ id }) => id).join('-')
      }
      if (noti?.groupedNotifications.length) {
        return noti.groupedNotifications.map(({ id }) => id).join('-')
      }
      return defaultGroupId
    } catch {
      return defaultGroupId
    }
  }

  const toggleExpandGroupRow = (groupId) => {
    const newSet = new Set(expandGroupedRowSet)
    if (newSet.has(groupId)) {
      newSet.delete(groupId)
    } else {
      newSet.add(groupId)
    }
    setExpandGroupedRowSet(newSet)
  }

  const toggleDuplicates = (groupId) => {
    const newSet = new Set(expandDuplicateRowSet)
    if (newSet.has(groupId)) {
      newSet.delete(groupId)
    } else {
      newSet.add(groupId)
    }
    setExpandDuplicateRowSet(newSet)
  }

  const checkAllIdsSelected = (notifications) => {
    for (let { id } of notifications) {
      if (!notificationsTableIdMap[String(id)]) {
        return false
      }
    }
    return true
  }

  const getAllIdsFromGroupedRow = (row) => {
    let ids = []
    if (row.duplicateNotifications?.length) {
      ids = ids.concat(row.duplicateNotifications.map((n) => n.id))
    }
    if (row.groupedNotifications?.length) {
      ids = ids.concat(row.groupedNotifications.map((n) => n.id))
    }
    return ids
  }

  const checkAllGroupRowSelected = (row) => {
    if (row.duplicateNotifications?.length) {
      const allDuplicatesSelected = checkAllIdsSelected(
        row.duplicateNotifications
      )
      if (!allDuplicatesSelected) return false
    }
    if (row.groupedNotifications?.length) {
      const allGroupedSelected = checkAllIdsSelected(row.groupedNotifications)
      if (!allGroupedSelected) return false
    }
    return true
  }

  const selectAllGroupedRow = (groupId, groupedRow) => {
    const idMap = { ...notificationsTableIdMap }
    const ids = getAllIdsFromGroupedRow(groupedRow)
    const groupedSet = new Set(selectGroupedRowSet)

    const allSelected = checkAllGroupRowSelected(groupedRow)
    if (allSelected) {
      groupedSet.delete(groupId)
      for (let id of ids) {
        delete idMap[String(id)]
      }
    } else {
      groupedSet.add(groupId)
      for (let id of ids) {
        idMap[String(id)] = true
      }
    }
    setNotificationsTableIdMap(idMap)
    setSelectGroupedRowSet(groupedSet)
  }

  const selectAllDuplicateRow = (duplicateRows) => {
    if (!duplicateRows.length) return
    const idMap = { ...notificationsTableIdMap }
    const allChecked = checkAllIdsSelected(duplicateRows)

    for (let { id } of duplicateRows) {
      allChecked ? delete idMap[String(id)] : (idMap[String(id)] = true)
    }
    setNotificationsTableIdMap(idMap)
  }

  const checkAllNotificationsSelected = useCallback(() => {
    if (!Object.keys(notifications).length) {
      return false
    }
    for (let date of Object.values(notifications)) {
      for (let n of date) {
        if (n.id) {
          if (!notificationsTableIdMap[n.id]) {
            return false
          }
        }
        if (n.duplicateNotifications?.length) {
          const allDuplicatesSelected = checkAllIdsSelected(
            n.duplicateNotifications
          )
          if (!allDuplicatesSelected) {
            return false
          }
        }
        if (n.groupedNotifications?.length) {
          const allGroupedSelected = checkAllIdsSelected(n.groupedNotifications)
          if (!allGroupedSelected) {
            return false
          }
        }
      }
    }
    return true
  }, [notifications, notificationsTableIdMap])

  const selectAllNotifications = useCallback(() => {
    const idMap = {}
    for (let date of Object.values(notifications)) {
      for (let n of date) {
        if (n.id) {
          idMap[n.id] = true
        }
        if (n.duplicateNotifications) {
          for (let { id } of n.duplicateNotifications) {
            idMap[String(id)] = true
          }
        }
        if (n.groupedNotifications) {
          for (let { id } of n.groupedNotifications) {
            idMap[String(id)] = true
          }
        }
      }
    }
    setNotificationsTableIdMap(idMap)
  }, [notifications, setNotificationsTableIdMap])

  const isItemSelected = (itemId) => {
    return notificationsTableIdMap[String(itemId)]
  }

  const toggleAll = () => {
    const allSelected = checkAllNotificationsSelected()
    if (allSelected) {
      setNotificationsTableIdMap({})
    } else {
      selectAllNotifications()
    }
    return
  }

  const findAndRead = (read, ids, notifications) => {
    const idSet = new Set(ids)
    const updatedNotifications = { ...notifications }
    for (let date of Object.values(updatedNotifications)) {
      for (let n of date) {
        if (n.id && idSet.has(n.id)) {
          n.read = read
          idSet.delete(n.id)
          if (!idSet.size) {
            return updatedNotifications
          }
        }
        if (n.groupedNotifications) {
          for (let g of n.groupedNotifications) {
            if (g.id && idSet.has(g.id)) {
              g.read = read
              idSet.delete(g.id)
              if (!idSet.size) {
                return updatedNotifications
              }
            }
          }
        }
        if (n.duplicateNotifications) {
          for (let d of n.duplicateNotifications) {
            if (d.id && idSet.has(d.id)) {
              d.read = read
              idSet.delete(d.id)
              if (!idSet.size) {
                return updatedNotifications
              }
            }
          }
        }
      }
    }
    return updatedNotifications
  }

  const readNotifications = useCallback(
    async (read, ids) => {
      const current = { ...notifications }
      const readBool = read ? false : true

      try {
        const resp = await api.readNotifications(ids, readBool)
        if (resp.data.errors?.length) {
          toaster({
            color: 'danger',
            title: 'Oops!',
            text: 'There was a problem saving your changes.',
          })
          return
        }
        const updatedNotifications = await findAndRead(readBool, ids, current)
        setNotifications(updatedNotifications)
      } catch (e) {
        console.log('Error updating notifications >>', e)
        toaster({
          color: 'danger',
          title: 'Oops!',
          text: 'There was a problem saving your changes.',
        })
      }
    },
    [notifications, setNotifications]
  )

  const checkGroupedRead = ({
    groupedNotifications,
    duplicateNotifications,
  }) => {
    if (groupedNotifications.length) {
      for (let g of groupedNotifications) {
        if (!g.read) return false
      }
    }
    if (duplicateNotifications.length) {
      for (let d of duplicateNotifications) {
        if (!d.read) return false
      }
    }
    return true
  }

  const checkDuplicateRowRead = (duplicateNotifications) => {
    if (!duplicateNotifications.length) {
      return false
    }
    for (let n of duplicateNotifications) {
      if (!n.read) {
        return false
      }
    }
    return true
  }

  const getCount = (notifications) => {
    return Object.values(notifications).reduce((count, date) => {
      for (let n of date) {
        if (n.id) {
          count++
          continue
        }
        if (n.groupedNotifications) {
          for (let g of n.groupedNotifications) {
            if (g.id) {
              count++
            }
          }
        }
        if (n.duplicateNotifications) {
          for (let d of n.duplicateNotifications) {
            if (d.id) {
              count++
            }
          }
        }
      }
      return count
    }, 0)
  }

  const renderOptions = ({ id, ids, row, options }) => {
    const getProject = () => {
      if (row.to) {
        return getProjectField(row.to)
      }
      if (row.project) {
        return row.project
      }
      return ''
    }

    const contextItems = {
      createTicket: (
        <EuiContextMenuItem
          key='A'
          icon='link'
          onClick={() => {
            handleOpenTicket(id, row)
            closePopover(id)
          }}
        >
          Create Ticket
        </EuiContextMenuItem>
      ),
      configure: (
        <EuiContextMenuItem
          key='B'
          icon='gear'
          onClick={() => openModal(id, getProject())}
        >
          Configure
        </EuiContextMenuItem>
      ),
      delete: (
        <EuiContextMenuItem
          key='C'
          icon='trash'
          color='danger'
          onClick={() => deleteNotifications([id])}
        >
          Delete
        </EuiContextMenuItem>
      ),
      deleteAll: (
        <EuiContextMenuItem
          key='D'
          icon='trash'
          color='danger'
          onClick={() => deleteNotifications(ids)}
        >
          Delete {`(${ids?.length})`}
        </EuiContextMenuItem>
      ),
    }

    const items = options.map((o) => contextItems[String(o)])

    return (
      <EuiPopover
        button={
          <EuiButtonIcon
            id={id}
            aria-label='Actions'
            iconType='gear'
            size='s'
            color='text'
            onClick={() => togglePopover(id)}
          />
        }
        isOpen={isPopoverOpen(id)}
        closePopover={() => closePopover(id)}
        panelPaddingSize='none'
        anchorPosition='leftCenter'
      >
        <EuiContextMenuPanel items={items} />
      </EuiPopover>
    )
  }

  const optionalActionButtons = () => {
    const count = Object.keys(notificationsTableIdMap).length
    const ids = Object.keys(notificationsTableIdMap)
    if (!count) {
      return <div style={{ minWidth: '.3rem' }}></div>
    }
    return (
      <EuiFlexItem
        className='fade-right'
        grow={false}
        style={{ flexDirection: 'row', alignItems: 'center' }}
      >
        <EuiButtonIcon
          iconSize='s'
          iconType='trash'
          aria-label='notifications-options-button'
          onClick={() => setConfirmDeleteModal(true)}
          className='notifications-option-action-button'
        />
        <EuiButtonIcon
          iconSize='s'
          iconType='eye'
          aria-label='notifications-options-button'
          onClick={() => {
            readNotifications(false, ids)
            setNotificationsTableIdMap({})
          }}
          className='notifications-option-action-button'
        />
        <EuiButtonIcon
          iconSize='s'
          iconType='eyeClosed'
          aria-label='notifications-options-button'
          onClick={() => {
            readNotifications(true, ids)
            setNotificationsTableIdMap({})
          }}
          className='notifications-option-action-button'
        />
      </EuiFlexItem>
    )
  }

  let columns = [
    {
      id: 'checkbox',
      isCheckbox: true,
    },
    {
      render: () => <></>,
    },
    {
      field: 'type',
      alignment: 'center',
      className: 'notifications-table-type',
      render: ({ cell }) => <CircleIcon type={cell} />,
    },
    {
      field: 'content',
      className: 'notifications-content',
      render: ({ cell, row }) => {
        return (
          <>
            <EuiButtonIcon
              size='xs'
              iconType='dot'
              className={`notifications-read-button ${
                row.read && 'notifications-read-button-is-read'
              }`}
              aria-label='notifications-read-button'
              onClick={() => readNotifications(row.read, [row.id])}
            />
            <EuiText className='notifications-content-text' size='s'>
              {cell}
            </EuiText>
          </>
        )
      },
    },
    {
      field: 'to',
      sortable: true,
      render: ({ cell }) => {
        const project = getProjectField(cell)
        const nameMap = displayNames.projects
        return (
          <span>
            {nameMap[String(project)] ? nameMap[String(project)] : project}
          </span>
        )
      },
    },
    {
      field: 'created_at',
      render: ({ cell }) => <span>{formatDateTime(cell)}</span>,
    },
    {
      field: 'id',
      alignment: 'right',
      name: <span></span>,
      width: '58px',
      isOptions: ({ row }) =>
        renderOptions({
          id: row.id,
          row,
          options: ['createTicket', 'configure', 'delete'],
        }),
    },
  ]

  let columnsGroupedTop = [
    {
      renderCheckbox: ({ row }) => {
        const groupId = getGroupId(row)
        return (
          <EuiTableRowCellCheckbox
            key={`notifications-checkbox-`}
            className='notifications-table-checkbox'
          >
            <EuiCheckbox
              id={`notifications-grouped-top-checkbox-${groupId}`}
              checked={checkAllGroupRowSelected(row)}
              onChange={() => selectAllGroupedRow(groupId, row)}
              type='inList'
              style={{ padding: 0 }}
            />
          </EuiTableRowCellCheckbox>
        )
      },
    },
    {
      field: 'expand-button-top',
      isExpander: true,
      render: ({ row }) => {
        const groupId = getGroupId(row)
        return (
          <EuiButtonIcon
            iconType={
              expandGroupedRowSet.has(groupId) ? 'arrowDown' : 'arrowRight'
            }
            aria-label='notifications-grouped-expand'
            onClick={() => toggleExpandGroupRow(groupId)}
          />
        )
      },
    },
    {
      field: 'type',
      alignment: 'center',
      className: 'notifications-table-type',
      render: ({ cell }) => <CircleIcon type={cell} />,
    },
    {
      field: 'type',
      className: 'notifications-content',
      render: ({ row }) => {
        const ids = getAllIdsFromGroupedRow(row)
        const allRead = checkGroupedRead(row)
        const mappedType = notificationTypeMap[String(row.type)]
          ? notificationTypeMap[String(row.type)]
          : notificationTypeMap['default']
        const groupId = getGroupId(row)
        return (
          <>
            <EuiButtonIcon
              size='xs'
              iconType='dot'
              className={`notifications-read-button ${
                allRead && 'notifications-read-button-is-read'
              }`}
              aria-label='notifications-read-button'
              onClick={() => readNotifications(allRead, ids)}
            />
            <EuiText
              className='notifications-content-text'
              size='s'
              style={{ width: '100%' }}
              onClick={() => toggleExpandGroupRow(groupId)}
            >
              {mappedType.groupedText}
            </EuiText>
          </>
        )
      },
    },
    {
      field: 'project',
    },
    {
      field: '',
      alignment: 'right',
      render: ({ row }) => {
        const ids = getAllIdsFromGroupedRow(row)
        return <>{ids.length}</>
      },
    },
    {
      field: 'id',
      alignment: 'right',
      name: <span></span>,
      isOptions: ({ row }) =>
        renderOptions({
          id: getGroupId(row),
          ids: getAllIdsFromGroupedRow(row),
          row,
          options: ['configure', 'deleteAll'],
        }),
    },
  ]

  let columnsGrouped = [
    {
      name: 'grouped-placeholder-1',
    },
    {
      name: 'grouped-placeholder-2',
    },
    {
      isCheckbox: true,
    },
    {
      field: 'content',
      name: 'Notification',
      colSpan: 2,
      className: 'notifications-content',
      render: ({ cell, row }) => {
        return (
          <>
            <EuiButtonIcon
              size='xs'
              iconType='dot'
              className={`notifications-read-button ${
                row.read && 'notifications-read-button-is-read'
              }`}
              aria-label='notifications-read-button'
              onClick={() => readNotifications(row.read, [row.id])}
            />
            <EuiText className='notifications-content-text' size='s'>
              {cell}
            </EuiText>
          </>
        )
      },
    },
    {
      field: 'created_at',
      name: `Date ${formatTimeZone()}`,
      render: ({ cell }) => <span>{formatDateTime(cell)}</span>,
    },
    {
      alignment: 'right',
      name: <span></span>,
      isOptions: ({ row }) =>
        renderOptions({
          id: row.id,
          row,
          options: ['createTicket', 'configure', 'delete'],
        }),
    },
  ]

  let columnsDuplicates = [
    {
      field: 'duplicates-placeholder',
    },
    {
      field: 'duplicates-expand-button',
      width: '40px',
      render: ({ groupedRow, curIdx, dupeIdx }) => {
        if (curIdx === 0) {
          const groupId = `${getGroupId(groupedRow)}-${dupeIdx}`
          return (
            <EuiButtonIcon
              iconType={
                expandDuplicateRowSet.has(groupId) ? 'arrowDown' : 'arrowRight'
              }
              aria-label='notification-row-duplicate'
              onClick={() => toggleDuplicates(groupId)}
            />
          )
        }
      },
    },
    {
      isCheckbox: true,
      width: '40px',
      renderCheckbox: ({ row, groupedRow, curIdx, dupeIdx }) => {
        const groupId = `${getGroupId(groupedRow)}-${dupeIdx}`
        const onChangeSelectDuplicates = () => {
          if (curIdx === 0 && !expandDuplicateRowSet.has(groupId)) {
            selectAllDuplicateRow(groupedRow.duplicateNotifications)
          } else {
            selectNotificationsTable(row.id)
          }
        }
        return (
          <EuiTableRowCellCheckbox
            key={`notifications-checkbox-`}
            className='notifications-table-checkbox'
          >
            <EuiCheckbox
              id={`notifications-grouped-top-checkbox-${groupId}`}
              checked={isItemSelected(row.id)}
              onChange={onChangeSelectDuplicates}
              type='inList'
            />
          </EuiTableRowCellCheckbox>
        )
      },
    },
    {
      field: 'content',
      className: 'notifications-content',
      colSpan: 2,
      render: ({ cell, row, groupedRow, curIdx, dupeIdx }) => {
        const groupId = `${getGroupId(groupedRow)}-${dupeIdx}`
        const showTotal = () => {
          if (curIdx !== 0) return
          let total = 0
          if (groupedRow.duplicateNotifications?.length) {
            total = groupedRow.duplicateNotifications.length
          }
          return `(${total})`
        }
        if (curIdx === 0 && !expandDuplicateRowSet.has(groupId)) {
          const ids = groupedRow.duplicateNotifications.map((n) => n.id)
          const allRead = checkDuplicateRowRead(
            groupedRow.duplicateNotifications
          )
          return (
            <>
              <EuiButtonIcon
                size='xs'
                iconType='dot'
                className={`notifications-read-button ${
                  allRead && 'notifications-read-button-is-read'
                }`}
                aria-label='notifications-read-button'
                onClick={() => readNotifications(row.read, ids)}
              />
              <EuiText
                className='notifications-content-text'
                size='s'
                style={{ width: '100%' }}
                onClick={() => toggleDuplicates(groupId)}
              >
                {cell} {showTotal()}
              </EuiText>
            </>
          )
        } else {
          return (
            <>
              <EuiButtonIcon
                size='xs'
                iconType='dot'
                className={`notifications-read-button ${
                  row.read && 'notifications-read-button-is-read'
                }`}
                aria-label='notifications-read-button'
                onClick={() => readNotifications(row.read, [row.id])}
              />
              <EuiText
                size='s'
                style={{ width: '100%' }}
                onClick={
                  curIdx === 0 ? () => toggleDuplicates(groupId) : () => {}
                }
              >
                {cell} {showTotal()}
              </EuiText>
            </>
          )
        }
      },
    },
    {
      field: 'created_at',
      name: `Date ${formatTimeZone()}`,
      render: ({ cell }) => <span>{formatDateTime(cell)}</span>,
    },
    {
      alignment: 'right',
      isOptions: ({ row, groupedRow, curIdx }) => {
        const groupId = getGroupId(groupedRow)
        if (curIdx === 0 && !expandDuplicateRowSet.has(groupId)) {
          const ids = groupedRow.duplicateNotifications.map((n) => n.id)
          return renderOptions({
            id: 'duplicate-row-' + ids.join('-'),
            ids,
            row: groupedRow,
            options: ['configure', 'deleteAll'],
          })
        } else {
          return renderOptions({
            id: row.id,
            row,
            options: ['createTicket', 'configure', 'delete'],
          })
        }
      },
    },
  ]

  const getRowKey = (item) => {
    try {
      if (item.id) {
        return item.id
      }
      if (item?.duplicateNotifications.length) {
        return item.duplicateNotifications.map(({ id }) => id).join('-')
      }
      if (item?.groupedNotifications.length) {
        return item.groupedNotifications.map(({ id }) => id).join('-')
      }
      return 'undefined-row-key'
    } catch {
      return 'undefined-row-key'
    }
  }

  const renderRows = () => {
    if (!notificationsLoaded) {
      return (
        <EuiTableRow>
          <EuiTableRowCell
            colSpan={columns.length}
            className='table-loader'
            align='center'
          >
            {/* <TableLoading /> */}
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    if (!Object.keys(notifications).length) {
      return (
        <EuiTableRow>
          <EuiTableRowCell colSpan={columns.length} align='center'>
            No notifications found
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    const renderRow = ({ item, columns, groupedRow, curIdx, dupeIdx }) => {
      const cells = columns.map((column, columnIndex) => {
        const cell = item[column.field]
        let child

        if (column.render) {
          child = column.render({
            cell,
            row: item,
            groupedRow,
            curIdx,
            dupeIdx,
          })
        } else {
          child = cell
        }

        if (column.renderCheckbox) {
          return column.renderCheckbox({
            row: item,
            groupedRow,
            curIdx,
            dupeIdx,
          })
        }

        if (column.isCheckbox) {
          return (
            <EuiTableRowCellCheckbox
              key={`notifications-checkbox-${item.id}`}
              className='notifications-table-checkbox'
            >
              <EuiCheckbox
                id={`notifications-${item.id}-checkbox`}
                checked={isItemSelected(item.id)}
                onChange={() => selectNotificationsTable(item.id)}
                type='inList'
              />
            </EuiTableRowCellCheckbox>
          )
        }

        if (column.isOptions) {
          child = column.isOptions({ row: item, groupedRow, curIdx })
        }

        return (
          <EuiTableRowCell
            key={`notifications-page-row-cell-${column.field}-${columnIndex}`}
            truncateText={column.truncateText}
            align={column.alignment}
            textOnly={true}
            style={{ textAlign: 'left' }}
            className={column.className}
            colSpan={column.colSpan}
            width={column.width}
          >
            {child}
          </EuiTableRowCell>
        )
      })

      const rowKey = getRowKey(item)

      return (
        <React.Fragment key={`notifications-row-page-${rowKey}`}>
          <EuiTableRow
            className='notifications-row'
            itemID={item.id}
            isExpandedRow={true}
          >
            {cells}
          </EuiTableRow>
        </React.Fragment>
      )
    }

    const splitDuplicateNotifications = (notis) => {
      const uniques = notis.reduce((uniqueMap, n) => {
        if (uniqueMap[String(n.content)]) {
          uniqueMap[String(n.content)].push(n)
        } else {
          uniqueMap[String(n.content)] = [n]
        }
        return uniqueMap
      }, {})
      return Object.values(uniques)
    }

    const renderDuplicateRows = (notifications, dupeIdx) => {
      const duplicateNotifications = notifications.duplicateNotifications
      if (!duplicateNotifications?.length) {
        return
      }
      const groupId = `${getGroupId(notifications)}-${dupeIdx}`
      if (expandDuplicateRowSet.has(groupId)) {
        return (
          <>
            {duplicateNotifications.map((d, idx) =>
              renderRow({
                item: d,
                columns: columnsDuplicates,
                groupedRow: notifications,
                curIdx: idx,
                dupeIdx,
              })
            )}
          </>
        )
      }
      return (
        <>
          {renderRow({
            item: duplicateNotifications[0],
            columns: columnsDuplicates,
            groupedRow: notifications,
            curIdx: 0,
            dupeIdx,
          })}
        </>
      )
    }

    const renderExpandedRows = (notifications) => {
      const groupId = getGroupId(notifications)
      const uniques = splitDuplicateNotifications(
        notifications.duplicateNotifications
      )
      if (expandGroupedRowSet.has(groupId)) {
        return (
          <React.Fragment key={`notifications-expanded-rows-${groupId}`}>
            {uniques.map((dupes, dupeIdx) =>
              renderDuplicateRows(
                {
                  ...notifications,
                  duplicateNotifications: dupes,
                },
                dupeIdx
              )
            )}
            {notifications.groupedNotifications.map((g) =>
              renderRow({
                item: g,
                columns: columnsGrouped,
                groupedRow: notifications,
              })
            )}
          </React.Fragment>
        )
      }
    }

    const renderDateHeader = (date) => {
      return (
        <React.Fragment key={`notifications-date-header-${date}`}>
          <EuiTableRow>
            <EuiTableRowCell
              colSpan={columns.length}
              className='notifications-date-header-content'
              style={{ height: '2rem' }}
            >
              <EuiText size='s'>
                <EuiIcon type='clock' style={{ marginRight: '12px' }} />
                {getNotificationDateHeader(date)}
              </EuiText>
            </EuiTableRowCell>
          </EuiTableRow>
        </React.Fragment>
      )
    }

    const rows = []

    for (var item of Object.keys(notifications)) {
      rows.push(renderDateHeader(item))
      for (var n of notifications[String(item)]) {
        if (n.groupedNotifications || n.duplicateNotifications) {
          rows.push(renderRow({ item: n, columns: columnsGroupedTop }))
          rows.push(renderExpandedRows(n))
          continue
        }
        rows.push(renderRow({ item: n, columns }))
      }
    }

    return rows
  }

  const renderFooter = () => {
    let { total } = notificationsPagination
    const count = getCount(notifications)
    const noMore = count >= total

    const renderCount = () => {
      if (!total) {
        return ''
      }
      if (count < 1) {
        return ''
      }
      return `${count} of ${total}`
    }

    return (
      <>
        <EuiTableFooterCell
          colSpan={columns.length}
          align='right'
          className='notifications-table-footer'
        >
          <EuiFlexGroup gutterSize='none' style={{ alignItems: 'center' }}>
            <EuiFlexItem>
              <EuiText size='xs'>{renderCount()}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButtonIcon
                color='text'
                iconType='arrowRight'
                aria-label='notifications-next'
                style={{ margin: '0 .5rem', backgroundColor: 'none' }}
                onClick={() =>
                  loadNotifications({ search: searchText, add: true })
                }
                disabled={noMore}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiTableFooterCell>
      </>
    )
  }

  useEffect(() => {
    if (Object.keys(notificationSettings).length) {
      const settings = buildSettingsState(notificationSettings)
      setSettingsState(settings)
      setSettingsStateCopy(settings)
    }
  }, [notificationSettings])

  useEffect(() => {
    if (Object.keys(notificationSettingsGlobal).length) {
      setSettingsStateGlobal(notificationSettingsGlobal['settings'])
    }
  }, [notificationSettingsGlobal])

  useEffect(() => {
    if (settingsState === settingsStateCopy) {
      return
    }
    if (!isMount) {
      saveAllSettings()
    }
    // eslint-disable-next-line
  }, [settingsState])

  useEffect(() => {
    const expandGroupedRow = (id) => {
      const newSet = new Set([id])
      setExpandGroupedRowSet(newSet)
    }
    const expandDuplicateRow = (id) => {
      const newSet = new Set([id])
      setExpandDuplicateRowSet(newSet)
    }
    const findAndExpand = () => {
      if (!notificationInitialSelect || !Object.values(notifications)) {
        return
      }
      for (let date of Object.values(notifications)) {
        for (let n of date) {
          if (n.id === notificationInitialSelect) {
            return
          }
          if (n.groupedNotifications || n.duplicateNotifications) {
            const groupId = getGroupId(n)
            for (let g of n.groupedNotifications) {
              if (g.id === notificationInitialSelect) {
                expandGroupedRow(groupId)
                return
              }
            }
            for (let d of n.duplicateNotifications) {
              if (d.id === notificationInitialSelect) {
                expandGroupedRow(groupId)
                expandDuplicateRow(groupId)
                return
              }
            }
          }
        }
      }
      return
    }
    findAndExpand()
    setNotificationInitialSelect('')
    // eslint-disable-next-line
  }, [notificationInitialSelect])

  const value = {
    settingsState,
    settingsStateGlobal,
    updateSettings,
    saveSettings,
    toggleSection,
    toggleSettingsExpand,
    settingsExpandMap,
    updateSettingsGlobal,
  }

  return (
    <NotificationsContext.Provider value={value}>
      <EuiPage
        className={`euiNavDrawerPage
          ${navBarExpanded ? 'navBarExpanded' : 'navBarCollapsed'}
          ${
            (exportToolExpanded || supportToolExpanded) &&
            'export-bar-expanded-pad'
          }`}
      >
        <EuiPageBody className='euiNavDrawerPage__pageBody'>
          <div className='page_wrapper'>
            <EuiPageHeader>
              <EuiPageHeaderSection>
                <EuiTitle size='m'>
                  <h1>Notifications</h1>
                </EuiTitle>
              </EuiPageHeaderSection>
              <EuiPageHeaderSection>
                {/* TODO - Remove after notifications testing */}
                {keycloak &&
                  keycloak.hasClientRole({
                    roleName: 'notifications-tester',
                  }) && (
                    <EuiButtonIcon
                      iconType='gear'
                      iconSize='l'
                      color='text'
                      aria-label='Options'
                      onClick={() => setShowNotificationSettings(true)}
                    />
                  )}
              </EuiPageHeaderSection>
            </EuiPageHeader>
            <EuiSpacer size='m' />
            <EuiFieldSearch
              fullWidth
              placeholder='Search...'
              onSearch={(search) => {
                setSearchText(search)
                loadNotifications({ search, reset: true })
              }}
              onChange={(e) => {
                if (e.target.value === '') {
                  loadNotifications({ reset: true })
                }
              }}
            />
            <EuiSpacer size='m' />
            <EuiPanel
              id='notifications-table-panel'
              color='transparent'
              style={{
                height: '70vh',
                overflow: 'auto',
                padding: 0,
                boxShadow: 'none',
              }}
            >
              <EuiFlexGroup className='notifications-header' gutterSize='none'>
                <EuiFlexItem
                  grow={false}
                  style={{
                    width: '40px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: '12px',
                  }}
                >
                  <EuiCheckbox
                    id='selectAllCheckbox'
                    checked={checkAllNotificationsSelected()}
                    onChange={toggleAll}
                    type='inList'
                  />
                </EuiFlexItem>
                <EuiFlexGroup
                  gutterSize='none'
                  className='notifications-option-action'
                >
                  {optionalActionButtons()}
                  <EuiFlexItem
                    grow={false}
                    style={{ flexDirection: 'row', alignItems: 'center' }}
                  >
                    <EuiIcon size='s' type='bell' />
                    <EuiText style={{ marginLeft: '1rem' }}>
                      Notifications
                    </EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiFlexGroup
                      gutterSize='none'
                      style={{
                        flexDirection: 'row-reverse',
                        textAlign: 'left',
                      }}
                    >
                      <EuiFlexItem
                        grow={false}
                        style={{ width: '275px', marginRight: '58px' }}
                      >
                        <EuiText>Date {formatTimeZone()}</EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false} style={{ width: '275px' }}>
                        <EuiText>Project</EuiText>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexGroup>
              <EuiTable
                className='notifications-table'
                tableLayout='fixed'
                style={{ backgroundColor: 'transparent', whiteSpace: 'nowrap' }}
              >
                <EuiTableHeader className='notifications-table-header-placeholder'>
                  <EuiTableHeaderCell width='40px' />
                  <EuiTableHeaderCell width='22px' />
                  <EuiTableHeaderCell width='32px' />
                  <EuiTableHeaderCell
                    style={{ minWidth: '20rem', maxWidth: '40rem' }}
                  />
                  <EuiTableHeaderCell width='275px' />
                  <EuiTableHeaderCell width='275px' />
                  <EuiTableHeaderCell width='58px' />
                </EuiTableHeader>
                <EuiTableBody>{renderRows()}</EuiTableBody>
                <EuiTableFooter>{renderFooter()}</EuiTableFooter>
              </EuiTable>
              {loading && <EuiProgress size='xs' color='primary' />}
            </EuiPanel>

            <NotificationsSettings
              showModal={showNotificationSettings}
              closeModal={closeModal}
            />
          </div>
        </EuiPageBody>
        <ConfirmTicket
          ticket={confirmExistingTicket}
          showModal={confirmTicketModal}
          onConfirm={createSupportTicket}
          onCancel={() => {
            setConfirmTicketModal(false)
            setConfirmExistingTicket(null)
            setActiveNotification(null)
          }}
          activeNotification={activeNotification}
        />
        <ConfirmDeleteNotiModal
          showModal={confirmDeleteModal}
          onConfirm={() =>
            deleteNotifications(Object.keys(notificationsTableIdMap))
          }
          onCancel={() => setConfirmDeleteModal(false)}
          ids={Object.keys(notificationsTableIdMap)}
        />
      </EuiPage>
    </NotificationsContext.Provider>
  )
}
