import React, { useContext } from 'react'

import {
  EuiDescriptionList,
  EuiAccordion,
  EuiFlexGroup,
  EuiFlexItem,
  EuiAvatar,
  EuiText,
  EuiTitle,
  EuiButtonIcon,
  EuiBadge,
  EuiPanel,
} from '@elastic/eui'
import './side_panel.css'
import { AppContext } from '../../app'

const UserDetailsCell = (name) => {
  return (
    <div style={{ width: '100%' }}>
      <EuiFlexGroup gutterSize='m' alignItems='center'>
        <EuiFlexItem grow={false}>
          <EuiAvatar name={name} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size='s'>
            <h3>{name}</h3>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}

const UserDetailsList = (user, tags, displayEditUser, toggleItem) => {
  let { id, created_at, email, name, updated_at } = user
  let items = [
    { title: 'Name', description: name },
    { title: 'Email', description: email },
    { title: 'Created On', description: created_at },
    { title: 'Last Updated', description: updated_at },
    { title: 'Tags', description: '' },
  ]

  return (
    <EuiFlexItem>
      <EuiAccordion
        id={id}
        initialIsOpen
        buttonContent={UserDetailsCell(name)}
        extraAction={[
          <EuiButtonIcon
            onClick={() => displayEditUser(id)}
            iconType='pencil'
            color='text'
            aria-label='Edit'
            key='A'
          />,
          <EuiButtonIcon
            onClick={() => toggleItem(id)}
            iconType='cross'
            color='text'
            aria-label='Close'
            key='B'
          />,
        ]}
        className='UserDetailsCell fade-down'
      >
        <EuiPanel>
          <EuiDescriptionList
            className='UserDetailsDescriptionList'
            textStyle='reverse'
            listItems={items}
          ></EuiDescriptionList>
          <div className='UserDetailsTagsContainer'>
            {UserTagsBadges(user, tags)}
          </div>
        </EuiPanel>
      </EuiAccordion>

      <div className='cellDivider'></div>
    </EuiFlexItem>
  )
}

function mapTagNamesForUser(user, tags) {
  let tagNames = []
  user.tags.forEach((tag) => {
    tags.forEach((tagName) => {
      if (tag.tag === tagName.id) {
        tagNames.push(tagName.name)
      }
    })
  })
  return tagNames
}

const UserTagsBadges = (user, tags) => {
  let tagsForUser = mapTagNamesForUser(user, tags)
  return (
    <div>
      <EuiFlexGroup style={{ maxWidth: '250px' }} wrap gutterSize='xs'>
        {tagsForUser.map((tag, idx) => {
          return (
            <EuiFlexItem grow={false} key={`user-tags-badges-idx-${idx}`}>
              <EuiBadge
                color={tag === 'Administrator' ? 'secondary' : 'default'}
                iconType='tag'
              >
                {tag}
              </EuiBadge>
            </EuiFlexItem>
          )
        })}
      </EuiFlexGroup>
    </div>
  )
}

export const SidePanel = ({ open, users, tags, displayEditUser, toggleItem }) => {
  const {
    showToolBar,
    exportToolExpanded,
    supportToolExpanded
  } = useContext(AppContext)
  let className = open ? 'SidePanelOpen' : 'SidePanelClosed'
  const renderUsers = () => {
    return users.map((user, idx) => {
      return (
        <EuiFlexGroup gutterSize='none' key={`side-panels-users-idx-${idx}`}>
          {UserDetailsList(user, tags, displayEditUser, toggleItem)}
        </EuiFlexGroup>
      )
    })
  }

  const getSidePanelPadWithExportBar = () => {
    if (showToolBar) {
      if (supportToolExpanded || exportToolExpanded) {
        return '271px'
      }
      return '48px'
    }
    return '0'
  }

  return (
    <div
      className={className}
      style={{ right: getSidePanelPadWithExportBar() }}
    >
      {users.length > 0 ? (
        <>
          <EuiTitle size='xxs'>
            <h3 className='UserDetailsHeader'>User Details</h3>
          </EuiTitle>
          <div className='UserDetailsListContainer'>{renderUsers()}</div>
        </>
      ) : (
        ''
      )}
    </div>
  )
}
