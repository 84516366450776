import React, {
  useContext,
  useRef,
  useEffect,
  useState
} from 'react'

import ProjectSettingsItem from './project_settings_item'
import { NotificationsContext } from './notifications_page'
import { AppContext } from '../../app'
import {
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiTitle,
  EuiSpacer,
  EuiSuperSelect,
  EuiButtonIcon,
  EuiButtonEmpty,
  EuiPopover,
  EuiPanel
} from '@elastic/eui'

import api from '../../lib/api'

// Ignore list when comparing user settings with group settings
const groupIgnoreList = ['web', 'disabledDays', 'weekend']

export const ProjectsSettings = () => {
  const { settingsState } = useContext(NotificationsContext)
  const {
    fetchNotificationSettings,
    notificationSettings,
    userGroups,
    projects,
    fetchUserGroups,
    toaster
  } = useContext(AppContext)
  const content = useRef()

  const [selectedGroupNoti, setSelectedGroupNoti] = useState('')
  const [groupOptions, setGroupOptions] = useState({})
  const [groupInfoPopover, setGroupInfoPopover] = useState(false)

  useEffect(() => {
    fetchUserGroups()
  }, [])

  useEffect(() => {
    if (!userGroups?.length) return
    const groupNotiOptions = userGroups.reduce((acc, group) => {
      if (!group.notificationConfigs?.length)
      return acc
      const configs = group.notificationConfigs.map(nc => {
        return {
          project: nc.entity.entityName,
          settings: nc.settings
        }
      })
      acc[String(group.name)] = configs
      return acc
    }, {})
    setGroupOptions(groupNotiOptions)
  }, [userGroups])

  const getGroupOptions = () => {
    if (!Object.keys(groupOptions)) {
      return [{ value: '', inputDisplay: 'No Group Settings' }]
    }
    const options = []
    for (const p of Object.keys(groupOptions)) {
      options.push({ value: p, inputDisplay: p })
    }
    return options
  }

  const filterState = (projects) => {
    const newObject = { ...projects }
    if (newObject['general']) {
      delete newObject['general']
    }
    return Object.keys(newObject).map((p) => p)
  }

  const projectSettings = filterState(settingsState)

  const scrollIntoView = () => {
    content.current.childContent.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    })
  }

  const saveGroupSettings = async () => {
    try {
      const data = {}
      const groupSettings = groupOptions[String(selectedGroupNoti)]
      for (const gs of groupSettings) {
        const userSetting = notificationSettings[String(gs.project)]
        if (!userSetting) continue
        data[String(userSetting.id)] = { ...gs.settings }
      }
      if (!Object.keys(data)) return
      await api.updateAllNotificationSettings(data)
      await fetchNotificationSettings(projects)
    } catch(e) {
      console.log('Error applying group settings >> ', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem saving your changes.',
      })
    }
  }

  const checkAllGroupSettings = () => {
    const groupSettings = groupOptions[String(selectedGroupNoti)]
    for (const gs of groupSettings) {
      if (!settingsState[String(gs.project)]) {
        continue
      }
      const userSetting = settingsState[String(gs.project)]
      for (const [k, v] of Object.entries(gs.settings)) {
        if (groupIgnoreList.includes(k)) continue
        if (Array.isArray(v)) {
          if (v.length !== userSetting[String(k)].length) {
            return false
          }
          for (let i=0; i < v.length; i++) {
            if (v[String(i)] !== userSetting[String(k)][String(i)]) {
              return false
            }
          }
        } else {
          if (v !== userSetting[String(k)]) {
            return false
          }
        }
      }
    }
    return true
  }

  const renderGroupNotiButton = () => {
    if (!selectedGroupNoti) {
      return (
        <EuiButtonEmpty
          disabled={true}
          style={{ fontSize: '.8em' }}
        >
          Apply Group Settings
        </EuiButtonEmpty>
      )
    }

    const applied = checkAllGroupSettings()

    return (
      <>
        <EuiButtonEmpty
          disabled={applied ? true : false}
          style={{ fontSize: '.8em' }}
          onClick={saveGroupSettings}
        >
          {applied ? 'Group Settings Applied': 'Apply Group Settings'}
        </EuiButtonEmpty>
        {applied && <EuiIcon size='s' color='success' type='checkInCircleFilled'/>}
      </>
    )
  }

  if (!Object.keys(notificationSettings).length) {
    return (
      <EuiFlexGroup
        gutterSize='s'
        responsive={false}
        alignItems='center'
        justifyContent='center'
      >
        <EuiFlexItem grow={false}>
          <EuiIcon type='package' size='l' />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText size='s'>
            <p>No projects available</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  return (
    <>
      <div className={`notifications-container`}>
        <EuiFlexGroup
          gutterSize='xs'
          responsive={false}
          alignItems='center'
          style={{ padding: '0 1rem' }}
        >
          <EuiFlexItem grow={false}>
            <EuiIcon type='package' size='l' />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiTitle
              size='s'
              style={{
                fontSize: '16px',
                letterSpacing: '1.5px',
                fontWeight: '600',
                marginLeft: '7px',
              }}
            >
              <p>PROJECTS</p>
            </EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiSpacer size='xs' className='notifications-spacer-project' />
      </div>
      <EuiFlexGroup direction='column' gutterSize='m'>
        <EuiFlexItem>
          <EuiFlexGroup className='notifications-group-select' gutterSize='none'>
            <EuiFlexItem>
              <EuiFlexGroup gutterSize='s' alignItems='center'>
                <EuiFlexItem grow={false}>
                  <EuiIcon type='users' size='l' />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiSuperSelect
                    style={{ width: '165px' }}
                    options={getGroupOptions()}
                    valueOfSelected={selectedGroupNoti}
                    onChange={(value) => setSelectedGroupNoti(value)}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiPopover
                    button={
                      <EuiButtonIcon
                        aria-label='group notifications info'
                        iconType='iInCircle'
                        size='s'
                        color='text'
                        onClick={() => setGroupInfoPopover(!groupInfoPopover)}
                        disabled={selectedGroupNoti ? false : true}
                      />
                    }
                    isOpen={groupInfoPopover}
                    closePopover={() => setGroupInfoPopover(false)}
                    panelPaddingSize='none'
                    anchorPosition='leftCenter'
                  >
                    <EuiPanel style={{ width: '14rem', padding: '18px' }}>
                      <EuiText size='s' style={{ fontWeight: 500 }}>
                        <EuiIcon type='users'/> Selected Group:
                      </EuiText>
                      <EuiText>{selectedGroupNoti}</EuiText>
                      <EuiSpacer size='xs' className='notifications-spacer' />
                      <EuiText size='s' style={{ fontWeight: 500 }}>
                        <EuiIcon type='package'/> Managed Projects:
                      </EuiText>
                      <EuiFlexGroup
                        direction='column'
                        style={{
                          paddingTop: '.5rem',
                          height: '17rem'
                        }}
                        gutterSize='none'
                      >
                        {groupOptions[String(selectedGroupNoti)]?.length &&
                          groupOptions[String(selectedGroupNoti)].map(g => (
                            <EuiFlexItem grow={false} key={`group-noti-info-${g.project}`}>
                              <EuiText size='s'>{g.project}</EuiText>
                            </EuiFlexItem>
                          ))
                        }
                        <EuiFlexItem grow={false} style={{ marginTop: 'auto' }}>
                          <EuiText size='xs'>
                            Applying this group&apos;s settings will update the listed projects&apos; notification settings.
                          </EuiText>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiPanel>
                  </EuiPopover>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem
              grow={false}
              style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}
            >
              {renderGroupNotiButton()}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        {projectSettings.map((project, idx) => (
          <EuiFlexItem key={`notifications-projects-${idx}`}>
            {settingsState[String(project)] && (
              <ProjectSettingsItem
                ref={content}
                id={notificationSettings[String(project)].id}
                name={project}
                scrollIntoView={scrollIntoView}
              />
            )}
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </>
  )
}
