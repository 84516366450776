import React, { useContext, useState } from 'react'
import { Calendar, DateObject } from 'react-multi-date-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'
import { NotificationsContext } from './notifications_page'
import {
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSwitch,
  EuiIcon,
  EuiTitle,
  EuiSpacer,
  EuiToolTip,
} from '@elastic/eui'
import holidays from '../../lib/holidays.json'

export const DisabledDaysSettings = () => {
  const [tooltip, setTooltip] = useState('')
  const {
    settingsStateGlobal,
    updateSettingsGlobal
  } = useContext(NotificationsContext)

  if (!settingsStateGlobal) return null

  const datesIn = (dates) => {
    if (!dates?.length) return
    return dates.map((d) => new DateObject(d))
  }
  const datesOut = (dates) => {
    if (!dates?.length) return
    return dates.map((d) => d.format('YYYY-MM-DD'))
  }

  const renderTooltip = (date) => {
    return (
      <EuiToolTip
        position='bottom'
        content={tooltip}
        className='calendar-tooltip'
      >
        <EuiText size='s'>{date.format('D')}</EuiText>
      </EuiToolTip>
    )
  }

  const disableDate = (date) => {
    const isWeekend = [0, 6].includes(date.weekDay.index)
    const formattedDate = date.format('YYYY-MM-DD')
    const holiday = holidays[String(formattedDate)]
    if (holiday || isWeekend)
      return {
        disabled: true,
        style: { color: '#ccc' },
        onMouseOver: () => {
          setTooltip(holiday ? holiday : '')
        },
        onMouseLeave: () => {
          setTooltip('')
        },
        children: renderTooltip(date),
      }
  }

  const holidate = (date) => {
    const formattedDate = date.format('YYYY-MM-DD')
    const holiday = holidays[String(formattedDate)]
    if (holiday) {
      return {
        style: { color: '#166bb5' },
        onMouseOver: () => {
          setTooltip(holiday ? holiday : '')
        },
        onMouseLeave: () => {
          setTooltip('')
        },
        children: renderTooltip(date),
      }
    }
  }

  return (
    <div className='notifications-container'>
      <EuiFlexGroup gutterSize='s' responsive={false} alignItems='center'>
        <EuiFlexItem grow={false}>
          <EuiIcon type='calendar' size='l' />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle
            size='s'
            style={{
              fontSize: '16px',
              letterSpacing: '1.5px',
              fontWeight: '600',
              marginLeft: '7px',
            }}
          >
            <p>WEEKENDS & HOLIDAYS</p>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='xs' className='notifications-spacer-project' />

      <EuiFlexGroup
        gutterSize='s'
        responsive={false}
        alignItems='center'
        justifyContent='spaceBetween'
        style={{ marginTop: '.5rem' }}
      >
        <EuiFlexItem>
          <EuiText size='m'>
            <p>Disable Weekend & Holidays</p>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiSwitch
            checked={settingsStateGlobal['weekends']}
            onChange={() =>
              updateSettingsGlobal({
                setting: 'weekends',
                newValue: !settingsStateGlobal['weekends'],
              })
            }
            label=''
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup
        gutterSize='s'
        responsive={false}
        alignItems='flexStart'
        justifyContent='center'
        style={{ marginTop: '.5rem' }}
        direction='column'
      >
        <EuiFlexGroup
          gutterSize='s'
          responsive={false}
          alignItems='center'
          style={{ margin: '.25rem 0 .25rem 0' }}
        >
          <EuiFlexItem grow={false}>
            <EuiIcon type='bellSlash' size='m' />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText size='s'>
              <p>Disabled Days</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFlexItem
          style={{ margin: '.5rem auto auto auto', minHeight: '19rem' }}
        >
          <Calendar
            containerStyle={{ position: 'relative' }}
            multiple
            value={datesIn(settingsStateGlobal['disabledDays'])}
            format='M/D/YY'
            className='custom-calendar'
            onChange={(selected) =>
              updateSettingsGlobal({
                setting: 'disabledDays',
                newValue: selected.length ? datesOut(selected) : [],
              })
            }
            plugins={[
              <DatePanel key={null} position='right' sort='date' datatype='string' />,
            ]}
            mapDays={
              settingsStateGlobal['weekends']
                ? ({ date }) => disableDate(date)
                : ({ date }) => holidate(date)
            }
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}
