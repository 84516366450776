import React from 'react'

import {
  EuiIcon,
  EuiFlexItem,
  EuiFlexGroup,
  EuiProgress,
  EuiText,
  EuiAvatar,
  EuiSpacer,
} from '@elastic/eui'

import { RouterCard } from '..'
import { HealthIndicator, healthBarColor } from '../stats/health_indicator'
import abbreviateNumber from 'number-abbreviate'

const HealthIndicators = ({ components = [] }) =>
  components.map(({ status }, key) => <HealthIndicator {...status} key={key} />)

export const ProjectCard = ({
  project,
  notificationCount = 0,
  messageCount = 0,
  errorCount = 0,
}) => {
  const { name, owner, components, status, description } = project

  const MessagesStat = () => (
    <EuiFlexGroup direction='column' gutterSize='xs' alignItems='center'>
      <EuiFlexItem grow={false}>
        <EuiIcon type='email' />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {abbreviateNumber(messageCount, 2)}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
  
  const ErrorsStat = () => (
    <EuiFlexGroup direction='column' gutterSize='xs' alignItems='center'>
      <EuiFlexItem grow={false}>
        <EuiIcon type='alert' />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>{abbreviateNumber(errorCount, 2)}</EuiFlexItem>
    </EuiFlexGroup>
  )

  const NotificationsStat = () => (
    <EuiFlexGroup direction='column' gutterSize='xs' alignItems='center'>
      <EuiFlexItem grow={false}>
        <EuiIcon type='bell' />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {abbreviateNumber(notificationCount, 2)}
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  const StatGroup = () => (
    <EuiFlexGroup gutterSize='m'>
      <EuiFlexItem grow={false}>
        <MessagesStat />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <ErrorsStat />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <NotificationsStat />
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  const HealthMeter = () => (
    <EuiFlexGroup direction='column' justifyContent='flexEnd' gutterSize='s'>
      <EuiFlexItem>
        <EuiText size='xs' style={{ textAlign: 'right' }}>
          {status.healthMeter}%
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiProgress value={status.healthMeter} max={100} size='xs' color={healthBarColor(status.health)}/>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
  
  const ProjectOwnerText = () => (
    <EuiFlexGroup
      direction='column'
      justifyContent='flexStart'
      gutterSize='none'
    >
      <EuiFlexItem>
        <EuiText size='xs'>Project Owner</EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText size='s'>{owner ? owner.name : 'John Doe'}</EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  const Footer = () => (
    <EuiFlexGroup justifyContent='spaceBetween'>
      <EuiFlexItem>{owner && <ProjectOwnerText />}</EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup justifyContent='flexEnd' alignItems='flexEnd'>
          <EuiFlexItem>
            <EuiSpacer />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {owner && <EuiAvatar name={owner.name} />}
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  return (
    <RouterCard
      to={`/projects/${name}`}
      // icon={<EuiIcon size="xxl" type={`logo${icons[index]}`} />}
      icon={<EuiIcon size='xxl' type={'package'} />}
      title={name}
      layout='horizontal'
      description={description || ' '}
    >
      <EuiFlexGroup direction='column'>
        <EuiFlexItem>
          <EuiFlexGroup gutterSize='none'>
            <EuiFlexItem>
              <StatGroup />
            </EuiFlexItem>
            {/* <EuiFlexItem
              style={{
                width: 130,
                height: 70,
                marginTop: -40,
                marginRight: -20,
              }}
              grow={false}
            >
              <ReLineGraph
                showDots={false}
                animate={false}
                lineWidth={2}
                color={"rgb(162, 162, 169)"}
              />
            </EuiFlexItem> */}
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup justifyContent='flexEnd'>
            <HealthIndicators name={name} components={components} />
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <HealthMeter />
        </EuiFlexItem>
        <EuiFlexItem>
          <Footer />
        </EuiFlexItem>
      </EuiFlexGroup>
    </RouterCard>
  )
}
