import React, { useState, Fragment } from "react";
import { EuiSuperDatePicker, EuiSpacer } from "@elastic/eui";
import { dateTimeFormatWithZone } from "../../lib/date";

export default function DateRangeSelector({ date, updateDate, refresh }) {
  const [recentlyUsedRanges, setRecentlyUsedRanges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [refreshInterval, setRefreshInterval] = useState();

  const onTimeChange = ({ start, end }) => {
    const recentlyUsedRange = recentlyUsedRanges.filter((recentlyUsedRange) => {
      const isDuplicate =
        recentlyUsedRange.start === start && recentlyUsedRange.end === end;
      return !isDuplicate;
    });
    recentlyUsedRange.unshift({ start, end });
    updateDate([`${start}`, `${end}`]);
    setRecentlyUsedRanges(
      recentlyUsedRange.length > 10
        ? recentlyUsedRange.slice(0, 9)
        : recentlyUsedRange
    );
    setIsLoading(true);
    startLoading();
  };

  const onRefresh = () => {
    return new Promise((resolve) => {
      setTimeout(resolve, 100);
    }).then(() => {
      refresh();
    });
  };

  const startLoading = () => {
    setTimeout(stopLoading, 1000);
  };
  const stopLoading = () => {
    setIsLoading(false);
  };

  const onRefreshChange = ({ isPaused, refreshInterval }) => {
    setIsPaused(isPaused);
    setRefreshInterval(refreshInterval);
  };

  return (
    <Fragment>
      <EuiSpacer />
      <EuiSuperDatePicker
        isLoading={isLoading}
        start={date[0]}
        end={date[1]}
        onTimeChange={onTimeChange}
        onRefresh={onRefresh}
        isPaused={isPaused}
        refreshInterval={refreshInterval}
        onRefreshChange={onRefreshChange}
        recentlyUsedRanges={recentlyUsedRanges}
        showUpdateButton={true}
        isAutoRefreshOnly={false}
        dateFormat={dateTimeFormatWithZone}
        commonlyUsedRanges={[
          { start: "now/d", end: "now", label: "Today" },
          { start: "now/w", end: "now", label: "Week to date" },
          { start: "now/M", end: "now", label: "Month to date" },
          { start: "now/y", end: "now", label: "Year to date" },
        ]}
      />
      <EuiSpacer />
    </Fragment>
  );
}
