import React, { useState, useEffect, useContext } from 'react'

import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiText,
  EuiSpacer,
  EuiTab,
  EuiTabs,
  EuiIcon,
  EuiPanel,
  EuiCodeBlock,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
} from '@elastic/eui'

import {
  EtorDataList,
  EtorTabResults,
  EtorTabErrorWarnings,
  EtorEventTabs,
  EtorContext,
  EtorTabTranscoding,
} from '../../components'
import { AppContext } from '../../app'

import { api, formatDateTime } from '../../lib'

import './etor_details_modal.css'

const TextWithIcon = ({ text, bold, icon }) => {
  return (
    <EuiFlexGroup
      gutterSize='s'
      alignItems='center'
      style={{ margin: '4px 0' }}
    >
      <EuiFlexItem grow={false}>
        {icon}
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiText style={{  color: '#006BB4', fontWeight: bold ? 500 : 400 }} >
          {text}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

const findEventType = (message) => message.ProjectData.ETOR.EventType

const findEtorIds = (eventType, message) => {
  try {
    const etorIds = new Set()
    const messageEtorId = message.ProjectData.ETOR.ETORId
    etorIds.add(messageEtorId)

    if (eventType === 'Result') {
      const results = message.ProjectData.ETOR.Tests
      for (const result in results) {
        const orderEtorId = results[String(result)].OrderEtorId
        if (orderEtorId?.length) {
          etorIds.add(orderEtorId)
        }
      }
    }
    return [ ...etorIds ]
  }
  catch (e) {
    console.log(e)
    return null
  }
}

export const EtorDetailsModal = ({ close }) => {
  const { toaster } = useContext(AppContext)

  const { activeMessage } = useContext(EtorContext)

  const [messages, setMessages] = useState([])
  const [activeTab, setActiveTab] = useState('results-list')
  const [loading, setLoading] = useState(false)

  const fetchAggregation = async () => {
    setLoading(true)
    const eventType = findEventType(activeMessage)
    const aggregationIds = await findEtorIds(eventType, activeMessage)
    if (!aggregationIds?.length) {
      toaster({
        color: 'danger',
        title: `Oops! Something went wrong`,
      })
      return
    }
    try {
      const resp = await api.getEtorAggregation({
        eventType,
        aggregationIds,
      })
      setMessages(resp.data)
    }
    catch (err) {
      toaster({
        color: 'danger',
        title: `Oops!`,
        text: err,
      })
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAggregation()
  }, [])

  useEffect(() => {
    const eventType = activeMessage?.ProjectData?.ETOR?.EventType 
    if (eventType === 'Order' && activeTab === 'results-list') {
      setActiveTab('errors-warnings-list')
    }
  }, [activeMessage])

  const tabs = [
    {
      id: 'results-list',
      name: 'Results',
      disabled: activeMessage?.ProjectData?.ETOR?.EventType !== 'Result',
      onClick: () => setActiveTab('results-list'),
    },
    {
      id: 'errors-warnings-list',
      name: 'Errors and Warnings',
      disabled: false,
      onClick: () => setActiveTab('errors-warnings-list'),
    },
    {
      id: 'transcoding-list',
      name: 'Transcoding',
      disabled: false,
      onClick: () => setActiveTab('transcoding-list'),
    },
    {
      id: 'metadata-list',
      name: 'Metadata',
      disabled: false,
      onClick: () => setActiveTab('metadata-list')
    },
    {
      id: 'raw-message',
      name: 'Raw',
      disabled: false,
      onClick: () => setActiveTab('raw-message'),
    },
  ]

  const renderTabs = () => {
    return tabs.map((tab, idx) => {
      if (tab.disabled) return
      return (
        <EuiTab
          onClick={tab.onClick}
          isSelected={tab.id === activeTab}
          disabled={tab.disabled}
          id={idx}
          key={idx}
        >
          {tab.name}
        </EuiTab>
      )
   })
  }

  const tabContentSwitch = () => {
    switch (activeTab) {
      case 'results-list':
        return (
          <EtorTabResults data={activeMessage} />
        )
      case 'errors-warnings-list':
        return (
          <EtorTabErrorWarnings data={activeMessage} />
        )
      case 'transcoding-list':
        return (
          <EtorTabTranscoding data={activeMessage} />
        )
      case 'metadata-list':
        return (
          <EtorDataList data={activeMessage} />
        )
      case 'raw-message':
        return (
          <EuiCodeBlock
            language='json'
            fontSize='m'
            paddingSize='m'
            lineNumbers
            style={{ maxHeight: '23rem' }}
          >
            {JSON.stringify(activeMessage, null, 2)}
          </EuiCodeBlock>
        )
    }
  }

  return (
    <>
      <EuiOverlayMask>
        <EuiModal
          style={{
            maxWidth: 1200,
            width: 1200,
            overflowX: 'hidden',
            height: '90vh',
          }}
          onClose={close}
          className='etor-modal'
        >
          <EuiModalHeader>
            <EuiFlexGroup
              gutterSize='none'
              alignItems='center'
              justifyContent='spaceBetween'
              display='flex'
            >
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='cross'
                  onClick={close}
                >
                  Close
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText textAlign='center' style={{ fontWeight: 500, fontSize: '1.1em' }}>
                  Details
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false} style={{ width: '5rem' }} />
            </EuiFlexGroup>
          </EuiModalHeader>

          <EuiModalBody>

            <EtorEventTabs
              items={messages}
              loading={loading}
            />

            <EuiSpacer />

            <EuiPanel style={{ padding: '0 4px', border: 'none', boxShadow: 'none' }}>
              <TextWithIcon
                text={`${activeMessage?.ProjectData?.ETOR?.EventType} Message Details`}
                icon={<EuiIcon type='iInCircle' />}
                bold
              />
            </EuiPanel>

            <EuiPanel style={{ padding: '0 4px', backgroundColor: '#F3F7FB' }}>
              <EuiFlexGroup gutterSize='s'>
                <EuiFlexItem grow={false}>
                  <TextWithIcon
                    icon={<EuiIcon type='document' style={{ height: '14px', width: '14px' }}/>}
                    text={activeMessage.MessageId}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <TextWithIcon
                    icon={<EuiIcon type='clock'/>}
                    text={formatDateTime(activeMessage.MessageDateTime)}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>

            <EuiSpacer />

            <EuiTabs>{renderTabs()}</EuiTabs>
            <EuiSpacer />
            {tabContentSwitch()}

          </EuiModalBody>
        </EuiModal>
      </EuiOverlayMask>
    </>
  )
}
