const statusMap = {
  Processed: 'processed',
  Processing: 'processing',
  Rejected: 'rejected',
  Error: 'errors',
  Routed: 'routed',
  Unknown: 'unknown',
  ExtractionError: 'ExtractionError',
  MappingError: 'MappingError',
  PackagingError: 'PackagingError',
  RoutingError: 'RoutingError',
  TransportError: 'TransportError',
  ValidatorError: 'ValidatorError',
}

const countSelected = ({ selectedStatuses, total, counts }) => {
  let count
  if (!selectedStatuses || !selectedStatuses.length) {
    count = total
  } else {
    count = selectedStatuses.reduce((agg, status) => {
      agg += counts[statusMap[String(status)] || status] || 0
      return agg
    }, 0)
  }
  return count
}

const compareHasCount = (a, b) => {
  if (a.count && !b.count) {
    return -1
  }
  if (!a.count && b.count) {
    return 1
  }
}

const compareCount = (a, b) => {
  if (a.count > b.count) {
    return 1
  }
  if (a.count < b.count) {
    return -1
  }
}

const compareName = (a, b) => {
  if (
    (a.displayName || a.name || '').toLowerCase() >
    (b.displayName || b.name || '').toLowerCase()
  ) {
    return 1
  }
  if (
    (a.displayName || a.name || '').toLowerCase() <
    (b.displayName || b.name || '').toLowerCase()
  ) {
    return -1
  }
}

const compareCurrent = (a, b) => {
  if (a.isCurrent && !b.isCurrent) {
    return -1
  }
  if (!a.isCurrent && b.isCurrent) {
    return 1
  }
}

const compareEntities =
  ({ field, direction }) =>
  (a, b) => {
    const thang = (a, b) => {
      const coeff = direction === 'asc' ? 1 : -1
      if (field === 'count') {
        return (
          compareCurrent(a, b) ||
          compareHasCount(a, b) ||
          coeff * compareCount(a, b) ||
          coeff * compareName(a, b) ||
          0
        )
      }
      return (
        compareCurrent(a, b) ||
        compareHasCount(a, b) ||
        coeff * compareName(a, b) ||
        coeff * compareCount(a, b) ||
        0
      )
    }
    const result = thang(a, b)
    return result
  }

module.exports = {
  statusMap,
  countSelected,
  compareHasCount,
  compareName,
  compareCurrent,
  compareEntities,
}
