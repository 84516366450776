const body = `Hello new AIMS Dash user!
Please fill out the following information to request access to appropriate data. Once you send this email, you will receive an email from the AIMS Platform Support Service Desk confirming your request. When your access is provisioned, you will receive an email from the AIMS Platform Support Service Desk indicating that the ticket has been closed. You may receive additional correspondence from AIMS support between those two emails if there is a question about your request.
————
1. I would like access to the following Projects
(You can cut the projects you should have access to from the list of options below and and paste them here):
>>>

2. The following Senders apply to me:
>>>

[Some questions to help you identify the senders you should have access to:

What is your sender name for InterPartner?

Do you work for a state? If so, what is your state code (ex: HI, AL, MO)? Do you work at the PHA or PHL for that state?

If you work at a private lab, what is the name of that lab?

If you don’t know any of this information, we will use your email to look up your related entities; we may ask you to verify your permissions.]

3. The following Recipients apply to me:
>>>

[Some questions to help you identify the recipients you should have access to:

Do you currently receive any data using InterPartner?

Do you work for the state? If so, what is your state code (ex: HI, AL, MO)? Do you work at the PHA or PHL for that state?

Do you work for the CDC?

If you don’t know any of this information, we will use your email to look up your related entities; we may ask you to verify your permissions.]

Possible Projects to choose from:
AMD
CDCStatistics
CentralizedELR
CELR
eCR
EIP
ELIMS
Flu
InterPartner
InterState
LRI
LOI
LWP
MVPS
PHLIP_231
PHLIP_251
Quest
Rabies

Try your best to fill out this email questionnaire, and if we need to clarify anything, we will reach out via the support desk email. If you have additional questions, please type them below this text snippet:
>>>`;

export default encodeURIComponent(body);
