import React from 'react'

import {
  EuiConfirmModal,
  EuiOverlayMask,
  EuiIcon,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

export const ConfirmUnsaved = ({ onCancel, onConfirm }) => {
  return (
    <EuiOverlayMask>
      <EuiConfirmModal
        title='Discard Changes?'
        onCancel={onCancel}
        onConfirm={onConfirm}
        cancelButtonText={
          <EuiFlexGroup gutterSize='s' style={{ alignItems: 'center' }}>
            <EuiFlexItem>
              <EuiIcon type='editorRedo' />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText>
                Keep Editing
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
        confirmButtonText={
          <EuiFlexGroup gutterSize='s' style={{ alignItems: 'center' }}>
            <EuiFlexItem>
              <EuiIcon type='trash' />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText>
                Discard
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        }
        buttonColor='danger'
        defaultFocusedButton='confirm'
      >
        <p>You will lose all unsaved changes made.</p>
      </EuiConfirmModal>
    </EuiOverlayMask>
  )
}
