import React, { useState, useContext, createContext } from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButtonIcon,
  EuiHorizontalRule,
  EuiIcon,
  EuiProgress,
} from '@elastic/eui'
import { AppContext } from '../../app'
import { SupportList } from './support_list'

import api from '../../lib/api'
import './support_tool.css'

export const SupportContext = createContext()

export const SupportTool = () => {
  const [loading, setLoading] = useState(false)
  const {
    supportToolExpanded,
    setSupportToolExpanded,
    setShowToolBar,
    showToolBar,
    supportTickets,
    toaster,
    fetchSupportTickets,
  } = useContext(AppContext)

  const deleteSupportTicket = async (id) => {
    try {
      const res = await api.deleteSupportTicket(id)
      setLoading(true)
      if (res.status !== 200) {
        toaster({
          color: 'danger',
          title: 'Unable to delete support ticket',
        })
        return
      }
      fetchSupportTickets()
      setLoading(false)
    } catch (err) {
      setLoading(false)
      toaster({
        color: 'danger',
        title: 'Unable to delete support ticket',
        text: err.message,
      })
    }
  }

  const supportToolButton = () => {
    return (
      <EuiButtonIcon
        color='text'
        onClick={() => setSupportToolExpanded(!supportToolExpanded)}
        iconType='help'
        aria-label='support'
        id='supportToolButton'
      />
    )
  }

  const renderTool = () => {
    if (!supportToolExpanded) {
      return (
        <EuiFlexGroup
          gutterSize='none'
          style={{
            padding: '4px',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {supportToolButton()}
          <EuiFlexItem
            grow={false}
            className='tool-bar-arrow'
            onClick={() => setShowToolBar(false)}
          >
            <EuiIcon type='arrowRight' color='text' size='s' />
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
    return (
      <>
        <EuiFlexGroup gutterSize='m' style={{ padding: '8px 4px' }}>
          <EuiFlexItem grow={false}>{supportToolButton()}</EuiFlexItem>
          <EuiFlexItem style={{ margin: 'auto 0' }}>
            <EuiText textAlign='left'>Support</EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              color='text'
              onClick={() => setSupportToolExpanded(!supportToolExpanded)}
              iconType='cross'
              aria-label='close'
              style={{ marginRight: '0', marginLeft: 'auto' }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>

        {loading ? (
          <EuiProgress
            size='xs'
            color='primary'
            style={{ marginBottom: '10px', marginTop: '10px' }}
          />
        ) : (
          <EuiHorizontalRule margin='xs' />
        )}

        <SupportList supportTickets={supportTickets} />
      </>
    )
  }

  if (!showToolBar) {
    return (
      <div className='ToolBar-hidden'>
        <EuiFlexGroup
          gutterSize='none'
          className='tool-bar-arrow-hidden'
          onClick={() => setShowToolBar(true)}
        >
          <EuiFlexItem grow={false}>
            <EuiIcon
              type='arrowLeft'
              color='text'
              size='s'
              style={{ margin: 'auto' }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    )
  }

  const value = {
    deleteSupportTicket,
  }

  return (
    <SupportContext.Provider value={value}>
      {renderTool()}
    </SupportContext.Provider>
  )
}
