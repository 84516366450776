import React, {
  useContext,
  useReducer,
  useEffect
} from 'react'
import moment from 'moment'

import {
  EuiButtonIcon,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiFieldText,
  EuiBasicTable,
  EuiPanel,
  EuiIcon,
  EuiBadge,
  EuiSwitch,
  EuiLink,
} from '@elastic/eui'

import {
  EntityBadge,
  entityDisplay,
  getSubsenders,
  parseCollection
} from './utils'
import { EntityForm } from './settings_entity_form'

import { AppContext } from '../../app'
import { SettingPageContext } from './settings_page'
import { OfflineStatus } from '../modals/offline_status'
import api from '../../lib/api'
import { formatDateTimeWithZone } from '../../lib/date'

const defaultEntityForm = {
  'project': '',
  'usecase': '',
  'sender': '',
  'recipient': ''
}

const initialState = (payload) => {
  const tableData = {}
  tableData[payload.entityType] = payload.data
  return {
    searchMap: { ...defaultEntityForm },
    addNameMap: { ...defaultEntityForm },
    errorMap: {},
    showModifySet: new Set(),
    modifyForm: {},
    modifyMap: {},
    tableData,
    subsenders: [],
    modal: null,
  }
}

const reducer = (state, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'set_search': {
      newState.searchMap[action.entityType] = action.value
      return newState
    }
    case 'set_name': {
      newState.addNameMap[action.entityType] = action.value
      return newState
    }
    case 'set_error': {
      newState.errorMap[action.id] = action.error
      return newState
    }
    case 'reset_error': {
      newState.errorMap = {}
      return newState
    }
    case 'onchange_modify': {
      newState.modifyForm[action.id] = action.value
      return newState
    }
    case 'onchange_modify_subsender': {
      const tmp = { ...newState.modifyForm[action.id] }
      if (action.name !== undefined) {
        tmp.name = action.name
      }
      if (action.displayName !== undefined) {
        tmp.displayName = action.displayName
      }
      newState.modifyForm[action.id] = tmp
      return newState
    }
    case 'show_modify': {
      newState.modifyForm[action.id] = action.initValue
      newState.showModifySet.add(action.id)
      return newState
    }
    case 'show_modify_subsender': {
      newState.modifyForm[action.id] = {
        name: action.initValue,
        parent: action.parent,
        displayName: action.displayName
      }
      newState.showModifySet.add(action.id)
      return newState
    }
    case 'rm_show_modify': {
      newState.showModifySet.delete(action.id)
      return newState
    }
    case 'confirm_modify': {
      newState.modifyMap[action.id] = action.value
      newState.showModifySet.delete(action.id)
      return newState
    }
    case 'rm_modify': {
      delete newState.modifyMap[action.id]
      return newState
    }
    case 'set_table_data': {
      newState.tableData[action.entityType] = action.data
      return newState
    }
    case 'set_subsenders': {
      newState.subsenders = action.data
      return newState
    }
    case 'set_modal': {
      newState.modal = action.modal
      return newState
    }
  }
  throw Error('Invalid action')
}

export const SettingsEntity = ({ entityType, data }) => {
  const {
    loadingProjects,
    loadingSenders,
    loadingRecipients,
    loadingUseCases,
    offlineStatus,
    toaster,
    fetchOffline
  } = useContext(AppContext)
  const { onboardState, onboardDispatch } = useContext(SettingPageContext)

  const addNameFieldId = `${entityType}-add-name`

  const [state, dispatch] = useReducer(reducer, { entityType, data }, initialState)
  useEffect(() => {
    dispatch({ type: 'set_table_data', entityType, data })
    if (entityType === 'sender') {
      dispatch({ type: 'set_subsenders', data: getSubsenders(data) })
    }
  }, [data])

  const findDupe = (value) => {
    const dupe = data.find(({ name }) => name === value)
    if (dupe) {
      return true
    }
    const pendingDupe = onboardState.addEntities[String(entityType)]
      .find(( { name }) => name === value)
    if (pendingDupe) {
      return true
    }
    return false
  }

  const findDupeSubsender = (fullName) => {
    const dupe = state.subsenders
      .find(s => s.name === fullName)
    if (dupe) {
      return true
    }
    const pendingDupe = onboardState.addEntities.sender
      .find(s =>s.name === fullName)
    if (pendingDupe) {
      return true
    }
    return false
  }

  const findDupeSubsenderDisplay = (subsenderDisplay) => {
    const dupe = state.subsenders
      .find(s =>  s.displayName === subsenderDisplay)
    if (dupe) {
      return true
    }
    const pendingDupe = onboardState.addEntities.sender
      .find(s => s.displayName === subsenderDisplay)
    if (pendingDupe) {
      return true
    }
    return false
  }

  const handleAdd = (value) => {
    dispatch({ type: 'reset_error' })
    if (!value) return

    const dupe = findDupe(value)
    if (dupe) {
      dispatch({
        type: 'set_error',
        id: addNameFieldId,
        error: [`${entityDisplay[String(entityType)].display}: duplicate found`]
      })
      return
    }

    onboardDispatch({
      type: 'add_entity',
      entityType,
      entity: { name: state.addNameMap[String(entityType)] }
    })
    dispatch({
      type: 'set_name',
      entityType,
      value: ''
    })

  }

  const handleAddSubsender = (subsenderName, subsenderDisplay, selectedParent) => {
    dispatch({ type: 'reset_error' })
    const nameField = `${addNameFieldId}-subsender-name`
    const displayField = `${addNameFieldId}-subsender-display`
    const parentField = `${addNameFieldId}-subsender-parent`

    if (!selectedParent) {
      dispatch({
        type: 'set_error',
        id: parentField,
        error: [`${entityDisplay.subsender.display}: missing subsender parent`]
      })
      return
    }

    if (!subsenderName) {
      dispatch({
        type: 'set_error',
        id: nameField,
        error: [`${entityDisplay.subsender.display}: missing subsender name`]
      })
      return
    }
    
    if (!subsenderDisplay) {
      dispatch({
        type: 'set_error',
        id: displayField,
        error: [`${entityDisplay.subsender.display}: missing subsender display name`]
      })
      return
    }

    const fullName = `${selectedParent.value}_${subsenderName}`
    const dupe = findDupeSubsender(fullName)
    if (dupe) {
      dispatch({
        type: 'set_error',
        id: nameField,
        error: [`${entityDisplay.subsender.display}: duplicate name found`]
      })
      return
    }
    const dupeDisplay = findDupeSubsenderDisplay(subsenderDisplay)
    if (dupeDisplay) {
      dispatch({
        type: 'set_error',
        id: displayField,
        error: [`${entityDisplay.subsender.display}: duplicate display name found`]
      })
      return
    }

    onboardDispatch({
      type: 'add_entity',
      entityType,
      entity: {
        name: fullName,
        displayName: subsenderDisplay,
        parent: selectedParent.value
      }
    })
  }

  const handleAddCollection = (subsenderCol, selectedParent, setSubsenderCol) => {
    const collectionField = `${addNameFieldId}-subsender-col`
    dispatch({ type: 'reset_error' })

    if (selectedParent.length !== 1) {
      dispatch({
        type: 'set_error',
        id: collectionField,
        error: [`${entityDisplay.subsender.display}: missing subsender parent`]
      })
      return
    }

    if (!subsenderCol) {
      dispatch({
        type: 'set_error',
        id: collectionField,
        error: [`${entityDisplay[String(entityType)].display}: missing field`]
      })
      return
    }

    const { subsenders, err } = parseCollection(subsenderCol, selectedParent[0].value)
  
    if (err) {
      dispatch({ type: 'set_error', id: collectionField, error: err })
      return
    }

    if (!subsenders?.length) {
      dispatch({
        type: 'set_error',
        id: collectionField,
        error: [`${entityDisplay[String(entityType)].display}: no subsenders found from text`]
      })
      return
    }

    for (const { name, displayName } of subsenders) {
      const dupe = findDupeSubsender(name)
      if (dupe) { 
        dispatch({
          type: 'set_error',
          id: collectionField,
          error: [`${entityDisplay[String(entityType)].display}: duplicate sender found - ${name}`]
        })
        return
      }
      const dupeDisplay = findDupeSubsenderDisplay(displayName)
      if (dupeDisplay) {
        dispatch({
          type: 'set_error',
          id: collectionField,
          error: [`${entityDisplay[String(entityType)].display}: duplicate display name found - ${displayName}`]
        })
        return
      }
    }

    onboardDispatch({ type: 'add_entity_multi', entityType, entities: subsenders })
    setSubsenderCol('')
  }

  const handleModify = (name, newName) => {
    dispatch({ type: 'reset_error' })
    const modifyFieldId = `${entityType}-${name}-modify`

    if (!newName) return
    if (newName === name) {
      dispatch({ type: 'rm_show_modify', id: `${entityType}-${name}` })
      return
    }

    const dupe = findDupe(newName)
    if (dupe) {
      dispatch({
        type: 'set_error',
        id: modifyFieldId,
        error: [`${entityDisplay[String(entityType)].display}: duplciate found`]
      })
      return
    }

    onboardDispatch({
      type: 'modify',
      entityType,
      entity: { name },
      newEntity: { name: newName }
    })
    dispatch({ type: 'rm_show_modify', id: `${entityType}-${name}` })
  }

  const handleModifySubsender = (subsender, newSubsender) => {
    dispatch({ type: 'reset_error' })
    const subsenderNameId = `${entityType}-${subsender.name}-modify-subsenderName`
    const subsenderDisplayNameId = `${entityType}-${subsender.name}-modify-displayName`

    if (!newSubsender.name) {
      dispatch({
        type: 'set_error',
        id: subsenderNameId,
        error: [`Missing value`]
      })
      return
    }
    if (!newSubsender.displayName) {
      dispatch({
        type: 'set_error',
        id: subsenderDisplayNameId,
        error: [`Missing value`]
      })
      return
    }
    
    const fullName = `${newSubsender.parent}_${newSubsender.name}`
    if (fullName === subsender.name && newSubsender.displayName === subsender.displayName) {
      dispatch({ type: 'rm_show_modify', id: `${entityType}-${subsender.name}` })
      return
    }
  
    if (fullName === subsender.name && newSubsender.displayName !== subsender.displayName) {
      const dupeDisplay = findDupeSubsenderDisplay(newSubsender.displayName)
      if (dupeDisplay) {
        dispatch({
          type: 'set_error',
          id: subsenderDisplayNameId,
          error: [`${entityDisplay.subsender.display}: duplicate name found`]
        })
        return
      }
    } else {
      const dupe = findDupeSubsender(fullName)
      if (dupe) {
        dispatch({
          type: 'set_error',
          id: subsenderNameId,
          error: [`${entityDisplay.subsender.display}: duplicate name found`]
        })
        return
      }
    }
    onboardDispatch({
      type: 'modify',
      entityType,
      entity: {...subsender},
      newEntity: {
        name: fullName,
        displayName: newSubsender.displayName,
        parent: newSubsender.parent
      }
    })
    dispatch({ type: 'rm_show_modify', id: `${entityType}-${subsender.name}` })
  }

  const handleDelete = (entity) => {
    dispatch({ type: 'rm_show_modify', id: `${entityType}-${entity.name}` })
    onboardDispatch({ type: 'modify_del_entity', entity, entityType })
  }

  const onChangeSearch = (value) => {
    dispatch({ type: 'set_search', entityType, value })
    if (!value) {
      dispatch({ type: 'set_table_data', entityType, data })
      return
    }
    const filtered = data.filter(entity => {
      const name = entity.name.replaceAll(' ', '').toLowerCase()
      const searchValue = value.replaceAll(' ', '').toLowerCase()
      
      if (entity.displayName) {
        const displayName = entity.displayName.replaceAll(' ', '').toLowerCase()
        if (displayName.indexOf(searchValue) > -1) {
          return true
        }
        if (name.indexOf(searchValue) > -1) {
          return true
        }
        return false
      }
      return name.indexOf(searchValue) > -1
    })
    dispatch({ type: 'set_table_data', entityType, data: filtered })
  }

  const getOfflineStatus = (entityType, entityName) => {
    if (!offlineStatus[String(entityType)]) {
      return null
    }
    return offlineStatus[String(entityType)][String(entityName)]
  }

  const showOfflineModal = (entityType, entityName, initPicker='') => {
    const offline = getOfflineStatus(entityType, entityName)
    dispatch({
      type: 'set_modal',
      modal: (
        <OfflineStatus
          data={offline}
          onClose={() => dispatch({ type: 'set_modal', modal: null })}
          title={
            <>
              <span style={{ textTransform: 'capitalize' }}>{entityType}</span>: {entityName}
            </>
          }
          initPicker={initPicker}
          entityType={entityType}
          entityName={entityName}
        />
      )
    })
  }

  const updateOfflineStatus = async (entityType, entityName) => {
    const now = moment()
    const start = now.unix()
    const end = now.add(1, 'day').unix()
    const data = { start, end }
    try {
      await api.updateOffline(entityType, entityName, data)
      await fetchOffline()
    }
    catch (e) {
      console.log('error updating offline status', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem saving changes.',
      })
    }
  }

  const deleteOfflineStatus = async (entityType, entityName) => {
    try {
      await api.deleteOffline(entityType, entityName)
      await fetchOffline()
    }
    catch (e) {
      console.log('error deleting offline status', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem saving changes.',
      })
    }
  }

  const toggleOffline = async (entityType, entityName) => {
    const offline = getOfflineStatus(entityType, entityName)
    if (offline) {
      await deleteOfflineStatus(entityType, entityName)
      return
    }
    await updateOfflineStatus(entityType, entityName)
  }

  const showOffline = (entityType, entityName) => {
    const offline = getOfflineStatus(entityType, entityName)
    if (!offline) {
      return <></>
    }
    const { StartTime, EndTime } = offlineStatus[String(entityType)][String(entityName)]
    const fStart = formatDateTimeWithZone(moment.unix(StartTime))
    const fEnd = formatDateTimeWithZone(moment.unix(EndTime))
    return (
      <EuiFlexItem grow={false}>
        <EuiFlexGroup
          gutterSize='none'
          style={{
            border: '1px solid #D3DAE6',
            borderRadius: '2px',
            padding: '4px',
            backgroundColor: '#F4F6F8',
            margin: '0 .5rem'
          }}
        >
          <EuiFlexItem grow={false} style={{ marginRight: '1rem' }}>
            <EuiBadge color='hollow'>
              Offline
            </EuiBadge>
          </EuiFlexItem>
          <EuiFlexItem
            grow={false}
            style={{ justifyContent: 'center', minWidth: '11rem' }}
          >
            <EuiLink
              color='text'
              onClick={() => showOfflineModal(entityType, entityName, 'start')}
            >
              {fStart}
            </EuiLink>
          </EuiFlexItem>
          <EuiFlexItem
            grow={false}
            style={{ justifyContent: 'center', margin: '0 1rem 0 .5rem' }}
          >
            <EuiText style={{ fontSize: '.8em' }}>→</EuiText>
          </EuiFlexItem>
          <EuiFlexItem
            grow={false}
            style={{ justifyContent: 'center', minWidth: '11rem' }}
          >
            <EuiLink
              color='text'
              onClick={() => showOfflineModal(entityType, entityName, 'end')}
            >
              {fEnd}
            </EuiLink>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    )
  }

  const columns = [
    {
      field: 'name',
      name: 'Name',
      truncateText: true,
      sortable: true,
      render: (name, { parent, displayName }) => {
        const id = `${entityType}-${name}`
        if (state.showModifySet.has(id)) {
          return (
            <EuiFlexGroup
              gutterSize='none'
              style={{ padding: '4px 14px 4px 4px' }}
              alignItems='center'
            >
              {parent ?
              <>
                <EuiFlexItem style={{ marginRight: '.5rem', maxWidth: '20rem' }}>
                  <EuiFieldText
                    value={parent}
                    aria-label='entity parent'
                    disabled={true}
                    fullWidth
                  />
                </EuiFlexItem>
                <EuiFlexItem style={{ marginRight: '.5rem' }}>
                  <EuiFieldText
                    placeholder={name.split('_')[1]}
                    value={state.modifyForm[String(id)].name}
                    onChange={e => dispatch({
                      type: 'onchange_modify_subsender',
                      id,
                      name: e.target.value
                    })}
                    aria-label='entity add'
                    fullWidth
                    icon='pencil'
                    isInvalid={state.errorMap[`${entityType}-${name}-modify-subsenderName`]?.length ? true : false}
                    autoFocus
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFieldText
                    placeholder={displayName}
                    value={state.modifyForm[String(id)].displayName}
                    onChange={e => dispatch({
                      type: 'onchange_modify_subsender',
                      id,
                      displayName: e.target.value
                    })}
                    aria-label='entity add'
                    fullWidth
                    icon='pencil'
                    isInvalid={state.errorMap[`${entityType}-${name}-modify-displayName`]?.length ? true : false}
                  />
                </EuiFlexItem>
              </> :
              <EuiFlexItem>
                <EuiFieldText
                  placeholder={name}
                  value={state.modifyForm[String(id)] || ''}
                  onChange={e => dispatch({
                    type: 'onchange_modify',
                    id,
                    value: e.target.value
                  })}
                  aria-label='entity add'
                  fullWidth
                  icon='pencil'
                  isInvalid={state.errorMap[`${entityType}-${name}-modify`]?.length ? true : false}
                  autoFocus
                />
              </EuiFlexItem>
            }
              <EuiFlexItem grow={false} style={{ margin: '.2rem' }}>
                <EuiButtonIcon
                  iconType='check'
                  color='success'
                  aria-label='entity-modify-check'
                  onClick={() => parent
                    ? handleModifySubsender({ name, displayName }, state.modifyForm[String(id)])
                    : handleModify(name, state.modifyForm[String(id)])
                  }
                />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonIcon
                  iconType='trash'
                  color='danger'
                  aria-label='entity-modify-trash'
                  onClick={() => {
                    if (parent) {
                      handleDelete({ name, displayName, parent })
                    } else {
                      handleDelete({ name })
                    }
                  }}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          )
        }
        return (
          <EuiFlexGroup gutterSize='none' style={{ padding: '4px 14px', minHeight: '2.4rem' }}>
            <EuiFlexItem style={{ justifyContent: 'center' }}>
              <EuiText size='s' align='left'>{displayName || name}</EuiText>
            </EuiFlexItem>
            {entityType === 'sender' &&
              <>
                {showOffline(entityType, name)}
                <EuiFlexItem
                  grow={false}
                  style={{ justifyContent: 'center', margin: 0 }}
                >
                  <EuiSwitch
                    checked={!getOfflineStatus(entityType, name)}
                    onChange={() => toggleOffline(entityType, name)}
                    label=''
                    compressed
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false} style={{ justifyContent: 'center' }}>
                  <EuiButtonIcon
                    iconType='calendar'
                    style={{ color: 'black' }}
                    aria-label='entity-edit'
                    onClick={() => showOfflineModal(entityType, name)}
                  />
                </EuiFlexItem>
              </>
            }
            <EuiFlexItem grow={false} style={{ marginLeft: '4px', justifyContent: 'center' }}>
              {onboardState.modifyMap[String(id)] ?
                <>
                  <EuiBadge className='settings-modified-badge'>
                    <EuiText style={{ fontSize: '.85em', lineHeight: 1 }}>
                      MODIFIED
                    </EuiText>
                  </EuiBadge>
                  <EuiButtonIcon
                    iconType='crossInACircleFilled'
                    size='xs'
                    iconSize='s'
                    style={{ color: '#D3DAE6' }}
                    aria-label='entity-modify-cancel'
                    onClick={() => onboardDispatch({ type: 'rm_modify', id, name, entityType })}
                  />
                </> :
                <EuiButtonIcon
                  iconType='pencil'
                  style={{ color: 'black' }}
                  aria-label='entity-edit'
                  onClick={() => {
                    if (parent) {
                      dispatch({
                        type: 'show_modify_subsender',
                        id,
                        initValue: name.split('_')[1],
                        parent,
                        displayName: displayName || ''
                      })
                    } else {
                      dispatch({ type: 'show_modify', id, initValue: name })
                    }
                  }}
                />
              }
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      }
    },
  ]

  const EntityChanges = () => {
    const addEntities = onboardState.addEntities[String(entityType)]
    const delEntities = onboardState.delEntities[String(entityType)]
    if (!addEntities?.length && !delEntities?.length) {
      return <></>
    }
    return (
      <EuiPanel className='settings-changes-panel' hasShadow={false} style={{ backgroundColor: 'unset' }}>
        <EuiFlexGroup gutterSize='none' className='settings-changes-title'>
          <EuiFlexItem grow={false}>
            <EuiIcon type='folderOpen' />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText textAlign='left' style={{ fontSize: '.9em', fontWeight: 500, marginLeft: '.5rem' }}>
              {entityDisplay[String(entityType)].display} Changes
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
        <div className="settings-changes-items">
        <EuiFlexGroup gutterSize='s' className='settings-changes-content' wrap style={{ minHeight: '2rem' }}>
          {addEntities.map(({ name, displayName }, idx) => (
            <EuiFlexItem key={`settings-changes-entity-add-badge-${idx}`} grow={false}>
              <EntityBadge
                entity={{ name, displayName }}
                add
                handler={() => onboardDispatch({ type: 'rm_add_entity', name, entityType })}
              />
            </EuiFlexItem>
          ))}
          {delEntities.map(({ name, displayName }, idx) => (
            <EuiFlexItem key={`settings-changes-entity-del-badge-${idx}`} grow={false}>
              <EntityBadge
                entity={{ name, displayName }}
                handler={() => onboardDispatch({ type: 'rm_del_entity', name, entityType })}
              />
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
        </div>        
      </EuiPanel>
    )
  }

  const loadingMap = {
    'project': loadingProjects,
    'usecase': loadingUseCases,
    'sender': loadingSenders,
    'recipient': loadingRecipients
  }

  if (!state.tableData[String(entityType)]) return (<></>)

  return (
    <>
      <EuiSpacer size='l' />
      <EntityChanges />
      <EntityForm
        state={state}
        data={data}
        dispatch={dispatch}
        onboardDispatch={onboardDispatch}
        entityType={entityType}
        handleAdd={handleAdd}
        handleAddSubsender={handleAddSubsender}
        handleAddCollection={handleAddCollection}
        fieldId={addNameFieldId}
      />
      <EuiFlexGroup
        gutterSize='none'
        style={{
          flexDirection: 'column'
        }}>
        <EuiSpacer size='l' />
        <EuiFlexItem>
          <EuiText textAlign='left' style={{ fontSize: '1.4em', fontWeight: 400 }}>
            {entityDisplay[String(entityType)].title}
            <span
              style={{
                fontSize: '.6em',
                verticalAlign: 'text-top',
                marginLeft: '.25rem',
                paddingTop: '.25rem',
                fontWeight: 300
              }}
            >
              {state.tableData[String(entityType)].length ? state.tableData[String(entityType)].length : ''}
            </span>
          </EuiText>
        </EuiFlexItem>
        <EuiSpacer size='s' />
        <EuiFieldSearch
          placeholder='Search'
          value={state.searchMap[String(entityType)]}
          onChange={e => onChangeSearch(e.target.value)}
          aria-label='Entity Search'
          isClearable
          fullWidth
        />
      </EuiFlexGroup>
      <EuiSpacer size='m' />
      <EuiPanel paddingSize='none' style={{ maxHeight: '60vh', overflowY: 'scroll' }}>
        <EuiBasicTable
          className='settings-entity-table'
          items={state.tableData[String(entityType)]}
          itemId='name'
          columns={columns}
          loading={loadingMap[String(entityType)]}
        />
      </EuiPanel>
      {state.modal}
    </>
  )
}
