import React, { useEffect, useContext, createContext, useReducer } from 'react'

import {
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiTabbedContent,
  EuiButton,
} from '@elastic/eui'
import { SettingsEntity } from './settings_entity'
import { SettingsOnboard } from './settings_onboard'
import { getChangesCount, buildOnboardReq } from './utils'

import { api } from '../../lib'
import { AppContext } from '../../app'
import './settings.css'

export const SettingPageContext = createContext()

const defaultEntity = {
  project: [],
  usecase: [],
  recipient: [],
  sender: [],
}
const defaultPagination = {
  offset: 0,
  limit: 25,
  total: 0,
}

const initialState = () => {
  return {
    onboards: null,
    selectedTab: '',
    addEntities: { ...defaultEntity },
    delEntities: { ...defaultEntity },
    modifyMap: {},
    loading: false,
    description: '',
    pagination: { ...defaultPagination },
  }
}

const reducer = (state, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'set_one': {
      newState[action.state] = action.value
      return newState
    }
    case 'set_concat': {
      let newList = [...newState[action.state]]
      newList = newList.concat(action.value)
      newState[action.state] = newList
      return newState
    }
    case 'add_entity': {
      const addList = [...newState.addEntities[action.entityType]]
      addList.push(action.entity)
      newState.addEntities[action.entityType] = addList
      return newState
    }
    case 'add_entity_multi': {
      const addList = [...newState.addEntities[action.entityType]]
      for (let entity of action.entities) {
        addList.push(entity)
      }
      newState.addEntities[action.entityType] = addList
      return newState
    }
    case 'rm_add_entity': {
      const addList = [...newState.addEntities[action.entityType]]
      const idx = addList.findIndex(({ name }) => name === action.name)
      if (idx === -1) {
        return newState
      }
      const addEntity = addList.splice(idx, 1)[0]
      if (addEntity.modify) {
        const delList = [...newState.delEntities[action.entityType]]
        const delIdx = delList.find(({ name }) => name === addEntity.modify)
        if (delIdx !== -1) {
          delList.splice(delIdx, 1)
          newState.delEntities[action.entityType] = delList
        }
        delete newState.modifyMap[`${action.entityType}-${addEntity.modify}`]
      }
      newState.addEntities[action.entityType] = addList
      return newState
    }
    case 'modify_del_entity': {
      const delList = [...newState.delEntities[action.entityType]]
      const { entity, entityType } = action
      delList.push(entity)
      newState.delEntities[String(entityType)] = delList
      newState.modifyMap[`${entityType}-${entity.name}`] = entity.name
      return newState
    }
    case 'rm_del_entity': {
      const delList = [...newState.delEntities[action.entityType]]
      const delIdx = delList.findIndex(({ name }) => name === action.name)
      if (delIdx === -1) {
        return newState
      }
      const delEntity = delList.splice(delIdx, 1)[0]
      if (delEntity.modify) {
        const addList = [...newState.addEntities[action.entityType]]
        const addIdx = addList.findIndex(
          ({ name }) => name === delEntity.modify
        )
        if (addIdx !== -1) {
          addList.splice(addIdx, 1)
          newState.addEntities[action.entityType] = addList
        }
      }
      newState.delEntities[action.entityType] = delList
      delete newState.modifyMap[`${action.entityType}-${action.name}`]
      return newState
    }
    case 'modify': {
      const { entity, newEntity, entityType } = action
      const addList = [...newState.addEntities[String(entityType)]]
      const addEntity = {
        name: newEntity.name,
        modify: entity.name,
      }
      if (newEntity.parent) {
        addEntity.parent = newEntity.parent
      }
      if (newEntity.displayName) {
        addEntity.displayName = newEntity.displayName
      }
      addList.push(addEntity)
      newState.addEntities[String(entityType)] = addList
      const delList = [...newState.delEntities[String(entityType)]]
      const delEntity = {
        name: entity.name,
        modify: newEntity.name,
      }
      if (entity.parent) {
        delEntity.parent = entity.parent
      }
      if (entity.displayName) {
        delEntity.displayName = entity.displayName
      }
      delList.push(delEntity)
      newState.delEntities[String(entityType)] = delList
      newState.modifyMap[`${entityType}-${entity.name}`] = newEntity.name
      return newState
    }
    case 'rm_modify': {
      const addList = [...newState.addEntities[action.entityType]]
      const modifiedName = newState.modifyMap[action.id]
      if (!modifiedName) {
        return newState
      }
      const addIdx = addList.findIndex(({ name }) => name === modifiedName)
      if (addIdx !== -1) {
        addList.splice(addIdx, 1)
        newState.addEntities[action.entityType] = addList
      }
      const delList = [...newState.delEntities[action.entityType]]
      const delIdx = delList.findIndex(({ name }) => name === action.name)
      if (delIdx !== -1) {
        delList.splice(delIdx, 1)
        newState.delEntities[action.entityType] = delList
      }
      delete newState.modifyMap[action.id]
      return newState
    }
    case 'retry_onboard': {
      newState.addEntities = action.addEntities
      newState.delEntities = action.delEntities
      newState.modifyMap = {}
      if (action.description) {
        newState.description = `Retry: ${action.description}`
      }
      return newState
    }
    case 'reset_changes': {
      newState.addEntities = { ...defaultEntity }
      newState.delEntities = { ...defaultEntity }
      newState.modifyMap = {}
      newState.description = ''
      return newState
    }
  }
  throw Error('Invalid action')
}

export const SettingsPage = () => {
  const {
    navBarExpanded,
    exportToolExpanded,
    supportToolExpanded,
    projects,
    useCases,
    senders,
    recipients,
    toaster,
  } = useContext(AppContext)

  const [onboardState, onboardDispatch] = useReducer(
    reducer,
    null,
    initialState
  )

  const fetchOnboard = async (params, loadMore = false) => {
    try {
      const resp = await api.fetchOnboard(params)
      if (loadMore) {
        onboardDispatch({
          type: 'set_concat',
          state: 'onboards',
          value: resp.data.onboards,
        })
      } else {
        onboardDispatch({
          type: 'set_one',
          state: 'onboards',
          value: resp.data.onboards,
        })
      }
      onboardDispatch({
        type: 'set_one',
        state: 'pagination',
        value: resp.data.pagination,
      })
    } catch (e) {
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem loading onboards.',
      })
    }
  }

  const loadOnboards = async ({ search, reset, loadMore }) => {
    try {
      const { offset, limit } = reset
        ? defaultPagination
        : onboardState.pagination
      let params = {
        offset,
        limit,
      }
      if (search) {
        params.q = search
      }
      if (loadMore) {
        params.offset = params.offset + limit
        await fetchOnboard(params, loadMore)
      } else {
        await fetchOnboard(params)
      }
    } catch {
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem loading onboards.',
      })
    }
  }

  const saveOnboard = async () => {
    onboardDispatch({ type: 'set_one', state: 'loading', value: true })
    try {
      const payload = buildOnboardReq({
        addEntities: onboardState.addEntities,
        delEntities: onboardState.delEntities,
      })
      await api.createOnboard({
        ...payload,
        description: onboardState.description,
      })
      await fetchOnboard()
      onboardDispatch({ type: 'reset_changes' })
    } catch {
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem saving changes.',
      })
    } finally {
      onboardDispatch({ type: 'set_one', state: 'loading', value: false })
    }
  }

  useEffect(() => {
    if (!onboardState.onboards) {
      const { offset, limit } = defaultPagination
      fetchOnboard({ offset, limit })
    }
  }, [onboardState.onboards])

  const renderChangesCount = () => {
    const count = getChangesCount({
      addEntities: onboardState.addEntities,
      delEntities: onboardState.delEntities,
    })
    if (count) {
      return `(${count})`
    }
    return ''
  }

  const tabs = [
    {
      id: 'project',
      name: 'Projects',
      content: <SettingsEntity entityType='project' data={projects} />,
    },
    {
      id: 'usecase',
      name: 'Use Cases',
      content: <SettingsEntity entityType='usecase' data={useCases} />,
    },
    {
      id: 'sender',
      name: 'Senders',
      content: <SettingsEntity entityType='sender' data={senders} />,
    },
    {
      id: 'recipient',
      name: 'Recipients',
      content: <SettingsEntity entityType='recipient' data={recipients} />,
    },
    {
      id: 'onboarding',
      name: <span>Onboarding {renderChangesCount()}</span>,
      content: <SettingsOnboard data={onboardState.onboards} />,
    },
  ]

  const value = {
    onboardState,
    onboardDispatch,
    loadOnboards,
  }

  return (
    <SettingPageContext.Provider value={value}>
      <EuiPage
        className={`euiNavDrawerPage
          ${navBarExpanded ? 'navBarExpanded' : 'navBarCollapsed'}
          ${
            (exportToolExpanded || supportToolExpanded) &&
            'export-bar-expanded-pad'
          }`}
      >
        <EuiPageBody className='euiNavDrawerPage__pageBody'>
          <div className='page_wrapper'>
            <EuiPageHeader>
              <EuiPageHeaderSection>
                <EuiTitle size='m'>
                  <h1>Settings</h1>
                </EuiTitle>
              </EuiPageHeaderSection>
              {onboardState.selectedTab === 'onboarding' && (
                <EuiPageHeaderSection>
                  <EuiButton
                    aria-label='Save Entity'
                    iconType='save'
                    fill
                    color='secondary'
                    onClick={saveOnboard}
                    disabled={
                      getChangesCount({
                        addEntities: onboardState.addEntities,
                        delEntities: onboardState.delEntities,
                      })
                        ? false
                        : true
                    }
                    isLoading={onboardState.loading}
                  >
                    Save
                  </EuiButton>
                </EuiPageHeaderSection>
              )}
            </EuiPageHeader>
            <EuiTabbedContent
              className='settings-tabs'
              tabs={tabs}
              autoFocus='selected'
              onTabClick={(tab) =>
                onboardDispatch({
                  type: 'set_one',
                  state: 'selectedTab',
                  value: tab.id,
                })
              }
            />
          </div>
        </EuiPageBody>
      </EuiPage>
    </SettingPageContext.Provider>
  )
}
