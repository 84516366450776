import React, { useContext, useState, useEffect, useCallback } from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton,
  EuiFieldText,
  EuiToolTip,
} from '@elastic/eui'

import { AppContext } from '../../app'
import { ExportContext } from './export_tool'
import moment from 'moment'
import api from '../../lib/api'
import { getEsAbsoluteDatetime } from '../../lib'

export const ExportForm = () => {
  const {
    fetchExports,
    messageCounts,
    toaster,
    date,
    query,
    selectedProjects,
    selectedUseCases,
    extractSenders,
    extractSubsenders,
    selectedRecipients,
    selectedProjectData,
    selectedStatuses,
    selectedSenders,
    environment,
  } = useContext(AppContext)
  const { setShowForm } = useContext(ExportContext)
  const [filename, setFilename] = useState('')
  const [loading, setLoading] = useState(false)
  const [messageCount, setMessageCount] = useState(0)
  const [validExportQuery, setValidExportQuery] = useState(true)

  const getMessageCounts = useCallback(() => {
    if (!Object.keys(messageCounts.total)) {
      return '---'
    }
    let total = 0
    for (let v of Object.values(messageCounts.total)) {
      total += v
    }
    return total
  }, [messageCounts])

  useEffect(() => {
    if (!filename) {
      setFilename(moment().utc().format('YYYY-MM-DD-HHmmss'))
    }
  }, [filename])

  useEffect(() => {
    setMessageCount(getMessageCounts())
  }, [messageCounts, getMessageCounts])

  useEffect(() => {
    const senders = selectedSenders.length ? extractSenders() : []
    const subsenders = selectedSenders.length ? extractSubsenders() : []
    const exportQuery = {
      text: query,
      projects: selectedProjects,
      useCases: selectedUseCases,
      senders,
      subsenders,
      recipients: selectedRecipients,
      projectData: selectedProjectData,
      statuses: selectedStatuses,
      environment: environment,
      messageCount: messageCount,
    }

    try {
      const exportQueryStr = JSON.stringify(exportQuery)
      if (exportQueryStr.length > 60000) {
        setValidExportQuery(false)
        return
      }
      setValidExportQuery(true)
      return
    } catch (e) {
      console.log('Error validating export query ', e)
      setValidExportQuery(false)
    }
  }, [
    query,
    selectedProjects,
    selectedUseCases,
    selectedSenders,
    selectedRecipients,
    selectedProjectData,
    selectedStatuses,
    environment,
    messageCount,
  ])

  const submit = async () => {
    setLoading(true)
    if (!filename) return
    try {
      const senders = selectedSenders.length ? extractSenders() : []
      const subsenders = selectedSenders.length ? extractSubsenders() : []
      const start = getEsAbsoluteDatetime(date[0])
      const end = getEsAbsoluteDatetime(date[1])
      const res = await api.createExport({
        filename,
        start,
        end,
        text: query,
        projects: selectedProjects,
        useCases: selectedUseCases,
        senders,
        subsenders,
        recipients: selectedRecipients,
        projectData: selectedProjectData,
        statuses: selectedStatuses,
        environment: environment,
        messageCount: messageCount,
      })
      if (res.status !== 200) {
        toaster({
          color: 'danger',
          title: 'Unable to create export',
        })
        return
      }
      fetchExports()
      setFilename('')
      setShowForm(false)
    } catch (err) {
      console.log('Error creating export ', err)
      toaster({
        color: 'danger',
        title: 'Unable to create export',
        text: err.message,
      })
    } finally {
      setLoading(false)
    }
  }

  const formatDate = (date) => {
    try {
      const absolute = getEsAbsoluteDatetime(date)
      const formatted = moment(absolute).format('DD MMM YYYY hh:mm:ss')
      if (formatted === 'Invalid date') {
        return date
      }
      return formatted
    } catch {
      return date
    }
  }

  const exportButton = () => {
    if (messageCount < 1 || messageCount > 1000000) {
      return (
        <EuiFlexItem>
          <EuiToolTip
            display='block'
            position='bottom'
            content={'Allowed message export 1 - 1mil'}
          >
            <EuiButton
              iconType='save'
              style={{
                width: '100%',
                height: '2.75rem',
                margin: '.5rem 0',
                borderColor: '#E8F2F1',
                backgroundColor: '#E8F2F1',
              }}
              onClick={() => {}}
              color='secondary'
              isLoading={loading}
              disabled={true}
            >
              Export
            </EuiButton>
          </EuiToolTip>
        </EuiFlexItem>
      )
    }
    if (!validExportQuery) {
      return (
        <EuiFlexItem>
          <EuiToolTip
            display='block'
            position='bottom'
            content={'Too many parameters included in search.'}
          >
            <EuiButton
              iconType='save'
              style={{
                width: '100%',
                height: '2.75rem',
                margin: '.5rem 0',
                borderColor: '#E8F2F1',
                backgroundColor: '#E8F2F1',
              }}
              onClick={() => {}}
              color='secondary'
              isLoading={loading}
              disabled={true}
            >
              Export
            </EuiButton>
          </EuiToolTip>
        </EuiFlexItem>
      )
    }
    return (
      <EuiFlexItem>
        <EuiButton
          iconType='save'
          style={{
            height: '2.75rem',
            margin: '.5rem 0',
            borderColor: '#E8F2F1',
            backgroundColor: '#E8F2F1',
          }}
          onClick={submit}
          color='secondary'
          isLoading={loading}
        >
          Export
        </EuiButton>
      </EuiFlexItem>
    )
  }

  return (
    <EuiFlexGroup gutterSize='none' direction='column' className='fade-down'>
      <EuiFlexGroup
        gutterSize='none'
        style={{ justifyContent: 'space-between' }}
      >
        <EuiFlexItem grow={false} style={{ padding: '4px 0' }}>
          <EuiText size='s' textAlign='left'>
            New
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ padding: '4px 0' }}>
          <EuiText
            size='s'
            textAlign='left'
            style={{
              color: '#076cb4',
              cursor: 'pointer',
            }}
            onClick={() => setShowForm(false)}
          >
            Cancel
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup
        gutterSize='m'
        style={{
          border: '1px solid rgb(224, 226, 229)',
          borderRadius: '4px',
          background: 'rgb(244, 246, 249)',
          margin: 0,
        }}
        direction='column'
      >
        <EuiFlexItem>
          <EuiFlexGroup
            gutterSize='none'
            direction='column'
            className='export-bar-form-label'
          >
            <EuiFlexItem>
              <EuiText size='xs' textAlign='left'>
                Filename
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFieldText
                placeholder='Filename'
                value={filename}
                onChange={(e) => setFilename(e.target.value)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup
            gutterSize='none'
            direction='column'
            className='export-bar-form-label'
          >
            <EuiFlexItem>
              <EuiText size='xs' textAlign='left'>
                Timeframe
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText textAlign='left'>{`${formatDate(date[0])} -`}</EuiText>
              <EuiText textAlign='left'>{`${formatDate(date[1])}`}</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup
            gutterSize='none'
            direction='column'
            className='export-bar-form-label'
          >
            <EuiFlexItem>
              <EuiText size='xs' textAlign='left'>
                Messages
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText textAlign='left'>
                {messageCount.toLocaleString('en-US')}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      {exportButton()}
    </EuiFlexGroup>
  )
}
