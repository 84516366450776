const config = {
  API_URL: process.env.REACT_APP_SMNS_API || 'https://smns-smns-server.eks.sandbox.aimsplatform.com/',
  KC_URL: process.env.REACT_APP_KEYCLOAK_URL || 'https://smns-smns-kc.eks.sandbox.aimsplatform.com/auth/',
  KC_REALM: process.env.REACT_APP_KEYCLOAK_REALM || 'smns',
  KC_CLIENT_ID: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'smns-app',
  WS_HOST: process.env.REACT_APP_WS_HOST || 'wss://zmmulzbrmi.execute-api.us-east-1.amazonaws.com/sandbox',
}

console.log(config)

export default config
