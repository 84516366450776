import React, {
  useState,
  useEffect,
} from 'react'

import { v4 as uuid } from 'uuid'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
  EuiIcon,
  EuiCopy,
  EuiPanel
} from '@elastic/eui'

import './etor_data_list.css'


export const EtorDataList = ({ data }) => {
  const [dataRows, setDataRows] = useState([])
  const [expandSet, setExpandSet] = useState(new Set())

  const DataRow = ({ name, value, indent, idx, expandId, expandParentId }) => {
    let margin = 0
    if (typeof indent === 'number') {
      margin = indent * 2
    }
    // only top level expandable
    const expandable = value === '' && indent === 0

    const renderExpandArrow = () => {
      if (expandable) {
        if (expandSet.has(expandParentId)) {
          return (
            <EuiButtonIcon
              iconType='arrowDown'
              style={{ margin: '0 4px' }}
              onClick={() => toggleExpand(expandParentId)}
              aria-label='expand metadata'
            />
          )
        } else {
          return (
            <EuiButtonIcon
              iconType='arrowRight'
              style={{ margin: '0 4px' }}
              onClick={() => toggleExpand(expandParentId)}
              aria-label='expand metadata'
            />
          )
        }
      }
      return <div style={{ width: '32px' }} />
    }

    if (expandId && !expandSet.has(expandId)) return <></>

    return (
      <EuiFlexItem
        grow={false}
        className='EtorProjectDataList-item ${}'
        style={{ marginLeft: `${margin}rem` }}
      >
        {renderExpandArrow()}
        {(idx === 0 && indent) ? <EuiIcon type='minus' style={{ marginLeft: '-20px', marginRight: '4px' }} /> : <></>}
        {name && <span className='EtorProjectDataList-key'>{name}: </span>}
        <span>{value}</span>
        {value &&
          <EuiCopy textToCopy={String(value)}>
            {(copy) => (
              <EuiButtonIcon
                onClick={(e) => {
                  e.stopPropagation()
                  copy()
                }}
                iconType='copy'
                color='subdued'
                style={{ width: '24px', margin: '0 4px' }}
                aria-label='Copy Text'
              />
            )}
          </EuiCopy>
        }
      </EuiFlexItem>
    )
  }

  const parseJson = (data) => {
    try {
      const json = JSON.parse(data)
      return json
    }
    catch {
      return null
    }
  }

  const toggleExpand = (id) => {
    const newSet = new Set(expandSet)
    if (newSet.has(id)) {
      newSet.delete(id)
    } else {
      newSet.add(id)
    }
    setExpandSet(newSet)
  }

  useEffect(() => {
    let allrows = []
    const genRows = async ({ data, indent, expandId }) => {
      let curIndent = indent || 0
      if (typeof data === 'string') {
        const jsonData = parseJson(data)
        if (!jsonData) {
          const row = {
            name: '',
            value: data,
            indent: curIndent,
            expandId
          }
          allrows.push(row)
          return
        }
        await genRows({ data: jsonData, indent: curIndent, expandId })
        return
      }
      if (typeof data === 'object') {
        for (const [idx, [k, v]] of Object.entries(data).entries()) {
          if (typeof v === 'string' || typeof v === 'number') {
            const row = {
              name: k,
              value: v,
              indent: curIndent,
              idx,
              expandId
            }
            allrows.push(row)
            continue
          }
          if (Array.isArray(v)) {
            if (!v.length) {
              const row = {
                name: k,
                value: '[]',
                indent: curIndent,
                expandId
              }
              allrows.push(row)
              continue
            }
            const id = expandId || uuid()
            // top level array
            const row = {
              name: k,
              value: '',
              indent: curIndent,
              expandId: id
            }
            allrows.push(row)
            // child rows
            for (const av of v) {
              await genRows({ data: av, indent: curIndent + 1, expandId: id })
            }
            continue
          }
          if (typeof v === 'object') {
            if (!Object.keys(v).length) {
              const row = {
                name: k,
                value: '{}',
                indent: curIndent,
                expandId
              }
              allrows.push(row)
              continue
            }
            const id = expandId || uuid()
            // top level object row
            const row = {
              name: k,
              value: '',
              indent: curIndent,
              expandId,
              expandParentId: id
            }
            allrows.push(row)
            // child rows
            await genRows({ data: v, indent: curIndent + 1, expandId: id })
          }
        }
      }
      return
    }

    const init = async () => {
      await genRows({ data })
      setDataRows(allrows)
    }

    init()

  }, [data])

  return (
    <EuiPanel
      style={{
        border: 'none',
        padding: 0,
        boxShadow: 'none',
        maxHeight: '26rem',
        overflow: 'auto'
      }}
    >
      <EuiFlexGroup
        direction='column'
        alignItems='flexStart'
        justifyContent='center'
        gutterSize='none'
      >
        {dataRows.map((r, idx) => (
          <DataRow
            key={`etor-metadata-rows-${idx}`}
            name={r.name}
            value={r.value}
            indent={r.indent}
            idx={r.idx}
            id={r.id}
            expandId={r.expandId}
            expandParentId={r.expandParentId}
          />
        ))}
      </EuiFlexGroup>
    </EuiPanel>
  )
}
