import React from "react";
import { EuiFieldSearch } from "@elastic/eui";

export default function SearchBar({ updateQuery, query }) {
  const onSearch = (value) => {
    updateQuery(value);
  };

  return (
    <EuiFieldSearch
      defaultValue={query}
      onSearch={onSearch}
      isClearable={true}
      fullWidth={true}
      style={{ paddingRight: "27px" }}
    />
  );
}
