import React, { useState, useContext } from 'react'

import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiButtonEmpty,
} from '@elastic/eui'

import { CreateRule } from './create_rule'
import { RulesList } from './rules_list'
import { AppContext } from '../../app'
import { AdminPageContext } from './admin_page'

export const TagRules = () => {
  const { projects } = useContext(AppContext)
  const { updateTag, adminState } = useContext(AdminPageContext)
  const [displayingCreateRule, setDisplayingCreateRule] = useState(false)

  const user = adminState.activeUser
  const tag = adminState.activeTag

  const [newRule, setNewRule] = useState({
    principalType: 'tag',
    principalId: user.id ? user.id : '',
    resourceType: 'project',
    resourceName: projects[0].name,
    action: 'read',
    effect: 'allow',
  })

  const displayCreateRule = () => {
    setDisplayingCreateRule(true)
  }

  const hideCreateRule = () => {
    setDisplayingCreateRule(false)
  }

  const updateNewRule = (rule) => {
    setNewRule({ ...rule })
  }

  const createRule = (rule) => {
    let updatedRules = []
    updatedRules = [...tag.rules, rule]
    updateTag({ ...tag, rules: [...updatedRules] })
    hideCreateRule()
  }

  const removeRule = (ruleIndex) => {
    const updatedRules = [...tag.rules]
    updatedRules.splice(ruleIndex, 1)
    updateTag({ ...tag, rules: updatedRules })
  }

  const renderAction = () => {
    if (adminState.tagModal === 'view') {
      return (
        <></>
      )
    }
    return (
      <>
        {displayingCreateRule ? (
          <EuiFlexGroup gutterSize='s'>
            <EuiFlexItem>
              <EuiButtonEmpty size='s' onClick={() => hideCreateRule()}>
                Cancel
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButton
                size='s'
                color='secondary'
                iconType='check'
                onClick={() => createRule(newRule)}
              >
                Add
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiButtonEmpty
            size='s'
            color='primary'
            iconType='listAdd'
            onClick={() => displayCreateRule()}
          >
            Add Rule
          </EuiButtonEmpty>
        )}
      </>
    )
  }

  return (
    <div>
      <EuiFlexGroup
        gutterSize='m'
        justifyContent='flexEnd'
        alignItems='flexEnd'
      >
        <EuiFlexItem grow={false}>
          {renderAction()}
        </EuiFlexItem>
      </EuiFlexGroup>


      {displayingCreateRule && (
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel>
              <CreateRule
                newRule={newRule}
                updateNewRule={updateNewRule}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
      <EuiFlexGroup>
        <EuiFlexItem>
          <RulesList
            rules={tag.rules}
            removeRule={removeRule}
            hideDelete={adminState.tagModal === 'view'}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}
