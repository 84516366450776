import React, { useContext, useState, useEffect } from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton,
  EuiFieldText,
  EuiToolTip,
} from '@elastic/eui'

import { AppContext } from '../../app'
import moment from 'moment'
import api from '../../lib/api'
import { Parser } from '@json2csv/plainjs'

export const ExportUsersForm = () => {
  const {
    toaster,
    usersPagination
  } = useContext(AppContext)
  const [filename, setFilename] = useState('')
  const [loading, setLoading] = useState(false)

  const totalUsers = usersPagination.total

  useEffect(() => {
    if (!filename) {
      setFilename(moment().utc().format('YYYY-MM-DD-HHmmss') + '-USERS.csv')
    }
  }, [filename])

  const submit = async () => {
    setLoading(true)
    if (!filename) return
    try {
      const res = await api.fetchAllUsers()
      if (res.status !== 200) {
        toaster({
          color: 'danger',
          title: 'Unable to create export',
        })
        return
      }
      const parser = new Parser({})
      const csv = parser.parse(res.data)

      const url = window.URL.createObjectURL(
        new Blob([csv]),
      )
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        filename,
      )
  
      document.body.appendChild(link)
  
      link.click()
      link.parentNode.removeChild(link)
    }
    catch (err) {
      console.log('Error creating export ', err)
      toaster({
        color: 'danger',
        title: 'Unable to create export',
        text: err.message,
      })
    }
    finally {
      setLoading(false)
    }
  }

  const exportButton = () => {
    if (!totalUsers) {
      return (
        <EuiFlexItem>
          <EuiToolTip
            display='block'
            position='bottom'
            content={'Invalid users'}
          >
            <EuiButton
              iconType='save'
              style={{
                width: '100%',
                height: '2.75rem',
                margin: '.5rem 0',
                borderColor: '#E8F2F1',
                backgroundColor: '#E8F2F1',
              }}
              onClick={() => {}}
              color='secondary'
              isLoading={loading}
              disabled={true}
            >
              Export
            </EuiButton>
          </EuiToolTip>
        </EuiFlexItem>
      )
    }
    return (
      <EuiFlexItem>
        <EuiButton
          iconType='save'
          style={{
            height: '2.75rem',
            margin: '.5rem 0',
            borderColor: '#E8F2F1',
            backgroundColor: '#E8F2F1',
          }}
          onClick={submit}
          color='secondary'
          isLoading={loading}
        >
          Export
        </EuiButton>
      </EuiFlexItem>
    )
  }

  return (
    <EuiFlexGroup gutterSize='none' direction='column' className='fade-down'>
      <EuiFlexGroup
        gutterSize='m'
        style={{
          border: '1px solid rgb(224, 226, 229)',
          borderRadius: '4px',
          background: 'rgb(244, 246, 249)',
          margin: 0,
        }}
        direction='column'
      >
        <EuiFlexItem>
          <EuiFlexGroup
            gutterSize='none'
            direction='column'
            className='export-bar-form-label'
          >
            <EuiFlexItem>
              <EuiText size='xs' textAlign='left'>
                Filename
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFieldText
                placeholder='Filename'
                value={filename}
                onChange={(e) => setFilename(e.target.value)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup
            gutterSize='none'
            direction='column'
            className='export-bar-form-label'
          >
            <EuiFlexItem>
              <EuiText size='xs' textAlign='left'>
                Users
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText textAlign='left'>
                {usersPagination.total && usersPagination.total.toLocaleString('en-US')}
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      {exportButton()}
    </EuiFlexGroup>
  )
}
