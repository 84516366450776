import React from 'react'
import './children_list.css'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiToolTip,
  EuiButtonIcon,
  EuiIcon,
} from '@elastic/eui'
import { HealthIndicator } from '../stats/health_indicator'
import { ListItemToggleButton } from '..'

const truncate = (str) =>
  str && str.length > 20 ? str.substring(0, 17) + '...' : str

export const ChildrenList = ({
  data,
  toggled,
  id,
  displayName,
  description,
  addItem = () => {},
  removeItem = () => {},
  selectedItems = [],
  toggleRow,
  parentTotal = 0,
}) => {
  const [isToggled, setIsToggled] = React.useState(toggled)

  return (
    <EuiFlexGroup gutterSize='s' alignItems='flexStart'>
      <EuiFlexItem grow={false}>
        <span>
          <EuiButtonIcon
            iconType={isToggled ? 'arrowDown' : 'arrowRight'}
            color='subdued'
            aria-label={`Remove ${displayName || id} from filter`}
            onClick={() => {
              setIsToggled(!isToggled)
              toggleRow(!isToggled, id)
            }}
          />

          <EuiToolTip content={`Parent Count: ${parentTotal}`}>
            <span style={{ paddingLeft: '4px' }}>
              <EuiIcon type='home' size='s' />
            </span>
          </EuiToolTip>
        </span>
      </EuiFlexItem>
      <EuiFlexItem>
        <ListItemToggleButton
          addItem={addItem}
          removeItem={removeItem}
          id={id}
          displayName={displayName}
          isIncluded={selectedItems.includes(id)}
        >
          <EuiFlexGroup gutterSize='s'>
            <EuiFlexItem grow={false}>
              {description ? (
                <EuiToolTip content={description}>
                  <span>{truncate(displayName || id)}</span>
                </EuiToolTip>
              ) : (
                <span>{truncate(displayName || id)}</span>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </ListItemToggleButton>

        {data.map(({ name, status, description, displayName }, idx) => (
          <EuiFlexGroup gutterSize='s' key={`treeview-key-${name}-${idx}`}>
            <EuiFlexItem>
              <ListItemToggleButton
                addItem={addItem}
                removeItem={removeItem}
                id={name}
                displayName={displayName}
                isIncluded={selectedItems.includes(name)}
                className={
                  isToggled
                    ? 'tree-element subsender'
                    : 'tree-element collapsed subsender'
                }
              >
                <EuiFlexGroup
                  gutterSize='s'
                  alignItems='center'
                  style={{
                    margin: '5px 0px 0px -30px',
                    height: '30px',
                  }}
                >
                  <EuiFlexItem>
                    {status && <HealthIndicator {...status} />}
                  </EuiFlexItem>

                  <EuiFlexItem grow={false} style={{ marginLeft: '-1px' }}>
                    {description ? (
                      <EuiToolTip content={description}>
                        <span>{displayName || name}</span>
                      </EuiToolTip>
                    ) : (
                      <EuiToolTip content={displayName || name}>
                        <span>{truncate(displayName || name)}</span>
                      </EuiToolTip>
                    )}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </ListItemToggleButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        ))}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
