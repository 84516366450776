export const mapTagNamesForUser = (user) => {
  if (user) {
    if (!user.tags.length > 0) {
      return []
    }
  }
  let tagNames = []

  user.tags.forEach((tag) => {
    const tagName = tag.name || tag.tag.name
    tagNames.push({ ...tag, label: tagName })
  })

  return tagNames
}
