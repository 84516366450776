import React from 'react'
import abbreviateNumber from 'number-abbreviate'
import commaNumber from 'comma-number'
import {
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
} from '@elastic/eui'
import { StatButton } from './stat_button'
import { HealthIndicator } from './health_indicator'
import './messages_stats.css'

export const MessagesStats = ({
  project,
  messageCounts,
  loadingMessageCounts,
  updateSelectedStatuses,
  selectedStatuses = [],
}) => {
  const onChange = (status) => (shouldSelect) => {
    if (shouldSelect) {
      updateSelectedStatuses([...selectedStatuses, status])
    } else {
      updateSelectedStatuses(selectedStatuses.filter((s) => s !== status))
    }
  }
  const onChangeMulti =
    (statuses = []) =>
    (shouldSelect) => {
      if (shouldSelect) {
        updateSelectedStatuses([...selectedStatuses, ...statuses])
      } else {
        updateSelectedStatuses(
          selectedStatuses.filter((s) => !statuses.includes(s))
        )
      }
    }
  return (
    <EuiPageHeader className='MessagesStats'>
      <EuiPageHeaderSection>
        {project && project.name && (
          <EuiFlexGroup alignItems='center'>
            <EuiFlexItem>
              <EuiTitle size='l'>
                <h1>{project.name}</h1>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem>
              {project && project.status && (
                <p style={{ textAlign: 'left' }}>
                  {project.status.healthMeter
                    ? `${project.status.healthMeter}%`
                    : ''}
                  <HealthIndicator {...project.status} />
                </p>
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
      </EuiPageHeaderSection>
      <EuiPageHeaderSection className='messageCounts'>
        <EuiFlexGroup gutterSize='none'>
          <EuiFlexItem>
            <StatButton
              title={abbreviateNumber(
                messageCounts ? messageCounts.processed : 0
              )}
              description='Processed'
              color='secondary'
              isLoading={loadingMessageCounts}
              isSelected={selectedStatuses.includes('Processed')}
              onChange={onChange('Processed')}
              tooltip={`Add ${commaNumber(
                messageCounts ? messageCounts.processed : 0
              )} processed messages to filter.`}
              tooltipSelected={`Remove ${commaNumber(
                messageCounts ? messageCounts.processed : 0
              )} processed messages from filter.`}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <StatButton
              title={abbreviateNumber(
                messageCounts ? messageCounts.processing : 0
              )}
              description='Processing'
              color='warning'
              isLoading={loadingMessageCounts}
              onChange={onChangeMulti([
                'Received',
                'Queued',
                'Batched',
                'Batching',
              ])}
              isSelected={
                selectedStatuses.includes('Received') &&
                selectedStatuses.includes('Queued') &&
                selectedStatuses.includes('Batched') &&
                selectedStatuses.includes('Batching')
              }
              tooltip={`Add ${commaNumber(
                messageCounts ? messageCounts.processing : 0
              )} processing messages to filter.`}
              tooltipSelected={`Remove ${commaNumber(
                messageCounts ? messageCounts.processing : 0
              )} processing messages from filter.`}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <StatButton
              title={abbreviateNumber(
                messageCounts ? messageCounts.rejected : 0
              )}
              description='Rejected'
              isLoading={loadingMessageCounts}
              onChange={onChange('Rejected')}
              isSelected={selectedStatuses.includes('Rejected')}
              tooltip={`Add ${commaNumber(
                messageCounts ? messageCounts.rejected : 0
              )} rejected messages to filter.`}
              tooltipSelected={`Remove ${commaNumber(
                messageCounts ? messageCounts.rejected : 0
              )} rejected messages from filter.`}
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <StatButton
              title={abbreviateNumber(messageCounts ? messageCounts.errors : 0)}
              description='Errors'
              color='danger'
              isLoading={loadingMessageCounts}
              onChange={onChange('Error')}
              isSelected={selectedStatuses.includes('Error')}
              tooltip={`Add ${commaNumber(
                messageCounts ? messageCounts.errors : 0
              )} errored messages to filter.`}
              tooltipSelected={`Remove ${commaNumber(
                messageCounts ? messageCounts.errors : 0
              )} errored messages from filter.`}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPageHeaderSection>
    </EuiPageHeader>
  )
}
