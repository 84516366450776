import React from 'react'
import {
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiToolTip,
} from '@elastic/eui'
import { HealthIndicator } from '../stats/health_indicator'
import commaNumber from 'comma-number'
import { ListItemToggleButton } from '..'
import { EntityTableHeader } from './table_header/entity_table_header'
import './entity_table.css'

export const EntityTable = ({
  title,
  onToggleFilter,
  filteringEnabled,
  items = [],
  addItem = () => {},
  removeItem = () => {},
  selectedItems = [],
  name = 'Name',
  loading = false,
  sort,
  onSort,
}) => {
  const sorting = { sort }
  const onTableChange = ({ sort }) => {
    onSort(sort)
  }
  const columns = [
    {
      field: 'name',
      name,
      width: '70%',
      truncateText: true,
      sortable: true,
      render: (id, { description, status }) => {
        return (
          <ListItemToggleButton
            addItem={addItem}
            removeItem={removeItem}
            id={id}
            isIncluded={selectedItems.includes(id)}
          >
            <EuiFlexGroup gutterSize='s' alignItems='center'>
              <EuiFlexItem>
                {description ? (
                  <EuiToolTip content={description}>
                    <span>{id}</span>
                  </EuiToolTip>
                ) : (
                  <span>{id}</span>
                )}
              </EuiFlexItem>
              {status && <HealthIndicator {...status} />}
            </EuiFlexGroup>
          </ListItemToggleButton>
        )
      },
    },
    {
      field: 'count',
      name: 'Count',
      align: 'right',
      sortable: true,
      render: (count) => <span>{count && commaNumber(count)}</span>,
    },
  ]

  const getRowProps = (item) => {
    const { name, isCurrent } = item
    return {
      'data-test-subj': `row-${name}`,
      className: `customRow${selectedItems.includes(name) ? ' selected' : ''}${
        isCurrent ? '' : ' previous'
      }`,
    }
  }

  const getCellProps = (item, column) => {
    const { id } = item
    const { field } = column
    return {
      className: 'customCell',
      'data-test-subj': `cell-${id}-${field}`,
      textOnly: true,
    }
  }

  return (
    <EuiFlexItem className='EntityTable'>
      <EuiFlexGroup direction='column' gutterSize='s'>
        <EntityTableHeader
          title={title}
          items={items}
          onToggleFilter={onToggleFilter}
          filteringEnabled={filteringEnabled}
        />
        <EuiFlexItem>
          <EuiPanel
            className='customPanel'
            style={{
              overflow: 'auto',
              height: '325px',
            }}
          >
            <EuiBasicTable
              items={items}
              columns={columns}
              rowProps={getRowProps}
              cellProps={getCellProps}
              loading={loading}
              noItemsMessage={loading ? 'Loading...' : 'No Items Found'}
              sorting={sorting}
              onChange={onTableChange}
            />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
  )
}
