import React, { useContext, useEffect } from 'react'
import {
  EuiPage,
  EuiPageBody,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiFlexItem,
  EuiFlexGrid,
} from '@elastic/eui'
import { getProjectField } from '..'
import { ProjectCard } from '.'
import { AppContext } from '../../app'

export const ProjectsPage = () => {
  const {
    navBarExpanded,
    exportToolExpanded,
    supportToolExpanded,
    clearFilters,
    projects,
    notifications,
    messageCounts,
    clearMessages,
  } = useContext(AppContext)

  useEffect(() => {
    clearFilters()
    clearMessages()
  }, [])

  const notificationsByProject = Object.values(notifications).reduce(
    (nbp, date) => {
      for (let noti of date) {
        if (noti.id) {
          const { to } = noti
          const project = getProjectField(to)
          nbp[String(project)] = nbp[String(project)] || []
          nbp[String(project)].push(noti)
          continue
        }
        if (noti.groupedNotifications) {
          for (let g of noti.groupedNotifications) {
            const { to } = g
            const project = getProjectField(to)
            nbp[String(project)] = nbp[String(project)] || []
            nbp[String(project)].push(noti)
          }
        }
        if (noti.duplicateNotifications) {
          for (let g of noti.duplicateNotifications) {
            const { to } = g
            const project = getProjectField(to)
            nbp[String(project)] = nbp[String(project)] || []
            nbp[String(project)].push(noti)
          }
        }
      }
      return nbp
    },
    {}
  )

  let projectNodes = null
  if (projects) {
    projectNodes = projects.map(function (project, index) {
      const { total, errors } = messageCounts[project.name] || {}
      return (
        <EuiFlexItem key={index}>
          <ProjectCard
            project={project}
            notificationCount={
              (notificationsByProject[project.name] || []).length
            }
            messageCount={total}
            errorCount={errors}
          />
        </EuiFlexItem>
      )
    })
  } else {
    projectNodes = <div>No projects available.</div>
  }

  return (
    <EuiPage
      className={`euiNavDrawerPage
        ${navBarExpanded ? 'navBarExpanded' : 'navBarCollapsed'}
        ${
          (exportToolExpanded || supportToolExpanded) &&
          'export-bar-expanded-pad'
        }`}
    >
      <div className='page_wrapper'>
        <EuiPageBody className='euiNavDrawerPage__pageBody'>
          <EuiPageHeader>
            <EuiPageHeaderSection>
              <EuiTitle size='m'>
                <h1>Projects</h1>
              </EuiTitle>
            </EuiPageHeaderSection>
          </EuiPageHeader>
          <EuiFlexGrid columns={4}>{projectNodes}</EuiFlexGrid>
        </EuiPageBody>
      </div>
    </EuiPage>
  )
}
