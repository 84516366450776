import React, { useState, useContext } from 'react'

import {
  EuiButton,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPopover,
  EuiSpacer,
  EuiPageHeader,
  EuiPageHeaderSection,
  EuiTitle,
  EuiTabs
} from '@elastic/eui'

import { api } from '../../lib'
import { AppContext } from '../../app'
import { GroupsPageContext } from './groups_page'
import { StatCount } from '../stats/stat_count'
import { GroupList } from '../lists/group_list'

import { ConfirmDeleteMsg } from '../modals'

export const GroupsListPage = () => {
  const { groups, toaster, groupsPagination, keycloak } =
    useContext(AppContext)

  const {
    loadGroups,
    renderGroupsTabs,
    groupsSearch,
    groupsState,
    groupsDispatch,
  } = useContext(GroupsPageContext)

  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState(null)
  const [itemIdToOpenActionsPopoverMap, setItemIdToOpenActionsPopoverMap] =
    useState({})

  const setSearch = (value) =>
    groupsDispatch({ type: 'set', state: 'groupsSearch', value })

  const addGroup = () =>
    groupsDispatch({ type: 'set', state: 'groupModal', value: 'create' })

  const editGroup = (group) => groupsDispatch({ type: 'edit_group', group })

  const deleteGroup = async (group) => {
    try {
      setItemIdToOpenActionsPopoverMap({})
      setModal(null)
      setLoading(true)

      const resp = await api.deleteGroup(group.id)
      if (resp.status !== 200) {
        toaster({
          color: 'danger',
          title: 'Oops!',
          text: 'There was a problem deleting some group.',
        })
        return
      }

      loadGroups({ reset: true, search: groupsSearch })

      toaster({
        color: 'success',
        title: `Group succesfully deleted.`,
      })
    } catch (err) {
      console.log(err)
      toaster({
        color: 'danger',
        title: 'Unable to delete group.',
        text: err.message,
      })
    } finally {
      setLoading(false)
      groupsDispatch({ type: 'reset_group' })
    }
  }

  const onCancelModal = () => {
    setModal(null)
    groupsDispatch({ type: 'reset_group' })
    setItemIdToOpenActionsPopoverMap({})
  }

  const onDelete = (group) => {
    setModal(
      <ConfirmDeleteMsg
        title='Confirm Delete'
        msg={`Are you sure you want to delete ${group.name}?`}
        onCancel={onCancelModal}
        onConfirm={() => deleteGroup(group)}
      />
    )
  }

  const actionsPopover = (item) => {
    if (!keycloak.hasClientRole({ roleName: 'edit' })) {
      return (<></>)
    }
    return (
      <EuiPopover
        id={`${item.id}-actions`}
        button={
          <EuiButtonIcon
            aria-label="Actions"
            iconType="gear"
            size="s"
            color="text"
            onClick={(e) => {
              e.stopPropagation()
              togglePopover(item.id)
            }}
          />
        }
        isOpen={isPopoverOpen(item.id)}
        closePopover={() => closePopover(item.id)}
        panelPaddingSize="none"
        anchorPosition="leftCenter"
        style={{
          display: 'flex',
          justifyContent: 'flex-end'    
        }}
      >
        <EuiContextMenuPanel
          items={[
            <EuiContextMenuItem
              key="A"
              icon="pencil"
              onClick={(e) => {
                e.stopPropagation()
                togglePopover(item.id)
                editGroup(item)
              }}
            >
              Edit
            </EuiContextMenuItem>,
            <EuiContextMenuItem
              key="B"
              icon="trash"
              onClick={(e) => {
                e.stopPropagation()
                togglePopover(item.id)
                onDelete(item)
              }}
            >
              Delete
            </EuiContextMenuItem>,
          ]}
        />
      </EuiPopover>
    )
  }

  const togglePopover = (itemId) => {
    const newItemIdToOpenActionsPopoverMap = {
      ...itemIdToOpenActionsPopoverMap,
      [itemId]: !itemIdToOpenActionsPopoverMap[String(itemId)],
    }

    setItemIdToOpenActionsPopoverMap(newItemIdToOpenActionsPopoverMap)
  }

  const closePopover = (itemId) => {
    if (isPopoverOpen(itemId)) {
      const newItemIdToOpenActionsPopoverMap = {
        ...itemIdToOpenActionsPopoverMap,
        [itemId]: false,
      }

      setItemIdToOpenActionsPopoverMap(newItemIdToOpenActionsPopoverMap)
    }
  }

  const isPopoverOpen = (itemId) => {
    return itemIdToOpenActionsPopoverMap[String(itemId)]
  }

  return (
    <>
      <EuiPageHeader>
        <EuiPageHeaderSection>
          <EuiTitle size="m">
            <h1>User Groups</h1>
          </EuiTitle>
        </EuiPageHeaderSection>
      </EuiPageHeader>

      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem style={{ justifyContent: 'center' }}>
          <EuiTabs>{renderGroupsTabs()}</EuiTabs>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup gutterSize="s" style={{ alignItems: 'center' }}>
            <EuiFlexItem grow={false}>
              <StatCount
                title={groupsPagination.total ? groupsPagination.total : 0}
                description="Total"
                tooltip={`total groups: ${
                  groupsPagination.total ? groupsPagination.total : 0
                }`}
                color="secondary"
              />
            </EuiFlexItem>
            {keycloak.hasClientRole({ roleName: 'edit' }) && (
              <EuiFlexItem grow={false}>
                <EuiButton
                  fill
                  color="primary"
                  iconType="listAdd"
                  onClick={addGroup}
                >
                  Create Group
                </EuiButton>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="m" />

      <EuiFlexGroup gutterSize="m">
        <EuiFlexItem>
          <EuiFieldSearch
            fullWidth
            placeholder="Search..."
            value={groupsState.groupsSearch}
            onChange={(e) => {
              setSearch(e.target.value)
              if (e.target.value === '') {
                loadGroups({ reset: true })
              }
            }}
            onSearch={(search) => {
              setSearch(search)
              if (search === '') return
              loadGroups({ search: groupsState.groupsSearch, reset: true })
            }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="m" />

      <GroupList
        groups={groups}
        groupsPagination={groupsPagination}
        loadMore={loadGroups}
        loading={loading}
        actionsPopover={actionsPopover}
        search={groupsState.groupsSearch}
      />
      {modal}
    </>
  )
}
