import React, { useContext } from 'react'
import {
  EuiTable,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableBody,
  EuiTableFooter,
  EuiTableFooterCell,
} from '@elastic/eui'
import { InView } from 'react-intersection-observer'
import { ListItemToggleButton } from '..'
import { TableLoadingIndicator } from '.'

import { formatDateTime, formatTimeZone } from '../../lib'

import { AppContext } from '../../app'

import { LEFT_ALIGNMENT } from '@elastic/eui/lib/services'
import { EtorContext } from '..'
import './messages_table.css'

export const EtorMessagesTable = () => {
  let {
    messages,
    moreMessagesAvailable,
    messagesTableSort,
    setMessagesTableSort,
    selectedStatuses,
    selectedSenders,
    selectedRecipients,
  } = useContext(AppContext)

  let {
    projectName,
    addStatus,
    removeStatus,
    addSender,
    removeSender,
    senders,
    addRecipient,
    removeRecipient,
    displayDetailsModal,
    loadNewMessages,
    loadingEtorMessages,
  } = useContext(EtorContext)

  let hideProjects = !!projectName

  const sort = messagesTableSort
  const setSort = setMessagesTableSort

  const sendersByName = senders.reduce((agg, sender) => {
    agg[sender.name] = sender
    return agg
  }, {})

  const items = messages.map(({ _source }) => _source)

  let columns = [
    {
      field: 'MessageDateTime',
      isSortable: true,
      name: `Date ${formatTimeZone()}`,
      width: '10%',
      alignment: LEFT_ALIGNMENT,
      render: (date) => formatDateTime(date),
      footer: () => (
        <InView
          hidden={!messages.length || !moreMessagesAvailable}
          onChange={(inView) => {
            if (inView && messages.length && moreMessagesAvailable) {
              loadNewMessages()
            }
          }}
        >
        </InView>
      ),
    },
    {
      field: 'MessageId',
      isSortable: true,
      name: 'Message ID',
      width: '15%',
      alignment: LEFT_ALIGNMENT,
    },
    {
      field: 'AccessionNumber',
      name: 'Accession',
      width: '7.5%',
    },
    {
      field: '',
      name: 'Type',
      width: '7.5%',
      render: (cell, row) => {
        return (
          <>
            {row?.ProjectData?.ETOR?.EventType}
          </>
        )
      }
    },
    {
      field: 'Status',
      isSortable: true,
      name: 'Status',
      width: '7.5%',
      truncateText: true,
      alignment: LEFT_ALIGNMENT,
      render: (status) => (
        <ListItemToggleButton
          addItem={addStatus}
          removeItem={removeStatus}
          id={status}
          isIncluded={selectedStatuses.includes(status)}
        >
          <span>{status}</span>
        </ListItemToggleButton>
      ),
    },
    {
      field: 'Senders',
      isSortable: true,
      name: 'Senders',
      width: '12.5%',
      truncateText: true,
      alignment: LEFT_ALIGNMENT,
      render: (sender, { SenderData }) => {
        const senderName = (SenderData && SenderData.SenderId) || sender
        const senderEntity = sendersByName[String(senderName)] || {
          displayName: 'Unrecognized Sender',
        }
        return (
          <ListItemToggleButton
            addItem={addSender}
            removeItem={removeSender}
            id={senderEntity.name}
            isIncluded={selectedSenders.includes(senderEntity.name)}
          >
            <span>{senderEntity.displayName || senderEntity.name}</span>
          </ListItemToggleButton>
        )
      },
    },
    {
      field: 'Recipients',
      isSortable: true,
      name: 'Recipients',
      width: '12.5%',
      truncateText: true,
      alignment: LEFT_ALIGNMENT,
      render: (name) => (
        <ListItemToggleButton
          addItem={addRecipient}
          removeItem={removeRecipient}
          id={name}
          isIncluded={selectedRecipients.includes(name)}
        >
          <span>{name}</span>
        </ListItemToggleButton>
      ),
    },
  ]

  if (hideProjects) {
    columns.splice(2, 1)
  }

  const onSort = (field) => {
    const newSort = Object.assign({}, sort)
    if (sort.field === field) {
      if (sort.direction === 'desc') {
        newSort.direction = 'asc'
      } else {
        newSort.direction = 'desc'
      }
    } else {
      newSort.field = field
      newSort.direction = 'asc'
    }
    setSort(newSort)
  }

  const renderHeaderCells = () => {
    const headers = []

    columns.forEach((column, columnIndex) => {
      headers.push(
        <EuiTableHeaderCell
          key={`messages-table-header-${column.name}-${columnIndex}`}
          width={column.width}
          align={columns[String(columnIndex)].alignment}
          onSort={column.isSortable ? () => onSort(column.field) : undefined}
          isSorted={column.field === sort.field}
          isSortAscending={sort.direction === 'asc'}
        >
          {column.name}
        </EuiTableHeaderCell>
      )
    })
    return headers.length ? headers : null
  }

  const renderRows = () => {
    if (loadingEtorMessages) {
      return (
        <EuiTableRow>
          <EuiTableRowCell
            colSpan={columns.length}
            className='table-loader'
            align='center'
          >
            <TableLoadingIndicator noMessages={!items.length} />
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    if (!items.length) {
      return (
        <EuiTableRow>
          <EuiTableRowCell colSpan={columns.length} align='center'>
            No Messages Found
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    const renderRow = (item) => {
      const cells = columns.map((column, columnIndex) => {
        const cell = item[column.field]
        let child

        if (column.render) {
          child = column.render(cell, item)
        } else {
          child = cell
        }

        return (
          <EuiTableRowCell
            key={`message-row-cell-${column.field}-${columnIndex}`}
            truncateText={column.truncateText}
            className='messages_cell'
            align={column.alignment}
            style={{ textAlign: 'left', minWidth: '40px' }}
            isExpander={column.isExpander}
          >
            {child}
          </EuiTableRowCell>
        )
      })

      return (
        <React.Fragment key={`message-row-${item.MessageId}`}>
          <EuiTableRow
            className='messages_row'
            isExpandable={true}
            itemID={item.MessageId}
            onClick={() =>
              displayDetailsModal(item)
            }
          >
            {cells}
          </EuiTableRow>
        </React.Fragment>
      )
    }

    const rows = []

    for (var item of items) {
      rows.push(renderRow(item))
    }

    return rows
  }

  const renderFooters = () => {
    const footers = []

    columns.forEach((column, columnIndex) => {
      if (column.footer) {
        footers.push(
          <EuiTableFooterCell
            key={`messages-footer-${columnIndex}`}
            align={column.alignment}
          >
            {column.footer()}
          </EuiTableFooterCell>
        )
      } else {
        footers.push(
          <EuiTableFooterCell
            key={`messages-footer-${columnIndex}`}
            align={column.alignment}
          >
            {undefined}
          </EuiTableFooterCell>
        )
      }
    })
    return footers
  }

  return (
    <div>
      <EuiTable id='messages-table'>
        <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>
        <EuiTableBody>{renderRows()}</EuiTableBody>
        <EuiTableFooter>{renderFooters()}</EuiTableFooter>
      </EuiTable>
    </div>
  )
}
