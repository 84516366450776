import React from 'react'
import { useParams } from 'react-router-dom'
import { DashboardPage, EtorPage } from '.'

export const DashboardSwitch = () => {
  const { projectName } = useParams()
  if (!projectName) {
    return <DashboardPage />
  }
  switch (projectName) {
    case 'ETOR':
      return <EtorPage />
    default:
      return <DashboardPage />
  }
}
