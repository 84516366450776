import React, {
  useContext,
  useEffect
} from 'react'

import { AppContext } from '../../app'
import { GroupsPageContext } from './groups_page'

import {
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiAccordion,
  EuiSwitch,
  EuiIcon,
  EuiSpacer,
  EuiCheckbox,
  EuiButtonIcon,
  EuiBadgeGroup,
  EuiBadge,
  EuiDualRange,
  EuiRange,
  EuiSuperSelect,
  EuiToolTip,
  EuiBeacon,
  EuiFieldNumber
} from '@elastic/eui'

const NotiSettingsItem = ({ config, updateSettings, toggleSettings }) => {
  const { displayNames, keycloak } = useContext(AppContext)
  const { groupsState } = useContext(GroupsPageContext)
  const maxMessagesVolume = 99999
  const defaultMaxMessageVolume = maxMessagesVolume

  const setting = config.settings
  const nameMap = displayNames.projects
  const name = config.entity?.entityName

  const editMode = groupsState.groupModal === 'edit'
  const enabled = setting['enabled']
  const messageSlider = setting['messages']
  const timeSlider = setting['time']

  useEffect(() => {
    if (
      !setting['events'] &&
      !setting['volumeEnabled'] &&
      !setting['statusEnabled'] &&
      setting['enabled']
    ) {
      toggleSettings({ id: config.id, enable: false })
    }
  }, [setting])

  const checkboxes = [
    {
      value: 'email',
      label: 'Email',
    },
    {
      value: 'sms',
      label: 'SMS',
    },
  ]

  const timeframeOptions = [
    {
      value: 'h',
      inputDisplay: 'Hours',
    },
    {
      value: 'd',
      inputDisplay: 'Days',
    },
  ]

  const sliderSwitch = () => {
    if (
      typeof setting['messages'] === 'number' ||
      typeof setting['messages'] === 'string'
    ) {
      const currentValue = parseInt(messageSlider)
      const newMax =
        currentValue >= defaultMaxMessageVolume
          ? maxMessagesVolume
          : defaultMaxMessageVolume
      updateSettings({
        id: config.id,
        setting: 'messages',
        newValue: [messageSlider, newMax],
      })
    } else {
      updateSettings({
        id: config.id,
        setting: 'messages',
        newValue: messageSlider[0],
      })
    }
  }

  return (
    <EuiAccordion
      id={`notification-accordion-project-${setting.id}`}
      className='groups-noti-accordion'
      buttonContent={
        <EuiFlexGroup gutterSize='m' alignItems='center' responsive={false}>
          <EuiFlexItem>
            <EuiText
              style={
                  groupsState.notiSettingsExpand[String(name)] && {
                  fontWeight: '600',
                  textDecoration: 'underline',
                }
              }
            >
              {nameMap[String(name)] ? nameMap[String(name)] : name}
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {enabled ? (
              <EuiBeacon />
            ) : (
              <EuiIcon type='bellSlash' size='m' />
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
      }
      extraAction={
        <EuiSwitch
          checked={enabled}
          onChange={() =>
            toggleSettings({
              id: config.id,
              enable: enabled ? false : true,
            })
          }
          label=''
          disabled={!editMode}
        />
      }
    >
      <div style={{ padding: '0 8px' }}>
        <EuiFlexGroup
          gutterSize='s'
          responsive={false}
          direction='column'
          style={{ marginTop: '.5rem' }}
        >
          <EuiFlexGroup
            gutterSize='s'
            responsive={false}
            alignItems='center'
            style={{
              marginTop: '.4rem',
              minHeight: '40px',
            }}
          >
            <EuiFlexItem
              grow={false}
              style={{ opacity: setting['events'] ? 1 : 0.3 }}
            >
              <EuiIcon type='watchesApp' size='m' color={'#343741'} />
            </EuiFlexItem>
            <EuiFlexItem style={{ opacity: setting['events'] ? 1 : 0.3 }}>
              <EuiText
                color={'#343741'}
                style={{
                  fontSize: '13px',
                  letterSpacing: '1px',
                  fontWeight: '600',
                }}
              >
                <p>EVENTS</p>
              </EuiText>
            </EuiFlexItem>
            <EuiSwitch
              checked={setting['events']}
              onChange={() =>
                updateSettings({
                  id: config.id,
                  setting: 'events',
                  newValue: !setting['events'],
                })
              }
              style={{ marginLeft: 'auto', marginRight: '0' }}
              compressed
              label=''
              disabled={!editMode}
            />
          </EuiFlexGroup>

          <EuiSpacer size='xs' className='notifications-spacer' />

          <EuiFlexGroup
            gutterSize='s'
            responsive={false}
            alignItems='center'
            style={{ minHeight: '40px' }}
          >
            <EuiFlexItem
              grow={false}
              style={{ opacity: setting['volumeEnabled'] ? 1 : 0.3 }}
            >
              <EuiIcon type='visGauge' size='m' color={'#343741'} />
            </EuiFlexItem>
            <EuiFlexItem
              style={{ opacity: setting['volumeEnabled'] ? 1 : 0.3 }}
            >
              <EuiText
                size='s'
                color={'#343741'}
                style={{
                  fontSize: '13px',
                  letterSpacing: '1px',
                  fontWeight: '600',
                }}
              >
                <p>MESSAGE VOLUME</p>
              </EuiText>
            </EuiFlexItem>
            <EuiSwitch
              checked={setting['volumeEnabled']}
              onChange={() =>
                updateSettings({
                  id: config.id,
                  setting: 'volumeEnabled',
                  newValue: !setting['volumeEnabled'],
                })
              }
              style={{ marginLeft: 'auto', marginRight: '0' }}
              compressed
              label=''
              disabled={!editMode}
            />
          </EuiFlexGroup>

          {setting['volumeEnabled'] && (
            <>
              <EuiSpacer size='xs' className='notifications-spacer' />

              <EuiFlexItem>
                <EuiText>Messages</EuiText>
              </EuiFlexItem>

              <EuiFlexGroup
                gutterSize='s'
                responsive={false}
                alignItems='center'
                justifyContent='spaceBetween'
                className='notifications-message-sliders'
              >
                <EuiFlexItem style={{ margin: '8px' }} grow={false}>
                  <label>Min</label>
                </EuiFlexItem>
                {typeof setting['messages'] === 'number' ||
                typeof setting['messages'] === 'string' ? (
                  <EuiFlexGroup
                    gutterSize='s'
                    alignItems='center'
                    responsive={false}
                  >
                    <EuiFlexItem>
                      <label style={{ color: '#BEBEBF' }}>Max</label>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiButtonIcon
                        iconType='plusInCircle'
                        size='xs'
                        color='primary'
                        onClick={sliderSwitch}
                        aria-label='messages-volume-max-button'
                        disabled={!editMode}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                ) : (
                  <EuiFlexGroup
                    gutterSize='s'
                    alignItems='center'
                    responsive={false}
                  >
                    <EuiFlexItem>
                      <label>Max</label>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiButtonIcon
                        iconType='trash'
                        size='xs'
                        color='primary'
                        onClick={sliderSwitch}
                        aria-label='messages-volume-max-button'
                        disabled={!editMode}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                )}
              </EuiFlexGroup>

              <EuiFlexItem style={{ margin: '.5rem' }}>
                {typeof setting['messages'] === 'number' ||
                typeof setting['messages'] === 'string' ? (
                  <>
                    <EuiFlexGroup gutterSize='m'>
                      <EuiFlexItem grow={false}>
                        <EuiFieldNumber
                          max={maxMessagesVolume}
                          value={messageSlider}
                          style={{ width: '6em' }}
                          onChange={e => updateSettings({
                            id: config.id,
                            setting: 'messages',
                            newValue: e.target.value,
                          })}
                          disabled={!editMode}
                        />
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <EuiRange
                          id={`messages-single-slider-${config.id}`}
                          value={messageSlider}
                          aria-label='Messages volume slider'
                          fullWidth
                          max={maxMessagesVolume}
                          onChange={e => updateSettings({
                            id: config.id,
                            setting: 'messages',
                            newValue: e.target.value,
                          })}
                          disabled={!editMode}
                        />
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </>
                ) : (
                  <EuiDualRange
                    id={`messages-dual-slider-${config.id}`}
                    value={messageSlider}
                    // onChange={onChangeMessageSlider}
                    showInput
                    minInputProps={{ 'aria-label': 'Min value' }}
                    maxInputProps={{ 'aria-label': 'Max value' }}
                    aria-label='Messages volume dual slider'
                    fullWidth
                    max={maxMessagesVolume}
                    onChange={e => updateSettings({
                      id: config.id,
                      setting: 'messages',
                      newValue: e,
                    })}
                    disabled={!editMode}
                  />
                )}
              </EuiFlexItem>

              <EuiFlexItem>
                <EuiText>Time Frame</EuiText>
              </EuiFlexItem>

              <EuiFlexGroup
                gutterSize='s'
                responsive={false}
                alignItems='center'
                style={{ margin: '.5rem' }}
              >
                <EuiFlexItem>
                  <EuiRange
                    id={`time-frame-slider-${config.id}`}
                    value={timeSlider}
                    showInput
                    aria-label='Timeframe volume slider'
                    onChange={e => updateSettings({
                      id: config.id,
                      setting: 'time',
                      newValue: e.target.value,
                    })}
                    disabled={!editMode}
                  />
                </EuiFlexItem>
                <EuiSuperSelect
                  className='time-frame-select'
                  options={timeframeOptions}
                  valueOfSelected={setting['timeInterval']}
                  onChange={(selected) =>
                    updateSettings({
                      id: config.id,
                      setting: 'timeInterval',
                      newValue: selected,
                    })
                  }
                  disabled={!editMode}
                />
              </EuiFlexGroup>
            </>
          )}

          <EuiSpacer size='xs' className='notifications-spacer' />

          <EuiFlexGroup
            gutterSize='s'
            responsive={false}
            alignItems='center'
            style={{ minHeight: '40px' }}
          >
            <EuiFlexItem
              grow={false}
              style={{ opacity: setting['statusEnabled'] ? 1 : 0.3 }}
            >
              <EuiIcon type='alert' size='m' color={'#343741'} />
            </EuiFlexItem>
            <EuiFlexItem
              style={{ opacity: setting['statusEnabled'] ? 1 : 0.3 }}
            >
              <EuiText
                size='s'
                color={'#343741'}
                style={{
                  fontSize: '13px',
                  letterSpacing: '1px',
                  fontWeight: '600',
                }}
              >
                <p>STATUS</p>
              </EuiText>
            </EuiFlexItem>
            <EuiSwitch
              checked={setting['statusEnabled']}
              onChange={() =>
                updateSettings({
                  id: config.id,
                  setting: 'statusEnabled',
                  newValue: !setting['statusEnabled'],
                })
              }
              style={{ marginLeft: 'auto', marginRight: '0' }}
              compressed
              label=''
              disabled={!editMode}
            />
          </EuiFlexGroup>

          <EuiSpacer size='xs' className='notifications-spacer' />

          {setting['statusEnabled'] && (
            <>
              <EuiBadgeGroup
                className='notification-badges'
                style={{ display: 'flex' }}
              >
                <EuiBadge
                  className='notification-badge'
                  iconType={setting['healthy'] ? 'check' : ''}
                  color='#177C73'
                  onClick={() =>
                    updateSettings({
                      id: config.id,
                      setting: 'healthy',
                      newValue: !setting['healthy'],
                    })
                  }
                  onClickAriaLabel={'Update healthy setting'}
                  disabled={!editMode}
                >
                  Healthy
                </EuiBadge>
                <EuiBadge
                  className='notification-badge'
                  iconType={setting['warning'] ? 'check' : ''}
                  color='#EFA600'
                  onClick={() =>
                    updateSettings({
                      id: config.id,
                      setting: 'warning',
                      newValue: !setting['warning'],
                    })
                  }
                  onClickAriaLabel={'Update warning setting'}
                  disabled={!editMode}
                >
                  Warning
                </EuiBadge>
                <EuiBadge
                  className='notification-badge'
                  iconType={setting['critical'] ? 'check' : ''}
                  color='#B72B20'
                  onClick={() =>
                    updateSettings({
                      id: config.id,
                      setting: 'critical',
                      newValue: !setting['critical'],
                    })
                  }
                  onClickAriaLabel={'Update critical setting'}
                  disabled={!editMode}
                >
                  Critical
                </EuiBadge>
              </EuiBadgeGroup>
            </>
          )}

          {enabled && (
            <div>
              <EuiFlexGroup
                gutterSize='s'
                responsive={false}
                alignItems='center'
                style={{ minHeight: '40px' }}
              >
                <EuiFlexItem grow={false}>
                  <EuiIcon type='bell' size='m' />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText
                    size='s'
                    color={'#343741'}
                    style={{
                      fontSize: '13px',
                      letterSpacing: '1px',
                      fontWeight: '600',
                    }}
                  >
                    <p>NOTIFICATION</p>
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiSpacer size='xs' className='notifications-spacer' />

              <EuiFlexItem>
                <EuiText size='s' textAlign='left'>Type</EuiText>
              </EuiFlexItem>

              <EuiFlexGroup
                responsive={false}
                alignItems='center'
                justifyContent='spaceBetween'
                style={{ padding: '.5rem' }}
              >
                <EuiFlexItem grow={false}>
                  <EuiFlexGroup gutterSize='s'>
                    {checkboxes.map(({ label, value }, i) => (
                      <EuiFlexItem key={`notifications-project-check-${i}`}>
                        {keycloak && keycloak.tokenParsed[String(value)] ? (
                          <EuiCheckbox
                            disabled={!enabled || !editMode}
                            id={`project-check-${config.id}-${value}`}
                            label={label}
                            checked={enabled ? setting[String(value)] : false}
                            onChange={() =>
                              updateSettings({
                                id: config.id,
                                setting: value,
                                newValue: !setting[String(value)],
                              })
                            }
                          />
                        ) : (
                          <EuiToolTip
                            position='bottom'
                            content='Update your personal information using "Edit profile" located in the top right user menu to enable this feature.'
                          >
                            <EuiCheckbox
                              disabled={true}
                              id={`project-check-${config.id}-${value}`}
                              label={label}
                              checked={false}
                              onChange={() => {}}
                            />
                          </EuiToolTip>
                        )}
                      </EuiFlexItem>
                    ))}
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          )}
        </EuiFlexGroup>
      </div>
    </EuiAccordion>
  )
}

export default NotiSettingsItem
