const scoreMap = {
  processed: 2,
  rejected: 2,
  processing: 1,
  errors: 0,
}
const maximumScore = 2

const entityTypeFormatted = {
  project: 'Project',
  useCase: 'Use Case',
  sender: 'Sender',
  recipient: 'Recipient',
}

const healthScoreExplanation =
  'Health scores are calcuated based on how many messages are able to reach terminal statuses such as Processed.'

const scoreToStatus = ({ score, entityType = 'Entity', entityName }) => {
  entityType = entityTypeFormatted[String(entityType)] || entityType
  if (score < 60) {
    return {
      health: 'critical',
      healthMeter: score,
      message: `Messages for ${entityType} ${entityName} in the given time range show a health score of ${score}%, which is in the Critical range of below 60%. ${healthScoreExplanation}`,
    }
  }
  if (score < 90) {
    return {
      health: 'warning',
      healthMeter: score,
      message: `Messages for ${entityType} ${entityName} in the given time range show a health score of ${score}%, which is in the Warning range of 60% to 90%. ${healthScoreExplanation}`,
    }
  }
  if (score >= 90) {
    return {
      health: 'good',
      healthMeter: score,
      message: `Messages for ${entityType} ${entityName} in the given time range show a health score of ${score}%, which is in the Good range of 90% or above. ${healthScoreExplanation}`,
    }
  }
  return {
    health: 'unknown',
    healthMeter: 0,
    message: `No messages found for ${entityType} ${entityName} matching the given filter.`,
  }
}

const calculateScore = (counts) => {
  let maximumTotalScore = 0

  return Math.round(
    (Object.keys(counts).reduce((sum, key) => {
      sum += counts[String(key)] * (scoreMap[String(key)] || 0)
      maximumTotalScore += counts[String(key)] * maximumScore
      return sum
    }, 0) /
      maximumTotalScore) *
      100
  )
}

export const evaluateStatusCounts = ({
  entityType = 'project',
  entityName = '',
  counts = {},
}) => {
  const score = calculateScore(counts)
  return scoreToStatus({ score, entityType, entityName })
}
