import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'

import {
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiFieldText,
  EuiFormRow,
  EuiPanel,
  EuiDatePicker,
  EuiButton,
} from '@elastic/eui'

import { AppContext } from '../../app'
import { formatDateTimeWithZone } from '../../lib'
import api from '../../lib/api'

export const OfflineStatus = ({ onClose, data, title, initPicker, entityName, entityType }) => {
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment().add(1, 'day'))
  const [picker, setPicker] = useState('')
  const [loading, setLoading] = useState(false)

  const { toaster, fetchOffline } = useContext(AppContext)

  const saveOffline = async () => {
    setLoading(true)
    try {
      const data = {
        start: startDate.unix(),
        end: endDate.unix()
      }
      await api.updateOffline(entityType, entityName, data)
      await fetchOffline()
      onClose()
    }
    catch (e) {
      console.log(e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem saving changes.',
      })
    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (data?.StartTime) {
      setStartDate(moment.unix(data.StartTime))
    }
    if (data?.EndTime) {
      setEndDate(moment.unix(data.EndTime))
    }
  }, [data])

  useEffect(() => {
    if (initPicker) {
      setPicker(initPicker)
    }
  }, [])

  const togglePicker = (value) => {
    if (picker === value) {
      setPicker('')
    } else {
      setPicker(value)
    }
  }

  const startDateFld = (
    <EuiFieldText
      icon='calendar'
      placeholder='Start Date'
      value={`${formatDateTimeWithZone(startDate)}`}
      style={{ borderRadius: '2px', width: '100%' }}
      onClick={() => togglePicker('start')}
      onChange={() => { }}
      fullWidth
    />
  )

  const endDateFld = (
    <EuiFieldText
      icon='calendar'
      placeholder='End Date'
      value={`${formatDateTimeWithZone(endDate)}`}
      style={{ borderRadius: '2px', width: '100%' }}
      onClick={() => togglePicker('end')}
      onChange={() => { }}
      fullWidth
    />
  )

  const renderStart = () => {
    if (picker === 'start') {
      return (
        <EuiPanel style={{ backgroundColor: 'rgba(211, 218, 230, 0.2)' }}>
          <EuiFormRow className='offline-calendar-button'>
            {startDateFld}
          </EuiFormRow>
          <EuiFormRow>
            <EuiDatePicker
              selected={startDate}
              onChange={v => setStartDate(v)}
              inline
              showTimeSelect
            />
          </EuiFormRow>
        </EuiPanel>
      )
    }
    return (<>{startDateFld}</>)
  }

  const renderEnd = () => {
    if (picker === 'end') {
      return (
        <EuiPanel style={{ backgroundColor: 'rgba(211, 218, 230, 0.2)' }}>
          <EuiFormRow className='offline-calendar-button'>
            {endDateFld}
          </EuiFormRow>
          <EuiFormRow>
            <EuiDatePicker
              selected={endDate}
              onChange={v => setEndDate(v)}
              inline
              showTimeSelect
            />
          </EuiFormRow>
        </EuiPanel>
      )
    }
    return (<>{endDateFld}</>)
  }

  return (
    <>
      <EuiModal
        onClose={onClose}
        style={{
          width: '30rem',
          padding: '32px',
        }}
      >
        <EuiModalHeader style={{ padding: 0 }}>
          <EuiFlexGroup gutterSize='none' direction='column'>
            <EuiFlexItem>
              <EuiModalHeaderTitle
                style={{
                  fontWeight: '600',
                  fontSize: '24px',
                  lineHeight: '2rem'
                }}
              >
                Schedule Offline Status
              </EuiModalHeaderTitle>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText color='subdued'>{title}</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiModalHeader>

        <EuiSpacer
          size='xs'
          style={{
            backgroundColor: 'rgb(226, 230, 240)',
            height: '1px',
            margin: '16px 0'
          }}
        />

        <EuiFormRow label='Offline start date' className='offline-calendar-button'>
          {renderStart()}
        </EuiFormRow>
        <EuiFormRow label='Offline end date' className='offline-calendar-button'>
          {renderEnd()}
        </EuiFormRow>

        <EuiSpacer />

        <EuiButton
          aria-label='Save'
          iconType='save'
          fill
          color='secondary'
          onClick={saveOffline}
          isLoading={loading}
          isDisabled={startDate >= endDate}
        >
          Save
        </EuiButton>
      </EuiModal>
    </>
  )
}
