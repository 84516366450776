import React, { useContext, useState } from 'react'

import { GroupsPageContext } from './groups_page'
import { AppContext } from '../../app'

import {
  EuiIcon,
  EuiSpacer,
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableFooter,
  EuiTableFooterCell,
  EuiText,
  EuiAvatar,
  EuiCheckbox,
  EuiPanel,
  EuiProgress,
  EuiButtonIcon,
  EuiToolTip
} from '@elastic/eui'

import { InView } from 'react-intersection-observer'

import { LEFT_ALIGNMENT, CENTER_ALIGNMENT, RIGHT_ALIGNMENT } from '@elastic/eui/lib/services'

export const GroupUsersList = ({
  users,
  onSelect,
  loading,
  loadUsers,
  noMoreUsers,
  filteredUsers,
  search
}) => {
  const { groupsState, groupsDispatch } = useContext(GroupsPageContext)
  const { keycloak } = useContext(AppContext)

  const [hoverRow, setHoverRow] = useState('')

  const mode = groupsState.groupModal

  const isSelected = (id) => users.find(u => (u.id === id) && u.selected)

  const updateManager = (userId, user) => {
    if (!isSelected(userId)) {
      onSelect(userId, user)
    }
    groupsDispatch({ type: 'update_group_manager', userId })
  }

  const removeManager = () => groupsDispatch({ type: 'remove_group_manager' })

  const getRows = () => {
    if (mode === 'create') {
      return users
    }
    if (mode === 'view') {
      if (filteredUsers) {
        return filteredUsers
      }
      return groupsState.activeGroup.users?.map(u => u.user)
    }
    if (mode === 'edit') {
      return users
    }
    return []
  }

  const getColumns = () => {
    const columns = [
      {
        id: 'avatar',
        label: '',
        alignment: CENTER_ALIGNMENT,
        width: '40px',
        textOnly: false,
        className: 'group-users-icon',
        render: (col, row) => {
          const av = row.name || row.user?.name || 'unknown'
          return (
            <EuiAvatar name={av} size='s' />
          )
        },
        mobileOptions: {
          show: false,
        },
      },
      {
        id: 'user',
        label: 'User',
        alignment: LEFT_ALIGNMENT,
        truncateText: true,
        render: (col, row) => {
          return (
            <>{row.name}</>
          )
        }
      },
      {
        id: 'email',
        label: 'Email',
        alignment: LEFT_ALIGNMENT,
        truncateText: true,
        render: (col, row) => {
          return (
            <>{row.email}</>
          )
        }
      },
      {
        id: 'role',
        label: 'Role',
        alignment: LEFT_ALIGNMENT,
        truncateText: true,
        render: (col, row) => {
          const manager = row.id === groupsState.activeGroup.managers[0]?.userId
          if (mode !== 'view' && !isSelected(row.id)) {
            return (
              <></>
            )
          }
          if (manager) {
            return (
              <EuiText color='#006BB4' size='s'>
                Manager
              </EuiText>
            )
          }
          return <>Member</>
        }
      },
    ]
    if (mode === 'create') {
      columns.unshift({
        id: 'checkbox',
        isCheckbox: true,
        width: '32px',
        render: (col, row) => {
          return (
            <EuiCheckbox
              id={`groups-users-${row.id}-checkbox`}
              checked={row.selected}
              onChange={() => onSelect(row.id, row)}
              type='inList'
            />
          )
        }
      })
    }
    if (mode === 'edit') {
      columns.unshift({
        id: 'checkbox',
        isCheckbox: true,
        width: '32px',
        render: (col, row) => {
          return (
            <EuiCheckbox
              id={`groups-users-${row.id}-checkbox`}
              checked={row.selected}
              onChange={() => onSelect(row.id, row)}
              type='inList'
            />
          )
        }
      })
    }
    if (mode === 'edit' || mode === 'create') {
      columns.push({
        id: 'popover',
        label: '',
        alignment: RIGHT_ALIGNMENT,
        render: (col, row) => {
          if (!keycloak.hasClientRole({ roleName: 'edit' })) {
            return <></>
          }
          const manager = row.id === groupsState.activeGroup.managers[0]?.userId
          if (hoverRow === row.id) {
            if (manager) {
              return (
                <EuiToolTip position='left' content='Remove Manager'>
                  <EuiButtonIcon
                    color='primary'
                    onClick={() => removeManager()}
                    iconType='starMinusEmpty'
                    aria-label='remove mananger'
                  />
                </EuiToolTip>
              )
            }
            return (
              <EuiToolTip position='left' content='Assign Manager'>
                <EuiButtonIcon
                  color='primary'
                  onClick={() => updateManager(row.id, row)}
                  iconType='starPlusEmpty'
                  aria-label='add manager'
                />
              </EuiToolTip>
            )
          }
          return <></>
        }
      })
    }
    return columns
  }

  const renderHeaderCells = () => {
    const headers = []
    const columns = getColumns()
    columns.forEach((column, columnIndex) => {
      headers.push(
        <EuiTableHeaderCell
          style={{ padding: 0 }}
          key={column.id}
          align={columns[String(columnIndex)].alignment}
          width={column.width}
          className='group-users-header fade-up'
        >
          {column.label}
        </EuiTableHeaderCell>
      )
    })
    return headers.length ? headers : null
  }

  const renderRows = () => {
    const columns = getColumns()
    const rows = getRows()
    const renderRow = (row, rowIdx) => {
      const cells = columns.map((column) => {
        const cellData = row[column.id]

        let child

        if (column.render) {
          child = column.render(column, row)
        } else {
          child = cellData
        }

        return (
          <EuiTableRowCell
            key={column.id}
            align={column.alignment}
            className={`${column.className} fade-up`}
            truncateText
            textOnly={cellData ? cellData.textOnly : true}
            mobileOptions={{
              header: column.label,
              ...column.mobileOptions,
            }}
            style={{ textAlign: 'left' }}
          >
            {child}
          </EuiTableRowCell>
        )
      })
      return (
        <EuiTableRow
          key={`user-group-row-${row.id}-${rowIdx}`}
          onMouseEnter={() => setHoverRow(row.id)}
          onMouseLeave={() => setHoverRow('')}
          className='fade-up'
        >
          {cells}
        </EuiTableRow>
      )
    }

    if (!rows?.length) {
      return (
        <EuiTableRow>
          <EuiTableRowCell
            colSpan={columns.length}
            align='center'
            style={{ padding: '2rem', border: 'none' }}
          >
            <EuiIcon type='users' size='l' color='#4A4D55' />
            <EuiSpacer size='s' />
            {filteredUsers || search
              ? <EuiText color='#4A4D55'>
                  No results found
                </EuiText>
              : <EuiText color='#4A4D55'>
                  No users in group
                </EuiText>
            }
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    return rows.map(renderRow)
  }

  const renderFooter = () => {
    if (mode === 'view') {
      return <></>
    }
    if (noMoreUsers.current) {
      return <></>
    }
    const columns = getColumns()
    return (
      <EuiTableFooter className='group-users-table-footer'>
        <EuiTableFooterCell colSpan={columns.length}>
          <InView
            onChange={(inView) => {
              if (inView && users?.length) {
                loadUsers({ add: true })
              }
            }}
          >
          </InView>
        </EuiTableFooterCell>
      </EuiTableFooter>
    )
  }

  return (
    <>
      <EuiPanel className='groups-table-panel' hasShadow={false}>
        <EuiTable id='user-groups-table'>
          <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>
          <EuiTableBody>{renderRows()}</EuiTableBody>
          {renderFooter()}
        </EuiTable>
      </EuiPanel>
      {loading && <EuiProgress size='xs' color='primary' />}
    </>
  )
}
