import React from 'react'

import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiText,
} from '@elastic/eui'

export const ProjectDataModal = ({ title, content, onClose }) => {
  const getContentList = () => {
    if (typeof content !== 'string') {
      return content
    }
    const splitStr = '#######################'
    const replaced = content.replaceAll(/;|\n/g, match => {
      return match === ';' ? `;${splitStr}` : splitStr
    })
    return replaced.split(splitStr)
  }

  const renderText = () => {
    if (!content) {
      return <></>
    }
    const items = getContentList()
    return items.map((item, idx) => (
      <EuiText
        style={{ fontSize: '.9em', textAlign: 'left', overflow: 'scroll' }}
        key={`project-data-text-${idx}`}
      >
        {item}
      </EuiText>
    ))
  }

  return (
    <>
      <EuiOverlayMask>
        <EuiModal
          style={{
            minWidth: 500,
            maxWidth: 700,
            minHeight: 200,
            maxHeight: 600,
            textAlign: 'center'
          }}
          onClose={onClose}
        >
          <EuiModalHeader>
            <EuiText style={{ fontWeight: 600, fontSize: '1.1em' }}>
              {title}
            </EuiText>
          </EuiModalHeader>

          <EuiModalBody>
            {renderText()}
          </EuiModalBody>

        </EuiModal>
      </EuiOverlayMask>
    </>
  )
}
