import React from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPanel,
  EuiText,
} from '@elastic/eui'

export const EtorErrorsList = ({ errorMessage }) => {
  const hasErrorMessage = errorMessage?.length
  const headerText = hasErrorMessage ? 'Error Description' : 'No Errors'
  const headerIcon = hasErrorMessage ? 'alert' : 'check'

  return (
    <EuiPanel
      style={{
        border: 'none',
        padding: '0 0 0 32px',
        boxShadow: 'none',
        minHeight: 'auto',
      }}
    >
      <EuiFlexGroup
        direction="column"
        alignItems="flexStart"
        justifyContent="center"
        gutterSize="none"
      >
        <EuiFlexItem>
          <label style={{ fontWeight: 500 }}>
            <EuiIcon type={headerIcon} /> {headerText}
          </label>
          {hasErrorMessage && (
            <EuiFlexGroup
              gutterSize="s"
              style={{ padding: '8px', alignItems: 'center' }}
            >
              <EuiFlexItem grow={false}>
                <EuiText>{errorMessage}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}
