import React, { useContext, useEffect, useState } from 'react'

import {
  EuiFlexGroup,
  EuiOverlayMask,
  EuiFlexItem,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiText,
  EuiSpacer,
  EuiAvatar,
  EuiForm,
  EuiIcon,
  EuiComboBox,
  EuiSuperSelect,
  EuiFieldText,
  EuiButtonEmpty,
  EuiBadge,
} from '@elastic/eui'

import { UserRules } from './user_rules'
import { RolesList } from './roles_list'
import { GroupsList } from './groups_list'
import { ConfirmUnsaved } from '../modals'
import { AppContext } from '../../app'
import { AdminPageContext } from './admin_page'
import { mapTagNamesForUser } from './util'
import { api, config } from '../../lib'

const CLIENT_ID = config.KC_CLIENT_ID

export const UserForm = () => {
  const { toaster, users, keycloak } = useContext(AppContext)
  const {
    adminState,
    adminDispatch,
    searchTagOpts,
    findAndUpdateUser,
    loadUsers
  } = useContext(AdminPageContext)

  const user = adminState.activeUser

  const [selectedTags, setSelectedTags] = useState([])
  const [selectedRoles, setSelectedRoles] = useState([])
  const [roleOptions, setRoleOptions] = useState([])
  const [effectRoles, setEffectRoles] = useState([])
  const [assignedRoles, setAssignedRoles] = useState([])
  const [origRoles, setOrigRoles] = useState([])
  const [cancelModal, setCancelModal] = useState(null)
  const [loading, setLoading] = useState(false)

  const [rules, setRules] = useState([])

  const showModal = Boolean(adminState.userModal)

  const onClose = () => {
    adminDispatch({ type: 'close_modal' })
    setRoleOptions([])
    setEffectRoles([])
    setAssignedRoles([])
    setSelectedRoles([])
  }

  const changeMode = (value) => {
    adminDispatch({ type: 'set', state: 'userModal', value })
  }

  const checkChanges = (origUser) => {
    const oUser = {...origUser}
    oUser.tags = oUser.tags.map(t => t.tag?.id || t.id).sort()
    const aUser = {...user}
    aUser.tags = aUser.tags.map(t => t.tag?.id || t.id).sort()
    const origUsrStr = JSON.stringify(oUser)
    const activeUsrStr = JSON.stringify(aUser)
    if (origUsrStr !== activeUsrStr) {
      return true
    }
    const origRolesStr = JSON.stringify(origRoles.map(o => o.id).sort())
    const selRolesStr = JSON.stringify(selectedRoles.map(s => s.id).sort())
    if (origRolesStr !== selRolesStr) {
      return true
    }
    return false
  }

  const cancelEdit = () => {
    const origUser = users.find(({ id }) => user.id === id)
    if (!origUser) return

    const changes = checkChanges(origUser)
    if (changes) {
      setCancelModal(
        <ConfirmUnsaved
          onCancel={() => setCancelModal(null)}
          onConfirm={() => {
            setCancelModal(null)
            adminDispatch({ type: 'view_user', user: origUser })
          }}
        />
      )
    } else {
      adminDispatch({ type: 'view_user', user: origUser })
    }
  }

  const viewUser = (user) => {
    adminDispatch({ type: 'view_user', user })
  }

  const saveNewUser = async () => {
    setLoading(true)
    try {
      const roles = {}
      roles[String(CLIENT_ID)] = selectedRoles.map(r => {
        return {
          id: r.id,
          name: r.name
        }
      })
      const resp = await api.createUser({
        ...user,
        roles
      })
      toaster({
        color: 'success',
        title: 'User Created',
        text: resp.message,
      })
      loadUsers({ reset: true })
      onClose()
    }
    catch (err) {
      toaster({
        color: 'danger',
        title: `Unable to create user.`,
        text: err.message,
      });
    }
    finally {
      setLoading(false)
    }
  }

  const saveUser = async () => {
    setLoading(true)
    try {
      const roles = {}
      roles[String(CLIENT_ID)] = selectedRoles.map(r => {
        return {
          id: r.id,
          name: r.name
        }
      })
      const tags = selectedTags.map(ut => ut.tag ? ut.tag.id : ut.id)
      const rules = user.rules.filter(r => r.principalType === 'user')
      const data = {
        email: user.email,
        id: user.id,
        name: user.name,
        phoneNumber: user.phoneNumber,
        rules,
        roles,
        tags
      }
      await api.updateUser(data)
      const resp = await api.fetchUser(user.id)
      const updatedUser = resp.data
      findAndUpdateUser(resp.data)
      viewUser(updatedUser)
      toaster({
        color: 'success',
        title: 'Your changes were successfully saved.',
      })
    }
    catch(err) {
      console.log(err)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem saving your changes',
      });
    }
    finally {
      setLoading(false)
    }
  }

  const rolesToOpts = (roles) => roles.map(r => {
    return {
      ...r,
      id: r.id,
      name: r.name,
      compositeRoles: r.compositeRoles,
      label: r.name
    }
  })

  const getAvailRoles = async (userId='') => {
    try {
      let resp = null
      if (userId) {
        resp = await api.getAvailUserRoles(CLIENT_ID, user.id)
      } else {
        const params = { clients: [CLIENT_ID] }
        resp = await api.getAvailRoles(params)
      }
      const roles = resp.data[String(CLIENT_ID)]
      if (roles.available?.length) {
        setRoleOptions(rolesToOpts(roles.available))
      }
      if (roles.assigned?.length) {
        setSelectedRoles(rolesToOpts(roles.assigned))
        setAssignedRoles(rolesToOpts(roles.assigned))
        setOrigRoles(roles.assigned)
      }
    }
    catch(err) {
      toaster({
        color: 'danger',
        title: `Oops!`,
        text: err.message,
      });
    }
  }

  const getUserRoles = async () => {
    try {
      const resp = await api.getUserRoles(user.id)
      const roles = resp.data[String(CLIENT_ID)]
      if (!roles?.length) {
        return
      }
      setEffectRoles(roles)
    }
    catch(err) {
      toaster({
        color: 'danger',
        title: `Oops!`,
        text: err.message,
      })
    }
  }

  const getClientRoles = async () => {
    try {
      const params = { clients: [CLIENT_ID] }
      const resp = await api.getAvailRoles(params)
      const roles = resp.data[String(CLIENT_ID)].roles
      if (!roles?.length) {
        return
      }
      setRoleOptions(rolesToOpts(roles))
    }
    catch(err) {
      toaster({
        color: 'danger',
        title: `Oops!`,
        text: err.message,
      })
    }
  }

  const updateRoleOpts = (role) => {
    const existingRole = roleOptions.find(({ id }) => id === role.id)
    if (!existingRole) {
      const newOpts = [...roleOptions, { ...role, label: role.name }]
      setRoleOptions(newOpts)
    }
  }

  const updateEffectiveRoles = (roles) => {
    const roleMap = new Map()
    for (const r of roles) {
      roleMap[r.id] = r
      if (r.compositeRoles) {
        for (const cr of r.compositeRoles) {
          roleMap[cr.id] = cr
        }
      }
    }
    const effectiveRoles = Object.values(roleMap)
    setEffectRoles(effectiveRoles)
  }

  const updateSelectedRoles = (selectedRoles) => {
    for (let ar of assignedRoles) {
      const existingRole = selectedRoles.find(({ id }) => id === ar.id)
      if (!existingRole) {
        updateRoleOpts(ar)
      }
    }
    setSelectedRoles(selectedRoles)
    updateEffectiveRoles(selectedRoles)
  }

  const updateSelectedTags = (selectedTags) => {
    setSelectedTags(selectedTags)
    adminDispatch({ type: 'select_user_tags', tags: selectedTags })
  }

  const updateField = (field, value) => {
    adminDispatch({ type: 'user_form', field, value })
  }

  function buildRulesList() {
    let rules = []

    if (user.rules && user.rules.length > 0) {
      rules = [...rules, ...user.rules]
    }
    if (selectedTags?.length) {
      for (const t of selectedTags) {
        if (!t.rules?.length) continue
        rules = [...rules, ...t.rules]
      }
    }

    return rules
  }

  useEffect(() => {
    if (!adminState.tagOptions) {
      searchTagOpts()
    }
  }, [adminState.tagOptions])

  useEffect(() => {
    setSelectedTags([
      ...mapTagNamesForUser(user)
    ])
    setRules(buildRulesList())
  }, [user])

  useEffect(() => {
    setRules(buildRulesList())
  }, [selectedTags])

  useEffect(() => {
    if (adminState.userModal === 'view') {
      getUserRoles()
    }
    if (adminState.userModal === 'edit') {
      getAvailRoles(user.id)
    }
    if (adminState.userModal === 'create') {
      getClientRoles()
    }
  }, [adminState.userModal])

  const invalidForm = () => {
    if (!user.firstName) {
      return true
    }
    if (!user.lastName) {
      return true
    }
    if (!user.email) {
      return true
    }
    return false
  }

  const invalidEditForm = () => {
    if (!user.name || user.name == ' ') {
      return true
    }
    if (!user.email) {
      return true
    }
    return false
  }

  if (!showModal) return null

  const renderHeader = () => {
    switch (adminState.userModal) {
      case 'create':
        return (
          <EuiModalHeader className='admin-modal-header'>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='cross'
                  onClick={onClose}
                >
                  Close
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText style={{ fontWeight: 500, fontSize: '1.4em', margin: 'auto' }}>
                  Create New User
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='save'
                  onClick={saveNewUser}
                  disabled={invalidForm() ? true : false}
                  isLoading={loading}
                >
                  Save
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalHeader>
        )
      case 'view':
        return (
          <EuiModalHeader className='admin-modal-header'>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='cross'
                  onClick={onClose}
                >
                  Close
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem style={{ alignItems: 'center' }}>
                <EuiAvatar name={user.avatar} size='l' />
              </EuiFlexItem>
              {keycloak.hasClientRole({ roleName: 'edit' }) ?
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty
                    size='s'
                    color='primary'
                    iconType='pencil'
                    onClick={() => changeMode('edit')}
                  >
                    Edit
                  </EuiButtonEmpty>
                </EuiFlexItem> :
                <EuiFlexItem grow={false} style={{ width: '5rem' }} />
              }
            </EuiFlexGroup>
          </EuiModalHeader>
        )
      case 'edit':
        return (
          <EuiModalHeader className='admin-modal-header'>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='cross'
                  onClick={() => cancelEdit()}
                >
                  Cancel
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem style={{ alignItems: 'center' }}>
                <EuiAvatar name={user.avatar} size='l' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='save'
                  onClick={saveUser}
                  disabled={invalidEditForm() ? true : false}
                  isLoading={loading}
                >
                  Save
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalHeader>
        )
    }
  }

  const renderName = () => {
    if (adminState.userModal === 'view') {
      return (
        <>
          <EuiFlexGroup gutterSize='none' style={{ flexDirection: 'column' }}>
            <label>Name</label>
            <EuiFlexGroup gutterSize='s' style={{ padding: '0 8px', alignItems: 'center' }}>
              <EuiFlexItem grow={false}>
                <EuiIcon type='user' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText style={{ fontWeight: 500 }}>{user.name}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGroup>
          <EuiSpacer size='xl'/>
          <EuiFlexGroup gutterSize='none' style={{ flexDirection: 'column' }}>
            <label>Email</label>
            <EuiFlexGroup gutterSize='s' style={{ padding: '0 8px', alignItems: 'center' }}>
              <EuiFlexItem grow={false}>
                <EuiIcon type='email' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText style={{ fontWeight: 500 }}>{user.email}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGroup>
        </>
      )
    }
    if (adminState.userModal === 'create') {
      return (
        <>
          <EuiFlexGroup gutterSize='none'>
            <EuiFlexItem>
              <label>Name</label>
              <EuiFlexGroup gutterSize='s'>
                <EuiFlexItem>
                  <EuiFieldText
                    icon='user'
                    fullWidth
                    placeholder='First Name'
                    onChange={e => updateField('firstName', e.target.value)}
                    value={user.firstname}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFieldText
                    icon='user'
                    fullWidth
                    placeholder='Last Name'
                    onChange={e => updateField('lastName', e.target.value)}
                    value={user.lastName}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiFlexGroup gutterSize='none'>
            <EuiFlexItem>
              <label>Email</label>
              <EuiFieldText
                icon='email'
                fullWidth
                placeholder='Email'
                onChange={(e) => updateField('email', e.target.value)}
                value={user.email}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      )
    }
    if (adminState.userModal === 'edit') {
      return (
        <>
          <EuiFlexGroup gutterSize='none'>
            <EuiFlexItem>
              <label>Name</label>
              <EuiFlexGroup gutterSize='s'>
                <EuiFlexItem>
                  <EuiFieldText
                    icon='user'
                    fullWidth
                    placeholder='First Name'
                    onChange={(e) => {
                      const name = e.target.value
                      const v = `${name} ${
                        user.name.includes(' ') ? user.name.split(' ')[user.name.split(' ').length - 1] : user.name
                      }`
                      updateField('name', v)
                    }}
                    value={user.name.includes(' ') ? user.name.split(' ')[0] : user.name}
                  />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFieldText
                    icon='user'
                    fullWidth
                    placeholder='Last Name'
                    onChange={(e) => {
                      const name = e.target.value
                      const v = `${
                        user.name.includes(' ') ? user.name.split(' ')[0] : user.name
                      } ${name}`
                      updateField('name', v)
                    }}
                    value={
                      user.name.includes(' ')
                        ? user.name.split(' ')[user.name.split(' ').length - 1]
                        : ''
                    }
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
          <EuiFlexGroup gutterSize='none'>
            <EuiFlexItem>
              <label>Email</label>
              <EuiFieldText
                icon='email'
                fullWidth
                placeholder='Email'
                onChange={(e) => updateField('email', e.target.value)}
                value={user.email}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      )
    }
  }

  const renderTags = () => {
    if (adminState.userModal === 'view') {
      if (!user.tags?.length) return <></>
      return (
        <EuiFlexGroup gutterSize='none' style={{ padding: '4px 8px' }}>
          <EuiFlexItem grow={false}>
            <EuiIcon type='tag' />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup
              gutterSize='s'
              style={{ marginLeft: '8px' }}
              wrap
            >
              {user.tags.map((t, idx) => (
                <EuiFlexItem key={`view-user-tags-${t.id}-${idx}`} grow={false}>
                  <EuiBadge
                    style={{
                      backgroundColor: '#fff',
                      fontWeight: 400,
                      border: '1px solid rgb(211, 218, 230)'
                    }}
                  >
                    {t.name || t.tag.name}
                  </EuiBadge>
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
    return (
      <EuiComboBox
        placeholder='Select tags'
        options={!adminState.tagOptions?.length ? [] : adminState.tagOptions}
        selectedOptions={selectedTags}
        onChange={updateSelectedTags}
        isClearable={true}
        data-test-subj='tags-combo-box'
        fullWidth
        onSearchChange={searchTagOpts}
      />
    )
  }

  const renderClientRoles = () => {
    const options = [
      {
        value: CLIENT_ID,
        inputDisplay: CLIENT_ID,
      }
    ]
    if (adminState.userModal === 'view') {
      return
    }
    return (
      <>
        <EuiSuperSelect
          options={options}
          valueOfSelected={options[0].value}
          onChange={() => {}}
          fullWidth
        />
        <EuiSpacer size='s'/>
        <EuiComboBox
          placeholder='Select roles'
          options={roleOptions?.length ? roleOptions : []}
          selectedOptions={selectedRoles}
          onChange={updateSelectedRoles}
          isClearable={true}
          data-test-subj='roles-combo-box'
          fullWidth
        />
        <EuiSpacer size='s' />
      </>
    )
  }

  return (
    <>
      <EuiOverlayMask>
        <EuiModal
          style={{ maxWidth: 900, width: 900, height: '85vh', textAlign: 'center' }}
          onClose={onClose}
          className='admin-modal'
        >

          {renderHeader()}

          <EuiModalBody className='admin-modal-body'>
            <EuiForm className='admin-form'>
              <EuiSpacer size='s' />

              {renderName()}

              <EuiSpacer size='xl' />

              <EuiFlexGroup gutterSize='none'>
                <EuiFlexItem>
                  <label>Tags</label>
                  {renderTags()}
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiSpacer />

              {adminState.userModal !== 'create' &&
                <>
                  <EuiFlexGroup gutterSize='none'>
                    <EuiFlexItem>
                      <label>Groups</label>
                      <GroupsList groups={user.groups} />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiSpacer />
                </>
              }

              <EuiFlexGroup>
                <EuiFlexItem>
                  <label>User Rules</label>
                  <UserRules
                    rules={rules}
                    setRules={setRules}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiSpacer />

              <EuiFlexGroup>
                <EuiFlexItem>
                  <label>Client Roles</label>
                  {renderClientRoles()}
                  <RolesList roles={effectRoles} />
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiSpacer size='s'/>

            </EuiForm>
          </EuiModalBody>

          <EuiSpacer />

        </EuiModal>
      </EuiOverlayMask>
      {cancelModal}
    </>
  )
}
