import React from 'react'

import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui'

export const ConfirmDeleteMsg = ({ title, msg, onConfirm, onCancel }) => {

  return (
    <>
      <EuiOverlayMask>
        <EuiConfirmModal
          title={title}
          onCancel={onCancel}
          onConfirm={onConfirm}
          cancelButtonText='Cancel'
          confirmButtonText='Confirm'
          buttonColor='danger'
          defaultFocusedButton='confirm'
        >
          <p>{msg}</p>
        </EuiConfirmModal>
      </EuiOverlayMask>
    </>
  )

}
