import React, { useContext } from 'react'
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiButton } from '@elastic/eui'
import { AppContext } from '../../app'
import { ExportStatus } from './export_status'
import { ExportContext } from './export_tool'

export const ExportList = () => {
  const { csvExports } = useContext(AppContext)
  const { setShowForm } = useContext(ExportContext)

  const listExports = () => {
    if (!csvExports.length) {
      return null
    }
    return csvExports.map((e, idx) => (
      <ExportStatus
        key={`csv-export-${idx}`}
        id={e.id}
        filename={e.filename || e.s3Key}
        status={e.status}
        createdAt={e.created_at}
      />
    ))
  }

  const createExportButton = () => {
    return (
      <EuiFlexItem>
        <EuiButton
          iconType='document'
          style={{
            height: '2.75rem',
            margin: '.5rem 0',
            borderColor: '#E9F0F8',
            backgroundColor: '#E9F0F8',
          }}
          onClick={() => setShowForm(true)}
        >
          Create Export
        </EuiButton>
      </EuiFlexItem>
    )
  }

  return (
    <EuiFlexGroup gutterSize='none' direction='column' className='fade-up'>
      <EuiFlexItem grow={false} style={{ padding: '4px 0' }}>
        <EuiText size='s' textAlign='left' style={{ fontWeight: '500' }}>
          Exports
        </EuiText>
      </EuiFlexItem>

      <EuiFlexItem>{createExportButton()}</EuiFlexItem>

      <EuiFlexItem
        style={{
          maxHeight: 'calc(100vh - 210px)',
          overflow: 'scroll',
        }}
      >
        {listExports()}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
