import React from 'react'

import {
  EuiTable,
  EuiTableBody,
  EuiTableHeader,
  EuiTableFooter,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiPanel,
  EuiTableFooterCell,
  EuiLoadingSpinner,
  EuiProgress,
} from '@elastic/eui'

import { InView } from 'react-intersection-observer'

export const AdminTable = ({
  columns,
  items,
  tablePagination,
  loadMore,
  loading,
  actionsPopover,
  search,
  onRowClick
}) => {

  const renderHeaderCells = () => {
    const headers = []

    columns.forEach((column, columnIndex) => {
      headers.push(
        <EuiTableHeaderCell
          style={{ padding: 0 }}
          key={column.id}
          align={columns[String(columnIndex)].alignment}
          width={column.width}
          mobileOptions={column.mobileOptions}
        >
          {column.label}
        </EuiTableHeaderCell>
      )
    })
    return headers.length ? headers : null
  }

  const renderRows = () => {
    const renderRow = (item) => {
      const cells = columns.map((column) => {
        const cell = item[column.id]

        let child

        if (column.isActionsPopover) {
          return actionsPopover(column, item)
        }

        if (column.render) {
          const nameText = item.name.truncateText ? item.name.value : item.name
          const name = nameText
          child = column.render(name, item)
        } else if (column.cellProvider) {
          child = column.cellProvider(cell)
        } else {
          child = cell
        }

        return (
          <EuiTableRowCell
            key={column.id}
            align={column.alignment}
            truncateText={cell && cell.truncateText || false}
            width={column.width}
            textOnly={cell ? cell.textOnly : true}
            mobileOptions={{
              header: column.label,
              ...column.mobileOptions,
            }}
          >
            {child}
          </EuiTableRowCell>
        )
      })

      return (
        <EuiTableRow
          key={item.id}
          className='admin-row'
          hasActions={true}
          onClick={() => onRowClick(item)}
        >
          {cells}
        </EuiTableRow>
      )
    }

    
    const rows = []

    for (var item of items) {
      rows.push(renderRow(item))
    }

    return rows
  }

  const renderFooter = () => {
    const { total } = tablePagination
    const noMore = items.length >= total

    return (
      <EuiTableFooterCell align='center' colSpan={columns.length - 1}>
        <InView
          hidden={!items?.length || noMore}
          onChange={(inView) => {
            if (inView && items.length && !noMore) {
              loadMore({ search, add: true })
            }
          }}
        >
          <EuiLoadingSpinner size='m' style={{ marginLeft: '1em' }} />
        </InView>
      </EuiTableFooterCell>
    )
  }

  return (
    <>
      <EuiPanel
        style={{
          height: '70vh',
          overflow: 'auto',
          padding: 0,
          boxShadow: 'none'
        }}
      >
        <EuiTable id='admin-table' style={{ backgroundColor: 'transparent' }}>
          <EuiTableHeader className='admin-table-header'>
            {renderHeaderCells()}
          </EuiTableHeader>
          <EuiTableBody>{renderRows()}</EuiTableBody>
          <EuiTableFooter>{renderFooter()}</EuiTableFooter>
        </EuiTable>
      </EuiPanel>
      {loading && <EuiProgress size='xs' color='primary' />}
    </>
  )
}
