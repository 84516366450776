import React from 'react'
import { EuiStat, EuiButton, EuiToolTip } from '@elastic/eui'
import './stat_button.css'

export const StatButton = ({
  title,
  description,
  color,
  isLoading,
  isSelected,
  onChange,
  tooltip,
  tooltipSelected,
  ...props
}) => {
  const onClick = () => onChange(!isSelected)
  return (
    <EuiToolTip
      content={isSelected && tooltipSelected ? tooltipSelected : tooltip}
      position='top'
    >
      <EuiButton
        className={`StatButton${isSelected ? ' selected' : ''}`}
        onClick={onClick}
        color={color}
        iconType={
          !isLoading ? (isSelected ? 'cross' : 'plusInCircleFilled') : ''
        }
        iconSide={'right'}
      >
        <EuiStat
          title={title}
          description={description}
          isLoading={isLoading}
          {...props}
        />
      </EuiButton>
    </EuiToolTip>
  )
}
