import React, { useContext } from "react";
import { EuiFlexGroup, EuiFlexItem, EuiIcon } from "@elastic/eui";
import { AppContext } from "../../app";
import { ExportTool } from "../export_tool";
import { ExportUsersTool } from "../export_tool";
import { SupportTool } from "../support_tool";
import "./tool_bar.css";

export const ToolBar = () => {
  const {
    keycloak,
    exportToolExpanded,
    supportToolExpanded,
    setShowToolBar,
    showToolBar,
  } = useContext(AppContext);

  const page = window.location.pathname

  if (!showToolBar) {
    return (
      <div className="ToolBar-hidden">
        <EuiFlexGroup
          gutterSize="none"
          className="tool-bar-arrow-hidden"
          onClick={() => setShowToolBar(true)}
        >
          <EuiFlexItem grow={false}>
            <EuiIcon
              type="arrowLeft"
              color="text"
              size="s"
              style={{ margin: "auto" }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    );
  }

  const renderTools = () => {
    if (supportToolExpanded) return
    if (page === '/admin') {
      return <ExportUsersTool />
    }
    return <ExportTool />
  }

  return (
    <div
      className={
        exportToolExpanded || supportToolExpanded
          ? `ToolBar-expanded`
          : `ToolBar-collapsed`
      }
    >
      <div className="tool-bar-border" onClick={() => {}} />

      {renderTools()}
      {!(exportToolExpanded || supportToolExpanded) && (
        <div className="spacer"></div>
      )}
      {keycloak &&
        keycloak.hasClientRole({ roleName: "notifications-tester" }) &&
        !exportToolExpanded && <SupportTool />}
    </div>
  );
};
