import React, { useState, useContext, useEffect } from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiButtonEmpty,
} from '@elastic/eui'

import { CreateGroupeRule } from './group_create_rule'
import { RulesList } from '../users/rules_list'
import { AppContext } from '../../app'
import { GroupsPageContext } from './groups_page'

export const GroupRules = () => {
  const { toaster } = useContext(AppContext)
  const { groupsState, updateGroup } = useContext(GroupsPageContext)

  const group = groupsState.activeGroup
  const rules = groupsState.activeGroup.rules

  const [displayingCreateRule, setDisplayingCreateRule] = useState(false)

  const initRule = {
    principalType: 'group',
    principalId: '',
    resourceType: 'project',
    resourceName: '',
    action: 'read',
    effect: 'allow',
  }

  const [newRule, setNewRule] = useState({ ...initRule })

  useEffect(() => {
    if (!group.entities?.length) {
      return
    }
    const nRule = { ...initRule }
    nRule.resourceType = group.entities[0].entityType
    nRule.resourceName = group.entities[0].entityName
    setNewRule(nRule)
  }, [])

  const displayCreateRule = () => {
    setDisplayingCreateRule(true)
  }

  const hideCreateRule = () => {
    setDisplayingCreateRule(false)
  }

  const updateNewRule = (rule) => {
    setNewRule({ ...rule })
  }

  const createRule = (rule) => {
    const updatedRules = [...rules, rule]
    updateGroup({ ...group, rules: [...updatedRules] })
    hideCreateRule()
  }

  const sortRule = (rule) => {
    return Object.keys(rule)
      .sort()
      .reduce((obj, key) => {
        obj[String(key)] = rule[String(key)]
        return obj
      }, {})
  }

  const checkRuleDuplicate = (rule) => {
    if (!rule.resourceName) {
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'Not able to add new rule.',
      })
      return
    }
    let newRule = {
      action: rule.action,
      principalType: rule.principalType,
      resourceName: rule.resourceName,
      resourceType: rule.resourceType,
    }
    newRule = sortRule(newRule)
    for (const {
      action,
      principalType,
      resourceName,
      resourceType,
    } of rules) {
      const tmp = {
        action,
        principalType,
        resourceName,
        resourceType,
      }
      const sortedTmp = sortRule(tmp)
      let duplicate = JSON.stringify(newRule) === JSON.stringify(sortedTmp)
      if (duplicate) {
        toaster({
          color: 'danger',
          title: 'Oops!',
          text: 'Group rule already exist.',
        })
        return
      } else {
        continue
      }
    }
    createRule(rule)
  }

  const removeRule = (ruleIndex) => {
    const updatedRules = [...rules]
    updatedRules.splice(ruleIndex, 1)
    updateGroup({ ...group, rules: updatedRules })
  }

  const renderAction = () => {
    if (groupsState.groupModal === 'view') {
      return (
        <></>
      )
    }
    return (
      <>
        {displayingCreateRule ? (
          <EuiFlexGroup gutterSize='s'>
            <EuiFlexItem>
              <EuiButtonEmpty
                size='s'
                onClick={() => hideCreateRule()}
                iconType='cross'
              >
                Cancel
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButtonEmpty
                size='s'
                color='success'
                iconType='check'
                onClick={() => checkRuleDuplicate(newRule)}
              >
                Add
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiButtonEmpty
            size='s'
            color='primary'
            iconType='listAdd'
            onClick={() => displayCreateRule()}
          >
            Add Rule
          </EuiButtonEmpty>
        )}
      </>      
    )
  }

  const renderCreateRule = () => {
    if (groupsState.groupModal === 'view') {
      return (<></>)
    }
    if (displayingCreateRule) {
      return (
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel>
              <CreateGroupeRule
                newRule={newRule}
                updateNewRule={updateNewRule}
                createRule={createRule}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
  }

  return (
    <div>
      <EuiFlexGroup
        gutterSize='m'
        justifyContent='flexEnd'
        alignItems='flexEnd'
      >
        <EuiFlexItem grow={false}>
          {renderAction()}
        </EuiFlexItem>
      </EuiFlexGroup>

      {renderCreateRule()}

      <RulesList
        rules={rules}
        removeRule={removeRule}
        hideDelete={groupsState.groupModal === 'view'}
        view='group'
      />
    </div>
  )
}
