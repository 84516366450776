import React, { useState, useContext } from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSuperSelect,
  EuiTitle,
  EuiSpacer,
} from '@elastic/eui'

import { AppContext } from '../../app'

export const CreateRule = ({ newRule, updateNewRule }) => {
  const {
    projects,
    useCases,
    senders,
    recipients
  } = useContext(AppContext)

  const resourceOptions = [
    {
      value: 'project',
      inputDisplay: <>Project</>,
      'data-test-subj': 'option-project',
    },
    {
      value: 'useCase',
      inputDisplay: <>Use Case</>,
      'data-test-subj': 'option-useCase',
    },
    {
      value: 'sender',
      inputDisplay: <>Sender</>,
      'data-test-subj': 'option-sender',
    },
    {
      value: 'subsender',
      inputDisplay: <>Subsender</>,
      'data-test-subj': 'option-subsender',
    },
    {
      value: 'recipient',
      inputDisplay: <>Recipient</>,
      'data-test-subj': 'option-recipient',
    },
  ]

  const actionOptions = [
    {
      value: 'read',
      inputDisplay: <>Read</>,
      'data-test-subj': 'option-read',
    },
  ]

  const accessOptions = [
    {
      value: 'allow',
      inputDisplay: <>Allow</>,
      'data-test-subj': 'option-allow',
    },
    {
      value: 'deny',
      inputDisplay: <>Deny</>,
      'data-test-subj': 'option-deny',
    },
  ]

  const resourceNames = (value) => {
    switch (value) {
      case 'project':
        if (!projects)
          return [{ value: null, inputDisplay: 'No projects available' }]
        return projects.map((project) => ({
          value: `${project.name}`,
          inputDisplay: <>{project.displayName || project.name}</>,
          'data-test-subj': `option-${project.name}`,
        }))
      case 'useCase':
        if (!useCases)
          return [{ value: null, inputDisplay: 'No use cases available' }]
        return useCases.map((useCase) => ({
          value: `${useCase.name}`,
          inputDisplay: <>{useCase.displayName || useCase.name}</>,
          'data-test-subj': `option-${useCase.name}`,
        }))
      case 'sender':
        if (!senders)
          return [{ value: null, inputDisplay: 'No senders available' }]
        return senders
          .reduce((agg, sender) => {
            if (!sender.parent) {
              agg.push({
                value: `${sender.name}`,
                inputDisplay: <>{sender.displayName || sender.name}</>,
                'data-test-subj': `option-${sender.name}`,
              })
            }
            return agg
          }, [])
          .concat({
            value: '*',
            inputDisplay: <>*</>,
            'data-test-subj': `option-*`,
          })
      case 'subsender':
        if (!senders)
          return [{ value: null, inputDisplay: 'No subsenders available' }]
        return senders.reduce((agg, sender) => {
          if (sender.parent) {
            agg.push({
              value: `${sender.name}`,
              inputDisplay: <>{sender.displayName || sender.name}</>,
              'data-test-subj': `option-${sender.name}`,
            })
          }
          return agg
        }, [])
      case 'recipient':
        if (!recipients)
          return [{ value: null, inputDisplay: 'No recipients available' }]
        return recipients
          .map((recipient) => ({
            value: `${recipient.name}`,
            inputDisplay: <>{recipient.displayName || recipient.name}</>,
            'data-test-subj': `option-${recipient.name}`,
          }))
          .concat({
            value: '*',
            inputDisplay: <>*</>,
            'data-test-subj': `option-*`,
          })
      default:
        break
    }
  }

  const [resourceValue, setResourceValue] = useState(newRule.resourceType)
  const [resourceNameValue, setResourceNameValue] = useState(
    newRule.resourceName
  )
  const [actionValue, setActionValue] = useState(newRule.action)
  const [accessValue, setAccessValue] = useState(newRule.effect)

  const onChangeResource = (value) => {
    setResourceValue(value)
    setResourceNameValue(resourceNames(value)[0].value)
    updateNewRule({
      ...newRule,
      resourceType: value,
      resourceName: resourceNames(value)[0].value,
    })
  }

  const onChangeResourceName = (value) => {
    setResourceNameValue(value)
    updateNewRule({ ...newRule, resourceName: value })
  }

  const onChangeAction = (value) => {
    setActionValue(value)
    updateNewRule({ ...newRule, action: value })
  }

  const onChangeAccess = (value) => {
    setAccessValue(value)
    updateNewRule({ ...newRule, effect: value })
  }

  return (
    <div>
      <EuiFlexGroup gutterSize='m'>
        <EuiFlexItem>
          <EuiTitle size='xxxs' style={{ textAlign: 'left' }}>
            <h6>Resource</h6>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiSuperSelect
            options={resourceOptions}
            valueOfSelected={resourceValue}
            onChange={(value) => onChangeResource(value)}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size='xxxs' style={{ textAlign: 'left' }}>
            <h6>Name</h6>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiSuperSelect
            options={resourceNames(resourceValue)}
            valueOfSelected={resourceNameValue}
            onChange={(value) => onChangeResourceName(value)}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size='xxxs' style={{ textAlign: 'left' }}>
            <h6>Action</h6>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiSuperSelect
            options={actionOptions}
            valueOfSelected={actionValue}
            onChange={(value) => onChangeAction(value)}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size='xxxs' style={{ textAlign: 'left' }}>
            <h6>Access</h6>
          </EuiTitle>
          <EuiSpacer size='s' />
          <EuiSuperSelect
            options={accessOptions}
            valueOfSelected={accessValue}
            onChange={(value) => onChangeAccess(value)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}
