import React, { useContext } from 'react'
import {
  EuiTable,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableBody,
  EuiPanel,
  EuiIcon,
  EuiText,
  EuiSpacer
} from '@elastic/eui'
import { TableLoadingIndicator } from '.'

import { EtorContext } from '..'

import './etor_modal_table.css'


export const EtorModalTable = ({ columns, items, id, loading, onRowClick, minHeight }) => {
  const { activeMessage } = useContext(EtorContext)

  const renderHeaderCells = () => {
    const headers = []

    columns.forEach((column, columnIndex) => {
      headers.push(
        <EuiTableHeaderCell
          key={`${id}-header-${column.name}-${columnIndex}`}
          width={column.width}
          style={{ padding: 0 }}
        >
          {column.name}
        </EuiTableHeaderCell>
      )
    })
    return headers.length ? headers : null
  }

  const renderRows = () => {
    if (loading) {
      return (
        <EuiTableRow>
          <EuiTableRowCell
            colSpan={columns.length}
            className='table-loader'
            align='center'
          >
            <TableLoadingIndicator noMessages={!items.length} />
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    if (!items.length) {
      return (
        <EuiTableRow>
          <EuiTableRowCell colSpan={columns.length} align='center'>
            No Messages Found
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    const renderRow = (item) => {
      const cells = columns.map((column, columnIndex) => {
        const cell = item[column.field]
        let child

        if (column.render) {
          child = column.render(cell, item)
        } else {
          child = cell
        }

        return (
          <EuiTableRowCell
            key={`${id}-row-cell-${column.field}-${columnIndex}`}
            className={column.className}
            truncateText={column.truncateText}
            align={column.alignment}
            style={{ textAlign: 'left' }}
            width={column.width}
          >
            {child}
          </EuiTableRowCell>
        )
      })

      return (
        <React.Fragment key={`${id}-row-${item.MessageId || item.id}`}>
          <EuiTableRow
            className='etor-modal-table-row'
            isExpandable={true}
            itemID={item.MessageId}
            onClick={() => {
              if (onRowClick) {
                return onRowClick(item)
              }
              return {}
            }}
            style={{ backgroundColor: activeMessage.MessageId === item.MessageId ? '#F3F7FB' : '#fff' }}
          >
            {cells}
          </EuiTableRow>
        </React.Fragment>
      )
    }

    const rows = []

    for (var item of items) {
      rows.push(renderRow(item))
    }

    return rows
  }

  if (!items?.length) {
    return (
      <EuiPanel className='etor-modal-table-panel-empty'>
        <EuiIcon type='document' size='l' color='#4A4D55' />
        <EuiSpacer size='s' />
        <EuiText color='#4A4D55'>
          No items
        </EuiText>
      </EuiPanel>
    )
  }

  return (
    <EuiPanel className='etor-modal-table-panel' style={{ minHeight: minHeight ? minHeight : 'auto' }}>
      <EuiTable className='etor-modal-table'>
        <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>
        <EuiTableBody>{renderRows()}</EuiTableBody>
      </EuiTable>
    </EuiPanel>
  )
}
