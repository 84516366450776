import React from 'react'
import { EuiStat, EuiButton, EuiToolTip } from '@elastic/eui'
import './stat_count.css'

export const StatCount = ({
  title,
  description,
  color,
  tooltip,
  onClick,
  ...props
}) => {
  return (
    <EuiToolTip
      content={tooltip}
      position='top'
    >
      <EuiButton
        className={`StatCount`}
        onClick={onClick}
        color={color}
      >
        <EuiStat
          title={title}
          description={description}
          titleSize='m'
          {...props}
        />
      </EuiButton>
    </EuiToolTip>
  )
}
