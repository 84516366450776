import React, {
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react'

import {
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiFieldText,
  EuiPanel,
  EuiIcon,
  EuiButtonIcon,
  EuiPopover
} from '@elastic/eui'

import { EntityBadge, entityDisplay, getChangesCount } from './utils'
import { OnboardTable } from './onboard_table'

import { AppContext } from '../../app'
import { SettingPageContext } from './settings_page'

import { config } from '../../lib'

const entities = ['project', 'usecase', 'sender', 'recipient']
const wsHost = config.WS_HOST

export const SettingsOnboard = ({ data }) => {
  const [search, setSearch] = useState('')
  const [showTip, setShowTip] = useState(false)
  const [ws, setWs] = useState(false)

  const {
    onboardState,
    onboardDispatch,
    loadOnboards
  } = useContext(SettingPageContext)
  const { keycloak } = useContext(AppContext)

  const openWs = useCallback(() => {
    if (ws) return
    try {
      const wsConnection = new WebSocket(`${wsHost}?token=${keycloak.token}&type=onboard`)
      setWs(wsConnection)
    }
    catch(err) {
      console.log('Error opening websocket connection >> ', err)
    }
  }, [ws, keycloak])

  const checkStatuses = (onboards) => {
    // Keep ws open for following statuses
    const validStatuses = ['SUBMITTED', 'RUNNING']
    for (let { status } of onboards) {
      if (validStatuses.includes(status)) {
        return true
      }
    }
    return false
  }

  const handleWsClose = () => {
    if (ws) {
      ws.close()
      setWs(null)
    }
  }

  useEffect(() => {
    const keepWsOpen = checkStatuses(onboardState.onboards)
    if (!keepWsOpen) {
      handleWsClose()
      return
    }
    openWs()
    if (!ws) return
    ws.onopen = () => {}
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data)
      if (data.action && data.action === 'update') {
        loadOnboards({ reset: true })
      }
    }
    ws.onclose = (event) => {
      /*
        Close codes:
        1001 - Going away / we want to refresh if export jobs are still running
      */
      if (event.code === 1001) {
        setWs(null)
      }
    }
    ws.onerror = (event) => {
      console.log('Websocket error event >> ', event)
    }
    // eslint-disable-next-line
  }, [ws, onboardState.onboards])

  const EntityChanges = () => {
    const changes = getChangesCount({
      addEntities: onboardState.addEntities,
      delEntities: onboardState.delEntities
    })
    if (!changes) {
      return <></>
    }
    const rows = []
    for (const entityType of entities) {
      const addEntities = onboardState.addEntities[String(entityType)]
      const delEntities = onboardState.delEntities[String(entityType)]
      if (!addEntities?.length && !delEntities?.length) {
        continue
      }
      rows.push(
        <div
          className='settings-changes-onboard-row'
          key={`settings-onboard-changes-row-${entityType}`}
        >
          <EuiFlexGroup gutterSize='none' style={{ padding: '10px' }}>
            <EuiFlexItem grow={false} >
              <EuiIcon type={entityDisplay[String(entityType)].icon} />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFlexGroup
                gutterSize='s'
                style={{ marginLeft: '8px' }}
                wrap
              >
                {addEntities.map(({ name, displayName }, idx) =>(
                  <EuiFlexItem key={`settings-onboard-changes-entity-add-badge-${idx}`} grow={false}>
                    <EntityBadge
                      entity={{ name, displayName }}
                      add
                      handler={() => onboardDispatch({ type: 'rm_add_entity', name, entityType })}
                    />
                  </EuiFlexItem>
                ))}
                {delEntities.map(({ name, displayName }, idx) =>(
                  <EuiFlexItem key={`settings-onboard-changes-entity-del-badge-${idx}`} grow={false}>
                    <EntityBadge
                      entity={{ name, displayName }}
                      handler={() => onboardDispatch({ type: 'rm_del_entity', name, entityType })}
                    />
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      )
    }

    const renderTips = () => {
      const tips = []
      for (const entityType of entities) {
        tips.push(
          <EuiText size='s' key={`settings-entity-changes-tip-${entityType}`}>
            <EuiIcon type={entityDisplay[String(entityType)].icon} />
            &nbsp; = &nbsp; {entityDisplay[String(entityType)].display}
          </EuiText>
        )
        if (entities.indexOf(entityType) !== entities.length - 1) {
          tips.push(<br key={`settings-entity-changes-tip-br-${entityType}`}/>)
        }
      }
      return tips
    }

    const tipButton = (
      <EuiButtonIcon
        iconType='iInCircle'
        style={{ color: 'black', marginLeft: '.25rem' }}
        aria-label='onboard-tips'
        onClick={() => setShowTip(!showTip)}
      />
    )

    const discardButton = () => (
      <EuiButtonIcon
        iconType='trash'
        style={{ color: 'black', marginLeft: '.25rem' }}
        aria-label='discard-changes'
        onClick={() => onboardDispatch({ type: 'reset_changes' })}
      />
    )

    return (
      <EuiPanel className='settings-changes-panel' hasShadow={false} style={{ backgroundColor: 'unset' }}>
        <EuiFlexGroup gutterSize='none' className='settings-changes-title'>
          <EuiFlexItem grow={false}>
            <EuiIcon type='folderOpen'/>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText textAlign='left' style={{ fontSize: '.9em', fontWeight: 500, marginLeft: '.5rem' }}>
              Entity Changes
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiPopover
              button={tipButton}
              isOpen={showTip}
              closePopover={() => setShowTip(false)}
              anchorPosition='leftCenter'
            >
              {renderTips()}
            </EuiPopover>
          </EuiFlexItem>
          <EuiFlexItem grow></EuiFlexItem>
          <EuiFlexItem grow={false}>
            {discardButton()}
          </EuiFlexItem>
        </EuiFlexGroup>
        <div className="settings-changes-items">
        {rows}
        </div>
      </EuiPanel>
    )
  }

  if (!data) return (<></>)

  return (
    <>
      <EuiSpacer size='l' />
      <EntityChanges />
      <EuiSpacer size='m' />
      <EuiFlexGroup
        gutterSize='none'
        style={{
          flexDirection: 'column'
        }}>
        <EuiFlexItem>
          <EuiText textAlign='left' size='s' style={{ marginBottom: '.25rem' }}>
            Description
          </EuiText>
        </EuiFlexItem>
        <EuiFlexGroup gutterSize='none' style={{ alignItems: 'center' }}>
          <EuiFlexItem>
            <EuiFieldText
              placeholder={`Onboarding Notes`}
              value={onboardState.description}
              onChange={e => onboardDispatch({ type: 'set_one', state: 'description', value: e.target.value})}
              aria-label='onboard description field'
              fullWidth
              icon='document'
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size='l' />
        <EuiFlexItem>
          <EuiText textAlign='left' style={{ fontSize: '1.4em', fontWeight: 400 }}>
            Onboarding
            <span
              style={{
                fontSize: '.6em',
                verticalAlign: 'text-top',
                marginLeft: '.25rem',
                paddingTop: '.25rem',
                fontWeight: 300
              }}
            >
              {data.length ? data.length : ''}
            </span>
          </EuiText>
        </EuiFlexItem>
        <EuiSpacer size='s' />
        <EuiFieldSearch
          placeholder='Search'
          value={search}
          onChange={e => {
            const value = e.target.value
            if (value === '') {
              loadOnboards({ search: value, reset: true })
              setSearch(value)
            } else {
              setSearch(value)
            }
          }}
          aria-label='Entity Search'
          isClearable
          fullWidth
          onSearch={(search) => loadOnboards({ search, reset: true })}
        />
      </EuiFlexGroup>
      <EuiSpacer size='m' />
      <EuiPanel paddingSize='none' style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
        <OnboardTable data={data} />
      </EuiPanel>
    </>
  )
}
