import React from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiIcon,
  EuiCard,
} from '@elastic/eui'

import { useHistory } from 'react-router-dom'

export const ErrorMessage = ({ error, reset }) => {
  const history = useHistory()
  return (
    <EuiFlexGroup
      justifyContent='center'
      alignItems='center'
      style={{ height: '100vh' }}
    >
      <EuiFlexItem grow={false}>
        <EuiCard
          style={{ width: '400px' }}
          icon={<EuiIcon size='xxl' type='alert' />}
          title='Something went wrong'
          description={error}
          footer={
            <div>
              <EuiButton
                aria-label='Go back to dashboard'
                onClick={() => {
                  reset()
                  history.push('/')
                }}
              >
                Reload Dashboard
              </EuiButton>
            </div>
          }
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
