import React from 'react'
import { EuiCard } from '@elastic/eui'
import { useHistory } from 'react-router-dom'

const isModifiedEvent = (event) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

const isLeftClickEvent = (event) => event.button === 0

export const RouterCard = ({ to, ...props }) => {
  const history = useHistory()

  function onClick(event) {
    if (event.defaultPrevented) {
      return
    }

    // If target prop is set (e.g. to "_blank"), let browser handle link.
    if (event.target.getAttribute('target')) {
      return
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return
    }

    // Prevent regular link behavior, which causes a browser refresh.
    event.preventDefault()
    history.push(to)
  }

  return <EuiCard {...props} href={to} onClick={onClick} />
}
