import React, { useContext, useState } from 'react'
import {
  EuiTable,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableBody,
  EuiTableFooter,
  EuiTableFooterCell,
  EuiFlexItem,
  EuiFlexGroup,
  EuiLoadingSpinner,
  EuiButtonIcon,
  EuiText,
  EuiBadge,
  EuiButton
} from '@elastic/eui'
import { InView } from 'react-intersection-observer'
import {
  CircleIcon,
  CircleIconError,
  entityDisplay,
  buildOnboardRetry
} from './utils'

import { formatDateTime } from '../../lib'

import { AppContext } from '../../app'
import { SettingPageContext } from './settings_page'

const statusMap = {
  'FAILED': {
    display: 'FAILED',
    bg: 'rgba(255, 123, 123, .15)',
    color: '#FF7B7B',
  },
  'SUCCESS': {
    display: 'COMPLETE',
    bg: 'rgba(51, 147, 137, .15)',
    color: '#339389',
  },
  'IN_PROGRESS': {
    display: 'IN PROGRESS',
    bg: 'rgba(48, 127, 191, .15)',
    color: '#307FBF',
  },
  'SUBMITTED': {
    display: 'SUBMITTED',
    bg: 'rgba(172, 177, 195, .15)',
    color: '#ACB1C3',
  }
}

export const OnboardTable = ({ data }) => {
  const [expandRowSet, setExpandRowSet] = useState(new Set())
  const {
    onboardState,
    onboardDispatch,
    loadOnboards
  } = useContext(SettingPageContext)
  const { toaster } = useContext(AppContext)

  const toggleExpand = (id) => {
    const expandRows = new Set(expandRowSet)
    if (expandRows.has(id)) {
      expandRows.delete(id)
    } else {
      expandRows.add(id)
    }
    setExpandRowSet(expandRows)
  }

  const handleRetry = (onboard) => {
    try {
      const { addEntities, delEntities } = buildOnboardRetry(onboard.entity)
      const payload = {
        type: 'retry_onboard',
        addEntities,
        delEntities
      }
      if (onboard.description) {
        payload.description = onboard.description
      }
      onboardDispatch(payload)
    }
    catch {
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was problem retrying this onboard.',
      })
    }
  }

  const getDisplayObj = (name) => {
    const fname = name.toLowerCase()
    if (entityDisplay[String(fname)]) {
      return entityDisplay[String(fname)]
    }
    return {
      display: name,
      title: name,
      icon: 'none'
    }
  }

  const getEntityCounts = (entities) => {
    return Object.values(entities).reduce((countMap, entity) => {
      for (const [entityType, values] of Object.entries(entity)) {
        if (countMap[String(entityType)]) {
          countMap[String(entityType)] =  countMap[String(entityType)] + values.length
        } else {
          countMap[String(entityType)] = values.length
        }
      }
      return countMap
    }, new Map())
  }

  let columns = [
    {
      field: 'content-placeholder',
      name: 'Onboarding',
      truncateText: true,
      width: '30%',
      isExpander: true,
      render: (cell, { id, status, entity }) => {
        const counts = getEntityCounts(entity)
        return (
          <EuiFlexGroup className='onboard-table-content'>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                style={{ minWidth: '24px' }}
                onClick={() => toggleExpand(id)}
                aria-label={
                  expandRowSet.has(id) ? 'Collapse' : 'Expand'
                }
                iconType={
                  expandRowSet.has(id) ? 'arrowDown' : 'arrowRight'
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <CircleIcon />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText size='s'>
                {Object.entries(counts).map(([entityType, value], idx) => (
                  <React.Fragment key={`settings-onboard-table-parent-row-${idx}`}>
                    {getDisplayObj(entityType).title}({value})
                    {idx !== Object.values(counts).length - 1 ? ', ' : ''}
                  </React.Fragment>
                ))}
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiBadge
                className='onboard-table-badge'
                style={{
                  color: statusMap[String(status)].color,
                  backgroundColor: statusMap[String(status)].bg
                }}
              >
                {statusMap[String(status)].display}
              </EuiBadge>
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      },
    },
    {
      field: 'description',
      name: 'Description',
      width: '20%',
      truncateText: true
    },
    {
      field: 'created_at',
      name: 'Date',
      width: '15%',
      render: (cell) => formatDateTime(cell),
    },
  ]

  const renderHeaderCells = () => {
    const headers = []

    columns.forEach((column, columnIndex) => {
      headers.push(
        <EuiTableHeaderCell
          key={`onboard-table-header-${columnIndex}`}
          width={column.width}
          align={columns[String(columnIndex)].alignment}
        >
          {column.name}
        </EuiTableHeaderCell>
      )
    })
    return headers.length ? headers : null
  }

  const renderRows = () => {
    if (!data) {
      return (
        <EuiTableRow>
          <EuiTableRowCell
            colSpan={columns.length}
            align='center'
          >
            <EuiLoadingSpinner size='m' />
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    if (!data.length) {
      return (
        <EuiTableRow>
          <EuiTableRowCell colSpan={columns.length} align='center'>
            No items found
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    const renderExpandedRow = (item) => {
      if (expandRowSet.has(item.id)) {
        const { addEntities, delEntities } = item.entity
        return (
          <>
            {item.error?.length ? item.error.map((err, idx) => (
              <EuiTableRow key={`onboard-table-expanded-errors-${idx}`} isExpandedRow style={{ borderTop: 'none' }}>
                <EuiTableRowCell
                  colSpan={columns.length}
                  className='onboard-table-expanded-cell-error'
                >
                  <EuiFlexGroup gutterSize='none' alignItems='center'>
                    <EuiFlexItem className='onboard-table-expanded-icon'>
                      <CircleIconError />
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiText size='s'>{err}</EuiText>
                    </EuiFlexItem>
                    {idx === 0
                      ? <EuiFlexItem style={{ margin: '0 1rem' }}>
                          <EuiButton
                            iconType='refresh'
                            color='danger'
                            size='s'
                            style={{ width: '5rem' }}
                            onClick={() => handleRetry(item)}
                          >
                            Retry
                          </EuiButton>
                        </EuiFlexItem>
                      : null
                    }
                  </EuiFlexGroup>
                </EuiTableRowCell>
              </EuiTableRow>
            )) : null}
            {Object.entries(delEntities).map(([entityType, entities]) => entities.map((entity, idx) => (
              <EuiTableRow key={`onboard-table-expanded-del-entities-${idx}`} isExpandedRow>
                <EuiTableRowCell
                  colSpan={columns.length}
                  className='onboard-table-expanded-cell'
                >
                  <EuiFlexGroup gutterSize='none' alignItems='center'>
                    <EuiFlexItem className='onboard-table-expanded-icon'>
                      <EuiText size='s' color='danger'>-</EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiText size='s'><b>{getDisplayObj(entityType).display}</b> - {entity}</EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiTableRowCell>
              </EuiTableRow>
            )))}
            {Object.entries(addEntities).map(([entityType, entities]) => entities.map((entity, idx) => (
              <EuiTableRow key={`onboard-table-expanded-add-entities-${idx}`} isExpandedRow>
                <EuiTableRowCell
                  colSpan={columns.length}
                  className='onboard-table-expanded-cell'
                >
                  <EuiFlexGroup gutterSize='none' alignItems='center'>
                    <EuiFlexItem className='onboard-table-expanded-icon'>
                      <EuiText  size='s' color='secondary'>+</EuiText>
                    </EuiFlexItem>
                    {entity.parent ?
                    <EuiFlexItem grow={false}>
                      <EuiText size='s'>
                        <b>{getDisplayObj(entityType).display}</b> - {entity.name}{entity.displayName ? `: ${entity.displayName}` : ''}
                      </EuiText>
                    </EuiFlexItem> :
                    <EuiFlexItem grow={false}>
                      <EuiText size='s'>
                        <b>{getDisplayObj(entityType).display}</b> - {entity}
                      </EuiText>
                    </EuiFlexItem>
                    }
                  </EuiFlexGroup>
                </EuiTableRowCell>
              </EuiTableRow>
            )))}
          </>
        )
      }
      return null
    }

    const renderRow = (item) => {
      const cells = columns.map((column, columnIndex) => {
        const cell = item[column.field]
        let child

        if (column.render) {
          child = column.render(cell, item)
        } else {
          child = cell
        }

        return (
          <EuiTableRowCell
            key={`onboard-row-cell-${column.field}-${columnIndex}`}
            truncateText={column.truncateText || false}
            className={column.className}
            style={{ textAlign: 'left', minWidth: '40px' }}
            isExpander={column.isExpander}
          >
            {child}
          </EuiTableRowCell>
        )
      })

      return (
        <React.Fragment key={`onboard-row-${item.id}`}>
          <EuiTableRow
            isExpandable={true}
          >
            {cells}
          </EuiTableRow>
          {renderExpandedRow(item)}
        </React.Fragment>
      )
    }

    const rows = []

    for (var item of data) {
      rows.push(renderRow(item))
    }

    return rows
  }

  const renderFooters = () => {
    const { rowCount } = onboardState.pagination
    const noMore = data.length >= rowCount
    return (
      <EuiTableFooterCell
        colSpan={3}
        className='onboard-table-footer'
      >
        <InView
          hidden={!data.length || noMore}
          onChange={(inView) => {
            if (inView && data.length && !noMore) {
              loadOnboards({ loadMore: true })
            }
          }}
        >
          <EuiLoadingSpinner size='m' style={{ marginLeft: '1em' }} />
        </InView>
      </EuiTableFooterCell>
    )
  }

  return (
    <div>
      <EuiTable id='onboard-table'>
        <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>
        <EuiTableBody>{renderRows()}</EuiTableBody>
        <EuiTableFooter>{renderFooters()}</EuiTableFooter>
      </EuiTable>
    </div>
  )
}
