import React, { useContext } from 'react'
import {
  EuiCard,
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLoadingSpinner,
  EuiPanel,
} from '@elastic/eui'

import {
  euiPaletteColorBlindBehindText,
  toInitials,
} from '@elastic/eui/lib/services'

import { GroupsPageContext } from '../groups/groups_page'

import { InView } from 'react-intersection-observer'

export const CardTitle = ({ title }) => (
  <EuiFlexGroup gutterSize="s" responsive={false}>
    <EuiFlexItem grow={false}>
      <span>
        <EuiIcon size="xl" type={'users'} />
      </span>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <span style={{ fontWeight: 400 }}>{title}</span>
    </EuiFlexItem>
  </EuiFlexGroup>
)

const CircleIcon = ({ value, style, color }) => {
  return (
    <div
      className="euiAvatar"
      style={{
        ...style,
        background: color,
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        fontSize: '.7em',
        border: '2px solid #fff',
        position: 'relative',
        zIndex: 99,
      }}
    >
      {value}
    </div>
  )
}

const renderUserAvs = (users) => {
  if (!users?.length) {
    return <></>
  }
  const avatars = []
  const limit = 4
  const visColors = euiPaletteColorBlindBehindText()
  for (const { user } of users) {
    if (!user) continue
    const assignedColor =
      visColors[Math.floor(user.name.length % visColors.length)]
    const margin = avatars.length ? '-12px' : '0'
    if (avatars.length >= limit) {
      const usersLeft = users.length - limit
      if (usersLeft === 1) {
        avatars.push(
          <CircleIcon
            key={`tag-av-last-${user.name}`}
            value={`+${usersLeft}`}
            style={{ marginLeft: margin }}
            color={'#D294AE'}
          />
        )
      } else {
        avatars.push(
          <CircleIcon
            key={`tag-av-last-${user.name}`}
            value={`+${usersLeft}`}
            style={{ marginLeft: margin }}
            color={assignedColor}
          />
        )
      }
      return avatars
    }
    avatars.push(
      <CircleIcon
        key={`tag-av-${user.name}`}
        value={toInitials(user.name, 2).toUpperCase()}
        style={{ marginLeft: margin }}
        color={assignedColor}
      />
    )
  }
  return avatars
}

export const GroupList = ({
  groups,
  groupsPagination,
  loadMore,
  actionsPopover,
  search,
}) => {

  const { groupsDispatch } = useContext(GroupsPageContext)

  const renderFooter = () => {
    const { total } = groupsPagination
    const noMore = groups.length >= total

    return (
      <EuiFlexItem style={{ flexBasis: '100%' }}>
        <InView
          hidden={!groups?.length || noMore}
          onChange={(inView) => {
            if (inView && groups.length && !noMore) {
              loadMore({ search, add: true })
            }
          }}
        >
          <EuiLoadingSpinner size="m" style={{ margin: 'auto' }} />
        </InView>
      </EuiFlexItem>
    )
  }

  return (
    <>
      <EuiPanel
        color="subdued"
        style={{
          height: '70vh',
          overflow: 'auto',
          border: '1px solid #D3DAE6',
        }}
      >
        <EuiFlexGrid gutterSize="s">
          {groups.map((group) => (
            <EuiFlexItem key={group.id}>
              <EuiCard
                display="plain"
                hasBorder={true}
                textAlign="left"
                title={<CardTitle title={group.name} />}
                description={group.description}
                style={{
                  width: 385,
                  padding: 32,
                  position: 'relative',
                }}
                className="group-list-container"
                footer={actionsPopover(group)}
                onClick={() => groupsDispatch({ type: 'view_group', group })}
              >
                {renderUserAvs(group.users)}
              </EuiCard>
            </EuiFlexItem>
          ))}
          {renderFooter()}
        </EuiFlexGrid>
      </EuiPanel>
    </>
  )
}
