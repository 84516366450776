import React, { useContext } from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiIcon,
  EuiLoadingSpinner,
} from '@elastic/eui'
import { ExportContext } from './export_tool'
import { formatRecentTime } from '../../lib/date'

export const ExportStatus = ({ id, status, filename, createdAt }) => {
  const { deleteExport, retryExport, downloadExport } =
    useContext(ExportContext)

  const statusMap = {
    SUBMITTED: {
      displayName: 'SUBMITTED',
      color: '#a0a6ba',
      icon: <EuiLoadingSpinner size='m' />,
    },
    RUNNING: {
      displayName: 'IN PROGRESS',
      color: 'primary',
      icon: <EuiLoadingSpinner size='m' />,
    },
    SUCCEEDED: {
      displayName: 'COMPLETED',
      color: 'success',
      icon: (
        <EuiIcon
          type='download'
          onClick={() => downloadExport(id)}
          style={{ cursor: 'pointer' }}
        />
      ),
    },
    FAILED: {
      displayName: 'FAILED',
      color: 'danger',
      icon: (
        <EuiIcon
          type='refresh'
          onClick={() => retryExport(id)}
          style={{ cursor: 'pointer' }}
        />
      ),
    },
    ERROR: {
      displayName: 'ERROR',
      color: 'danger',
      icon: (
        <EuiIcon
          type='refresh'
          onClick={() => retryExport(id)}
          style={{ cursor: 'pointer' }}
        />
      ),
    },
  }

  const statusDef = statusMap[String(status)]
    ? statusMap[String(status)]
    : statusMap['ERROR']

  const statusIcon = () => {
    return (
      <EuiFlexItem grow={false} style={{ padding: '7px' }}>
        {statusDef.icon}
      </EuiFlexItem>
    )
  }

  const statusButton = () => {
    return statusIcon()
  }

  return (
    <EuiFlexGroup
      gutterSize='none'
      style={{
        alignItems: 'center',
        padding: '4px 8px',
        border: '1px solid rgb(224, 226, 229)',
        borderRadius: '4px',
        background: 'rgb(244, 246, 249)',
        margin: '4px 0',
      }}
    >
      {statusButton()}

      <EuiFlexItem>
        <EuiFlexGroup
          gutterSize='none'
          style={{
            justifyContent: 'space-between',
            width: '100%',
            padding: '0 8px',
          }}
          direction='column'
        >
          <EuiFlexItem>
            <EuiFlexGroup gutterSize='none'>
              <EuiFlexItem>
                <EuiText textAlign='left' size='xs' color={statusDef.color}>
                  {statusDef.displayName}
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false} style={{ padding: '0 8px' }}>
                <EuiText size='xs' color='#a0a6ba'>
                  {formatRecentTime(createdAt)}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText
              textAlign='left'
              size='s'
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '11rem',
              }}
            >
              {filename}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiIcon
          type='trash'
          style={{ cursor: 'pointer' }}
          onClick={() => deleteExport(id)}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
