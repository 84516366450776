import React, { useContext } from 'react'
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { EntityTable } from '.'
import { SendersTable } from '.'
import { compareEntities } from '../dashboard/utils'
import { AppContext } from '../../app'
import { DashContext } from '..'

export const EntityTableGroup = () => {
  let {
    selectedProjects,
    selectedUseCases,
    selectedSenders,
    selectedRecipients,
    messageCounts,
    loadingProjects,
    loadingUseCases,
    loadingSenders,
    loadingRecipients,
    projectsChecked,
    onChangeProjectsSwitch,
    useCasesChecked,
    onChangeUseCasesSwitch,
    sendersChecked,
    onChangeSendersSwitch,
    recipientsChecked,
    onChangeRecipientsSwitch,
    setEntityTableSorts,
    entityTableSorts,
  } = useContext(AppContext)

  let {
    projectName,
    senders,
    projects,
    addProject,
    removeProject,
    useCases,
    addUseCase,
    removeUseCase,
    addSender,
    removeSender,
    recipients,
    addRecipient,
    removeRecipient,
  } = useContext(DashContext)

  const generateSenderTree = (senders) => {
    let senderTree = []
    let senderCounts = messageCounts.senderCounts

    senders.forEach(
      ({
        name,
        parent,
        status,
        description,
        displayName,
        isCurrent,
        parentCount,
      }) => {
        let senderItem = parent
          ? messageCounts.subsenderCounts[String(name)]
          : senderCounts[String(name)]
        let total = null
        let emptyStatus = {
          health: 'unknown',
          healthMeter: 0,
          message: `No messages found for Sender ${
            displayName || name
          } matching the given filter.`,
        }
        if (senderItem) {
          total = senderItem.total
        }
        if (!parent) {
          senderTree.push({
            name,
            displayName,
            count: total,
            children: [],
            isCurrent,
            status: total ? status : emptyStatus,
            description,
            parent: null,
            parentCount,
          })
        }
      }
    )

    senders.forEach(
      ({
        name,
        parent,
        count,
        isCurrent,
        status,
        description,
        displayName,
      }) => {
        let senderItem = parent
          ? messageCounts.subsenderCounts[String(name)]
          : senderCounts[String(name)]
        let total = null
        let emptyStatus = {
          health: 'unknown',
          healthMeter: 0,
          message: `No messages found for Sender ${
            displayName || name
          } matching the given filter.`,
        }
        if (senderItem) {
          total = senderItem.total
        }
        if (parent) {
          var index = senderTree.findIndex((sender) => sender.name === parent)
          if (index !== -1) {
            senderTree[String(index)].children.push({
              name,
              displayName,
              count: total,
              isCurrent,
              status: total ? status : emptyStatus,
              description,
              parent,
            })
          } else {
            // sender has a parent (aka a subsender) but it is not listed in the senders data (aka not allowed to see it)
            senderTree.push({
              name,
              displayName,
              count,
              children: [],
              isCurrent,
              status,
              description,
              parent,
            })
          }
        }
      }
    )

    return senderTree.sort(compareEntities(entityTableSorts.senders))
  }


  return (
    <EuiFlexGroup wrap>
      {!projectName && (
        <EuiFlexItem>
          <EntityTable
            items={projects}
            addItem={addProject}
            removeItem={removeProject}
            selectedItems={selectedProjects}
            loading={loadingProjects}
            title='Projects'
            filteringEnabled={projectsChecked}
            onToggleFilter={onChangeProjectsSwitch}
            sort={entityTableSorts.projects}
            onSort={(projects) => {
              setEntityTableSorts((sorts) =>
                Object.assign({}, sorts, { projects })
              )
            }}
            entityType='project'
          />
        </EuiFlexItem>
      )}
      <EuiFlexItem>
        <EntityTable
          items={useCases}
          addItem={addUseCase}
          removeItem={removeUseCase}
          selectedItems={selectedUseCases}
          loading={loadingUseCases}
          title='Use Cases'
          filteringEnabled={useCasesChecked}
          onToggleFilter={onChangeUseCasesSwitch}
          sort={entityTableSorts.useCases}
          onSort={(useCases) => {
            setEntityTableSorts((sorts) =>
              Object.assign({}, sorts, { useCases })
            )
          }}
          entityType='useCase'
        />
      </EuiFlexItem>

      <SendersTable
        items={generateSenderTree(senders)}
        addItem={addSender}
        removeItem={removeSender}
        selectedItems={selectedSenders}
        loading={loadingSenders}
        title='Senders'
        filteringEnabled={sendersChecked}
        onToggleFilter={onChangeSendersSwitch}
        sort={entityTableSorts.senders}
        onSort={(senders) => {
          setEntityTableSorts((sorts) => Object.assign({}, sorts, { senders }))
        }}
        entityType='sender'
      />
      <EuiFlexItem>
        <EntityTable
          items={recipients}
          addItem={addRecipient}
          removeItem={removeRecipient}
          selectedItems={selectedRecipients}
          loading={loadingRecipients}
          title='Recipients'
          filteringEnabled={recipientsChecked}
          onToggleFilter={onChangeRecipientsSwitch}
          sort={entityTableSorts.recipients}
          onSort={(recipients) => {
            setEntityTableSorts((sorts) =>
              Object.assign({}, sorts, { recipients })
            )
          }}
          entityType='recipient'
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
