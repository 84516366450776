import React, { useContext } from "react";
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiIcon } from "@elastic/eui";
import { SupportContext } from "./support_tool";
import { formatRecentTime } from "../../lib/date";
import { jiraMap } from "./utils";

export const SupportTicket = ({ id, ticket, status, url, createdAt }) => {
  const { deleteSupportTicket } = useContext(SupportContext);
  const fStatus = status.toLowerCase();

  const ticketStatus = jiraMap[String(fStatus)]
    ? jiraMap[String(fStatus)]
    : jiraMap["default"];

  return (
    <EuiFlexGroup
      gutterSize="none"
      style={{
        alignItems: "center",
        padding: "4px 8px",
        border: "1px solid rgb(224, 226, 229)",
        borderRadius: "4px",
        background: "rgb(244, 246, 249)",
        margin: "4px 0",
      }}
    >
      <EuiFlexItem grow={false} style={{ padding: "7px" }}>
        <EuiIcon
          type="popout"
          // eslint-disable-next-line
          onClick={() => window.open(url, "_blank")}
          style={{ cursor: "pointer" }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup
          gutterSize="none"
          style={{
            justifyContent: "space-between",
            width: "100%",
            padding: "0 8px",
          }}
          direction="column"
        >
          <EuiFlexItem>
            <EuiFlexGroup gutterSize="none">
              <EuiFlexItem>
                <EuiText textAlign="left" size="xs" color={ticketStatus.color}>
                  {ticketStatus.displayName}
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false} style={{ padding: "0 8px" }}>
                <EuiText size="xs" color="#a0a6ba">
                  {formatRecentTime(createdAt)}
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText
              textAlign="left"
              size="s"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "11rem",
              }}
            >
              {ticket}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiIcon
          type="trash"
          style={{ cursor: "pointer" }}
          onClick={() => deleteSupportTicket(id)}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
