import moment from "moment-timezone";
import { parse as parseElasticDate } from "@elastic/datemath";

const formatTimeZone = (d) =>
  d ? moment.tz(d).format("z") : moment.tz(moment.tz.guess()).format("z");

const dateTimeFormat = "DD MMM YYYY H:mm:ss";
const dateTimeFormatWithZone = `DD MMM YYYY H:mm:ss [${formatTimeZone()}]`;
const dateTimeFormatWithZoneShort = `DD MMM YYYY H:mm [${formatTimeZone()}]`;
const dateFormat = "DD MMM YYYY";
const timeFormat = "H:mm:ss";
const timeFormatWithZone = `H:mm:ss [${formatTimeZone()}]`;
const dateFormatShort = "DD MMM YY";
const elasticsearchDateTimeFormat = "YYYY-MM-DD HH:mm:ss.SSSSSSZZ";
const dateDashFormat = "YYYY-MM-DD";
const longFormat = "dddd, MMMM Do YYYY, h:mm a"
const dateTimeReverse = "h:mmA MMM DD, YYYY"

const format = (string, format) => {
  const m = moment(string);
  if (typeof format === "function") {
    return m.isValid() ? format(m) : string;
  }
  return m.isValid() ? m.format(format) : string;
};

const formatDateTime = (dateTime) => format(dateTime, dateTimeFormat);
const formatDateTimeWithZone = (dateTime) =>
  format(dateTime, dateTimeFormatWithZone);
const formatDate = (date) => format(date, dateFormat);
const formatDateShort = (date) => format(date, dateFormatShort);
const formatEpochSecond = (dateTime) => format(dateTime, (m) => m.unix());
const formatTime = (time) => format(time, timeFormat);
const formatTimeWithZone = (time) => format(time, timeFormatWithZone);
const formatForElasticsearch = (dateTime) => format(dateTime, elasticsearchDateTimeFormat)
const formatDateDash = (dateTime) => format(dateTime, dateDashFormat)
const formatLongDate = (dateTime) => format(dateTime, longFormat)
const formatReverseDate = (dateTime) => format(dateTime, dateTimeReverse)

const calculateHistogramInterval = ({ date: [start, end], buckets = 100 }) => {
  const totalMilliseconds = parseElasticDate(end) - parseElasticDate(start);
  const intervalMilliseconds = totalMilliseconds / buckets;
  return `${Math.ceil(intervalMilliseconds)}ms`;
};

const getAbsoluteDatetime = (datetime) => {
  const m = moment(datetime)
  if (m.isValid()) {
    return datetime
  }
  const parsedDatetime = parseElasticDate(datetime)
  if (parsedDatetime.isValid()) {
    return parsedDatetime.format()
  }
  return datetime
}

const getEsAbsoluteDatetime = (datetime) => {
  const absoluteStr = getAbsoluteDatetime(datetime)
  const esFormatted = formatForElasticsearch(absoluteStr)
  return esFormatted
}

const formatRecentTime = (date) => {
  const seconds = moment.duration(moment().diff(moment(date))).asSeconds()
  if (seconds < 10) {
    return 'just now'
  } else if (seconds > 60 && seconds < 3600) {
    return `${parseInt(seconds / 60)}m ago`
  } else if (seconds > 3600 && seconds < 86400) {
    return `${parseInt(seconds / 3600)}h ago`
  } else if (seconds > 86400) {
    return `${parseInt(seconds / 86400)}d ago`
  } else {
    return `${parseInt(seconds)}s ago`
  }
}

export {
  formatDateTime,
  formatDateTimeWithZone,
  dateTimeFormat,
  dateTimeFormatWithZone,
  dateTimeFormatWithZoneShort,
  formatDate,
  formatDateShort,
  formatEpochSecond,
  parseElasticDate,
  calculateHistogramInterval,
  formatReverseDate,
  formatTimeZone,
  formatTime,
  formatTimeWithZone,
  formatForElasticsearch,
  getAbsoluteDatetime,
  getEsAbsoluteDatetime,
  formatDateDash,
  formatRecentTime,
  formatLongDate,
};
