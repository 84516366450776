import React from 'react'
import {
  EuiFlexItem,
  EuiText,
  EuiFlexGroup,
  EuiTitle,
  EuiSwitch,
  EuiToolTip,
} from '@elastic/eui'
import './entity_table_header.css'

export const EntityTableHeader = ({
  title,
  items = [],
  filteringEnabled = false,
  onToggleFilter = () => {},
}) => (
  <EuiFlexItem className='EntityTableHeader'>
    <EuiFlexGroup alignItems='center'>
      <EuiFlexItem grow={false}>
        <EuiTitle size='s'>
          <span>{title}</span>
        </EuiTitle>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText
          size='m'
          style={{
            fontWeight: '300',
            textAlign: 'left',
          }}
        >
          <p>{items.length}</p>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiToolTip
          position='top'
          content={
            filteringEnabled
              ? `Remove all ${title} from filter`
              : `Add all ${title} to filter`
          }
        >
          <EuiSwitch
            compressed
            checked={filteringEnabled}
            onChange={onToggleFilter}
            label=''
          />
        </EuiToolTip>
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiFlexItem>
)
