import React, { useContext } from 'react'
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { EntityTable } from '.'
import { SendersTable } from '.'
import { compareEntities } from '../dashboard/utils'
import { AppContext } from '../../app'
import { EtorContext } from '..'

export const EtorTableGroup = () => {
  let {
    selectedUseCases,
    selectedSenders,
    selectedRecipients,
    selectedEventTypes,
    selectedWarnings,
    messageCounts,
    loadingUseCases,
    loadingSenders,
    loadingRecipients,
    loadingEventTypes,
    useCasesChecked,
    onChangeUseCasesSwitch,
    sendersChecked,
    onChangeSendersSwitch,
    recipientsChecked,
    onChangeRecipientsSwitch,
    eventTypesChecked,
    onChangeEventTypesSwitch,
    warningsChecked,
    onChangeWarningsSwitch,
    setEntityTableSorts,
    entityTableSorts,
  } = useContext(AppContext)

  let {
    senders,
    useCases,
    addUseCase,
    removeUseCase,
    addSender,
    removeSender,
    recipients,
    addRecipient,
    removeRecipient,
    eventTypes,
    addEventType,
    removeEventType,
    warnings,
    addWarning,
    removeWarning,
  } = useContext(EtorContext)

  const generateSenderTree = (senders) => {
    let senderTree = []
    let senderCounts = messageCounts.senderCounts

    senders.forEach(
      ({
        name,
        parent,
        description,
        displayName,
        isCurrent,
        parentCount,
      }) => {
        let senderItem = parent
          ? messageCounts.subsenderCounts[String(name)]
          : senderCounts[String(name)]
        let total = null
        if (senderItem) {
          total = senderItem.total
        }
        if (!parent) {
          senderTree.push({
            name,
            displayName,
            count: total,
            children: [],
            isCurrent,
            status: null,
            description,
            parent: null,
            parentCount,
          })
        }
      }
    )

    senders.forEach(
      ({
        name,
        parent,
        count,
        isCurrent,
        description,
        displayName,
      }) => {
        let senderItem = parent
          ? messageCounts.subsenderCounts[String(name)]
          : senderCounts[String(name)]
        let total = null
        if (senderItem) {
          total = senderItem.total
        }
        if (parent) {
          var index = senderTree.findIndex((sender) => sender.name === parent)
          if (index !== -1) {
            senderTree[String(index)].children.push({
              name,
              displayName,
              count: total,
              isCurrent,
              status: null,
              description,
              parent,
            })
          } else {
            // sender has a parent (aka a subsender) but it is not listed in the senders data (aka not allowed to see it)
            senderTree.push({
              name,
              displayName,
              count,
              children: [],
              isCurrent,
              status: null,
              description,
              parent,
            })
          }
        }
      }
    )

    return senderTree.sort(compareEntities(entityTableSorts.senders))
  }

  const parentSenders = (senderTree) => {
    let parents = []
    for (let sender of senderTree) {
      if (sender.children.length > 0) {
        parents.push(sender.name)
      }
    }
    return parents
  }

  return (
    <EuiFlexGroup wrap>
      <EuiFlexItem>
        <EntityTable
          items={eventTypes}
          addItem={addEventType}
          removeItem={removeEventType}
          selectedItems={selectedEventTypes}
          loading={loadingEventTypes}
          title="Event Types"
          filteringEnabled={eventTypesChecked}
          onToggleFilter={onChangeEventTypesSwitch}
          sort={entityTableSorts.eventTypes}
          onSort={(eventTypes) => {
            setEntityTableSorts((sorts) =>
              Object.assign({}, sorts, { eventTypes })
            )
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EntityTable
          items={useCases}
          addItem={addUseCase}
          removeItem={removeUseCase}
          selectedItems={selectedUseCases}
          loading={loadingUseCases}
          title="Use Cases"
          filteringEnabled={useCasesChecked}
          onToggleFilter={onChangeUseCasesSwitch}
          sort={entityTableSorts.useCases}
          onSort={(useCases) => {
            setEntityTableSorts((sorts) =>
              Object.assign({}, sorts, { useCases })
            )
          }}
        />
      </EuiFlexItem>

      <SendersTable
        items={generateSenderTree(senders)}
        addItem={addSender}
        removeItem={removeSender}
        selectedItems={selectedSenders}
        loading={loadingSenders}
        title="Senders"
        filteringEnabled={sendersChecked}
        onToggleFilter={onChangeSendersSwitch}
        sort={entityTableSorts.senders}
        onSort={(senders) => {
          setEntityTableSorts((sorts) => Object.assign({}, sorts, { senders }))
        }}
        senderTree={generateSenderTree(senders)}
        parents={parentSenders(generateSenderTree(senders))}
      />
      <EuiFlexItem>
        <EntityTable
          items={recipients}
          addItem={addRecipient}
          removeItem={removeRecipient}
          selectedItems={selectedRecipients}
          loading={loadingRecipients}
          title="Recipients"
          filteringEnabled={recipientsChecked}
          onToggleFilter={onChangeRecipientsSwitch}
          sort={entityTableSorts.recipients}
          onSort={(recipients) => {
            setEntityTableSorts((sorts) =>
              Object.assign({}, sorts, { recipients })
            )
          }}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EntityTable
          items={warnings}
          addItem={addWarning}
          removeItem={removeWarning}
          selectedItems={selectedWarnings}
          title="Warnings"
          filteringEnabled={warningsChecked}
          onToggleFilter={onChangeWarningsSwitch}
          sort={entityTableSorts.warnings}
          onSort={(warnings) => {
            setEntityTableSorts((sorts) =>
              Object.assign({}, sorts, { warnings })
            )
          }}
        />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
