import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { linearGradientDef } from '@nivo/core'
import { TableTooltip } from '@nivo/tooltip'
import { LegendSvgItem } from '@nivo/legends'
import {
  formatDateShort,
  formatTime,
  formatDateTime,
  formatTimeZone,
} from '../../lib'

const Chip = ({ color }) => (
  <span
    style={{
      display: 'block',
      width: '12px',
      height: '12px',
      background: color,
    }}
  />
)

const normalizeInterval = ({ interval }) => {
  interval = /^(\d+)ms$/.exec(interval)[1]
  let unit = ''
  if (interval < 1000) {
    unit = 'Millisecond'
  } else if (interval < 60000) {
    unit = 'Second'
    interval = Math.ceil(interval / 1000)
  } else if (interval < 3600000) {
    interval = Math.ceil(interval / 60000)
    unit = 'Minute'
  } else if (interval < 86400000) {
    interval = Math.ceil(interval / 3600000)
    unit = 'Hour'
  } else {
    interval = Math.ceil(interval / 86400000)
    unit = 'Day'
  }
  return { value: interval, unit }
}

const pallette = [
  '#0055ff',
  '#0099ff',
  '#11dddd',
  '#05dd66',
  '#ffcc00',
  '#ff9900',
  '#ff3333',
  '#DD0A73',
  '#bb00ff',
  '#6600ff',
]

const buildColors = ({ data, highlightLines = [] }) =>
  data.map(({ id }, i) =>
    highlightLines.includes(id) || !(highlightLines && highlightLines.length)
      ? pallette[i % pallette.length]
      : '#ddd'
  )

const shouldAnimate = (data) => {
  const serie = data && data[0] && data[0].data
  if (!(serie && serie.length)) {
    return false
  }
  return serie[serie.length - 1].x - serie[0].x > 8000
}

const CustomLegends =
  ({ highlightLines }) =>
  // eslint-disable-next-line react/display-name
  ({ legends, width }) => {
    return (
      <React.Fragment>
        {legends.map((legend, legendIndex) => {
          return (
            <React.Fragment key={`legend.${legendIndex}`}>
              {legend.data.map((data, dataIndex) => {
                return (
                  <g
                    transform={`translate(${width - legend.itemWidth - 55},${
                      legend.itemHeight * dataIndex
                    })`}
                    key={dataIndex}
                  >
                    <LegendSvgItem
                      {...legend}
                      key={`item.${dataIndex}`}
                      width={legend.itemWidth}
                      height={legend.itemHeight}
                      direction={legend.itemDirection}
                      textColor={legend.itemTextColor}
                      background={legend.itemBackground}
                      opacity={legend.itemOpacity}
                      x={0}
                      y={0}
                      data={data}
                    />
                    <title>
                      {legend.data.length > 1
                        ? highlightLines.includes(data.id)
                          ? `Remove ${data.label} from highlights`
                          : `Highlight ${data.label}`
                        : data.label}
                    </title>
                  </g>
                )
              })}
            </React.Fragment>
          )
        })}
      </React.Fragment>
    )
  }

export const NivoLineGraph = ({
  data,
  interval,
  highlightLines,
  setHighlightLines = () => {},
}) => {
  interval = normalizeInterval({ interval })
  data = data.map((serie) =>
    Object.assign({}, serie, {
      data: serie.data.map((point) =>
        Object.assign({}, point, { y: Math.ceil(point.y / interval.value) })
      ),
    })
  )
  const toggleHighlightLines = (id) => {
    setHighlightLines((prev = []) =>
      prev.includes(id) ? prev.filter((x) => x !== id) : [...prev, id]
    )
  }
  const colors = buildColors({ data, highlightLines })
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 20, right: 110, bottom: 50, left: 60 }}
      xScale={{
        type: 'time',
        format: '%Q',
      }}
      animate={shouldAnimate(data)}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
      }}
      axisTop={null}
      axisRight={null}
      lineWidth={1}
      enablePoints={false}
      enableArea={true}
      defs={[
        linearGradientDef('gradientA', [
          { offset: 0, color: 'inherit' },
          { offset: 100, color: 'inherit', opacity: 0 },
        ]),
      ]}
      fill={[{ match: '*', id: 'gradientA' }]}
      enableSlices='x'
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 45,
        renderTick: (tick) => {
          return (
            <g transform={`translate(${tick.x},${tick.y + 22})`}>
              <line
                stroke='rgb(119,119,119)'
                strokeWidth={1.5}
                y1={-22}
                y2={-17}
              />
              <text
                textAnchor='start'
                dominantBaseline='central'
                style={{
                  fill: '#333',
                  fontSize: 10,
                }}
                transform={`rotate(${tick.rotate}) translate(-10, -5)`}
              >
                {formatDateShort(tick.value)}
              </text>
              <text
                textAnchor='start'
                dominantBaseline='central'
                style={{
                  fill: '#333',
                  fontSize: 10,
                }}
                transform={`rotate(${tick.rotate}) translate(-10, 5)`}
              >
                {formatTime(tick.value)}
              </text>
            </g>
          )
        },
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: `Messages Per ${interval.unit}`,
        legendOffset: -55,
        legendPosition: 'middle',
      }}
      crosshairType='cross'
      colors={colors}
      sliceTooltip={({ slice, axis }) => {
        const otherAxis = axis === 'x' ? 'y' : 'x'
        return (
          <TableTooltip
            title={
              <div style={{ marginBottom: '0.5em' }}>
                {slice.points.length &&
                  `${formatDateTime(
                    slice.points[0].data.x
                  )} ${formatTimeZone()}`}
              </div>
            }
            rows={slice.points.map((point, i) => [
              <Chip key={`chip-${i}`} color={point.serieColor} />,
              point.serieId,
              <strong key={`value-${i}`}>
                {point.data[`${otherAxis}Formatted`]}
              </strong>,
            ])}
          />
        )
      }}
      layers={[
        'areas',
        'axes',
        'crosshair',
        'grid',
        'lines',
        'markers',
        'slices',
        CustomLegends({ highlightLines }),
      ]}
      legends={[
        {
          data: data.map(({ id }, i) => ({
            id,
            label: id,
            color: colors[i % colors.length],
          })),
          anchor: 'top-right',
          direction: 'column',
          itemDirection: 'left-to-right',
          itemWidth: 100,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          onClick: ({ id }) => {
            toggleHighlightLines(id)
          },
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  )
}
