import React, { useContext } from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButtonIcon,
  EuiHorizontalRule,
  EuiIcon,
} from '@elastic/eui'
import { AppContext } from '../../app'
import { ExportUsersForm } from './export_users_form'

export const ExportUsersTool = () => {

  const {
    exportToolExpanded,
    setExportToolExpanded,
    setShowToolBar,
    showToolBar,
  } = useContext(AppContext)


  const exportToolButton = () => {
    return (
      <EuiButtonIcon
        color='text'
        onClick={() => setExportToolExpanded(!exportToolExpanded)}
        iconType='exportAction'
        aria-label='export users'
        id='exportPanelButton'
      />
    )
  }

  if (!showToolBar) {
    return (
      <div className='ExportBar-hidden'>
        <EuiFlexGroup
          gutterSize='none'
          className='tool-bar-arrow-hidden'
          onClick={() => setShowToolBar(true)}
        >
          <EuiFlexItem grow={false}>
            <EuiIcon
              type='arrowLeft'
              color='text'
              size='s'
              style={{ margin: 'auto' }}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    )
  }

  if (!exportToolExpanded) {
    return (
      <EuiFlexGroup
        gutterSize='none'
        style={{
          padding: '4px',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {exportToolButton()}
        <EuiFlexItem
          grow={false}
          className='tool-bar-arrow'
          onClick={() => setShowToolBar(false)}
        >
          <EuiIcon type='arrowRight' color='text' size='s' />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }
  return (
    <>
      <EuiFlexGroup gutterSize='m' style={{ padding: '8px 4px' }}>
        <EuiFlexItem grow={false}>{exportToolButton()}</EuiFlexItem>
        <EuiFlexItem style={{ margin: 'auto 0' }}>
          <EuiText textAlign='left'>Export Users</EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            color='text'
            onClick={() => setExportToolExpanded(!exportToolExpanded)}
            iconType='cross'
            aria-label='export users'
            style={{ marginRight: '0', marginLeft: 'auto' }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule margin='xs' />

      <ExportUsersForm />
    </>
  )
}
