import React, { Fragment } from 'react'
import { EuiButton, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import accessRequestEmailBody from './accessRequestEmailBody'

const idNoUserFound = 'noUserFound'

const identify = ({ title, text }) => {
  if (/^No user found matching email/.test(text)) {
    return idNoUserFound
  }
  return `${title}${text}`
}

const buildToast = ({ title, color, text, iconType, toastLifeTimeMs }) => {
  const id = identify({ title, text })
  if (id === idNoUserFound) {
    title = 'Registration Incomplete'
    color = 'primary'
    iconType = 'training'
    toastLifeTimeMs = 1800000
    text = (
      <Fragment>
        It looks like you don&apos;t yet have data access on Dash. Please request
        access.
        <EuiFlexGroup justifyContent='flexEnd' gutterSize='s'>
          <EuiFlexItem grow={false}>
            <EuiButton
              iconType='email'
              size='s'
              target='_blank'
              style={{ marginTop: '1em' }}
              href={`mailto:support@aimsplatform.com?subject=Dash Access Request for ${
                /^No user found matching email (.+)/.exec(text)[1]
              }&body=${accessRequestEmailBody}`}
            >
              Request Access
            </EuiButton>
          </EuiFlexItem>
        </EuiFlexGroup>
      </Fragment>
    )
  }
  return {
    id,
    title,
    color,
    text,
    iconType,
    toastLifeTimeMs,
  }
}

export const Toaster =
  (setToasts) =>
  ({ title, color, text, iconType, toastLifeTimeMs }) => {
    const newToast = buildToast({
      title,
      color,
      text,
      iconType,
      toastLifeTimeMs,
    })
    setToasts((toasts = []) => {
      toasts = toasts.filter((t) => t.id !== newToast.id)
      return toasts.concat(newToast)
    })
  }
