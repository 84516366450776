import React from 'react'
import { ErrorMessage } from './error_message'
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.reset = this.reset.bind(this)
    this.state = { hasError: false, errorMessage: null }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.toString() }
  }

  componentDidCatch(error, info) {
    // todo: add sending log to sentry or other service
    console.log(error, info.componentStack)
  }

  reset() {
    this.setState({ hasError: false, errorMessage: null })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage error={this.state.errorMessage} reset={this.reset} />
    }

    return this.props.children
  }
}
export { ErrorBoundary }
