import React, { useContext, useState } from 'react'

import { GroupsPageContext } from './groups_page'
import { GroupEntitiesList } from './group_entities_list'

import {
  EuiIcon,
  EuiCheckbox,
  EuiTab,
  EuiTabs
} from '@elastic/eui'

import { AppContext } from '../../app'

import { LEFT_ALIGNMENT, CENTER_ALIGNMENT } from '@elastic/eui/lib/services'


const getGroupEntities = (groupEntities, entityType) => {
    return groupEntities.reduce((acc, e) => {
    if (e.entityType === entityType) {
      acc.push({ name: e.entityName })
    }
    return acc
  }, [])
}

export const GroupEntities = ({
  entities,
  filteredEntities,
  filteredEntitiesView,
  findEntityIdx
}) => {
  const { groupsState, groupsDispatch } = useContext(GroupsPageContext)
  const { keycloak } = useContext(AppContext)

  const [activeTab, setActiveTab] = useState('project')

  const entityIconMap = {
    project: 'package',
    useCase: 'wrench',
    sender: 'push',
    subsender: 'push',
    recipient: 'importAction'
  }

  const mode = groupsState.groupModal
  const group = groupsState.activeGroup
  const groupEntities = groupsState.activeGroup.entities
  const groupRules = groupsState.activeGroup.rules

  const onSelect = (entity, entityType) => {
    const nGroup = { ...group }
    const nEntities = [...groupEntities]
    const eType = entity.parent ? 'subsender' : entityType
    const idx = findEntityIdx(entity.name, entityType)
    if (idx !== -1) {
      nEntities.splice(idx, 1)
      const rulesIdx = groupRules.findIndex(r =>
        (r.resourceType === entityType) && (r.resourceName === entity.name))
      if (rulesIdx !== -1) {
        const nRules = [...groupRules]
        nRules.splice(rulesIdx, 1)
        nGroup.rules = nRules
      }
    } else {
      nEntities.push({
        entityName: entity.name,
        entityType: eType
      })
    }
    nGroup.entities = nEntities
    groupsDispatch({
      type: 'set',
      state: 'activeGroup',
      value: nGroup
    })
  }

  const tabs = [
    {
      id: 'project',
      name: 'Projects',
      disabled: false,
      onClick: () => setActiveTab('project'),
    },
    {
      id: 'useCase',
      name: 'Use Cases',
      disabled: false,
      onClick: () => setActiveTab('useCase')
    },
    {
      id: 'sender',
      name: 'Senders',
      disabled: false,
      onClick: () => setActiveTab('sender')
    },
    {
      id: 'recipient',
      name: 'Recipients',
      disabled: false,
      onClick: () => setActiveTab('recipient')
    },
  ]

  const renderTabs = () => {
    return tabs.map((tab, idx) => (
      <EuiTab
        onClick={tab.onClick}  
        isSelected={tab.id === activeTab}
        disabled={tab.disabled}
        key={idx}
      >
        {tab.name}
      </EuiTab>
    ))
  }

  const renderTabContent = () => {
    let rows = []
    const cols = [
      {
        id: 'entity-icon',
        label: '',
        alignment: LEFT_ALIGNMENT,
        width: '32px',
        textOnly: false,
        render: () => <EuiIcon type={entityIconMap[String(activeTab)]} />,
        mobileOptions: {
          show: false,
        },
      },
      {
        id: 'name',
        label: 'Name',
        alignment: LEFT_ALIGNMENT,
        truncateText: true,
        render: (col, row) => {
          const content = row.displayName ? `${row.displayName} (${row.name})` : row.name
          return (
            <>{content}</>
          )
        }
      }
    ]
    if (mode === 'view') {
      rows = getGroupEntities(group.entities, activeTab)
    }
    if (mode == 'create') {
      rows = entities[String(activeTab)]
      cols.unshift({
        id: 'checkbox',
        width: '32px',
        render: (col, row) => {
          const entityType = row.parent ? 'subsender' : activeTab
          return (
            <EuiCheckbox
              id={`groups-entities-${row.name}-checkbox`}
              checked={findEntityIdx(row.name, entityType) !== -1}
              onChange={() => onSelect(row, entityType)}
              type='inList'
            />
          )
        }
      })
    }
    if (mode == 'edit') {
      rows = entities[String(activeTab)]
      cols.unshift({
        id: 'checkbox',
        width: '32px',
        render: (col, row) => {
          const entityType = row.parent ? 'subsender' : activeTab
          return (
            <EuiCheckbox
              id={`groups-entities-${row.name}-checkbox`}
              checked={findEntityIdx(row.name, entityType) !== -1}
              onChange={() => onSelect(row, entityType)}
              type='inList'
            />
          )
        }
      })
    }

    const items = filteredEntities[String(activeTab)] ? filteredEntities[String(activeTab)] : rows

    return (
      <GroupEntitiesList columns={cols} items={items} filteredItems={filteredEntities} />
    )
  }

  const viewCols = [
    {
      id: 'entity-icon',
      label: '',
      alignment: CENTER_ALIGNMENT,
      width: '42px',
      textOnly: false,
      render: (col, row) => <EuiIcon type={entityIconMap[String(row.entityType)]} />,
      mobileOptions: {
        show: false,
      },
    },
    {
      id: 'entityName',
      label: 'Name',
      alignment: LEFT_ALIGNMENT,
      truncateText: true,
    },
    {
      id: 'entityType',
      label: 'Type',
      alignment: LEFT_ALIGNMENT,
      truncateText: true,
    }
  ]

  if (mode === 'view' || !keycloak.hasClientRole({ roleName: 'edit' })) {
    const viewItems = filteredEntitiesView ? filteredEntitiesView : groupEntities
    return (
      <>
        <GroupEntitiesList columns={viewCols} items={viewItems} filteredItems={filteredEntitiesView} />
      </>
    )
  }

  return (
    <>
      <EuiTabs>
        {renderTabs()}
      </EuiTabs>
      {renderTabContent()}
    </>
  )
}
