import React from 'react'
import { EuiFlexGroup, EuiFlexItem, EuiProgress } from '@elastic/eui'

export const TableLoadingIndicator = () => {
  return (
    <EuiFlexGroup direction='column' gutterSize='s' className='table-loader'>
      <EuiFlexItem grow={true}>
        <EuiProgress size='xs' color='primary' />
      </EuiFlexItem>
      <EuiFlexItem>Loading...</EuiFlexItem>
      <EuiFlexItem>
        <EuiProgress size='xs' color='primary' />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
