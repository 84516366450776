import React, {
  useState,
  useContext,
  createContext,
  useEffect
} from 'react'
import {
  EuiPage,
  EuiPageBody,
  EuiTitle,
  EuiPanel,
  EuiLoadingChart,
  EuiFlexGroup,
  EuiButton,
} from '@elastic/eui'
import { useParams } from 'react-router-dom'
import { EntityTableGroup, MessagesStats } from '..'
import { NivoLineGraph } from '..'
import { MessagesTable } from '..'
import { evaluateStatusCounts } from '../../lib'
import { countSelected, compareEntities } from './utils'
import './dashboard_page.css'

import { AppContext } from '../../app'

export const DashContext = createContext()

export const DashboardPage = () => {
  let {
    projects,
    useCases,
    senders,
    senderTree,
    recipients,
    loadingMessageCounts,
    loadingMessageCountsOverTime,
    messageCountsOverTime,
    selectedStatuses,
    selectedProjects,
    selectedUseCases,
    selectedSenders,
    selectedRecipients,
    updateSelectedStatuses,
    updateSelectedProjects,
    updateSelectedUseCases,
    updateSelectedRecipients,
    updateSelectedSenders,
    messageCounts,
    highlightLines,
    setHighlightLines,
    entityTableSorts,
    selectedProjectData,
    setSelectedProjectData,
    navBarExpanded,
    exportToolExpanded,
    supportToolExpanded,
  } = useContext(AppContext)

  const { projectName } = useParams()
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({})
  const [expandAllMessages, setExpandAllMessages] = useState(false)

  const initEntities = {
    projects: [...projects],
    useCaes: [...useCases],
    senders: [...senders],
    recipients: [...recipients]
  }
  const [entities, setEntities] = useState({ ...initEntities })

  if (projectName && !selectedProjects.includes(projectName)) {
    updateSelectedProjects([projectName])
  }

  const project = projects.reduce((p, proj) => {
    if (proj.name === projectName) {
      return proj
    }
    return p
  }, {})

  useEffect(() => {
    const nEntities = { ...entities }
    nEntities.projects = projects
      .map(({ name, description, displayName }) => {
        const isCurrent =
          !selectedProjects.length || selectedProjects.includes(name)
        const { total, ...counts } = isCurrent
          ? messageCounts.projectCounts[String(name)] || {}
          : messageCounts.previous.projectCounts[String(name)] || {}
        const status = evaluateStatusCounts({
          counts,
          entityName: displayName || name,
          entityType: 'project',
        })
        return {
          name,
          displayName,
          count: countSelected({ total, counts, selectedStatuses }),
          isCurrent,
          status,
          description,
        }
      })
      .sort(compareEntities(entityTableSorts.projects))

    nEntities.useCases = useCases
      .map(({ name, description, displayName }) => {
        const isCurrent =
          !selectedUseCases.length || selectedUseCases.includes(name)
        const { total, ...counts } = isCurrent
          ? messageCounts.useCaseCounts[String(name)] || {}
          : messageCounts.previous.useCaseCounts[String(name)] || {}
        const status = evaluateStatusCounts({
          counts,
          entityName: displayName || name,
          entityType: 'useCase',
        })
        return {
          name,
          displayName,
          count: countSelected({ total, counts, selectedStatuses }),
          isCurrent,
          status,
          description,
        }
      })
      .sort(compareEntities(entityTableSorts.useCases))

    nEntities.senders = senders
      .map(({ name, description, parent, displayName }) => {
        var index = senderTree.findIndex((sender) => sender.name === name)
        let childSelected = false
        if (index !== -1) {
          // located a parent
          let parent = senderTree[String(index)]
          let children = parent.children

          // check if item is a parent

          // check if any of the parents children are selected
          for (let child of children) {
            if (selectedSenders.includes(child.name)) {
              childSelected = true

              break
            }
          }
        }

        const isCurrent =
          !selectedSenders.length ||
          selectedSenders.includes(name) ||
          childSelected

        const countsType = parent ? 'subsenderCounts' : 'senderCounts'

        const { total, parentCount, ...counts } = isCurrent
          ? messageCounts[String(countsType)][String(name)] || {}
          : messageCounts.previous[String(countsType)][String(name)] || {}
        const status = evaluateStatusCounts({
          counts,
          entityName: displayName || name,
          entityType: 'sender',
        })
        return {
          name,
          displayName,
          count: countSelected({ total, counts, selectedStatuses }),
          isCurrent,
          status,
          description,
          parent,
          parentCount,
        }
      })
      .sort(compareEntities(entityTableSorts.senders))

    nEntities.recipients = recipients
      .map(({ name, description, displayName }) => {
        const isCurrent =
          !selectedRecipients.length || selectedRecipients.includes(name)
        const { total, ...counts } = isCurrent
          ? messageCounts.recipientCounts[name.toLowerCase()] || {}
          : messageCounts.previous.recipientCounts[name.toLowerCase()] || {}
        const status = evaluateStatusCounts({
          counts,
          entityName: displayName || name,
          entityType: 'recipient',
        })
        return {
          name,
          count: countSelected({ total, counts, selectedStatuses }),
          isCurrent,
          status,
          description,
        }
      })
      .sort(compareEntities(entityTableSorts.recipients))
    setEntities(nEntities)
  }, [messageCounts])

  const addStatus = (status) => {
    updateSelectedStatuses([...selectedStatuses, status])
  }

  const removeStatus = (status) => {
    updateSelectedStatuses(selectedStatuses.filter((s) => s !== status))
  }

  const addProject = (name) => {
    updateSelectedProjects([...selectedProjects, name])
  }

  const removeProject = (name) => {
    let updatedSelectedProjects = [].concat(selectedProjects)
    let projectIndex = updatedSelectedProjects.indexOf(name)
    updatedSelectedProjects.splice(projectIndex, 1)

    updateSelectedProjects(updatedSelectedProjects)
  }

  const addUseCase = (name) => {
    updateSelectedUseCases([...selectedUseCases, name])
  }

  const removeUseCase = (name) => {
    let updatedSelectedUseCases = [].concat(selectedUseCases)
    let projectIndex = updatedSelectedUseCases.indexOf(name)
    updatedSelectedUseCases.splice(projectIndex, 1)

    updateSelectedUseCases(updatedSelectedUseCases)
  }

  const addRecipient = (name) => {
    updateSelectedRecipients([...selectedRecipients, name])
  }

  const removeRecipient = (name) => {
    let updatedSelectedRecipients = [].concat(selectedRecipients)
    let projectIndex = updatedSelectedRecipients.indexOf(name)
    updatedSelectedRecipients.splice(projectIndex, 1)

    updateSelectedRecipients(updatedSelectedRecipients)
  }

  const addSender = (name) => {
    var index = senderTree.findIndex((sender) => sender.name === name)
    let latestSelectedSenders = [].concat(selectedSenders)

    let children = []
    if (index !== -1) {
      // located a non child sender (sender without a parent)
      let selectedSender = senderTree[String(index)]
      if (selectedSender.children.length > 0) {
        children = selectedSender.children.map((child) => {
          return child.name
        })
      }
    }
    updateSelectedSenders([...latestSelectedSenders, name, ...children])
    // updateSelectedSenders([...latestSelectedSenders, name])
  }

  const removeSender = (name) => {
    var index = senderTree.findIndex((sender) => sender.name === name)
    let updatedSelectedSenders = [].concat(selectedSenders)

    let selectedSenderIndex = updatedSelectedSenders.indexOf(name)
    updatedSelectedSenders.splice(selectedSenderIndex, 1)

    let children = []
    let parent = null
    if (index !== -1) {
      // located a parent
      let parent = senderTree[String(index)]
      children = parent.children.map((child) => {
        return child.name
      })

      for (let child of children) {
        let selectedSenderIndex = updatedSelectedSenders.indexOf(child.name)
        updatedSelectedSenders.splice(selectedSenderIndex, 1)
      }
    } else {
      // selected sender is a child, make sure to remove its parent if no children are selected any longer
      for (let sender of senderTree) {
        if (sender.children.length > 0) {
          for (let child of sender.children) {
            if (child.name === name) {
              parent = child.parent
            }
          }
        }
      }
    }

    if (parent) {
      let siblingsSelected = false
      let parentSender = null
      let children = []
      var parentIndex = senderTree.findIndex((sender) => sender.name === parent)

      if (parentIndex !== -1) {
        parentSender = senderTree[String(parentIndex)]
        children = parentSender.children

        for (let child of children) {
          if (updatedSelectedSenders.includes(child.name)) {
            siblingsSelected = true
          }
        }
      }

      if (!siblingsSelected && updatedSelectedSenders.includes(parent)) {
        // no siblings remain selected, remove parent if selected
        let selectedSenderIndex = updatedSelectedSenders.indexOf(parent)
        updatedSelectedSenders.splice(selectedSenderIndex, 1)
      }
    }

    updateSelectedSenders(updatedSelectedSenders)
  }

  const addProjectData = (newFieldObject) => {
    const projectData = Object.assign({}, selectedProjectData)
    const projectName = Object.keys(newFieldObject)[0]
    const newField = Object.keys(newFieldObject[String(projectName)])[0]

    if (projectData[String(projectName)]) {
      projectData[String(projectName)][String(newField)] =
        newFieldObject[String(projectName)][String(newField)]
    } else {
      projectData[String(projectName)] = newFieldObject[String(projectName)]
    }
    setSelectedProjectData(projectData)
  }

  const removeProjectData = (newFieldObject) => {
    let projectData = Object.assign({}, selectedProjectData)
    const projectName = Object.keys(newFieldObject)[0]
    const newField = Object.keys(newFieldObject[String(projectName)])[0]

    if (Object.keys(projectData[String(projectName)]).length > 1) {
      delete projectData[String(projectName)][String(newField)]
    } else {
      delete projectData[String(projectName)]
    }
    setSelectedProjectData(projectData)
  }

  const toggleExpand = (id) => {
    const expandRowMapValues = { ...itemIdToExpandedRowMap }
    if (expandRowMapValues[String(id)]) {
      delete expandRowMapValues[String(id)]
    } else {
      expandRowMapValues[String(id)] = true
    }
    setItemIdToExpandedRowMap(expandRowMapValues)
  }

  const toggleExpandAll = () => {
    if (expandAllMessages) {
      setItemIdToExpandedRowMap({})
      setExpandAllMessages(false)
    } else {
      setExpandAllMessages(true)
    }
  }

  const value = {
    projectName,
    senders: entities.senders,
    projects: entities.projects,
    addProject,
    removeProject,
    useCases: entities.useCases,
    addUseCase,
    removeUseCase,
    addSender,
    removeSender,
    recipients: entities.recipients,
    addRecipient,
    removeRecipient,
    addStatus,
    removeStatus,
    addProjectData,
    removeProjectData,
    toggleExpand,
    toggleExpandAll,
    expandAllMessages,
    itemIdToExpandedRowMap,
    setItemIdToExpandedRowMap,
  }

  return (
    <DashContext.Provider value={value}>
      <EuiPage
        className={`euiNavDrawerPage
          ${navBarExpanded ? 'navBarExpanded' : 'navBarCollapsed'}
          ${(exportToolExpanded || supportToolExpanded) &&
          'export-bar-expanded-pad'
          }`}
      >
        <EuiPageBody className='euiNavDrawerPage__pageBody'>
          <div className='page_wrapper'>
            <MessagesStats
              project={project}
              messageCounts={messageCounts && messageCounts.total}
              loadingMessageCounts={loadingMessageCounts}
              updateSelectedStatuses={updateSelectedStatuses}
              selectedStatuses={selectedStatuses}
            />
            <EntityTableGroup />
            <EuiTitle size='m' className='sectionHeader'>
              <p>Messages</p>
            </EuiTitle>
            <EuiPanel
              style={{
                height: 300,
                marginBottom: '1.75em',
                textAlign: 'right',
                position: 'relative',
              }}
            >
              {loadingMessageCountsOverTime && (
                <EuiLoadingChart
                  size='l'
                  style={{ position: 'absolute', top: '8px', right: '6px' }}
                />
              )}
              {messageCountsOverTime && (
                <NivoLineGraph
                  data={messageCountsOverTime.counts}
                  interval={messageCountsOverTime.interval}
                  highlightLines={highlightLines}
                  setHighlightLines={setHighlightLines}
                />
              )}
            </EuiPanel>
            <EuiFlexGroup
              gutterSize='none'
              justifyContent='flexEnd'
              style={{ marginBottom: '.5em' }}
            >
              <EuiButton
                size='s'
                color='primary'
                iconType={expandAllMessages ? 'eye' : 'eyeClosed'}
                iconSide='right'
                onClick={() => toggleExpandAll()}
                fill={expandAllMessages}
                style={{ border: 'none', boxShadow: 'none' }}
              >
                Expand All
              </EuiButton>
            </EuiFlexGroup>
            <EuiPanel
              style={{
                height: 500,
                overflow: 'auto',
                padding: 0,
              }}
            >
              <MessagesTable />
            </EuiPanel>
          </div>
        </EuiPageBody>
      </EuiPage>
    </DashContext.Provider>
  )
}
