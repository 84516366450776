import React, { useState } from 'react'
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiLink,
  EuiText,
  EuiSpacer,
  EuiPopover,
  EuiButton,
  EuiButtonEmpty,
} from '@elastic/eui'
import './headerUserMenu.css'
import urljoin from 'url-join'
import aimsLogo from '../../assets/aims.svg'

export default function HeaderUserMenu({
  keycloak,
  reportIssueHidden,
  featureRequestHidden,
  userGuideUrl,
}) {
  const [isOpen, setIsOpen] = useState(false)

  const onMenuButtonClick = () => {
    setIsOpen(!isOpen)
  }

  const closeMenu = () => {
    setIsOpen(false)
  }

  const userFullName = keycloak.getUserFullName()

  const button = (
    <EuiHeaderSectionItemButton
      aria-expanded={isOpen}
      aria-haspopup='true'
      aria-label='Account menu'
      onClick={onMenuButtonClick}
    >
      <EuiAvatar name={userFullName} size='s' />
    </EuiHeaderSectionItemButton>
  )

  const editProfileUrl = urljoin(
    keycloak.authServerUrl,
    `realms/${keycloak.realm}/account`
  )

  const links = [
    {
      icon: 'link',
      href: 'https://g-d7244a042b.grafana-workspace.us-east-1.amazonaws.com/',
      display: 'Grafana',
    },
    {
      icon: aimsLogo,
      href: 'https://aphlstatus.statuspage.io/#:~:text=AIMS%20Platform%20Dashboard',
      display: 'AIMS Platform Status',
    },
    {
      icon: 'logoAWSMono',
      href: 'https://health.aws.amazon.com/health/status',
      display: 'AWS Status',
    },
    {
      icon: 'reporter',
      href: userGuideUrl
        ? userGuideUrl
        : 'https://docs.google.com/document/d/e/2PACX-1vRJ9ROOsntIkIamcRUKgAt5kDAqBk-LnAUwnQepfZn5YixanVt7ez9jIxXw9com12E-wodrW5GIbCEl/pub',
      display: 'User guide',
    },
    {
      icon: 'bug',
      href: 'https://aphlinformatics.atlassian.net/servicedesk/customer/portal/3',
      display: 'Report an issue',
      hidden: reportIssueHidden ? true : false
    },
    {
      icon: 'beaker',
      href: 'https://aphlinformatics.atlassian.net/servicedesk/customer/portal/15/group/47/create/251',
      display: 'Submit a feature request',
      hidden: featureRequestHidden ? true : false
    }
  ]

  return (
    <EuiPopover
      ownFocus
      button={button}
      isOpen={isOpen}
      anchorPosition='downRight'
      closePopover={closeMenu}
      panelPaddingSize='s'
      repositionOnScroll
      style={{ width: '60px' }}
    >
      <EuiFlexGroup
        gutterSize='none'
        className='euiHeaderProfile'
        responsive={false}
        style={{ flexDirection: 'column' }}
      >
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <EuiAvatar name={userFullName} size='l' />
          </EuiFlexItem>
          <EuiFlexGroup
            gutterSize='none'
            style={{
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <EuiFlexItem grow={false}>
              <EuiText className='UserFullName'>
                <p>{userFullName}</p>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false} className='NoFocusColor'>
              <EuiLink
                className='EditProfileLink NoFocusColor'
                href={editProfileUrl}
              >
                Edit profile
              </EuiLink>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexGroup>

        <EuiFlexItem>

          <EuiSpacer size='m' />

          <EuiFlexGroup
            direction='column'
            gutterSize='s'
          >

            {links.map((link, idx) => (
              <EuiFlexItem key={`user-menu-links-${idx}`}>
                <EuiButtonEmpty
                  iconSide='left'
                  iconType={link.icon}
                  size='s'
                  href={link.href}
                  flush='left'
                  className='user-menu-link'
                >
                  {link.display}
                </EuiButtonEmpty>
              </EuiFlexItem>
            ))}
            <EuiFlexItem grow={false}>
              <EuiSpacer size='s' />
              <EuiButton
                variant='filled'
                iconType='exit'
                fill
                onClick={() => {
                  keycloak.logout()
                }}
              >
                Log out
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>

  )
}
