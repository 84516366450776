import React, {
  useState,
  Fragment,
  useContext,
  useEffect,
  useCallback,
} from "react";

import {
  EuiIcon,
  EuiHeaderSectionItemButton,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiTitle,
  EuiFlyoutFooter,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonEmpty,
  EuiText,
} from "@elastic/eui";

import { AppContext } from "../../app";
import { LatestNotifications } from "..";
import api from "../../lib/api";

export default function HeaderUpdates() {
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const {
    packageInfo,
    latestNotifications,
    setLatestNotifications,
    toaster,
  } = useContext(AppContext);

  const closeFlyout = () => {
    setIsFlyoutVisible(false);
  };

  const showFlyout = () => {
    setIsFlyoutVisible(!isFlyoutVisible);
  };

  const findAndReadNotis = (ids) => {
    const idSet = new Set(ids);
    const updatedNotifications = [...latestNotifications];
    for (let n of updatedNotifications) {
      if (idSet.has(n.id)) {
        n.read = true;
        idSet.delete(n.id);
      }
      if (!idSet.size) {
        return updatedNotifications;
      }
    }
    return updatedNotifications;
  }

  const markAllRead = async () => {
    try {
      const ids = latestNotifications
        .filter(({ read }) => !read)
        .map(n => n.id)
      if (!ids.length) return;
      const readResp = await api.readNotifications(ids, true)
      if (readResp.data.errors?.length) {
        toaster({
          color: 'danger',
          title: 'Oops!',
          text: 'There was a problem saving your changes.',
        });
        return;
      }
      const updatedNotis = findAndReadNotis(ids);
      setLatestNotifications(updatedNotis);
      setShowBadge(false);
    } catch (err) {
      console.log("Error reading all notifications >> %s", err);
      toaster({
        color: "danger",
        title: "Oops!",
        text: "There was a problem saving your changes.",
      });
    }
  };

  const checkUnread = useCallback(async () => {
    for (let n of latestNotifications) {
      if (n.read === 0) {
        setShowBadge(true);
        return;
      }
    }
    setShowBadge(false);
  }, [latestNotifications]);

  useEffect(() => {
    checkUnread();
  }, [latestNotifications, checkUnread]);

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls="headerNewsFeed"
      aria-expanded={isFlyoutVisible}
      aria-haspopup="true"
      aria-label={`News feed: ${
        showBadge ? "Updates available" : "No updates"
      }`}
      onClick={() => showFlyout()}
      notification={showBadge}
    >
      <EuiIcon type="bell" size="m" />
    </EuiHeaderSectionItemButton>
  );

  let flyout;
  if (isFlyoutVisible) {
    flyout = (
      <EuiFlyout
        onClose={() => closeFlyout()}
        size="s"
        id="headerNewsFeed"
        aria-labelledby="flyoutSmallTitle"
      >
        <EuiFlyoutHeader hasBorder style={{ display: "flex" }}>
          <EuiTitle size="s">
            <h2 id="flyoutSmallTitle">Latest Updates</h2>
          </EuiTitle>
          {latestNotifications?.length ? (
            <EuiButtonEmpty
              style={{ marginLeft: "auto", marginRight: "1rem" }}
              onClick={markAllRead}
              disabled={!showBadge}
            >
              Mark All Read
            </EuiButtonEmpty>
          ) : null}
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <LatestNotifications
            closeFlyout={closeFlyout}
            checkUnread={checkUnread}
          />
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="cross"
                onClick={() => closeFlyout()}
                flush="left"
              >
                Close
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText color="subdued" size="s">
                <p>Version {packageInfo.version}</p>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    );
  }

  return (
    <Fragment>
      {button}
      {flyout}
    </Fragment>
  );
}
