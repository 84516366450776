import React, {
  useContext,
  useEffect,
  useState,
  useRef
} from 'react'

import {
  EuiFlexGroup,
  EuiOverlayMask,
  EuiFlexItem,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiText,
  EuiSpacer,
  EuiForm,
  EuiIcon,
  EuiTabs,
  EuiTab,
  EuiFieldText,
  EuiButtonEmpty,
  EuiFieldSearch
} from '@elastic/eui'

import { GroupUsersList } from './group_users_list'
import { GroupEntities } from './group_entities'
import { GroupRules } from './group_rules'
import { NotiSettings } from './noti_settings'
import { ConfirmUnsaved } from '../modals'

import { AppContext } from '../../app'
import { GroupsPageContext } from './groups_page'
import api from '../../lib/api'
import { formatReverseDate } from '../../lib/date'


export const GroupForm = () => {
  const {
    toaster,
    keycloak,
    initialPagination,
    groups,
    senders,
    projects,
    recipients,
    useCases
  } = useContext(AppContext)
  const {
    groupsState,
    groupsDispatch,
    findAndUpdateGroup,
    loadGroups,
    updateGroup,
    fetchNotiSettings
  } = useContext(GroupsPageContext)

  const mode = groupsState.groupModal

  const [activeTab, setActiveTab] = useState('group-details')
  const [rules, setRules] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingUsers, setLoadingUsers] = useState(false)

  const [users, setUsers] = useState([])
  const [usersSearch, setUsersSearch] = useState('')
  const [filteredUsers, setFilteredUsers] = useState(null)
  const [usersPagination, setUsersPagination] = useState({ ...initialPagination })
  const noMoreUsers = useRef(false)

  const [modal, setModal] = useState(null)

  const initFilteredEntities = {
    project: null,
    useCase: null,
    sender: null,
    recipient: null,
  }
  const [filteredEntities, setFilteredEntities] = useState({ ...initFilteredEntities })
  const [filteredEntitiesView, setFilteredEntitiesView] = useState(null)

  const initEntities = {
    project: [],
    useCase: [],
    sender: [],
    recipient: []
  }
  const [entities, setEntities] = useState({ ...initEntities })

  const group = groupsState.activeGroup
  const groupEntities = groupsState.activeGroup.entities
  const showEdit = keycloak.hasClientRole({ roleName: 'edit' }) || keycloak.hasClientRole({ roleName: 'group-manager' })

  const onClose = () => {
    groupsDispatch({ type: 'close_modal' })
    setUsers([])
    setUsersSearch('')
    setActiveTab('group-details')
    setFilteredUsers(null)
    setFilteredEntities({ ...initFilteredEntities })
    setFilteredEntitiesView(null)
  }

  const changeMode = (value) => {
    groupsDispatch({ type: 'set', state: 'groupModal', value })
  }

  const viewGroup = (group) => {
    groupsDispatch({ type: 'view_group', group })
  }

  const checkNotiChanges = () => {
    const bk = JSON.stringify(groupsState.notiBackup)
    const cur = JSON.stringify(groupsState.activeGroupNotiSettings)
    if (bk !== cur) {
      return true
    }
    return false
  }

  const checkGroupChanges = (groupOrig) => {
    const bk = JSON.stringify(groupOrig)
    const cur = JSON.stringify(group)
    if (bk !== cur) {
      return true
    }
    const bkUsers = groupOrig.users.map(u => u.user.id).sort()
    const curUsers = users.filter(u => u.selected).map(u => u.id).sort()
    if (JSON.stringify(bkUsers) !== JSON.stringify(curUsers)) {
      return true
    }
    return false
  }

  const cancelEdit = (groupOrig) => {
    groupsDispatch({ type: 'reset_group_notis' })
    changeMode('view')
    setUsers([])
    updateGroup(groupOrig)
    setModal(null)
  }

  const onCancel = () => {
    const groupOrig = groups.find(({ id }) => id === group.id)
    if (!groupOrig) return
    const notiChanges = checkNotiChanges()
    const groupChanges = checkGroupChanges(groupOrig)
    if (notiChanges || groupChanges) {
      setModal(
        <ConfirmUnsaved
          onCancel={() => setModal(null)}
          onConfirm={() => cancelEdit(groupOrig)}
        />
      )
    } else {
      cancelEdit(groupOrig)
    }
  }

  const formUpdate = (field, value) => {
    groupsDispatch({ type: 'group_form', field, value })
  }

  const saveNewGroup = async () => {
    try {
      setLoading(true)
      const groupUsers = users.filter(u => u.selected).map(um => um.id)
      const managerUserId = group.managers[0]?.userId || ''
      const data = {
        name: group.name,
        description: group.description,
        users: groupUsers,
        managerUserId,
        entities: group.entities
      }
      const resp = await api.createGroup(data)
      toaster({
        color: 'success',
        title: 'Group created',
        text: resp.message,
      })
      loadGroups({ reset: true })
      onClose()
    }
    catch (err) {
      toaster({
        color: 'danger',
        title: `Unable to create group.`,
        text: err.message,
      });
    }
    finally {
      setLoading(false)
    }
  }

  const buildUpdatePayload = () => {
    const origGroup = groups.find(g => g.id === group.id)
    if (!origGroup) return
    const payload = {
      name: group.name,
      description: group.description,
      managerUserId: '',
      addUsers: [],
      delUsers: [],
      addEntities: [],
      delEntities: [],
      addRules: [],
      delRules: []
    }
    if (group.managers?.length) {
      payload.managerUserId = group.managers[0].userId
    }
    const selectedUsers = users.filter(u => u.selected)
    for (const { id } of selectedUsers) {
      const exist = origGroup.users.find(u => u.user.id === id)
      if (!exist) {
        payload.addUsers.push(id)
      }
    }
    for (const { user: { id } } of origGroup.users) {
      const exist = selectedUsers.find(u => u.id === id)
      if (!exist) {
        payload.delUsers.push(id)
      }
    }
    if (keycloak.hasClientRole({ roleName: 'edit' })) {
      for (const { entityName, entityType } of group.entities) {
        const exist = origGroup.entities.find(e =>
          (e.entityType === entityType) && (e.entityName === entityName))
        if (!exist) {
          payload.addEntities.push({ name: entityName, type: entityType })
        }
      }
      for (const { id, entityName, entityType } of origGroup.entities) {
        const exist = group.entities.find(e =>
          (e.entityType === entityType) && e.entityName === entityName)
        if (!exist) {
          payload.delEntities.push(id)
        }
      }
    }
    for (const r of group.rules) {
      const exist = origGroup.rules.find(or => {
        if (r.resourceType !== or.resourceType) {
          return false
        }
        if (r.resourceName !== or.resourceName) {
          return false
        }
        if (r.action !== or.action) {
          return false
        }
        if (r.effect !== or.effect) {
          return false
        }
        return true
      })
      if (!exist) {
        payload.addRules.push({
          entityType: r.resourceType,
          entityName: r.resourceName,
          action: r.action,
          effect: r.effect
        })
      }
    }
    for (const r of origGroup.rules) {
      const exist = group.rules.find(or => {
        if (r.resourceType !== or.resourceType) {
          return false
        }
        if (r.resourceName !== or.resourceName) {
          return false
        }
        if (r.action !== or.action) {
          return false
        }
        if (r.effect !== or.effect) {
          return false
        }
        return true
      })
      if (!exist) {
        payload.delRules.push(r.id)
      }
    }
    return payload
  }

  const saveGroup = async () => {
    try {
      setLoading(true)
      const data = buildUpdatePayload()
      if (groupsState.activeGroupNotiSettings?.length) {
        const notiChanges = checkNotiChanges()
        if (notiChanges) {
          const params = {
            configs: groupsState.activeGroupNotiSettings,
            notifyUsers: true
          }
          await api.updateGroupNotis(group.id, params)
        }
      }
      await api.updateGroup(group.id, data)
      const resp = await api.fetchGroup(group.id)
      const updatedGroup = resp.data
      findAndUpdateGroup(resp.data)
      viewGroup(updatedGroup)
      fetchNotiSettings()
      toaster({
        color: 'success',
        title: 'Your changes were successfully saved.',
      })
    }
    catch (err) {
      console.log(err)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem saving your changes',
      })
    }
    finally {
      setLoading(false)
    }
  }

  const managerSaveGroup = async () => {
    try {
      setLoading(true)
      const data = buildUpdatePayload()
      if (groupsState.activeGroupNotiSettings?.length) {
        const notiChanges = checkNotiChanges()
        if (notiChanges) {
          const params = {
            configs: groupsState.activeGroupNotiSettings,
            notifyUsers: true
          }
          await api.managerUpdateGroupNotis(group.id, params)
        }
      }
      await api.managerUpdateGroup(group.id, data)
      const resp = await api.managerFetchGroup(group.id)
      const updatedGroup = resp.data
      findAndUpdateGroup(resp.data)
      viewGroup(updatedGroup)
      fetchNotiSettings()
      toaster({
        color: 'success',
        title: 'Your changes were successfully saved.',
      })
    }
    catch (err) {
      console.log(err)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem saving your changes',
      })
    }
    finally {
      setLoading(false)
    }
  }

  const filterUsers = (searchStr, users) => {
    const nUsers = [...users]
    const filtered = nUsers.filter(u => {
      const fStr = searchStr.replace(' ', '').toLowerCase()
      const fName = u.name.replace(' ', '').toLowerCase()
      const fEmail = u.email.toLowerCase()
      if (fName.indexOf(fStr) === 0) {
        return true
      }
      if (fEmail.indexOf(fStr) === 0) {
        return true
      }
      return false
    })
    return filtered
  }

  const concatSelectedUsers = (nUsers, pUsers, search='') => {
    let result = []
    const selected = users.filter(({ selected }) => selected)
    if (search) {
      const filtered = filterUsers(search, selected)
      result = result.concat(filtered)
    } else {
      result = result.concat(selected)
    }
    result = result.concat(pUsers.filter(u => !result.find(ru => ru.id === u.id)))
    result = result.concat(nUsers.filter(u => !result.find(ru => ru.id === u.id)))
    return result
  }

  const resetUsersEdit = () => {
    const groupUsers = [...group.users].map(u => {
      return { ...u.user, selected: true }
    })
    loadUsers({ reset: true, pUsers: groupUsers, search: '' })
  }

  const addUserAvatar = (users) => {
    return users.map(u => {
      return {
        ...u,
        avatar: u.name || u.user?.name || 'unknown'
      }
    })
  }

  const loadUsers = async ({
    search = usersSearch,
    add = false,
    reset = false,
    pUsers = []
  } = {}) => {
    if (add && noMoreUsers.current) return
    if (reset) {
      noMoreUsers.current = false
    }
    try {
      setLoadingUsers(true)
      const { offset, limit } = reset
        ? initialPagination
        : usersPagination
      const newOffset = add ? offset + limit : offset
      const resp = await api.fetchUsersGroups({
        offset: newOffset,
        limit,
        search,
      })
      if (!resp.data.users?.length) {
        noMoreUsers.current = true
      }
      const respUsers = addUserAvatar(resp.data.users)
      if (add) {
        setUsers(prevState => [...prevState, ...respUsers])
      } else {
        const selectedUsers = concatSelectedUsers(respUsers, pUsers, search)
        setUsers(selectedUsers)
      }
      if (resp.data.pagination) {
        setUsersPagination((prevState) => ({
          ...prevState,
          total: resp.data.pagination.rowCount,
          offset: resp.data.pagination.offset
        }))
      }
    }
    catch(e) {
      console.log('Error loading group users', e)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a problem loading group users'
      })
    }
    finally {
      setLoadingUsers(false)
    }
  }

  const onSelectUser = (id) => {
    const cUsers = [...users]
    const idx = cUsers.findIndex(u => u.id === id)
    if (idx === -1) return
    if (cUsers[String(idx)].selected) {
      const manager = groupsState.activeGroup.managers[0]?.userId
      if (manager === id) {
        groupsDispatch({ type: 'remove_group_manager' })
      }
      delete cUsers[String(idx)].selected
    } else {
      cUsers[String(idx)].selected = true
    }
    setUsers(cUsers)
  }

  const getLastUpdate = (group) => {
    let lastUpdated = group.updated_at
    if (groupsState.activeNotiSettings?.length) {
      for (const { updated_at } of groupsState.activeNotiSettings) {
        if (!updated_at) continue
        if (updated_at > lastUpdated) {
          lastUpdated = updated_at
        }
      }
    }
    if (group.entities?.length) {
      for (const { updated_at } of group.entities) {
        if (!updated_at) continue
        if (updated_at > lastUpdated) {
          lastUpdated = updated_at
        }
      }
    }
    if (group.managers?.length) {
      for (const { updated_at } of group.managers) {
        if (!updated_at) continue
        if (updated_at > lastUpdated) {
          lastUpdated = updated_at
        }
      }
    }
    if (group.rules?.length) {
      for (const { updated_at } of group.rules) {
        if (!updated_at) continue
        if (updated_at > lastUpdated) {
          lastUpdated = updated_at
        }
      }
    }
    if (group.users?.length) {
      for (const { updated_at } of group.users) {
        if (!updated_at) continue
        if (updated_at > lastUpdated) {
          lastUpdated = updated_at
        }
      }
    }
    return formatReverseDate(lastUpdated)
  }

  const onSearchUsersView = (search) => {
    if (search === '') {
      setFilteredUsers(null)
      return
    }
    const mUsers = groupsState.activeGroup.users?.map(u => u.user)
    const fUsers = filterUsers(search, mUsers)
    setFilteredUsers(fUsers)
  }

  const findEntityIdx = (entityName, entityType) =>
    groupEntities.findIndex(e => e.entityName === entityName && e.entityType === entityType)

  const filterEntities = (searchStr) => {
    const fStr = searchStr.replace(' ', '').toLowerCase()
    if (searchStr === '') {
      setFilteredEntities({ ...initFilteredEntities })
      return
    }
    const nRows = { ...filteredEntities }
    for (const [entityType, entityList] of Object.entries(entities)) {
      const filtered = entityList.filter(e => {
        const fName = e.name.replace(' ', '').toLowerCase()
        if (fName.indexOf(fStr) === 0) {
          return true
        }
        if (e.displayName) {
          const dName = e.displayName.replace(' ', '').toLowerCase()
          if (dName.indexOf(fStr) === 0) {
            return true
          }
        }
        return false
      })
      nRows[String(entityType)] = filtered
    }
    setFilteredEntities(nRows)
  }

  const filterEntitiesView = (searchStr) => {
    const fStr = searchStr.replace(' ', '').toLowerCase()
    if (searchStr === '') {
      setFilteredEntitiesView(null)
      return
    }
    const nRows = groupsState.activeGroup.entities.filter(e => {
      const fName = e.entityName.replace(' ', '').toLowerCase()
      return fName.indexOf(fStr) === 0
    })
    setFilteredEntitiesView(nRows)
  }

  const onSearchEntities = (search) => {
    if (search === '') {
      setFilteredEntities({ ...initFilteredEntities })
    }
    if (mode === 'view') {
      filterEntitiesView(search)
      return
    }
    filterEntities(search)
  }

  useEffect(() => {
    const entityRows = { ...entities }
    entityRows.project = [...projects]
    entityRows.sender = [...senders]
    entityRows.useCase = [...useCases]
    entityRows.recipient = [...recipients]
    if (keycloak.hasClientRole({ roleName: 'edit' })) {
      if (!entityRows.sender.find(s => s.name === '*')) {
        entityRows.sender.push({ name: '*' })
      }
      if (!entityRows.recipient.find(s => s.name === '*')) {
        entityRows.recipient.push({ name: '*' })
      }
    }
    setEntities(entityRows)
  }, [senders, recipients, useCases, projects])

  useEffect(() => {
    if (mode === 'edit') {
      resetUsersEdit()
    }
    if (mode === 'create') {
      loadUsers({ reset: true })
    }
  }, [mode])

  useEffect(() => {
    setRules(groupsState.activeGroup.rules)
  }, [groupsState.activeGroup])

  const invalidForm = () => {
    if (!group.name) {
      return true
    }
    return false
  }

  if (!mode) return <></>

  const renderHeader = () => {
    switch (mode) {
      case 'create':
        return (
          <EuiModalHeader className='groups-modal-header'>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='cross'
                  onClick={onClose}
                >
                  Close
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup gutterSize='s' justifyContent='center' alignItems='center'>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type='users' />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText style={{ fontWeight: 500, fontSize: '1.2em', margin: 'auto' }}>
                      Create Group
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='save'
                  onClick={saveNewGroup}
                  disabled={invalidForm() ? true : false}
                  isLoading={loading}
                >
                  Save
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalHeader>
        )
      case 'view':
        return (
          <EuiModalHeader className='groups-modal-header'>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='cross'
                  onClick={onClose}
                >
                  Close
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup gutterSize='s' justifyContent='center' alignItems='center'>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type='users' />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText style={{ fontWeight: 500, fontSize: '1.2em', margin: 'auto' }}>
                      Group
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              {showEdit ?
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty
                    size='s'
                    color='primary'
                    iconType='pencil'
                    onClick={() => changeMode('edit')}
                  >
                    Edit
                  </EuiButtonEmpty>
                </EuiFlexItem> :
                <EuiFlexItem grow={false} style={{ width: '5rem' }} />
              }
            </EuiFlexGroup>
          </EuiModalHeader>
        )
      case 'edit':
        return (
          <EuiModalHeader className='groups-modal-header'>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='cross'
                  onClick={onCancel}
                >
                  Cancel
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup gutterSize='s' justifyContent='center' alignItems='center'>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type='users' />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText style={{ fontWeight: 500, fontSize: '1.2em', margin: 'auto' }}>
                      Edit Group
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='save'
                  onClick={() => {
                    if (keycloak.hasClientRole({ roleName: 'edit' })) {
                      saveGroup()
                    } else if (keycloak.hasClientRole({ roleName: 'group-manager' })) {
                      managerSaveGroup()
                    }
                  }}
                  disabled={invalidForm() ? true : false}
                  isLoading={loading}
                >
                  Save
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalHeader>
        )
    }
  }

  const renderName = () => {
    if (mode === 'view') {
      return (
        <EuiFlexGroup gutterSize='s'>
          <EuiFlexItem>
            <EuiFlexGroup gutterSize='none' style={{ flexDirection: 'column' }}>
              <label>Name</label>
              <EuiFlexGroup gutterSize='s' style={{ padding: '0 8px', alignItems: 'center' }}>
                <EuiFlexItem grow={false}>
                  <EuiIcon type='users' />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiText style={{ fontWeight: 500 }}>{group.name}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiFlexGroup gutterSize='none' style={{ flexDirection: 'column' }}>
              <label>Description</label>
              <EuiFlexGroup gutterSize='s' style={{ padding: '0 8px', alignItems: 'center' }}>
                <EuiFlexItem grow={false}>
                  <EuiIcon type='indexEdit' />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiText style={{ fontWeight: 500 }}>{group.description}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
    if (mode === 'create' || mode === 'edit') {
      return (
        <>
          <EuiFlexGroup gutterSize='s'>
            <EuiFlexItem>
              <label>Name</label>
              <EuiFlexGroup gutterSize='s'>
                <EuiFlexItem>
                  <EuiFieldText
                    icon='user'
                    fullWidth
                    placeholder='Name'
                    onChange={e => formUpdate('name', e.target.value)}
                    value={group.name}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem>
              <label>Description</label>
              <EuiFieldText
                icon='email'
                fullWidth
                placeholder='Description'
                onChange={(e) => formUpdate('description', e.target.value)}
                value={group.description}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      )
    }
  }

  const renderUsers = () => {
    if (mode === 'view') {
      return (
        <EuiFlexGroup gutterSize='none'>
          <EuiFlexItem>
            <label>
              <EuiFlexGroup gutterSize='none'>
                <EuiFlexItem style={{ display: 'flex', justifyContent: 'center' }}>
                  Users
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFieldSearch
                    placeholder='Search...'
                    style={{ width: '20rem' }}
                    onSearch={search => onSearchUsersView(search)}
                    onChange={e => {
                      if (e.target.value === '') {
                        onSearchUsersView('')
                      }
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </label>
            <GroupUsersList
              users={users}
              search={usersSearch}
              filteredUsers={filteredUsers}
              onSelect={onSelectUser}
              loading={loadingUsers}
              loadUsers={loadUsers}
              noMoreUsers={noMoreUsers}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
    if (mode === 'create') {
      return (
        <EuiFlexGroup gutterSize='none'>
          <EuiFlexItem>
            <label>
              <EuiFlexGroup gutterSize='none'>
                <EuiFlexItem style={{ display: 'flex', justifyContent: 'center' }}>
                  Users
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFieldSearch
                    placeholder='Search...'
                    value={usersSearch}
                    onChange={(e) => {
                      setUsersSearch(e.target.value)
                      if (e.target.value === '') {
                        loadUsers({ search: '', reset: true })
                      }
                    }}
                    onSearch={(search) => {
                      setUsersSearch(search)
                      if (search === '') return
                      loadUsers({ search: usersSearch, reset: true })
                    }}
                    style={{ width: '20rem' }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </label>
            <GroupUsersList
              users={users}
              onSelect={onSelectUser}
              loading={loadingUsers}
              loadUsers={loadUsers}
              noMoreUsers={noMoreUsers}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
    if (mode === 'edit') {
      return (
        <EuiFlexGroup gutterSize='none'>
          <EuiFlexItem>
            <label>
              <EuiFlexGroup gutterSize='none'>
                <EuiFlexItem style={{ display: 'flex', justifyContent: 'center' }}>
                  Users
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiFieldSearch
                    placeholder='Search...'
                    value={usersSearch}
                    onChange={(e) => {
                      setUsersSearch(e.target.value)
                      if (e.target.value === '') {
                        resetUsersEdit()
                      }
                    }}
                    onSearch={(search) => {
                      setUsersSearch(search)
                      if (search === '') return
                      loadUsers({ search, reset: true })
                    }}
                    style={{ width: '20rem' }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </label>
            <GroupUsersList
              users={users}
              onSelect={onSelectUser}
              loading={loadingUsers}
              loadUsers={loadUsers}
              noMoreUsers={noMoreUsers}
              search={usersSearch}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
  }

  const renderRules = () => {
    if (mode !== 'create') {
      return (
        <EuiFlexGroup>
          <EuiFlexItem>
            <label>Group Rules</label>
            <GroupRules rules={rules} setRules={setRules} />
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    }
    return <></>
  }

  const tabs = [
    {
      id: 'group-details',
      name: 'Details',
      disabled: false,
      onClick: () => setActiveTab('group-details')
    },
    {
      id: 'group-notifications',
      name: 'Notifications',
      disabled: false,
      onClick: async () => setActiveTab('group-notifications'),
      hide: Boolean(mode === 'create')
    },
  ]

  const renderTabs = () => {
    return tabs.map((tab, idx) => {
      if (tab.hide) return <React.Fragment key={idx} />
      return (
        <EuiTab
          onClick={tab.onClick}
          isSelected={tab.id === activeTab}
          disabled={tab.disabled}
          key={`group-tabs-${idx}`}
        >
          {tab.name}
        </EuiTab>
      )
    })
  }

  const renderTabContent = () => {
    switch (activeTab) {
      case 'group-details':
        return (
          <EuiForm className='groups-form fade-up'>
            <EuiSpacer size='s' />

            {renderName()}

            <EuiSpacer size='xl' />

            {renderUsers()}

            <EuiSpacer />

            <EuiFlexGroup gutterSize='none'>
              <EuiFlexItem>
                <label>
                  <EuiFlexGroup gutterSize='none'>
                    <EuiFlexItem style={{ display: 'flex', justifyContent: 'center' }}>
                      Entities
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiFieldSearch
                        placeholder='Search...'
                        style={{ width: '20rem' }}
                        onSearch={(search) => onSearchEntities(search)}
                        onChange={e => {
                          if (e.target.value === '') {
                            onSearchEntities('')
                          }
                        }}
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </label>
                <GroupEntities
                  filteredEntities={filteredEntities}
                  filteredEntitiesView={filteredEntitiesView}
                  findEntityIdx={findEntityIdx}
                  entities={entities}
                />
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer />

            {renderRules()}

          </EuiForm>
        )
      case 'group-notifications':
        return (
          <EuiForm className='groups-form fade-down'>
            <EuiFlexGroup gutterSize='none'>
              <EuiFlexItem>
                <label>
                  <EuiIcon type='bell' style={{ margin: '0 8px 0 8px' }} />
                  Group Notification Settings
                </label>
              </EuiFlexItem>
            </EuiFlexGroup>

            <NotiSettings />

          </EuiForm>
        )
      default:
        return (<></>)
    }
  }

  return (
    <>
      <EuiOverlayMask>
        <EuiModal
          style={{ maxWidth: 900, width: 900, height: '85vh', textAlign: 'center' }}
          onClose={onClose}
          className='groups-modal'
        >

          {renderHeader()}

          <EuiModalBody className='groups-modal-body'>
            <EuiTabs>
              {renderTabs()}
              {mode !== 'create' &&
                <EuiFlexGroup
                  gutterSize='none'
                  style={{ flexDirection: 'column', alignItems: 'flex-end', marginTop: '4px' }}
                >
                  <EuiFlexItem grow={false}>
                    <EuiText size='xs' style={{ fontWeight: 500 }}>Last Updated</EuiText>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText size='xs'>{getLastUpdate(group)}</EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              }
            </EuiTabs>

            <EuiSpacer size='xl' />

            {renderTabContent()}

          </EuiModalBody>

          <EuiSpacer />

        </EuiModal>
      </EuiOverlayMask>
      {modal}
    </>
  )
}
