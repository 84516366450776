import React from 'react'
import { EuiBadge, EuiText, EuiIcon } from '@elastic/eui'

export const getSubsenders = (senders) => {
  return senders.reduce((subsenders, sender) => {
    if (sender.parent) {
      const tmp = { name: sender.name }
      if (sender.displayName) {
        tmp.displayName = sender.displayName
      }
      subsenders.push(tmp)
    }
    return subsenders
  }, [])
}

export const parseCollection = (subsenderCol, parent) => {
  try {
    const lines = subsenderCol.split(',')
    const uniqueSet = new Set()
    const result = { subsenders: [], err: '' }
    lines.forEach(line => {
      const parts = line.split(':')
      if (parts.length == 2) {
        const name = parts[0].substring(parts[0].indexOf(`"`) + 1, parts[0].lastIndexOf(`"`))
        if (!name) throw Error()
        const fullName = `${parent}_${name}`
        const displayName = parts[1].substring(parts[1].indexOf(`"`) + 1, parts[1].lastIndexOf(`"`))
        if (!displayName) throw Error()
        if (uniqueSet.has(fullName)) {
          result.subsenders = []
          result.err = 'Duplicate name found in collection'
          return result
        }
        uniqueSet.add(fullName)
        result.subsenders.push({ name: fullName, displayName, parent })
      }
    })
    return result
  }
  catch {
    return { subsenders: [], err: 'Error parsing collection required format: "CLIA": "Display Name",' }
  }
}

export const getChangesCount = (entities) => {
  return Object.values(entities).reduce((count, entityMap) => {
    for (const entity of Object.values(entityMap)) {
      count = count + entity.length
    }
    return count
  }, 0)
}

export const buildOnboardReq = ({ addEntities, delEntities }) => {
  const add = Object.entries(addEntities).reduce((addMap, [entityType, entities]) => {
    if (entityType === 'sender') {
      const senders = entities.filter(entity => !entity.parent)
      const subsenders = entities.filter(entity => entity.parent)
      addMap.sender = senders.map(entity => entity.name)
      addMap.subsender = subsenders.map(entity => {
        return {
          name: entity.name,
          parent: entity.parent,
          displayName: entity.displayName
        }
      })
      return addMap
    }
    if (entityType === 'usecase') {
      addMap.useCase = entities.map(entity => entity.name)
      return addMap
    }
    addMap[String(entityType)] = entities.map(entity => entity.name)
    return addMap
  }, {})
  const del = Object.entries(delEntities).reduce((delMap, [entityType, entities]) => {
    if (entityType === 'usecase') {
      delMap.useCase = entities.map(entity => entity.name)
      return delMap
    }
    delMap[String(entityType)] = entities.map(entity => entity.name)
    return delMap
  }, {})
  return {
    addEntities: add,
    delEntities: del
  }
}

export const buildOnboardRetry = (entity) => {
  return Object.entries(entity).reduce((entityMap, [key, entityTypeMap]) => {
    entityMap[String(key)] = Object.entries(entityTypeMap).reduce((reducedEntities, [entityType, values]) => {
      if (entityType === 'subsender') {
        if (reducedEntities.sender) {
          let allSenders = reducedEntities.sender
          allSenders = allSenders.concat(values)
          reducedEntities.sender = allSenders
        } else {
          reducedEntities.sender = values
        }
        return reducedEntities
      }
      if (entityType === 'useCase') {
        reducedEntities.usecase = values.map(name => {
          return { name }
        })
        return reducedEntities
      }
      reducedEntities[String(entityType)] = values.map(name => {
        return { name }
      })
      return reducedEntities
    }, {})
    return entityMap
  }, {})
}

export const entityDisplay = {
  'project': {
    display: 'Project',
    title: 'Projects',
    icon: 'package'
  },
  'usecase': {
    display: 'Use Case',
    title: 'Use Cases',
    icon: 'wrench'
  },
  'sender': {
    display: 'Sender',
    title: 'Senders',
    icon: 'push'
  },
  'subsender': {
    display: 'Subsender',
    title: 'Subsenders',
    icon: 'push'
  },
  'recipient': {
    display: 'Recipient',
    title: 'Recipients',
    icon: 'importAction'
  }
}

export const EntityBadge = ({ entity, add, handler }) => {
  return (
    <EuiBadge
      className='settings-entity-badge'
      color='hollow'
      iconType='cross'
      iconSide='right'
      iconOnClick={handler}
      iconOnClickAriaLabel='remove add entity'
    >
      <EuiText
        size='xs'
        color={add ? 'secondary' : 'danger'}
        style={{ lineHeight: 1 }}
      >
        {add ? '+' : '-'}
      </EuiText>
      <EuiText style={{ padding: '0 6px', fontSize: '.9em' }}>
        {entity.name}{entity.displayName && `: ${entity.displayName}`}
      </EuiText>
    </EuiBadge>
  )
}

export const CircleIcon = () => {
  return (
    <div
      style={{
        background: '#EEE',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <EuiIcon size='s' type='folderOpen' />
    </div>
  )
}

export const CircleIconError = () => {
  return (
    <div
      style={{
        background: '#FB6967',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <EuiIcon size='s' type='alert' />
    </div>
  )
}
