import React, { useContext } from 'react'
import { NotificationsContext } from './notifications_page'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiTitle,
  EuiSpacer,
} from '@elastic/eui'
import { GeneralSettingsItem } from './general_settings_item'

export const GeneralSettings = () => {
  const { settingsState } = useContext(NotificationsContext)

  if (!settingsState['general']) return null

  return (
    <div className={`notifications-container`}>
      <EuiFlexGroup
        gutterSize='xs'
        responsive={false}
        alignItems='center'
        style={{ padding: '0 1rem' }}
      >
        <EuiFlexItem grow={false}>
          <EuiIcon type='globe' size='l' />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle
            size='s'
            style={{
              fontSize: '16px',
              letterSpacing: '1.5px',
              fontWeight: '600',
              marginLeft: '7px',
            }}
          >
            <p>GENERAL</p>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size='xs' className='notifications-spacer-project' />

      <div style={{ paddingTop: '1rem' }}>
        <GeneralSettingsItem />
      </div>
    </div>
  )
}
