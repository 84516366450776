import React, { useContext } from 'react'
import {
  EuiTable,
  EuiTableHeader,
  EuiTableHeaderCell,
  EuiTableRow,
  EuiTableRowCell,
  EuiTableBody,
  EuiTableFooter,
  EuiTableFooterCell,
  EuiFlexItem,
  EuiFlexGroup,
  EuiLoadingSpinner,
  EuiButtonIcon,
  EuiIcon,
} from '@elastic/eui'
import { InView } from 'react-intersection-observer'
import { HealthIndicator } from '../stats/health_indicator'
import { ListItemToggleButton } from '..'
import { ProjectDataList } from '..'
import { TableLoadingIndicator } from '.'

import { formatDateTime, formatTimeZone } from '../../lib'

import { AppContext } from '../../app'

import { LEFT_ALIGNMENT } from '@elastic/eui/lib/services'
import { DashContext } from '..'
import './messages_table.css'

export const MessagesTable = () => {
  let {
    messages,
    loadingMessages,
    loadNewMessages,
    moreMessagesAvailable,
    messagesTableSort,
    setMessagesTableSort,
    selectedStatuses,
    selectedProjects,
    selectedUseCases,
    selectedSenders,
    selectedRecipients,
    selectedProjectData,
  } = useContext(AppContext)

  let {
    projects = [],
    projectName,
    addStatus,
    removeStatus,
    addProject,
    removeProject,
    addUseCase,
    removeUseCase,
    addSender,
    removeSender,
    senders,
    addRecipient,
    removeRecipient,
    addProjectData,
    removeProjectData,
    toggleExpand,
    itemIdToExpandedRowMap,
    expandAllMessages,
  } = useContext(DashContext)

  let hideProjects = !!projectName

  const sort = messagesTableSort
  const setSort = setMessagesTableSort

  const projectsByName = projects.reduce((agg, proj) => {
    agg[proj.name] = proj
    return agg
  }, {})

  const sendersByName = senders.reduce((agg, sender) => {
    agg[sender.name] = sender
    return agg
  }, {})

  const items = messages.map(({ _source }) => _source)

  const renderProjectDataItem = (item, project) => {
    const data = item.ProjectData[String(project)]
    if (!data) return
    return (
      <ProjectDataList
        addProjectData={addProjectData}
        removeProjectData={removeProjectData}
        data={data}
        selectedProjectData={selectedProjectData}
        projectName={project}
      />
    )
  }

  let columns = [
    {
      align: LEFT_ALIGNMENT,
      width: '40px',
      isExpander: true,
      render: (cell, { ProjectData, MessageId }) => {
        if (!ProjectData || !Object.keys(ProjectData).length) {
          return null
        }
        return (
          <EuiButtonIcon
            style={{ minWidth: '24px' }}
            onClick={() => toggleExpand(MessageId)}
            aria-label={
              itemIdToExpandedRowMap[String(MessageId)] ? 'Collapse' : 'Expand'
            }
            iconType={
              itemIdToExpandedRowMap[String(MessageId)]
                ? 'arrowDown'
                : 'arrowRight'
            }
          />
        )
      },
    },
    {
      field: 'MessageDateTime',
      isSortable: true,
      name: `Date ${formatTimeZone()}`,
      width: '20%',
      alignment: LEFT_ALIGNMENT,
      render: (date) => formatDateTime(date),
      footer: () => (
        <InView
          hidden={!messages.length || !moreMessagesAvailable}
          onChange={(inView) => {
            if (inView && messages.length && moreMessagesAvailable) {
              loadNewMessages()
            }
          }}
        >
          <EuiLoadingSpinner size='m' style={{ marginLeft: '1em' }} />
        </InView>
      ),
    },
    {
      field: 'FileName',
      isSortable: true,
      name: 'Filename',
      width: '20%',
      alignment: LEFT_ALIGNMENT,
      footer: () => (
        <span hidden={!messages.length || !moreMessagesAvailable}>
          Loading messages...
        </span>
      ),
    },
    {
      field: 'Project',
      isSortable: true,
      name: 'Project',
      width: '12.5%',
      alignment: LEFT_ALIGNMENT,
      truncateText: true,
      render: (name) => (
        <ListItemToggleButton
          addItem={addProject}
          removeItem={removeProject}
          id={name}
          isIncluded={selectedProjects.includes(name)}
        >
          <EuiFlexGroup gutterSize='none' alignItems='center'>
            <EuiFlexItem>
              <span>{name}</span>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <HealthIndicator
                {...(projectsByName[String(name)] || { status: {} }).status}
                style={{ marginLeft: '0.5em' }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </ListItemToggleButton>
      ),
    },
    {
      field: 'UseCase',
      isSortable: true,
      name: 'Use Case',
      width: '12.5%',
      truncateText: true,
      alignment: LEFT_ALIGNMENT,
      render: (name) => (
        <ListItemToggleButton
          addItem={addUseCase}
          removeItem={removeUseCase}
          id={name}
          isIncluded={selectedUseCases.includes(name)}
        >
          <span>{name}</span>
        </ListItemToggleButton>
      ),
    },
    {
      field: 'Size',
      isSortable: true,
      name: 'Size',
      width: '7.5%',
    },
    {
      field: 'Status',
      isSortable: true,
      name: 'Status',
      width: '12.5%',
      truncateText: true,
      alignment: LEFT_ALIGNMENT,
      render: (status) => (
        <ListItemToggleButton
          addItem={addStatus}
          removeItem={removeStatus}
          id={status}
          isIncluded={selectedStatuses.includes(status)}
        >
          <span>{status}</span>
        </ListItemToggleButton>
      ),
    },
    {
      field: 'Senders',
      isSortable: true,
      name: 'Senders',
      width: '12.5%',
      truncateText: true,
      alignment: LEFT_ALIGNMENT,
      render: (sender, { SenderData }) => {
        const senderName = (SenderData && SenderData.SenderId) || sender
        const senderEntity = sendersByName[String(senderName)] || {
          displayName: 'Unrecognized Sender',
        }
        return (
          <ListItemToggleButton
            addItem={addSender}
            removeItem={removeSender}
            id={senderEntity.name}
            isIncluded={selectedSenders.includes(senderEntity.name)}
          >
            <span>{senderEntity.displayName || senderEntity.name}</span>
          </ListItemToggleButton>
        )
      },
    },
    {
      field: 'Recipients',
      isSortable: true,
      name: 'Recipients',
      width: '12.5%',
      truncateText: true,
      alignment: LEFT_ALIGNMENT,
      render: (name) => (
        <ListItemToggleButton
          addItem={addRecipient}
          removeItem={removeRecipient}
          id={name}
          isIncluded={selectedRecipients.includes(name)}
        >
          <span>{name}</span>
        </ListItemToggleButton>
      ),
    },
  ]

  if (hideProjects) {
    columns.splice(3, 1)
  }

  const onSort = (field) => {
    const newSort = Object.assign({}, sort)
    if (sort.field === field) {
      if (sort.direction === 'desc') {
        newSort.direction = 'asc'
      } else {
        newSort.direction = 'desc'
      }
    } else {
      newSort.field = field
      newSort.direction = 'asc'
    }
    setSort(newSort)
  }

  const renderHeaderCells = () => {
    const headers = []

    columns.forEach((column, columnIndex) => {
      headers.push(
        <EuiTableHeaderCell
          key={`messages-table-header-${column.name}-${columnIndex}`}
          width={column.width}
          align={columns[String(columnIndex)].alignment}
          onSort={column.isSortable ? () => onSort(column.field) : undefined}
          isSorted={column.field === sort.field}
          isSortAscending={sort.direction === 'asc'}
        >
          {column.name}
        </EuiTableHeaderCell>
      )
    })
    return headers.length ? headers : null
  }

  const renderRows = () => {
    if (loadingMessages) {
      return (
        <EuiTableRow>
          <EuiTableRowCell
            colSpan={columns.length}
            className='table-loader'
            align='center'
          >
            <TableLoadingIndicator noMessages={!items.length} />
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    if (!items.length) {
      return (
        <EuiTableRow>
          <EuiTableRowCell colSpan={columns.length} align='center'>
            No Messages Found
          </EuiTableRowCell>
        </EuiTableRow>
      )
    }

    const renderExpandedRow = (item) => {
      if (!item.ProjectData || !Object.keys(item.ProjectData).length) {
        return null
      }
      if (itemIdToExpandedRowMap[item.MessageId] || expandAllMessages) {
        return (
          <>
            {Object.keys(item.ProjectData).map((projectName, idx) => (
              <React.Fragment key={`message-row-expanded-header-${idx}`}>
                <EuiTableRow
                  key={`message-row-expanded-header-${item.MessageId}`}
                  className='messages_row'
                  isExpandedRow={true}
                >
                  <EuiTableRowCell colSpan={columns.length}>
                    <EuiIcon
                      type='folderOpen'
                      style={{ margin: '0 .5rem 0 .5rem' }}
                    />
                    <span style={{ fontWeight: 600, fontSize: '.75rem' }}>
                      Project Data - {projectName}
                    </span>
                  </EuiTableRowCell>
                </EuiTableRow>
                <EuiTableRow
                  key={`message-row-expanded-${item.MessageId}`}
                  className='messages_row'
                  isExpandedRow={true}
                >
                  <EuiTableRowCell
                    colSpan={columns.length}
                    style={{ backgroundColor: 'white' }}
                  >
                    {renderProjectDataItem(item, projectName)}
                  </EuiTableRowCell>
                </EuiTableRow>
              </React.Fragment>
            ))}
          </>
        )
      }
      return null
    }

    const renderRow = (item) => {
      const cells = columns.map((column, columnIndex) => {
        const cell = item[column.field]
        let child

        if (column.render) {
          child = column.render(cell, item)
        } else {
          child = cell
        }

        return (
          <EuiTableRowCell
            key={`message-row-cell-${column.field}-${columnIndex}`}
            truncateText={column.truncateText}
            className='messages_cell'
            align={column.alignment}
            // textOnly={true}
            style={{ textAlign: 'left', minWidth: '40px' }}
            isExpander={column.isExpander}
          >
            {child}
          </EuiTableRowCell>
        )
      })

      return (
        <React.Fragment key={`message-row-${item.MessageId}`}>
          <EuiTableRow
            className='messages_row'
            isExpandable={true}
            itemID={item.MessageId}
          >
            {cells}
          </EuiTableRow>
          {renderExpandedRow(item)}
        </React.Fragment>
      )
    }

    const rows = []

    for (var item of items) {
      rows.push(renderRow(item))
    }

    return rows
  }

  const renderFooters = () => {
    const footers = []

    columns.forEach((column, columnIndex) => {
      if (column.footer) {
        footers.push(
          <EuiTableFooterCell
            key={`messages-footer-${columnIndex}`}
            align={column.alignment}
          >
            {column.footer()}
          </EuiTableFooterCell>
        )
      } else {
        footers.push(
          <EuiTableFooterCell
            key={`messages-footer-${columnIndex}`}
            align={column.alignment}
          >
            {undefined}
          </EuiTableFooterCell>
        )
      }
    })
    return footers
  }

  return (
    <div>
      <EuiTable id='messages-table'>
        <EuiTableHeader>{renderHeaderCells()}</EuiTableHeader>
        <EuiTableBody>{renderRows()}</EuiTableBody>
        <EuiTableFooter>{renderFooters()}</EuiTableFooter>
      </EuiTable>
    </div>
  )
}
