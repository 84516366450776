import React, { useState } from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
  EuiIcon,
  EuiCopy,
} from '@elastic/eui'
import './project_data_list.css'
import { ListItemToggleButton } from '..'
import { ProjectDataModal } from '..'

export const ProjectDataList = ({
  addProjectData,
  removeProjectData,
  selectedProjectData,
  data,
  projectName,
}) => {
  const [modal, setModal] = useState(null)

  const showProjectData = (title, content) => {
    setModal(
      <ProjectDataModal
        title={title}
        content={content}
        onClose={() => setModal(null)}
      />
    )
  }


  const getField = (field) => {
    const fieldObject = {}
    fieldObject[String(projectName)] = {}
    fieldObject[String(projectName)][String(field)] = data[String(field)]
    return fieldObject
  }

  const checkIncluded = (field) => {
    if (!selectedProjectData[String(projectName)]) return false
    if (selectedProjectData[String(projectName)][String(field)]) return true
    return false
  }

  const renderRows = (data) => {
    if (typeof data !== 'object' || !Object.keys(data).length) {
      return <></>
    }
    const rows = []
    for (const [idx, [k, v]] of Object.entries(data).entries()) {
      const key = `project-data-list-item-${idx}`
      if (typeof v === 'string' || typeof v === 'number') {
        if (v.length > 100) {
          rows.push(
            <EuiFlexItem
              grow={false}
              className='ProjectDataList-item'
              key={key}
            >
              <EuiIcon type='iInCircle' className='info-icon' />
              <span className='ProjectDataList-key'>{k}: </span>
              <span className='ProjectDataList-data'>{v}</span>
              <EuiButtonIcon
                onClick={() => {
                  showProjectData(k, v)
                }}
                iconType='popout'
                color='subdued'
                style={{ width: '24px', margin: '0 2px' }}
                aria-label='Copy Text'
              />
              <EuiCopy textToCopy={String(v)}>
                {(copy) => (
                  <EuiButtonIcon
                    onClick={(e) => {
                      e.stopPropagation()
                      copy()
                    }}
                    iconType='copy'
                    color='subdued'
                    style={{ width: '24px', margin: '0 2px' }}
                    aria-label='Copy Text'
                  />
                )}
              </EuiCopy>
            </EuiFlexItem>
          )
          continue
        }
        rows.push(
          <EuiFlexItem
            // grow={false}
            className='ProjectDataList-item'
            key={key}
          >
            <EuiIcon type='iInCircle' className='info-icon' />
            {v ?
              <ListItemToggleButton
                addItem={addProjectData}
                removeItem={removeProjectData}
                id={`${k}: ${v}`}
                isIncluded={checkIncluded(k)}
                projectData={getField(k)}
              >
                <span className='ProjectDataList-key'>{k}: </span>
                <span className='ProjectDataList-data'>{v}</span>
                <EuiCopy textToCopy={String(v)}>
                  {(copy) => (
                    <EuiButtonIcon
                      onClick={(e) => {
                        e.stopPropagation()
                        copy()
                      }}
                      iconType='copy'
                      color='subdued'
                      style={{ width: '24px' }}
                      aria-label='Copy Text'
                    />
                  )}
                </EuiCopy>
              </ListItemToggleButton> :
              <>
                <span className='ProjectDataList-key'>{k}: </span>
                <span className='ProjectDataList-data'>{v}</span>
              </>
            }
          </EuiFlexItem>
        )
      }
      if (typeof v === 'object') {
        rows.push(
          <EuiFlexItem
            grow={false}
            className='ProjectDataList-item'
            key={key}
          >
            <EuiIcon type='iInCircle' className='info-icon' />
            <span className='ProjectDataList-key'>{k}: </span>
            <span className='ProjectDataList-data'>Object</span>
            <EuiCopy textToCopy={JSON.stringify(v)}>
              {(copy) => (
                <EuiButtonIcon
                  onClick={(e) => {
                    e.stopPropagation()
                    copy()
                  }}
                  iconType='copy'
                  color='subdued'
                  style={{ width: '24px' }}
                  aria-label='Copy Text'
                />
              )}
            </EuiCopy>
          </EuiFlexItem>
        )
      }
    }
    return rows
  }

  return (
    <>
      <EuiFlexGroup
        direction='column'
        alignItems='flexStart'
        justifyContent='center'
        gutterSize='none'
        style={{ paddingLeft: '1rem' }}
      >
        {renderRows(data)}
      </EuiFlexGroup>
      {modal}
    </>
  )
}
