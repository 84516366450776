import React, { useContext } from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiBeacon,
  EuiToolTip,
  EuiText
} from '@elastic/eui'
import { AppContext } from '../../app'

export const PushNotifications = () => {
  const { checkPushNotiPermission } = useContext(AppContext)

  const enabled = checkPushNotiPermission()

  return (
    <EuiToolTip
      position='bottom'
      content='You can enable or disable push notifications in your browser settings'
    >
      <div className={`notifications-container-push`} >
        <EuiFlexGroup gutterSize='s' responsive={false} alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiIcon type={enabled ? 'bell' : 'bellSlash'} />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              <p>Push Notifications {enabled ? 'Enabled' : 'Disabled'}</p>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {enabled
              ? <EuiBeacon />
              : <EuiIcon type='dot' color='danger' size='l'/>
            }
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiToolTip>
  )
}
