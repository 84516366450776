import React from 'react'
import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonIcon,
  EuiToolTip,
} from '@elastic/eui'
import './list_item_toggle_button.css'

export const ListItemToggleButton = ({
  addItem,
  removeItem,
  isIncluded,
  id,
  displayName,
  show,
  children,
  className,
  projectData = null,
}) => {
  const onClick = isIncluded
    ? (e) => {
        e.stopPropagation()
        removeItem(projectData || id)
      }
    : (e) => {
        e.stopPropagation()
        addItem(projectData || id)
      }

  return (
    <EuiFlexGroup
      gutterSize='none'
      onClick={onClick}
      alignItems='center'
      className={`ListItemToggleButton${show ? ' show' : ''}${
        className ? ' ' + className : ''
      }`}
    >
      <EuiFlexItem grow={false} className='children'>
        {children}
      </EuiFlexItem>
      <EuiToolTip
        position='top'
        content={
          isIncluded
            ? `Remove ${displayName || id} from filter`
            : `Add ${displayName || id} to filter`
        }
      >
        <EuiFlexItem grow={false}>
          {isIncluded ? (
            <EuiButtonIcon
              iconType='cross'
              color='subdued'
              aria-label={`Remove ${displayName || id} from filter`}
            />
          ) : (
            <EuiButtonIcon
              iconType='plusInCircleFilled'
              color='subdued'
              className='addItemButton'
              aria-label={`Filter by ${displayName || id}`}
            />
          )}
        </EuiFlexItem>
      </EuiToolTip>
    </EuiFlexGroup>
  )
}
