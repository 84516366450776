import React, { useContext, useEffect } from 'react'

import NotiSettingsItem from './noti_settings_item'
import { GroupsPageContext } from './groups_page'

import {
  EuiText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner
} from '@elastic/eui'

export const NotiSettings = () => {
  const {
    groupsState,
    groupsDispatch,
    fetchNotiSettings,
    loading
  } = useContext(GroupsPageContext)

  const notiSettings = groupsState.activeGroupNotiSettings

  const updateSettings = ({ id, setting, newValue }) => {
    const notis = [...groupsState.activeGroupNotiSettings]
    const idx = notis.findIndex(s => id === s.id)
    const updateSetting = notis[String(idx)].settings
    updateSetting[String(setting)] = newValue
    if (
      (setting === 'events' && newValue === true) ||
      (setting === 'volumeEnabled' && newValue === true) ||
      (setting === 'statusEnabled' && newValue === true)
    ) {
      updateSetting['enabled'] = true
    } else if (
      updateSetting['events'] === false &&
      updateSetting['volumeEnabled'] === false &&
      updateSetting['statusEnabled'] === false
    ) {
      updateSetting['enabled'] = false
    }
    groupsDispatch({
      type: 'set',
      state: 'activeGroupNotiSettings',
      value: notis
    })
  }

  const toggleSettings = ({ id, enable }) => {
    const notis = [...groupsState.activeGroupNotiSettings]
    const tglIdx = notis.findIndex(s => id === s.id)
    const tglSetting = notis[String(tglIdx)].settings
    if (enable) {
      tglSetting['enabled'] = true
      tglSetting['events'] = true
      tglSetting['volumeEnabled'] = true
      tglSetting['statusEnabled'] = true
    } else {
      tglSetting['enabled'] = false
      tglSetting['events'] = false
      tglSetting['volumeEnabled'] = false
      tglSetting['statusEnabled'] = false
    }
    groupsDispatch({
      type: 'set',
      state: 'activeGroupNotiSettings',
      value: notis
    })
  }

  useEffect(() => {
    if (!notiSettings) {
      fetchNotiSettings()
    }
  }, [])

  if (loading) {
    return (
      <EuiFlexGroup
        gutterSize='s'
        alignItems='center'
        justifyContent='center'
        style={{ height: '5rem' }}
      >
        <EuiFlexItem grow={false}>
          <EuiLoadingSpinner size='l' />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  if (!notiSettings || !notiSettings?.length) {
    return (
      <EuiFlexGroup
        gutterSize='s'
        responsive={false}
        alignItems='center'
        justifyContent='center'
      >
        <EuiFlexItem>
          <EuiText size='s'>
            <p>No settings available</p>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  return (
    <>
      {notiSettings.map((notiSetting) => (
        <div key={`notifications-projects-${notiSetting.id}`}>
          <div className='group-noti-settings-container'>
            <NotiSettingsItem
              config={notiSetting}
              updateSettings={updateSettings}
              toggleSettings={toggleSettings}
            />
          </div>
        </div>
      ))}
    </>
  )
}
