import React, { useContext, useState } from 'react'

import {
  EuiFlexGroup,
  EuiOverlayMask,
  EuiFlexItem,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiText,
  EuiSpacer,
  EuiForm,
  EuiIcon,
  EuiFieldText,
  EuiButtonEmpty,
} from '@elastic/eui'

import { AppContext } from '../../app'
import { AdminPageContext } from './admin_page'
import { TagRules } from './tag_rules'
import { ConfirmUnsaved } from '../modals'
import api from '../../lib/api'


export const TagForm = () => {
  const { toaster, tags, keycloak } = useContext(AppContext)
  const {
    adminState,
    adminDispatch,
    loadTags,
    findAndUpdateTag
  } = useContext(AdminPageContext)

  const [cancelModal, setCancelModal] = useState(null)
  const [loading, setLoading] = useState(false)

  const tag = adminState.activeTag
  const showModal = Boolean(adminState.tagModal)

  const onClose = () => {
    adminDispatch({ type: 'close_modal' })
  }

  const changeMode = (value) => {
    adminDispatch({ type: 'set', state: 'tagModal', value })
  }

  const checkChanges = (origTag) => {
    const origTagStr = JSON.stringify(origTag)
    const activeTagStr = JSON.stringify(tag)
    if (origTagStr !== activeTagStr) {
      return true
    }
    return false
  }
  
  const cancelEdit = () => {
    const origTag = tags.find(({ id }) => tag.id === id)
    if (!origTag) return
    
    const changes = checkChanges(origTag)
    if (changes) {
      setCancelModal(
        <ConfirmUnsaved
          onCancel={() => setCancelModal(null)}
          onConfirm={() => {
            setCancelModal(null)
            adminDispatch({ type: 'view_tag', tag: origTag })
          }}
        />
      )
    } else {
      adminDispatch({ type: 'view_tag', tag: origTag })
    }
  }

  const viewTag = (tag) => adminDispatch({ type: 'view_tag', tag })

  const saveNewTag = async () => {
    setLoading(true)
    try {
      await api.createTag({
        ...tag,
      })
      loadTags({ search: adminState.tagsSearch, reset: true })
      onClose()
    }
    catch (err) {
      console.log(err)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a saving your changes.',
      })
    }
    finally {
      setLoading(false)
    }
  }

  const saveUpdatedTag = async () => {
    setLoading(true)
    try {
      await api.updateTag({
        ...tag,
      })
      viewTag(tag)
      findAndUpdateTag(tag)
      toaster({
        color: 'success',
        title: 'Your changes were successfully saved.',
      })
    }
    catch (err) {
      console.log(err)
      toaster({
        color: 'danger',
        title: 'Oops!',
        text: 'There was a saving your changes.',
      })
    }
    finally {
      setLoading(false)
    }
  }

  const updateField = (field, value) => {
    adminDispatch({ type: 'tag_form', field, value })
  }

  if (!showModal) return null

  const renderHeader = () => {
    switch (adminState.tagModal) {
      case 'create':
        return (
          <EuiModalHeader className='admin-modal-header'>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='cross'
                  onClick={onClose}
                >
                  Close
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup gutterSize='s' justifyContent='center' alignItems='center'>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type='tag' />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText style={{ fontWeight: 500, fontSize: '1.4em', margin: 'auto' }}>
                      Create Tag
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='save'
                  onClick={saveNewTag}
                  disabled={tag.name ? false : true}
                  isLoading={loading}
                >
                  Save
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalHeader>
        )
      case 'view':
        return (
          <EuiModalHeader className='admin-modal-header'>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='cross'
                  onClick={onClose}
                >
                  Close
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup gutterSize='s' justifyContent='center' alignItems='center'>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type='tag' />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText style={{ fontWeight: 500, fontSize: '1.4em', margin: 'auto' }}>
                      Tag
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              {keycloak.hasClientRole({ roleName: 'edit' }) ?
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty
                    size='s'
                    color='primary'
                    iconType='pencil'
                    onClick={() => changeMode('edit')}
                  >
                    Edit
                  </EuiButtonEmpty>
                </EuiFlexItem> :
                <EuiFlexItem grow={false} style={{ width: '5rem' }} />
              }
            </EuiFlexGroup>
          </EuiModalHeader>
        )
      case 'edit':
        return (
          <EuiModalHeader className='admin-modal-header'>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='cross'
                  onClick={() => cancelEdit()}
                >
                  Cancel
                </EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFlexGroup gutterSize='s' justifyContent='center' alignItems='center'>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type='tag' />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText style={{ fontWeight: 500, fontSize: '1.4em', margin: 'auto' }}>
                      Edit Tag
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size='s'
                  color='primary'
                  iconType='save'
                  onClick={saveUpdatedTag}
                  isLoading={loading}
                >
                  Save
                </EuiButtonEmpty>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalHeader>
        )
    }
  }

  const renderName = () => {
    if (adminState.tagModal === 'view') {
      return (
        <>
          <EuiFlexGroup gutterSize='none' style={{ flexDirection: 'column' }}>
            <label>Name</label>
            <EuiFlexGroup gutterSize='s' style={{ padding: '0 8px', alignItems: 'center' }}>
              <EuiFlexItem grow={false}>
                <EuiIcon type='tag' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText style={{ fontWeight: 500 }}>{tag.name}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGroup>
          <EuiSpacer size='xl'/>
          <EuiFlexGroup gutterSize='none' style={{ flexDirection: 'column' }}>
            <label>Description</label>
            <EuiFlexGroup gutterSize='s' style={{ padding: '0 8px', alignItems: 'center' }}>
              <EuiFlexItem grow={false}>
                <EuiIcon type='documentEdit' />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText style={{ fontWeight: 500 }}>{tag.description}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGroup>
        </>
      )
    }
    return (
      <>
        <EuiFlexGroup gutterSize='none'>
          <EuiFlexItem>
            <label>Name</label>
            <EuiFieldText
              icon='tag'
              fullWidth
              placeholder='Tag Name'
              onChange={e => updateField('name', e.target.value)}
              value={tag.name}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />
        <EuiFlexGroup gutterSize='none'>
          <EuiFlexItem>
            <label>Description</label>
            <EuiFieldText
              icon='documentEdit'
              fullWidth
              placeholder='Description'
              onChange={e => updateField('description', e.target.value)}
              value={tag.description}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    )
  }

  return (
    <>
      <EuiOverlayMask>
        <EuiModal
          style={{ maxWidth: 900, width: 900, height: '85vh', textAlign: 'center' }}
          onClose={onClose}
          className='admin-modal'
        >
          {renderHeader()}

          <EuiModalBody className='admin-modal-body'>
            <EuiForm className='admin-form'>
              <EuiSpacer size='s' />

              {renderName()}

              <EuiSpacer size='xl' />

              <EuiFlexGroup>
                <EuiFlexItem>
                  <label>Rules</label>
                  <TagRules />
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiSpacer />

            </EuiForm>
          </EuiModalBody>

          <EuiSpacer />

        </EuiModal>
      </EuiOverlayMask>
      {cancelModal}
    </>
  )
}
