import React from 'react'
import './health_indicator.css'

import { EuiFlexItem, EuiHealth, EuiToolTip } from '@elastic/eui'

export const HealthIndicator = ({ health, message }) => {
  let color = healthBarColor(health)

  return (
    <EuiFlexItem grow={false} className='HealthIndicator'>
      <EuiToolTip position='top' content={message || 'Status Unknown'}>
        <EuiHealth color={color} />
      </EuiToolTip>
    </EuiFlexItem>
  )
}

export const healthBarColor = (healthMeter) => {
  let color

  switch (healthMeter) {
    case 'good':
      color = 'success'
      break
    case 'warning':
      color = 'warning'
      break
    case 'critical':
      color = 'danger'
      break
    case 'unknown':
      color = '#c6c9d7'
      break
    default:
      color = 'rgba(0,0,0,0)'
  }
  
  return color
}
