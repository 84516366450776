import React, { useState, useContext } from 'react'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiButtonEmpty,
} from '@elastic/eui'

import { CreateRule } from './create_rule'
import { RulesList } from './rules_list'
import { AppContext } from '../../app'
import { AdminPageContext } from './admin_page'

export const UserRules = ({ rules, setRules }) => {
  const { projects, toaster } = useContext(AppContext)
  const { adminState, updateUser } = useContext(AdminPageContext)

  const user = adminState.activeUser

  const [displayingCreateRule, setDisplayingCreateRule] = useState(false)

  const getResourceName = () => {
    if (projects) {
      if (projects.length) {
        return projects[0].name
      }
    }
    return null
  }

  const [newRule, setNewRule] = useState({
    principalType: 'user',
    principalId: user.id ? user.id : '',
    resourceType: 'project',
    resourceName: getResourceName(),
    action: 'read',
    effect: 'allow',
  })

  const displayCreateRule = () => {
    setDisplayingCreateRule(true)
  }

  const hideCreateRule = () => {
    setDisplayingCreateRule(false)
  }

  const updateNewRule = (rule) => {
    setNewRule({ ...rule })
  }

  const createRule = (rule) => {
    const updatedRules = [...rules, rule]
    updateUser({ ...user, rules: [...updatedRules] })
    setRules(updatedRules)
    hideCreateRule()
  }

  const sortRule = (rule) => {
    return Object.keys(rule)
      .sort()
      .reduce((obj, key) => {
        obj[String(key)] = rule[String(key)]
        return obj
      }, {})
  }

  const checkRuleDuplicate = (rule) => {
    if (!rule.resourceName) {
      toaster({
        color: 'danger',
        title: 'Oops',
        text: 'Not able to add new rule.',
      })
      return
    }
    let newRule = {
      action: rule.action,
      principalId: rule.principalId,
      principalType: rule.principalType,
      resourceName: rule.resourceName,
      resourceType: rule.resourceType,
    }
    newRule = sortRule(newRule)
    for (const {
      action,
      principalId,
      principalType,
      resourceName,
      resourceType,
    } of rules) {
      const tmp = {
        action,
        principalId,
        principalType,
        resourceName,
        resourceType,
      }
      const sortedTmp = sortRule(tmp)
      let duplicate = JSON.stringify(newRule) === JSON.stringify(sortedTmp)
      if (duplicate) {
        toaster({
          color: 'danger',
          title: 'Oops',
          text: 'User rule already exists.',
        })
        return
      } else {
        continue
      }
    }
    createRule(rule)
  }

  const removeRule = (ruleIndex) => {
    const updatedRules = [...rules]
    updatedRules.splice(ruleIndex, 1)
    updateUser({ ...user, rules: updatedRules })
    setRules(updatedRules)
  }

  const renderAction = () => {
    if (adminState.userModal === 'view') {
      return (
        <></>
      )
    }
    return (
      <>
        {displayingCreateRule ? (
          <EuiFlexGroup gutterSize='s'>
            <EuiFlexItem>
              <EuiButtonEmpty
                size='s'
                onClick={() => hideCreateRule()}
                iconType='cross'
              >
                Cancel
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiButtonEmpty
                size='s'
                color='success'
                iconType='check'
                onClick={() => checkRuleDuplicate(newRule)}
              >
                Add
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
        ) : (
          <EuiButtonEmpty
            size='s'
            color='primary'
            iconType='listAdd'
            onClick={() => displayCreateRule()}
          >
            Add Rule
          </EuiButtonEmpty>
        )}
      </>      
    )
  }

  return (
    <div>
      <EuiFlexGroup
        gutterSize='m'
        justifyContent='flexEnd'
        alignItems='flexEnd'
      >
        <EuiFlexItem grow={false}>
          {renderAction()}
        </EuiFlexItem>
      </EuiFlexGroup>

      {displayingCreateRule && (
        <EuiFlexGroup>
          <EuiFlexItem>
            <EuiPanel>
              <CreateRule
                newRule={newRule}
                updateNewRule={updateNewRule}
                createRule={createRule}
              />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
      <EuiFlexGroup>
        <EuiFlexItem>
          <RulesList
            rules={rules}
            removeRule={removeRule}
            hideDelete={adminState.userModal !== 'edit' ? true : false}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}
